import React, {useContext, useEffect, useState} from "react";
import {DatePicker, Input, Modal, Select, Row, Col} from "antd";

import moment from "moment";
import MenuButton from "../../../../buttons/MenuButton";
import FineManualPaymentsTab from "./FineManualPaymentsTab";
import FineTransactionExecutionHistoryTab from "./FineTransactionExecutionHistoryTab";
import {TemplatePaymentSource} from "../../../../common/AppEnums";
import UserProfileContext from "../../../../../services/UserProfileContext";
import './EditFineTransactionExecutionDialog.css'

const { TextArea } = Input;
const { Option } = Select;

const rowStyle = {padding: '8px'};

export default function EditFineTransactionExecutionDialog({visible, record, driver, onOK, onCancel, onUpdate}) {
  let userProfile = useContext(UserProfileContext);

  let [editedRecord, setEditedRecord] = useState({});
  let [recordChanges, setRecordChanges] = useState({});
  let [selectedTab, setSelectedTab] = useState("1");

  useEffect(() => {
    setRecordChanges({});
    if(visible && record) {
      setEditedRecord(record);
    } else {
      setEditedRecord({});
    }
  }, [visible, record]);

  const canChangeFineBalance = () => {
    return userProfile.permissions.some(el => el === "change_fine_balance");
  };

  function draw() {
    return (
      <Modal
        className="edit-fine-modal-style"
        title="Рассрочка штрафа"
        visible={visible}
        okText={'OK'}
        destroyOnClose={true}
        onOk={() => {
          onUpdate(recordChanges);
          onOK();
        }}
        onCancel={onCancel}
      >
        <div>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Описание</Col>
            <Col md={21}>
              <Input
                value={editedRecord?.payment_template?.title}
                disabled={editedRecord?.execution_status === "completed"}
                onChange={(e) => {
                  const val = e.target.value;
                  setRecordChanges({...recordChanges, "payment_template_title": val});
                  setEditedRecord(prevState => {
                    prevState.payment_template.title = val;
                    return prevState;
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Комментарий</Col>
            <Col md={21}>
              <TextArea
                value={editedRecord?.payment_template?.comment}
                rows={3}
                disabled={editedRecord?.execution_status === "completed"}
                onChange={(e) => {
                  const val = e.target.value;
                  setRecordChanges({...recordChanges, "payment_template_comment": val});
                  setEditedRecord(prevState => {
                    prevState.payment_template.comment = val;
                    return prevState;
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Счет</Col>
            <Col md={21}>
              <Select
                value={editedRecord?.payment_template?.payment_source}
                style={{width: '100%'}}
                disabled={!canChangeFineBalance() || editedRecord?.execution_status === "completed"}
                onChange={(val, option) => {
                  setRecordChanges({...recordChanges, "payment_template_source": val});
                  setEditedRecord(prevState => {
                    prevState.payment_template.payment_source = val;
                    return prevState;
                  });
                }}>
                {
                  TemplatePaymentSource.filter(el => el.value !== "summary").map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                }
              </Select>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Дата начала списания</Col>
            <Col md={21}>
              <DatePicker
                disabled={true}
                format="DD.MM.YYYY HH:mm:ss"
                value={record.start_dt ? moment(record.start_dt + "T" + record.execution_time) : null}
              />
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Сумма</Col>
            <Col md={4}>
              <Input disabled={true} value={record.payment_template.limit}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Списано</Col>
            <Col md={4}>
              <Input disabled={true} value={record.paid}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Наличными</Col>
            <Col md={4}>
              <Input disabled={true} value={Number(record.cash_income ?? 0).toFixed(2)}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Остаток</Col>
            <Col md={4}>
              <Input disabled={true} value={Number(record.balance).toFixed(2)}/>
            </Col>
          </Row>

          <MenuButton value = "Внесения наличными" statusBtn={selectedTab === "1"} onClick={() => setSelectedTab("1")}/>
          <MenuButton value = "Списания" statusBtn={selectedTab === "2"} onClick={() => setSelectedTab("2")}/>

          {record && selectedTab === "1" ? (<FineManualPaymentsTab record={record} driver={driver} onPaymentCreated={() => { onUpdate({}) }}/>) : null}
          {record && selectedTab === "2" ? (<FineTransactionExecutionHistoryTab record={record} driver={driver} />) : null}

        </div>
      </Modal>
    );
  }

  return visible && draw();
}
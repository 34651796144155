import React, {useEffect, useState} from "react";
import {message, Select, Space, Checkbox} from "antd";
import {apiClient} from "../../../../services/ApiClient";

const { Option } = Select;

export default function MenuAccessManagementTab({hash}) {
  let [update, setUpdate] = useState(0);
  let [groups, setGroups] = useState([]);
  let [menuItems, setMenuItems] = useState([]);
  let [allowedMenu, setAllowedMenu] = useState([]);
  let [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    setSelectedGroup(null);
    apiClient.getGroups()
      .then(res => {
        setGroups(res.records);
      })
      .catch(err => {
        message.error(err);
      });
  }, [hash]);

  useEffect(() => {
    if(selectedGroup) {
      apiClient.getGroupMenuAccess(selectedGroup.id)
        .then(res => {
          setAllowedMenu(res.records);
        })
        .catch(err => {
          message.error(err);
        });

    } else {
      setAllowedMenu([]);
    }
  }, [selectedGroup, update, hash]);

  useEffect(() => {
    apiClient.getRootMenuItems()
      .then(res => {
        setMenuItems(res.records);
      })
      .catch(err => {
        message.error(err);
      });
  }, [update, hash]);

  const updateMenuAccess = (menu, checked) => {
    if(checked) {
      apiClient.addGroupMenuAccess(selectedGroup.id, menu.id)
        .then(res => {
          setUpdate(u => u + 1);
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      apiClient.removeGroupMenuAccess(selectedGroup.id, menu.id)
        .then(res => {
          setUpdate(u => u + 1);
        })
        .catch(err => {
          message.error(err);
        });
    }
  };

  return (
    <>
      <div style={{marginBottom: 16}}>
        <Space direction="horizontal">
          <Select
            placeholder="Выберите роль"
            value={selectedGroup ? selectedGroup.id : null}
            style={{width: 300}}
            onChange={(val, option) => {
              let group = groups.find(g => g.id === val);
              if(group) {
                setSelectedGroup(group)
              }
            }}>
            {
              groups.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)
            }
          </Select>
        </Space>
      </div>
      {
        menuItems.map(menu => (
          <div style={{margin: '5px 0'}} key={menu.id}>
            <Checkbox
              onChange={(e) => { updateMenuAccess(menu, e.target.checked) }}
              checked={allowedMenu.some(e => e.id === menu.id)}
              disabled={!selectedGroup}>
              {menu.title}
            </Checkbox>
          </div>
        ))
      }
    </>
  )
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {
  DatePicker,
  message,
  Space,
  Table
} from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function SupplyHoursTab({driver, active}) {

  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [filters, setFilters] = useState({});

  useEffect(() => {
    if(driver && driver.id > 0 && active) {
      setLoading(true);
      apiClient.getDriverSupplyHours(driver.id, filters)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [driver, active, update, filters]);

  return (
    <>
      <div style={{marginBottom: 16}}>
        <Space style={{float: "right", marginBottom: 8}} direction="horizontal">
          <RangePicker
            showTime={false}
            format="DD.MM.YYYY"
            style={{width: 350}}
            onChange={(dates) => setFilters({...filters, fromDate: dates ? dates[0].format("YYYY-MM-DD") : null, toDate: dates ? dates[1].format("YYYY-MM-DD") : null})}
          />
        </Space>
      </div>
      <Table
        loading={loading}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: 'Дата',
            defaultSortOrder: "descend",
            render: (text, record, index) => {
              return `${moment(record.day_dt).format('DD.MM.YYYY')}`;
            }
          },
          {
            title: 'План (часов)',
            render: (text, record, index) => {
              return record.supply_plan
                ? parseFloat(record.supply_plan / 3600).toFixed(2)
                : 0;
            }
          },
          {
            title: 'Факт (часов)',
            render: (text, record, index) => {
              return record.supply_fact ? parseFloat(record.supply_fact / 3600).toFixed(2) : 0;
            }
          },
          {
            title: 'Сумма списания',
            render: (text, record, index) => {
              return record.penalty_amount;
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />
    </>
  )
}
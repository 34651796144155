import React, {useEffect, useState} from "react";
import {Button, Col, Input, Modal, Row, Space, message} from "antd";
import moment from "moment";

const {TextArea} = Input;

const rowStyle = {padding: '8px'};

export default function PaymentExecutionReturnDialog({record, onOK, onCancel}) {

  let [amount, setAmount] = useState('');
  let [comment, setComment] = useState('');

  useEffect(() => {
    console.log(record);

    let finalAmount = parseFloat(record.amount);
    if(record.refunds) {
      record.refunds.forEach(el => {
        finalAmount = finalAmount - parseFloat(el.amount).toFixed(2);
      });
    }
    setAmount(finalAmount);
    if(record.payment_execution?.payment_template?.title) {
      setComment(`Возврат за ${moment(record.event_dt).format('DD.MM.YYYY')}: ${record.payment_execution?.payment_template?.title}`);
    } else if (record.category) {
      setComment(`Возврат за ${moment(record.event_dt).format('DD.MM.YYYY')}: ${record.category.label}`);
    } else if (record.comment) {
      setComment(`Возврат за ${moment(record.event_dt).format('DD.MM.YYYY')}: ${record.comment}`);
    } else {
      setComment(`Возврат за ${moment(record.event_dt).format('DD.MM.YYYY')}`);
    }

  }, [record]);

  return (
    <Modal
      title={"Возврат платежа"}
      visible={true}
      width={800}
      onOk={() => {
        let finalAmount = parseFloat(record.amount);
        if(record.refunds) {
          record.refunds.forEach(el => {
            finalAmount = finalAmount - parseFloat(el.amount).toFixed(2);
          });
        }
        if(!amount || parseFloat(amount) < 0 || parseFloat(amount) > finalAmount) {
          console.log(amount, record.amount);
          message.error('Укажите сумму возврата в пределах суммы платежа');
          return;
        }
        onOK(amount, comment);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Row gutter={[8, 0]} align="middle" style={rowStyle}>
        <Col md={6}>Дата</Col>
        <Col md={12}>
          {`${moment(record.event_dt).format('DD.MM.YYYY')}`}
        </Col>
      </Row>
      {
        record.comment && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={6}>Назначение</Col>
            <Col md={12}>
              {record.comment}
            </Col>
          </Row>
        )
      }
      {
        record.payment_execution && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={6}>Условия работы</Col>
            <Col md={12}>
              {record.payment_execution?.work_condition?.title}
            </Col>
          </Row>
        )
      }
      {
        record.payment_execution && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={6}>Шаблон списаний</Col>
            <Col md={12}>
              {record.payment_execution?.payment_template?.title}
            </Col>
          </Row>
        )
      }
      {
        record.payment_execution && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={6}>Комментарий</Col>
            <Col md={12}>
              {record.payment_execution?.payment_template?.comment}
            </Col>
          </Row>
        )
      }
      {
        record.comment && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={6}>Назначение</Col>
            <Col md={12}>
              {record.comment}
            </Col>
          </Row>
        )
      }

      <Row gutter={[8, 0]} align="middle" style={rowStyle}>
        <Col md={6}>Сумма возврата</Col>
        <Col md={12}>
          <Space
            direction={"horizontal"}
          >
            <Input
              placeholder="руб"
              value={amount}
              onChange={(e) => {setAmount(e.target.value);}}
            />
            <Button onClick={() => setAmount(record.amount * 0.5)}>50%</Button>
            <Button onClick={() => setAmount(record.amount)}>100%</Button>
          </Space>
        </Col>
      </Row>
      <Row gutter={[8, 0]} align="middle" style={rowStyle}>
        <Col md={6}>Комментарий</Col>
        <Col md={12}>
          <TextArea rows={4} onChange={(e) => { setComment(e.target.value) }} value={comment} placeholder="Комментарий"/>
        </Col>
      </Row>
    </Modal>
  )
}
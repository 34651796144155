import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row} from "antd";
import {apiClient} from "../../../../../services/ApiClient";

const { TextArea } = Input;

export default function MessageTemplates({active}) {

  let [rentInfo, setRentInfo] = useState('');
  let [carChangeInfo, setCarChangeInfo] = useState('');
  let [dtpInfo, setDtpInfo] = useState('');

  useEffect(() => {
    apiClient.getSystemProperty("bots.rent_info")
      .then(res => {
        setRentInfo(res.value);
      });
    apiClient.getSystemProperty("bots.car_change_info")
      .then(res => {
        if(res.value) {
          setCarChangeInfo(res.value);
        }
      });
    apiClient.getSystemProperty("bots.dtp_info")
      .then(res => {
        if(res.value) {
          setDtpInfo(res.value);
        }
      });
  }, [active])

  return (
    <>
      <Row gutter={[0, 16]} style={{margin: '24px 0'}}>
        <Col span={3}>
          <h4>Оплатить аренду</h4>
        </Col>
        <Col span={8}>
          <TextArea
            style={{marginBottom: 10}}
            rows={6}
            value={rentInfo}
            onChange={(e) => { setRentInfo(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={{margin: '24px 0'}}>
        <Col span={3}>
          <h4>Поменять, либо сдать авто</h4>
        </Col>
        <Col span={8}>
          <TextArea
            style={{marginBottom: 10}}
            rows={6}
            value={carChangeInfo}
            onChange={(e) => { setCarChangeInfo(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={{margin: '24px 0'}}>
        <Col span={3}>
          <h4>ДТП</h4>
        </Col>
        <Col span={8}>
          <TextArea
            style={{marginBottom: 10}}
            rows={6}
            value={dtpInfo}
            onChange={(e) => { setDtpInfo(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={{margin: '24px 0'}}>
        <Col span={3}>
          <Button
            type="primary"
            onClick={() => {
              Promise.all([
                apiClient.setSystemProperty('bots.rent_info', {value: rentInfo})
                  .catch(err => {
                    message.error(err);
                  }),
                apiClient.setSystemProperty('bots.dtp_info', {value: dtpInfo})
                  .catch(err => {
                    message.error(err);
                  }),
                apiClient.setSystemProperty('bots.car_change_info', {value: carChangeInfo})
                  .catch(err => {
                    message.error(err);
                  }),
              ]).finally(() => {
                message.info("Изменения сохранены")
              })
            }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>

  )
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {Button, Dropdown, Menu, message, Modal, Space, Table} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditFilled,
  PrinterOutlined,
  QuestionOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import moment from "moment";
import {RentContractStatus} from "../../../../common/AppEnums";
import RentContractEdit from "../../../park/contract/RentContractEdit";
import PrintFormSelectDialog from "../../../common/PrintFormSelectDialog";
import UpdateButton from "../../../../buttons/UpdateButton";

const CMD_REMOVE = "remote";

export default function DriverContractsTab({active, driver}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [selected, setSelected] = useState(null);
  let [printRecord, setPrintRecord] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);

  useEffect(() => {
    if(active && driver && driver.id > 0) {
      setLoading(true);
      apiClient.getDriverRentContracts(driver.id)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [active, driver, update]);

  const handlePrint = (template, contract) => {
    apiClient.renderRentContractTemplate(contract.id, template.id)
      .then(res => {
        window.open(res.record.url, '_blank');
      })
      .catch(err => message.error(err));
  };

  const executeSelectedRecordsOperation = operationKey => {
    if(operationKey === CMD_REMOVE) {
      Modal.confirm({
        title:  "Удаление",
        content: "Вы уверены, что хотите удалить выбранные записи?",
        icon: <DeleteOutlined/>,
        visible: true,
        onOk() {
          setLoading(true);
          apiClient.removeRentContracts(selectedRecords)
            .then(res => {
              message.success("Записи удалены");
              setSelectedRecords([]);
              setUpdate(prevState => prevState + 1);
            })
            .catch(err => message.error("Ошибка"))
            .finally(() => setLoading(false));
        },
      });
    }
  };

  return (
    <>
      <div style={{marginBottom: 16}}>
        <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
          <UpdateButton onClick={() => { setUpdate(u => u + 1) }} />
          <Button onClick={() => { setSelected({}) }} type={"primary"}>
            Создать
          </Button>
          {
            selectedRecords.length > 0
              ? (
                <>
                  <Dropdown
                    disabled={selectedRecords.length < 1}
                    overlay={(
                      <Menu onClick={(e) => {
                        executeSelectedRecordsOperation(e.key);
                      }}>
                        <Menu.Item key={CMD_REMOVE} icon={<DeleteOutlined/>}>
                          Удалить
                        </Menu.Item>
                      </Menu>
                    )}>
                    <Button style={{textAlign: "left"}}>
                      С выбранными <DownOutlined/>
                    </Button>
                  </Dropdown>
                </>
              ) : null
          }
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1400}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          Table.SELECTION_COLUMN,
          {
            title: '',
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  <Button
                    type="link"
                    icon={<EditFilled/>}
                    disabled={!record?.id}
                    onClick={() => {
                      setLoading(true);
                      apiClient.getRentContract(record.id)
                        .then(res => {
                          setSelected(res.record);
                        })
                        .catch(err => message.error(err))
                        .finally(() => setLoading(false));
                    }}
                  />
                  <Button type={"link"} onClick={() => { setPrintRecord(record) }}>
                    <PrinterOutlined/>
                  </Button>
                </Space>
              )
            }
          },
          {
            title: 'Дата',
            defaultSortOrder: "descend",
            render: (text, record, index) => {
              return record.contract_dt ? `${moment(record.contract_dt).format('DD.MM.YYYY')}` : "";
            }
          },
          {
            title: 'Номер',
            dataIndex: 'contract_number',
          },
          {
            title: 'Статус',
            render: (text, record, index) => {
              return RentContractStatus.filter(el => el.id === record.status)[0].title;
            }
          },
          {
            title: 'ТС',
            render: (text, record, index) => {
              return record.vehicle
                ? (
                  <span>{record.vehicle.name}</span>
                ) : "";
            }
          },
          {
            title: 'Начало действия',
            render: (text, record, index) => {
              return record.contract_start_dt ? `${moment(record.contract_start_dt).format('DD.MM.YYYY')}` : "";
            }
          },
          {
            title: 'Окончание действия',
            render: (text, record, index) => {
              return record.contract_end_dt ? `${moment(record.contract_end_dt).format('DD.MM.YYYY')}` : "";
            }
          },
          {
            title: 'Стоимость аренды в сутки',
            dataIndex: 'rent_day_price',
          },
          {
            title: 'Организация',
            dataIndex: 'company',
          },
          {
            title: 'Арендодатель',
            dataIndex: 'owner',
            render: (text, record, index) => {
              return record.owner ? record.owner.name : "";
            }
          },
          {
            title: 'Ответственный',
            dataIndex: 'responsible',
            render: (text, record, index) => {
              return record.responsible ? `${record.responsible?.first_name} (${record.responsible?.username})` : "";
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
        rowSelection={{
          columnWidth: '50px',
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRecords,
          onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys);
          }
        }}
      />

      {
        selected !== null
          ? (
            <RentContractEdit
              title={"Договор аренды"}
              visible={true}
              driver={driver}
              vehicle={selected.vehicle}
              contract={selected}
              onOk={(record) => {
                setLoading(true);
                if(selected.id > 0) {
                  apiClient.updateRentContract(record.id, record)
                    .then(res => {
                      setSelected(null);
                      setUpdate(prevState => prevState + 1);
                      message.info("Договор успешно обновлен");
                    })
                    .catch(err => message.error(err))
                    .finally(() => setLoading(false));
                } else {
                  apiClient.createRentContract(record)
                    .then(res => {
                      setSelected(null);
                      setUpdate(prevState => prevState + 1);
                      message.info("Договор успешно создан");
                    })
                    .catch(err => message.error(err))
                    .finally(() => setLoading(false));
                }
                if(record.work_condition && record.work_condition_dt && record.driver) {
                  Modal.confirm({
                    title:  "Подтверждение",
                    content: `Назначить ${record.driver.last_name} ${record.driver.first_name} ${record.driver.middle_name} условия работы ${record.work_condition.title}?`,
                    icon: <QuestionOutlined/>,
                    visible: true,
                    onOk() {
                      apiClient.updateDriverWorkCondition(record.driver.id, {id: record.work_condition.id, date: record.work_condition_dt})
                        .then(() => {
                          message.success("Условия работы назначены");
                        })
                        .catch(err => {
                          message.error('Ошибка, не удалось выполнить операцию');
                        });
                    }
                  });
                }
              }}
              onClose={() => {
                setSelected(null);
              }}
            />
          ) : null
      }

      <PrintFormSelectDialog
        visible={printRecord !== null}
        modelClass={"crm_pm_vehicle_contract"}
        unitType={printRecord?.owner?.type}
        title={"Выберите печатную форму"}
        onOk={(template) => {
          handlePrint(template, printRecord);
          setPrintRecord(null);
        }}
        onCancel={() => {
          setPrintRecord(null);
        }}
      />
    </>
  );
}
import React, {useEffect, useState} from "react";
import {
  AutoComplete,
  Button,
  Col,
  Collapse,
  DatePicker,
  Input,
  message, Modal, Popover,
  Row,
  Select,
  Space,
  Switch,
  Tooltip
} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import moment from "moment";
import {UnitTypes, PaymentMethod, PhotoControlMode} from "../../../../common/AppEnums";
import {DeleteOutlined, InfoCircleTwoTone, LoadingOutlined, QuestionOutlined} from "@ant-design/icons";
import {daDataService} from "../../../../../services/DaDataService";
import DriverParkChangeDialog from "./DriverParkChangeDialog";

const { Panel } = Collapse;
const {Option} = Select;

const rowStyle = {padding: '4px'};
const titleColStyle = {textAlign: "left", padding: 4, paddingLeft: 16};
const FIRST_COL_SIZE = 3;
const NOT_EDITABLE_FIELDS = [
  "last_name",
  "first_name",
  "middle_name",
  "phone",
  "license",
  "license_issued",
  "yandex_service_id",  // пока не удалять, для изменения нужны дополнительные действия на бэке
];

export default function DriverPropertiesTab({active, driver, onUpdated, onDeleted}) {

  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState(null);
  let [vehicles, setVehicles] = useState([]);
  let [workRules, setWorkRules] = useState([]);
  let [suggestions, setSuggestions] = useState({});
  let [parkChangeVisible, setParkChangeVisible] = useState(false);

  useEffect(() => {
    if(active && driver) {
      console.log("[DriverPropertiesTab] [Init] driver:", driver);
      setRecord({
        ...driver
      });
      apiClient.getVehicles({owned: true, documents_disabled: false})
        .then(res => {
          setVehicles(res.records);
        })
        .catch(err => message.error(err));
      apiClient.getParkWorkRulesForDriver(driver.id)
        .then(res => {
          setWorkRules(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [active, driver]);

  useEffect(() => {
    console.log(record);
  }, [record]);

  const isDisabled = (fieldName) => {
    return (record.yandex_service_id || record.citymobil_service_id) && NOT_EDITABLE_FIELDS.indexOf(fieldName) >= 0;
  };

  const saveChanges = () => {
    setLoading(true);
    apiClient.updateDriverDetails(driver.id, {...record})
      .then(res => {
        message.info("Изменения сохранены");
        onUpdated();
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); })
  };

  const form = () => {
    return (
      <>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Общие" key="1">
            {/* Условия работы */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Условия (Яндекс)</Col>
              <Col span={8}>
                <Select
                  style={{ width: '100%' }}
                  disabled={isDisabled("yandex_work_rule")}
                  value={record.yandex_work_rule?.id}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, yandex_work_rule: workRules.find(el => el.id === value)});
                    } else {
                      setRecord({...record, yandex_work_rule: value});
                    }
                  }}
                  allowClear={false}
                >
                  {workRules.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)}
                </Select>
              </Col>
            </Row>

            {/* Тип */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Тип</Col>
              <Col span={4}>
                <Select
                  style={{ width: '100%' }}
                  disabled={isDisabled("type")}
                  value={record.type}
                  onChange={(value) => { setRecord({...record, type: value}); }}
                  allowClear={false}
                >
                  {UnitTypes.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)}
                </Select>
              </Col>
            </Row>

            {/* ФИО */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Фамилия</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("last_name")}
                  style={{width: "100%"}}
                  placeholder="Фамилия"
                  value={record.last_name}
                  onChange={(e) => { setRecord({...record, last_name: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Имя</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("first_name")}
                  style={{width: "100%"}}
                  placeholder="Имя"
                  value={record.first_name}
                  onChange={(e) => { setRecord({...record, first_name: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Отчество</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("middle_name")}
                  style={{width: "100%"}}
                  placeholder="Отчество"
                  value={record.middle_name}
                  onChange={(e) => { setRecord({...record, middle_name: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Работает</Col>
              <Col span={3}>
                <Switch
                  disabled={isDisabled("is_working")}
                  title={"Работает"}
                  onChange={(checked) => { setRecord({...record, is_working: checked }); }}
                  checked={record.is_working}
                />
              </Col>
            </Row>

            {/* Дата рождения + Место рождения */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Дата рождения</Col>
              <Col span={4}>
                <DatePicker
                  disabled={isDisabled("birth_dt")}
                  allowClear={true}
                  format="DD.MM.YYYY"
                  placeholder="Дата рождения"
                  value={record.birth_dt ? moment(record.birth_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, birth_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                  style={{width: '100%'}}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Место</Col>
              <Col span={15}>
                <AutoComplete
                  allowClear={true}
                  disabled={isDisabled("birth_place")}
                  placeholder="Место рождения"
                  options={suggestions.birth_place || []}
                  value={record?.birth_place}
                  style={{width: "100%"}}
                  onChange={(data) => {
                    setRecord({...record, birth_place: data});
                    setSuggestions({...suggestions, birth_place: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 1 && !text.endsWith(" ")) {
                      daDataService.suggestAddress(text)
                        .then(res => {
                          setSuggestions({...suggestions, birth_place: res.suggestions.map(el => {return {value: el.value}})});
                        })
                    }
                  }}
                />
              </Col>
            </Row>

            {/* Телефон + Доп.Телефон + WhatsApp */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Телефон</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("phone")}
                  style={{width: "100%"}}
                  placeholder="Телефон"
                  value={record.phone}
                  onChange={(e) => { setRecord({...record, phone: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Доп номер</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("phone_secondary")}
                  style={{width: "100%"}}
                  placeholder="Доп номер"
                  value={record.phone_secondary}
                  onChange={(e) => { setRecord({...record, phone_secondary: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Whatsapp</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("phone_whatsapp")}
                  style={{width: "100%"}}
                  placeholder="Whatsapp"
                  value={record.phone_whatsapp}
                  onChange={(e) => { setRecord({...record, phone_whatsapp: e.target.value}) }}
                />
              </Col>
            </Row>

            {/* email */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Email</Col>
              <Col span={4}>
                <AutoComplete
                  allowClear={true}
                  disabled={isDisabled("email")}
                  placeholder="Email"
                  options={suggestions.email || []}
                  value={record?.email}
                  style={{width: "100%"}}
                  onChange={(data) => {
                    setRecord({...record, email: data});
                    setSuggestions({...suggestions, email: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 1 && !text.endsWith(" ")) {
                      daDataService.suggestEmail(text)
                        .then(res => {
                          setSuggestions({...suggestions, email: res.suggestions.map(el => {return {value: el.value}})});
                        })
                    }
                  }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>СНИЛС</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("snils_number")}
                  style={{width: "100%"}}
                  value={record.snils_number}
                  onChange={(e) => { setRecord({...record, snils_number: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>ИНН</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("inn")}
                  style={{width: "100%"}}
                  value={record.inn}
                  onChange={(e) => { setRecord({...record, inn: e.target.value}) }}
                />
              </Col>
            </Row>

            {/* факт. адрес */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Факт. адрес</Col>
              <Col span={21}>
                <AutoComplete
                  allowClear={true}
                  disabled={isDisabled("act_address")}
                  placeholder="Фактический адрес"
                  options={suggestions.act_address || []}
                  value={record?.act_address}
                  style={{width: "100%"}}
                  onChange={(data) => {
                    setRecord({...record, act_address: data});
                    setSuggestions({...suggestions, act_address: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 1 && !text.endsWith(" ")) {
                      daDataService.suggestAddress(text)
                        .then(res => {
                          setSuggestions({...suggestions, act_address: res.suggestions.map(el => {return {value: el.value}})});
                        })
                    }
                  }}
                />
              </Col>
            </Row>

            {/* рег. адрес */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Рег. адрес</Col>
              <Col span={21}>
                <AutoComplete
                  allowClear={true}
                  disabled={isDisabled("reg_address")}
                  placeholder="Адрес регистрации"
                  options={suggestions.reg_address || []}
                  value={record?.reg_address}
                  style={{width: "100%"}}
                  onChange={(data) => {
                    setRecord({...record, reg_address: data});
                    setSuggestions({...suggestions, reg_address: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 1 && !text.endsWith(" ")) {
                      daDataService.suggestAddress(text)
                        .then(res => {
                          setSuggestions({...suggestions, reg_address: res.suggestions.map(el => {return {value: el.value}})});
                        })
                    }
                  }}
                />
              </Col>
            </Row>

            {/* гражданство */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Гражданство</Col>
              <Col span={5}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("citizenship")}
                  style={{width: "100%"}}
                  placeholder="Гражданство"
                  value={record.citizenship}
                  onChange={(e) => { setRecord({...record, citizenship: e.target.value}) }}
                />
              </Col>
            </Row>

            {/* Паспорт: серия/номер + дата выдачи */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Паспорт</Col>
              <Col span={5}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("passport_number")}
                  style={{width: "100%"}}
                  placeholder="Серия и номер"
                  value={record.passport_number}
                  onChange={(e) => { setRecord({...record, passport_number: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Выдан</Col>
              <Col span={4}>
                <DatePicker
                  allowClear={true}
                  disabled={isDisabled("passport_issue_dt")}
                  format="DD.MM.YYYY"
                  placeholder="Дата выдачи"
                  value={record.passport_issue_dt ? moment(record.passport_issue_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, passport_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                  style={{width: '100%'}}
                />
              </Col>
            </Row>

            {/* Паспорт: кем выдан + код подразделения */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Кем выдан</Col>
              <Col span={13}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("passport_issuer_title")}
                  style={{width: "100%"}}
                  placeholder="Кем выдан"
                  value={record.passport_issuer_title}
                  onChange={(e) => { setRecord({...record, passport_issuer_title: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Код подр.</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("passport_issuer_code")}
                  style={{width: "100%"}}
                  placeholder="Код подразделения"
                  value={record.passport_issuer_code}
                  onChange={(e) => { setRecord({...record, passport_issuer_code: e.target.value}) }}
                />
              </Col>
            </Row>

            {/* ВУ: Серия и номер + Дата выдачи + Срок действия */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>ВУ</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("license")}
                  style={{width: "100%"}}
                  placeholder="Серия и номер"
                  value={record.license}
                  onChange={(e) => { setRecord({...record, license: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Выдано</Col>
              <Col span={4}>
                <DatePicker
                  allowClear={true}
                  disabled={isDisabled("license_issued")}
                  format="DD.MM.YYYY"
                  placeholder="Дата выдачи"
                  value={record.license_issued ? moment(record.license_issued, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, license_issued: val ? val.format("YYYY-MM-DD") : null }) }}
                  style={{width: '100%'}}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Срок действия</Col>
              <Col span={4}>
                <DatePicker
                  allowClear={true}
                  disabled={isDisabled("licence_due_dt")}
                  format="DD.MM.YYYY"
                  placeholder="Срок действия"
                  value={record.licence_due_dt ? moment(record.licence_due_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, licence_due_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                  style={{width: '100%'}}
                />
              </Col>
            </Row>

            {/* ВУ: Страна + Стаж */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Страна выдачи ВУ</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("licence_country")}
                  style={{width: "100%"}}
                  placeholder="Страна выдачи ВУ"
                  value={record.licence_country}
                  onChange={(e) => { setRecord({...record, licence_country: e.target.value}) }}
                />
              </Col>
              <Col span={2} style={titleColStyle}>Стаж с</Col>
              <Col span={4}>
                <DatePicker
                  allowClear={true}
                  disabled={isDisabled("licence_experience_dt")}
                  format="DD.MM.YYYY"
                  placeholder="Стаж с"
                  value={record.licence_experience_dt ? moment(record.licence_experience_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, licence_experience_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                  style={{width: '100%'}}
                />
              </Col>
            </Row>

            {/* Взнос по депозиту */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Взнос по депозиту</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("deposit_initial")}
                  type={"number"}
                  placeholder={"Начальный взнос по депозиту"}
                  value={record.deposit_initial}
                  onChange={(e) => { setRecord({...record, deposit_initial: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ТС */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>ТС</Col>
              <Col span={8}>
                <Select
                  showSearch
                  allowClear={true}
                  placeholder={"Выберите ТС"}
                  style={{ width: '100%' }}
                  disabled={isDisabled("current_rent")}
                  value={record.current_rent?.id}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  onClear={() => {
                    setRecord({...record, current_rent: null});
                  }}
                  onChange={(value) => {
                    if(!value) {
                      setRecord({...record, current_rent: null});
                    } else {
                      // тс в списке
                      const vehicle = vehicles.find(el => el.id === value);
                      // получить детализацию
                      setLoading(true);
                      apiClient.getVehicleById(value)
                        .then(res => {
                          Modal.confirm({
                            title: "Подтверждение",
                            icon: <QuestionOutlined/>,
                            visible: true,
                            content: res.record?.current_driver?.id > 0
                              ? (
                                <p>ТС `{res.record.reg_number}` уже арендуется водителем `{res.record.current_driver.name}`. Продолжить?</p>
                              ) : (
                                <p>Изменить арендуемое ТС?</p>
                              ),
                            onOk() {
                              setRecord({...record, current_rent: vehicle});
                            }
                          });
                        })
                        .catch(err => message.error(err))
                        .finally(() => { setLoading(false); })
                    }
                  }}
                >
                  {vehicles.map(el => <Option key={el.id} value={el.id}>{`${el.brand} ${el.model} (${el.reg_number}, ${el.registration_cert})`}</Option>)}
                </Select>
              </Col>
            </Row>

            {/* Тип оплаты */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Тип оплаты</Col>
              <Col span={4}>
                <Select
                  placeholder={"Тип оплаты"}
                  disabled={isDisabled("deposit_pay_method")}
                  style={{ width: '100%' }}
                  value={record.deposit_pay_method ? PaymentMethod.find(el => el.id === record.deposit_pay_method)?.title : null}
                  onChange={(value) => { setRecord({...record, deposit_pay_method: value}); }}
                  allowClear={false}
                >
                  {PaymentMethod.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)}
                </Select>
              </Col>
            </Row>

            {/* Номер карты/киви кошелька */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Номер карты/киви кошелька</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("pay_account")}
                  placeholder={"Номер карты/киви кошелька"}
                  value={record.pay_account}
                  onChange={(e) => { setRecord({...record, pay_account: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Комментарий */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Комментарий</Col>
              <Col span={20}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("comment")}
                  placeholder={"Комментарий"}
                  value={record.comment}
                  onChange={(e) => { setRecord({...record, comment: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Подразделение */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Подразделение</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("department")}
                  placeholder={"Подразделение"}
                  value={record.department}
                  onChange={(e) => { setRecord({...record, department: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Форма работы */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Форма работы</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("work_method")}
                  placeholder={"Форма работы"}
                  value={record.work_method}
                  onChange={(e) => { setRecord({...record, work_method: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Куратор */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Куратор</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("curator")}
                  placeholder={"Куратор"}
                  value={record.curator}
                  onChange={(e) => { setRecord({...record, curator: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Номер договора */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Номер договора</Col>
              <Col span={4}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("last_contract_number")}
                  placeholder={"Номер договора"}
                  value={record.last_contract_number}
                  onChange={(e) => { setRecord({...record, last_contract_number: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>ФИО родственника/друга</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("contact_fio")}
                  value={record.contact_fio}
                  onChange={(e) => {
                    setRecord({...record, contact_fio: e.target.value})
                  }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Телефон родственника/друга</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("contact_phone")}
                  value={record.contact_phone}
                  onChange={(e) => {
                    setRecord({...record, contact_phone: e.target.value})
                  }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Банковские реквизиты" key="2">
            {/* Банк: наименование */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Наименование</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("bank_title")}
                  placeholder={"Наименование банка"}
                  value={record.bank_title}
                  onChange={(e) => { setRecord({...record, bank_title: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Банк: Расчетный счет */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Расчетный счет</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("bank_check_account")}
                  type={"number"}
                  placeholder={"Расчетный счет"}
                  value={record.bank_check_account}
                  onChange={(e) => { setRecord({...record, bank_check_account: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Банк: Корр счет */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Корр счет</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("bank_corr_account")}
                  type={"number"}
                  placeholder={"Корр счет"}
                  value={record.bank_corr_account}
                  onChange={(e) => { setRecord({...record, bank_corr_account: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Банк: БИК */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>БИК</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("bank_bik")}
                  type={"number"}
                  placeholder={"БИК"}
                  value={record.bank_bik}
                  onChange={(e) => { setRecord({...record, bank_bik: e.target.value}) }}/>
              </Col>
            </Row>

          </Panel>
          <Panel header="Штрафы" key="6">
            {/* Начислять штрафы */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Начислять штрафы</Col>
              <Col span={8}>
                <Switch
                  title={"Начислять штрафы"}
                  onChange={(checked) => { setRecord({...record, is_fines_enabled: checked }); }}
                  checked={record.is_fines_enabled}
                />
                  <Tooltip
                    placement="top"
                    title="По умолчанию у всех новых водителей включено начисление  штрафов. 
                    При необходимости Вы можете отключить начисление штрафов водителю."
                  >
                    <QuestionCircleOutlined style={{marginLeft: 5}}/>
                  </Tooltip>
              </Col>
            </Row>
          </Panel>
          <Panel header="ООО" key="3" collapsible={!record || record.type !== "ul" ? "disabled" : "header"}>
            {/* ООО: ФИО директора */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>ФИО директора</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("director_fio")}
                  placeholder={"ФИО директора"}
                  value={record.director_fio}
                  onChange={(e) => { setRecord({...record, director_fio: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ООО: На основании чего действует директор */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>
                <Popover content={"На основании чего действует директор"}>
                  Документ <InfoCircleTwoTone/>
                </Popover>
              </Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("director_document")}
                  placeholder={"На основании чего действует директор"}
                  value={record.director_document}
                  onChange={(e) => { setRecord({...record, director_document: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ООО: Юридический адрес */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Юридический адрес</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("company_legal_address")}
                  placeholder={"Юридический адрес"}
                  value={record.company_legal_address}
                  onChange={(e) => { setRecord({...record, company_legal_address: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ООО: Фактический адрес */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Фактический адрес</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("company_act_address")}
                  placeholder={"Фактический адрес"}
                  value={record.company_act_address}
                  onChange={(e) => { setRecord({...record, company_act_address: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ООО: Номер телефона сотрудника, курирующего водителей */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>
                <Popover content={"Номер телефона сотрудника, курирующего водителей"}>
                  Куратор <InfoCircleTwoTone/>
                </Popover>
              </Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("company_curator")}
                  placeholder={"Куратор"}
                  value={record.company_curator}
                  onChange={(e) => { setRecord({...record, company_curator: e.target.value}) }}/>
              </Col>
            </Row>

            {/* ООО: EMail */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Email</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("company_email")}
                  placeholder={"Email"}
                  value={record.company_email}
                  onChange={(e) => { setRecord({...record, company_email: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Интеграции" key="4">
            {/* Yandex */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Яндекс.Таксометр ID</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  // disabled
                  disabled={isDisabled("yandex_service_id")}
                  placeholder={"Яндекс.Таксометр ID"}
                  value={record.yandex_service_id}
                  onChange={(e) => { setRecord({...record, yandex_service_id: e.target.value}) }}/>
              </Col>
            </Row>

            {/* CityMobil */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>CityMobil ID</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  // disabled
                  disabled={isDisabled("citymobil_service_id")}
                  placeholder={"CityMobil ID"}
                  value={record.citymobil_service_id}
                  onChange={(e) => { setRecord({...record, citymobil_service_id: e.target.value}) }}/>
              </Col>
            </Row>

            {/* Telegram */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Telegram ID</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("telegram_id")}
                  placeholder={"Telegram ID"}
                  value={record.telegram_id}
                  onChange={(e) => { setRecord({...record, telegram_id: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>Telegram Username</Col>
              <Col span={8}>
                <Input
                  allowClear={true}
                  disabled={isDisabled("telegram_username")}
                  placeholder={"Telegram Username"}
                  value={record.telegram_username}
                  onChange={(e) => { setRecord({...record, telegram_username: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Боты" key="5">
            {/* Вид контроля в боте Водитель */}
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={FIRST_COL_SIZE} style={titleColStyle}>
                <div>
                  <div style={{width: '85%', float: 'left'}}>
                    Вид контроля в боте Водитель
                  </div>
                  <div style={{width: '15%', float: 'right'}}>
                    <Tooltip
                      placement="top"
                      title="Настраивает порядок вопросов в боте при прохождении фотоконтроля"
                    >
                      <QuestionCircleOutlined/>
                    </Tooltip>
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <Select
                  showSearch
                  allowClear
                  style={{width: 500}}
                  value={record.photo_control_mode}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, photo_control_mode: value})
                    }
                  }}
                >
                  {
                    PhotoControlMode.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </>
    )
  };

  const isFromCandidate = () => {
    return record.is_candidate;
  };

  const deleteDriver = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить водителя?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        setLoading(true);
        apiClient.removeDriver(record.id)
          .then(res => {
            onDeleted()
          })
          .catch(err => {
            return message.error(err);
          })
          .finally(() => {
            setLoading(false);
          })
      },
    });
  };

  return (
    <>
      {
        (active && record)
          ? (
            <>
              <div>
                <Space style={{float: "right"}} direction="horizontal" align={"end"}>
                  <Button style={{marginBottom: 16}} onClick={() => saveChanges()} type={"primary"} loading={loading}>
                    Сохранить
                  </Button>
                  {
                    isFromCandidate()
                      ? (
                        <Button style={{marginBottom: 16}} onClick={() => deleteDriver()} type={"danger"} loading={loading}>
                          Удалить
                        </Button>
                      ) : null
                  }
                  <Button style={{marginBottom: 16}} onClick={() => setParkChangeVisible(true)} loading={loading}>
                    Сменить диспетчерскую
                  </Button>
                </Space>
              </div>
              <div style={{marginTop: 45}}>
                {
                  form()
                }
              </div>
              {
                parkChangeVisible && (
                  <DriverParkChangeDialog
                    record={record}
                    onOK={(selectedPark, selectedWorkRule) => {
                      setLoading(true);
                      apiClient.updateDriverPark(record.id, selectedPark, selectedWorkRule)
                        .then(() => {
                          message.info("Изменения сохранены");
                          onUpdated();
                        })
                        .catch(err => { message.error(err) })
                        .finally(() => { setLoading(false); });
                      setParkChangeVisible(false);
                    }}
                    onCancel={() => { setParkChangeVisible(false) }}/>
                )
              }
            </>
          ) : (
            <div style={{width: '300px', margin: '20px auto'}}>
              <LoadingOutlined/>
            </div>
          )
      }
    </>
  )
}
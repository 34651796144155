import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import UpdateButton from "../../../../buttons/UpdateButton";
import TaxiLicenceAuthorityEditDialog from "./TaxiLicenceAuthorityEditDialog";

export default function TaxiLicenceAuthoritySettingsTab({active}) {
  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" onClick={() => { setEditedRecord(record) }}><EditFilled/></Button>
          </Space>
        )
      }
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    if(active) {
      setLoading(true);
      let params = {
        search: search,
        order: "title"
      };
      apiClient.getTaxiLicenceAuthorities(params)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false); });
    } else {
      setRecords([]);
    }
  }, [active, search, update]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeTaxiLicenceAuthorities(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(u => u + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAdd = ({title}) => {
    setLoading(true);
    apiClient.createTaxiLicenceAuthority({title})
      .then(res => {
        message.success("Запись добавлена");
        setUpdate(u => u + 1);
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      })

  };

  const onUpdate = (recordId, params) => {
    apiClient.updateTaxiLicenceAuthority(recordId, params)
      .then(res => {
        message.success("Запись обновлена");
        setUpdate(u => u + 1);
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>
          {/*  todo: add filters */}
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            {
              selected.length > 0 && (<span>{`Выбрано записей: ${selected.length}`}</span>)
            }
            <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
            <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>
              Добавить
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить
            </Button>
          </Space>
        </div>
      </div>
      <Table
        pagination={
          {
            position: 'topRight',
            defaultPageSize: 50,
            pageSizeOptions: [25, 50, 75]
          }
        }
        rowSelection={{
          selectedRowKeys: selected,
          onChange: onSelectChange,
        }}
        size={'small'}
        columns={cols}
        scroll={{y: 600}}
        sticky
        dataSource={records}
        rowKey="id"
      />

      {
        editedRecord !== null
          ? (
            <TaxiLicenceAuthorityEditDialog
              visible={true}
              region={editedRecord}
              onOk={(p) => {
                if (p.id > 0) {
                  onUpdate(p.id, {...p});
                } else {
                  onAdd(p);
                }
                setEditedRecord(null);
              }}
              onCancel={() => {
                setEditedRecord(null)
              }}
              title={"Организация"}
            />
          ) : null
      }
    </>
  )
}
import React, {useEffect, useState} from "react";
import {Button, message, Space, Table, Modal, Input} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import GroupDialog from "./GroupDialog";
import UpdateButton from "../../../buttons/UpdateButton";
const {Search} = Input;

export default function GroupsManagementTab({hash, onUpdated, active}) {

  let [loading, setLoading] = useState(false);
  let [addGroupVisible, setAddGroupVisible] = useState(false);
  let [editedGroup, setEditedGroup] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Кол-во пользователей',
      dataIndex: 'users_count',
      align: "center",
    },
    {
      title: "Действия",
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return <Button type="dashed" icon={<EditFilled/>} onClick={() => { setEditedGroup(record) }}>Изменить</Button>
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getGroups({search: search})
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [active, update, hash, search]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeGroups(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(u => u + 1)
            onUpdated();
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAddGroup = group => {
    apiClient.addGroup(group)
      .then(res => {
        message.success("Группа добавлена");
        setUpdate(u => u + 1)
        onUpdated();
      })
      .catch(err => message.error(err));
  };

  const onUpdateGroup = group => {
    apiClient.updateGroup(group.id, {
      name: group.name,
    })
      .then(res => {
        message.success("Группа обновлена");
        setUpdate(u => u + 1)
        onUpdated();
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <span style={{marginLeft: 8, marginRight: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>
            <Search
              enterButton
              placeholder={"Поиск по наименованию"}
              onSearch={(value, event) => { setSearch(value); }}
              allowClear={true}
              style={{width: 400}}/>
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
            <Button type="primary" loading={loading} onClick={() => { setAddGroupVisible(true) }}>
              Создать роль
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить роль
            </Button>
          </Space>
        </div>
      </div>
      <Table
        rowSelection={{
          selectedRowKeys: selected,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled: record.users_count > 0,
          }),
        }}
        columns={cols}
        scroll={{x: 1000}}
        size="small"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        sticky
        dataSource={records}
        rowKey="id"/>

      <GroupDialog visible={addGroupVisible}
                  onOk={(group) => { setAddGroupVisible(false); onAddGroup(group); }}
                  onCancel={() => { setAddGroupVisible(false) }}/>
      <GroupDialog visible={editedGroup !== null}
                   record={editedGroup}
                  onOk={(group) => { onUpdateGroup(group); setEditedGroup(null); }}
                  onCancel={() => { setEditedGroup(null) }}
                  title={"Изменить запись"}/>
    </>
  )
}
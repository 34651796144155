import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Space, Table} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import UpdateButton from "../../../buttons/UpdateButton";
import moment from "moment";
import LinkButton from "../../../buttons/LinkButton";
import CarReturnLimitEdit from "./CarReturnLimitEdit";

export default function CarReturnSettingsTab({active}) {
  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "Действия",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"} size={"middle"}>
            <LinkButton label={<EditFilled/>} onClick={() => { setEditedRecord(record); }}/>
          </Space>
        )
      }
    },
    {
      title: 'Начало периода',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return moment(record.from_dt).format('DD.MM.YYYY');
      }
    },
    {
      title: 'Конец периода',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return moment(record.to_dt).format('DD.MM.YYYY');
      }
    },
    {
      title: 'Лимит в день',
      dataIndex: 'limit',
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    setLoading(true);
    apiClient.getCarReturnRequestCalendar()
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update]);

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.deleteCarReturnRequestCalendarPeriods(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAddRecord = record => {
    apiClient.addCarReturnRequestCalendarPeriod(record)
      .then(res => {
        message.success("Запись добавлена, обновляем данные");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const onUpdateRecord = record => {
    apiClient.updateCarReturnRequestCalendarPeriod(record.id, { ...record })
      .then(res => {
        message.success("Запись обновлена, загружаем данные");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div style={{background: "rgb(88, 211, 165, 0.2)", color: "black", borderTop: "2px solid #58D3A5", width: "100%", padding: "10px"}}>
        Настройка ограничений на число заявок сдачи ТС в день
      </div>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>

          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
            <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>
              Добавить
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить
            </Button>
          </Space>
        </div>
      </div>
      <Table rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          disabled: record.users_count > 0,
        }),
      }}
             columns={cols}
             size="small"
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
             scroll={{x: 1000}}
             sticky
             dataSource={records}
             rowKey="id"
      />
      {
        editedRecord !== null && (
          <CarReturnLimitEdit
            record={editedRecord}
            onOk={(record) => {
              if(record.id && record.id > 0) {
                onUpdateRecord(record);
              } else {
                onAddRecord(record);
              }
              setEditedRecord(null);
            }}
            onCancel={() => setEditedRecord(null) }
          />
        )
      }
    </>
  )
}
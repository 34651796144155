import React, {useEffect, useState} from "react";
import {Checkbox, DatePicker, Form, Input, message, Modal, Select, Table, Tooltip} from "antd";
import {CheckCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import {PaymentMethod} from "../../../../../components/common/AppEnums";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

export default function AddDriverTransactionDialog({
                                                     visible,
                                                     transaction,
                                                     title,
                                                     srcBalance,
                                                     maxCredit,
                                                     maxDebit,
                                                     driverId,
                                                     onOK,
                                                     onCancel
                                                   }) {
  const [form] = Form.useForm();

  let [selectedType, setSelectedType] = useState('debit');
  let [selectedGroup, setSelectedGroup] = useState('debit');
  let [paymentSources, setPaymentSources] = useState([]);
  let [enablePayFines, setEnablePayFines] = useState(false);
  let [enableCreateExpense, setEnableCreateExpense] = useState(false);
  let [enableCreateCompensation, setEnableCreateCompensation] = useState(false);
  let [finesLoading, setFinesLoading] = useState(false);
  let [finesToPay, setFinesToPay] = useState([]);
  let [finesToPayTotal, setFinesToPayTotal] = useState(0);
  let [sumToPay, setSumToPay] = useState('');
  let [transactionGroups, setTransactionGroups] = useState([]);
  let [allTransactionGroups, setAllTransactionGroups] = useState([]);
  let [expenseCategories, setExpenseCategories] = useState([]);
  let [compensationCategories, setCompensationCategories] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [driverVehicles, setDriverVehicles] = useState([]);

  useEffect(() => {
    if (transaction) {
      form.setFieldsValue({type: transaction.type});
      setSelectedType(transaction.type);

      apiClient.getTransactionPaymentSourcesForDriver(driverId)
        .then(res => {
          setPaymentSources(res.records.map(el => {
            return {value: el.id, text: el.title};
          }));
        });

      apiClient.getTransactionGroups({order: "title"})
        .then(res => {
          setAllTransactionGroups(res.records);
          setTransactionGroups(res.records.filter(el => el.transaction_type === transaction.type));
        })
        .catch(err => message.error(err));
    } else {
      form.setFieldsValue({type: selectedType});
    }
  }, [transaction, visible]);

  useEffect(() => {
    if(enablePayFines) {
      setFinesLoading(true);
      apiClient.getDriverAssignedPaymentTemplates(driverId)
        .then(res => {
          let sumToPayBalance = parseFloat(sumToPay);
          let toPay = res.records
            .filter(el => el.payment_template.category === "fine" && el.balance > 0)
            .sort((a, b) => {
              return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
            })
            .map(el => {
              el.total = el.balance;
              el.payment = 0;
              if(sumToPayBalance > 0) {
                let payment = Math.min(parseFloat(el.balance), sumToPayBalance);
                sumToPayBalance -= payment;
                el.payment = payment;
                el.total = el.balance - payment;
              }
              return el;
            });
          setFinesToPay(toPay);
          setFinesToPayTotal(toPay.reduce((toPayTotal, el) => toPayTotal + parseFloat(el.balance), 0));
        })
        .catch(err => {
          message.error("Не удалось получить список назначенных шаблонов");
        })
        .finally(() => {
          setFinesLoading(false);
        });
    } else {
      setFinesToPay([]);
    }
  }, [driverId, enablePayFines, sumToPay]);

  useEffect(() => {
    if(visible && selectedType === "credit") {
      apiClient.getExpenseCategories()
        .then(res => {
          setExpenseCategories(res.records);
        })
        .catch(err => message.error(err));
      apiClient.getCompensationCategories()
        .then(res => {
          setCompensationCategories(res.records);
        })
        .catch(err => message.error(err));
      apiClient.getVehicles({owned: true, documents_disabled: false}, "minimal")
        .then(res => {
          setVehicles(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [visible, selectedType]);

  useEffect(() => {
    if(visible && selectedType === "debit") {
      apiClient.getDriverVehicleHistory(driverId)
        .then(res => {
          setDriverVehicles(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [visible, selectedType]);

  const updateComment = () => {
    if(enableCreateExpense) {
      let expenseCategoryId = form.getFieldValue("expenseCategory");
      let expenseVehicleId = form.getFieldValue("expenseVehicle");
      let amount = form.getFieldValue("amount");
      if(expenseCategoryId && expenseVehicleId) {
        let expenseCategory = expenseCategories.find(el => el.id === expenseCategoryId)?.title;
        let expenseVehicle = vehicles.find(el => el.id === expenseVehicleId)?.reg_number;
        let comment = `${amount}р., ${expenseCategory}, ${expenseVehicle}`;
        form.setFieldsValue({"comment": comment});
      }
    } else if(enableCreateCompensation) {
      let compensationCategoryId = form.getFieldValue("compensationCategory");
      let compensationVehicleId = form.getFieldValue("compensationVehicle");
      let amount = form.getFieldValue("amount");
      if(compensationCategoryId && compensationVehicleId) {
        let compensationCategory = compensationCategories.find(el => el.id === compensationCategoryId)?.title;
        let vehicle = vehicles.find(el => el.id === compensationVehicleId)?.reg_number;
        let comment = `${amount}р., ${compensationCategory}, ${vehicle}`;
        form.setFieldsValue({"comment": comment});
      }
    }
  };

  const formatVehicleTitle = el => {
    return el ? `${el.brand} ${el.model} ${el.reg_number}` : "";
  };


  function draw() {
    return (
      <Modal
        title={`Новая транзакция: ${title}`}
        visible={visible}
        width={1024}
        style={{top: 20}}
        okText={'Выполнить'}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              if (values.type === "debit" && maxDebit && values.amount > maxDebit) {
                message.warn("Превышен максимум списания: " + maxDebit);
                return;
              }
              if (values.type === "credit" && maxCredit && values.amount > maxCredit) {
                message.warn("Превышен максимум пополнения: " + maxCredit);
                return;
              }
              if(values.rentPaymentDate) {
                values.rentPaymentDate = values.rentPaymentDate.format("YYYY-MM-DD");
              }

              form.resetFields();
              form.setFieldsValue({type: "debit"});

              setSelectedType('debit');
              setEnablePayFines(false);
              setEnableCreateExpense(false);
              setFinesToPay([]);
              setFinesToPayTotal(0);
              setTransactionGroups(allTransactionGroups.filter(el => el.transaction_type === 'debit'));

              onOK({
                source: srcBalance,
                enablePayFines: enablePayFines,
                enableCreateExpense: enableCreateExpense,
                enableCreateCompensation: enableCreateCompensation,
                finesToPay: finesToPay.filter(el => el.payment && el.payment > 0),
                ...values
              });
            })
            .catch(info => {
              message.warn("Ошибка заполнения формы");
            });
        }}
        onCancel={() => {
          form.resetFields();
          form.setFieldsValue({type: "debit"});

          setSelectedType('debit');
          setEnablePayFines(false);
          setEnableCreateExpense(false);
          setFinesToPay([]);
          setFinesToPayTotal(0);
          setTransactionGroups(allTransactionGroups.filter(el => el.transaction_type === 'debit'));

          onCancel();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onValuesChange={(changedValues, allValues) => {
            console.log(allValues);
            setSelectedType(allValues.type);
            setSelectedGroup(allValues.payment_group);
            setTransactionGroups(allTransactionGroups.filter(el => el.transaction_type === allValues.type));
          }}
        >
          <Form.Item
            label="Операция"
            name="type"
            rules={[
              {
                required: true,
                message: 'Укажите тип операции',
              },
            ]}
          >
            <Select bordered allowClear={false}>
              <Option key='debit' value='debit'>Списание</Option>
              <Option key='credit' value='credit'>Пополнение</Option>
              <Option key='move' value='move'>Перевод</Option>
            </Select>
          </Form.Item>

          {
            selectedType === "move"
              ? (
                <Form.Item
                  label="Целевой баланс"
                  name="target"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите баланс для перевода',
                    },
                  ]}
                >
                  <Select bordered allowClear={false}>
                    {
                      paymentSources
                        .filter(el => el.value !== srcBalance)
                        .map(el => <Option key={el.value} value={el.value}>{el.text}</Option>)
                    }
                  </Select>
                </Form.Item>
              ) : null
          }

          {
            selectedType === "credit"
              ? (
                <Form.Item
                  label="Способ оплаты"
                  name="method"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите категорию платежа',
                    },
                  ]}
                >
                  <Select bordered allowClear={false}>
                    {
                      PaymentMethod.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                    }
                  </Select>
                </Form.Item>
              ) : null
          }

          {
            (selectedType === "credit" || selectedType === "debit")
              ? (
                <Form.Item
                  label="Группа платежа"
                  name="payment_group"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите группу платежа',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}>
                    {
                      transactionGroups.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                    }
                  </Select>
                </Form.Item>
              ) : null
          }

          <Form.Item
            label="Сумма"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Укажите сумму',
              },
            ]}
          >
            <Input
              type={'number'}
              placeholder={"Сумма для оплаты"}
              onChange={(e) => {
                setSumToPay(e.target.value);
                updateComment();
              }}
            />
          </Form.Item>

          {
            selectedType === "credit"
              ? (
                <div>
                  <Checkbox
                    disabled={enableCreateCompensation}
                    style={{margin: "10px 0"}}
                    onChange={e => setEnableCreateExpense(e.target.checked)}
                  >
                    Сформировать расход
                  </Checkbox>
                </div>
              ) : null
          }

          {
            enableCreateExpense && (
              <>
                <Form.Item
                  label="Категория расхода"
                  name="expenseCategory"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите категорию расхода',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(e) => { updateComment() }}
                  >
                    {
                      expenseCategories.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="ТС"
                  name="expenseVehicle"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите на какое ТС отнести расходы',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(e) => { updateComment() }}
                  >
                    {
                       vehicles.map(el => <Option key={el.id} value={el.id}>{formatVehicleTitle(el)}</Option>)
                    }
                  </Select>
                </Form.Item>
              </>
            )
          }

          {
            selectedType === "debit" && (srcBalance === "balance" || srcBalance === "deposit")
              ? (
                <div>
                  <Checkbox
                    disabled={enableCreateExpense}
                    style={{margin: "10px 0"}}
                    onChange={e => setEnableCreateCompensation(e.target.checked)}
                  >
                    Сформировать удержание
                  </Checkbox>
                </div>
              ) : null
          }

          {
            enableCreateCompensation && (
              <>
                <Form.Item
                  label="Категория удержания"
                  name="compensationCategory"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите категорию',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(e) => { updateComment() }}
                  >
                    {
                      compensationCategories.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="ТС"
                  name="compensationVehicle"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите на какое ТС отнести компенсацию',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.label .toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(e) => { updateComment() }}
                  >
                    {
                      driverVehicles.filter(el => !el.delete_dt).map(el => <Option key={el.vehicle.id} value={el.vehicle.id} label={el.vehicle.title}>{el.delete_dt ? null : <CheckCircleOutlined />} {el.vehicle.title}</Option>)
                    }
                  </Select>
                </Form.Item>
              </>
            )
          }

          {
            selectedType === "debit" && allTransactionGroups.find(el => el.id === selectedGroup)?.transaction_category === "rent" && (
              <>
                <Form.Item
                  label="Дата аренды"
                  name="rentPaymentDate"
                  initialValue={moment()}
                  rules={[
                    {
                      required: true,
                      message: 'Укажите дату оплаты аренды',
                    },
                  ]}
                >
                  <DatePicker
                    style={{width:"220px"}}
                    format="DD.MM.YYYY"
                    // disabledDate={(dt) => {
                    //   return dt && moment().day() <= 11
                    // }}
                  />
                </Form.Item>
                <Form.Item
                  label="ТС"
                  name="rentPaymentVehicle"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите на какое ТС отнести аренду',
                    },
                  ]}
                >
                  <Select
                    bordered
                    allowClear={false}
                    showSearch
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(e) => { updateComment() }}
                  >
                    {
                      driverVehicles.map(el => <Option key={el.vehicle.id} value={el.vehicle.id} label={el.vehicle.title}>{el.delete_dt ? null : <CheckCircleOutlined />} {el.vehicle.title}</Option>)
                    }
                  </Select>
                </Form.Item>
              </>
            )
          }

          {
            selectedType === "credit" && srcBalance === "summary"
            ? (
                <div>
                  <Checkbox style={{margin: "10px 0"}} onChange={e => setEnablePayFines(e.target.checked)}>Оплатить штрафы</Checkbox>
                  <Tooltip
                    placement="top"
                    title="При пополнении баланса Вы можете сразу оплатить штрафы (если они есть)."
                  >
                    <QuestionCircleOutlined/>
                  </Tooltip>
                </div>
            ) : null
          }

          <Form.Item
            label="Комментарий"
            name="comment"
            rules={[
              {
                required: true,
                message: 'Укажите комментарий',
              },
            ]}
          >
            <TextArea placeholder={'Комментарий'} rows={2}/>
          </Form.Item>
        </Form>

        {
          enablePayFines
          ? (
            <div>
              <h3>{`Штрафы для оплаты (${finesToPayTotal} руб)`}</h3>
              <Table
                loading={finesLoading}
                sticky
                columns={[
                  {
                    title: "",
                    align: "center",
                    width: 30,
                  },
                  {
                    title: 'Шаблон',
                    dataIndex: 'title',
                  },
                  {
                    title: 'Лимит',
                    render: (text, record, index) => {
                      return record.payment_template && record.payment_template.processing_type === "limited" ? record.payment_template.limit : "-";
                    },
                  },
                  {
                    title: 'Ставка',
                    dataIndex: 'rate',
                    render: (text, record, index) => {
                      return parseFloat(record.rate).toFixed(2);
                    },
                  },
                  {
                    title: 'Списано',
                    dataIndex: 'paid',
                    render: (text, record, index) => {
                      return parseFloat(record.paid).toFixed(2);
                    },
                  },
                  {
                    title: 'Остаток',
                    dataIndex: 'balance',
                    render: (text, record, index) => {
                      return parseFloat(record.balance).toFixed(2);
                    },
                  },
                  {
                    title: 'После пополнения',
                    dataIndex: 'total',
                    render: (text, record, index) => {
                      return parseFloat(record.total).toFixed(2);
                    },
                  },
                  {
                    title: "",
                    align: "center",
                    width: 20,
                  }
                ]}
                size="small"
                dataSource={finesToPay}
                rowKey="id"
              />
            </div>
          ) : null
         }
      </Modal>
    );
  }

  return (
    transaction ? draw() : null
  );
}
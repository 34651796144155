import React, {useEffect, useState} from "react";
import moment from "moment";
import {ParkStatuses} from "../../../common/AppEnums";
import {apiClient} from "../../../../services/ApiClient";
import {
  Button,
  DatePicker, Dropdown,
  Input, Menu,
  message,
  PageHeader, Popover,
  Select,
  Space,
  Switch,
  Table,
  Tooltip
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  FileExcelOutlined,
  FilterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {windowService} from "../../../../services/WindowService";
import LinkButton from "../../../buttons/LinkButton";
import VINDuplicatesDialog from "./VINDuplicatesDialog";

const {Search} = Input;
const {Option} = Select;

const STATES = [
  {"id": "issuance", title: "ВОЗВРАТ/ВЫДАЧА (ПРОСТОЙ)"},
  {"id": "paid_issuance", title: "ВОЗВРАТ/ВЫДАЧА С ОПЛАТОЙ"},
  {"id": "paid_issuance_only", title: "ВЫДАЧА С ОПЛАТОЙ"},
  {"id": "return", title: "ВОЗВРАТ (ПРОСТОЙ)"},
  {"id": "paid_return", title: "ВОЗВРАТ С ОПЛАТОЙ"},
];
const FILTER_VIN_DUPLICATES = "vinDuplicates";
const CMD_INDIVIDUAL_REPORT = "individualReport";
const CMD_GROUP_REPORT = "groupReport";

export default function InvestorReportPage() {
  let [records, setRecords] = useState([]);
  let [dates, setDates] = useState([]);
  let [loading, setLoading] = useState(false);
  let [cols, setCols] = useState([]);
  let [paymentGroups, setPaymentGroups] = useState([]);
  let [ownerOptions, setOwnerOptions] = useState([]);
  let [modelOptions, setModelOptions] = useState([]);
  let [regions, setRegions] = useState([]);
  let [filter, setFilter] = useState({});
  let [activeUpdate, setActiveUpdate] = useState(null);
  let [currentPage, setCurrentPage] = useState(0);
  let [currentPageSize, setCurrentPageSize] = useState(0);
  let [cmdOperation, setCmdOperation] = useState(null);
  let [filterName, setFilterName] = useState(null);

  useEffect(() => {
    apiClient.getVehiclesFilters()
      .then(res => {
        setOwnerOptions(res.owners);
        setModelOptions(res.brands);
        setPaymentGroups(res.groups);
        setRegions(res.regions);
      })
      .catch(err => message.error(err));
    apiClient.getActiveReportRecordsUpdate("invest/summary")
      .then(res => {
        if(res.record?.uid) {
          setLoading(true);
          setActiveUpdate(waitForData(res.record?.uid));
        }
      })
      .catch(err => message.error(err));
  }, []);

  useEffect(() => {
    if(activeUpdate) {
      return () => {
        if(activeUpdate) {
          clearTimeout(activeUpdate);
        }
      }
    }
  }, [activeUpdate]);

  useEffect(() => {
    let columns = [
      {
        title: "",
        align: "center",
        width: 30,
      },
      {
        title: "#",
        align: "center",
        width: 50,
        render: (text, record, index) => {
          return (currentPage - 1) * currentPageSize + index + 1;
        }
      },
      {
        title: 'Номер ТС',
        width: 170,
        render: (text, record, index) => {
          return (
            <LinkButton
              label={(
                <Popover content={record.vehicle?.vin}>
                  <span>{record.vehicle?.title}</span>
                </Popover>
              )}
              onClick={() => {
                windowService.openRouteWindow(`cp/park/vehicles/${record.vehicle?.id}`);
              }}
            />
          );
        },
      },
      {
        title: "VIN",
        width: 200,
        align: "center",
        render: (text, record, index) => {
          return record.vehicle?.vin;
        },
      },
      {
        title: "Собственник ТС",
        width: 200,
        render: (text, record, index) => {
          return record.owner?.title;
        },
      }
    ];

    if (dates) {
      dates.forEach(el => {
        const dateLabel = moment(el).format('DD.MM.YYYY');
        columns.push({
          title: dateLabel,
          width: 100,
          align: "center",
          render: (text, record, index) => {
            const dayData = record.days.find(d => d.date === dateLabel);
            if(!dayData) {
              return null;
            }
            if(dayData?.documents_disabled) {
              return <div style={{textAlign: 'center'}}><Tooltip title="Исключено из договоров"><CloseOutlined /></Tooltip></div>
            }
            return dayData.total !== 0 ? (
              <div style={{textAlign: 'center'}}>
                <Tooltip
                  title={STATES.find(s => s.id === dayData?.state)?.title}
                >
                  <span style={dayData?.state !== "none" ? {fontWeight: 'bold'} : null}>{dayData.total}</span>
                </Tooltip>
              </div>
            ) : dayData?.state === "none"
              ? ParkStatuses.find(el => el.id === dayData?.status)?.title
              : STATES.find(s => s.id === dayData?.state)?.title;
          },
        });
      });
    }

    columns.push({
      title: "",
      align: "center",
      width: 20,
    });

    setCols(columns);
  }, [dates, currentPageSize, currentPage]);

  const getFilter = (params) => {
    let args = {
      year: moment().year(),
      month: moment().month() + 1,
      group: filter.group,
      search: filter.search,
      owner: filter.owner,
      filterName: filterName,
      ...params
    };
    if(filter.dateFilter) {
      args.year = moment(filter.dateFilter, "YYYY-MM-DD").year();
      args.month = moment(filter.dateFilter, "YYYY-MM-DD").month() + 1;
    }
    return args;
  };

  const reloadData = () => {
    let args = getFilter();
    setLoading(true);
    apiClient.getReportRecords("invest/summary", args)
      .then(res => {
        if(res.records) {
          if(filterName === FILTER_VIN_DUPLICATES) {
            setRecords(res.records.sort((a, b) => { return a.vehicle?.vin.localeCompare(b.vehicle?.vin)}));
          } else {
            setRecords(res.records);
          }

          setDates(res.dates);
        }
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); });
  };

  const updateData = () => {
    let args = getFilter();
    setLoading(true);
    apiClient.updateReportRecords("invest/summary", args)
      .then(res => {
        if(!res.task_uid) {
          message.error("Не удалось запустить обновление данных");
          setLoading(false);
          return;
        }
        waitForData(res.task_uid);
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      });
  };

  const waitForData = (uid) => {
    apiClient.getBackgroundTaskStatus(uid)
      .then(res => {
        if(!res.end_dt) {
          setActiveUpdate(setTimeout(() => { waitForData(uid) }, 1000));
          return;
        }
        //
        setActiveUpdate(null);
        //
        if(res.error_message) {
          message.error(res.error_message);
          setLoading(false);
          return;
        }
        // query updated data
        reloadData();
      })
      .catch(err => message.error(err));
  };

  const executeSelectedRecordsOperation = key => {
    if(key === CMD_INDIVIDUAL_REPORT) {
      let args = getFilter({export: true, mode: "individual"});
      setLoading(true);
      apiClient.getReportRecords("invest/summary", args)
        .then(res => {
          if(res.url) {
            window.open(res.url, '_blank');
          }
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); });
    } else if(key === CMD_GROUP_REPORT) {
      let args = getFilter({export: true, mode: "group"});
      setLoading(true);
      apiClient.getReportRecords("invest/summary", args)
        .then(res => {
          if(res.url) {
            window.open(res.url, '_blank');
          }
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); });
    }
  };


  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Отчет по группам"
        extra={
          <Space direction={"horizontal"}>
            <span>Всего ТС: {records?.length}</span>
            <Button
              type="primary"
              icon={<FilterOutlined/>}
              loading={loading}
              onClick={reloadData}
            >
              Обновить
            </Button>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              loading={loading}
              onClick={updateData}
            >
              Перестроить отчет
            </Button>
            <Dropdown
              overlay={(
                <Menu onClick={(e) => {
                  executeSelectedRecordsOperation(e.key);
                }}>
                  <Menu.Item key={CMD_INDIVIDUAL_REPORT} icon={<FileExcelOutlined/>}>
                    Индивидуальный отчет
                  </Menu.Item>
                  <Menu.Item key={CMD_GROUP_REPORT} icon={<FileExcelOutlined/>}>
                    Групповой отчет
                  </Menu.Item>
                </Menu>
              )}>
              <Button style={{textAlign: "left"}}>
                Экспорт <DownOutlined/>
              </Button>
            </Dropdown>
          </Space>
        }
      />

      <Space direction={"horizontal"} wrap style={{width: '100%', marginBottom: 10}}>
        <Search
          enterButton
          placeholder={"Поиск по номеру ТС"}
          onSearch={(value) => {
            setFilter(prevState => {
              return {...prevState, "search": value}
            });
          }}
          allowClear={true}
          style={{width: 400}}
        />
        <DatePicker
          allowClear
          format="MMMM, YYYY"
          picker={"month"}
          placeholder="Год/Месяц"
          value={filter.dateFilter ? moment(filter.dateFilter, "YYYY-MM-DD") : null}
          onChange={(val) => {
            setFilter(prevState => {
              return {...prevState, "dateFilter": val ? val.format("YYYY-MM-DD") : null}
            });
          }}
          style={{width: 220}}
        />
        <Select
          showSearch
          allowClear
          defaultValue={[]}
          style={{width: 400}}
          placeholder="Группа"
          onChange={(value) => {
            setFilter(prevState => {
              return {...prevState, "group": value}
            })
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {paymentGroups.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
        </Select>
        <Select
          allowClear
          showSearch
          placeholder="Собственник"
          style={{width: 500}}
          onChange={(val) => {
            setFilter(prevState => {
              return {...prevState, "owner": val}
            });
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            ownerOptions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Switch
          checkedChildren={<span>Дубликаты ТС</span>}
          unCheckedChildren={<span>Дубликаты ТС</span>}
          checked={filterName === FILTER_VIN_DUPLICATES}
          onChange={(e) => {
            e ? setFilterName(FILTER_VIN_DUPLICATES) : setFilterName(null);
          }}
        />
      </Space>

      <Table
        dataSource={records}
        scroll={{x: 1000}}
        sticky
        columns={cols}
        size="small"
        style={{margin: '10px 0', marginTop: 40}}
        rowKey="id"
        loading={loading}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
        onChange={(pagination, filters, sorter, extra) => {
          setCurrentPage(pagination.current);
          setCurrentPageSize(pagination.pageSize);
        }}
      />
      {
        cmdOperation !== null ? (
          <VINDuplicatesDialog
            filter={getFilter()}
            onOK={() => {setCmdOperation(null)}}
            onCancel={() => {setCmdOperation(null)}}
          />
        ) : null
      }
    </>
  );
}
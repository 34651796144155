import React, {useEffect, useState} from "react";
import {Button, Col, Input, InputNumber, message, Row, Select, Space, Switch, TimePicker} from "antd";
import {Periodicity} from "../../../common/AppEnums";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";

const {Option} = Select;

export default function FinesMainSettingsTab({active}) {

  let [commissionType, setCommissionType] = useState('percent');
  let [commissionValue, setCommissionValue] = useState('');
  let [maxPaymentValue, setMaxPaymentValue] = useState('');
  let [maxPaymentThreshold, setMaxPaymentThreshold] = useState('');
  let [enablePaymentDiscount, setEnablePaymentDiscount] = useState(false);
  let [schedulerPeriod, setSchedulerPeriod] = useState(null);
  let [schedulerTime, setSchedulerTime] = useState('00:00:00');
  let [enableInstallmentPayment, setEnableInstallmentPayment] = useState(false);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    apiClient.getSystemProperty("fines.*")
      .then(res => {
        if(res.values) {
          if(res.values["fines.max_payment_value"]) {
            setMaxPaymentValue(res.values["fines.max_payment_value"]);
          }
          if(res.values["fines.max_payment_threshold"]) {
            setMaxPaymentThreshold(res.values["fines.max_payment_threshold"]);
          }
          if(res.values["fines.enable_payment_discount"]) {
            setEnablePaymentDiscount(res.values["fines.enable_payment_discount"].toLowerCase() === "true");
          }
          if(res.values["fines.enable_installment_payment"]) {
            setEnableInstallmentPayment(res.values["fines.enable_installment_payment"].toLowerCase() === "true");
          }
          if(res.values["fines.commission_value"]) {
            setCommissionValue(res.values["fines.commission_value"]);
          }
          if(res.values["fines.commission_type"]) {
            setCommissionType(res.values["fines.commission_type"]);
          }
          if(res.values["fines.scheduler_time"]) {
            setSchedulerTime(res.values["fines.scheduler_time"]);
          }
          if(res.values["fines.scheduler_period"]) {
            setSchedulerPeriod(res.values["fines.scheduler_period"]);
          }
        }
      })
      .catch(err => message.error(err));
  }, [active]);

  const updateCommissionType = (val) => {
    setCommissionType(val);
  };

  const updateCommissionValue = (val) => {
    setCommissionValue(val);
  };

  const updateMaxPaymentValue = (val) => {
    setMaxPaymentValue(val);
  };

  const updateMaxPaymentThreshold = (val) => {
    console.log(val);
    setMaxPaymentThreshold(val);
  };

  const updateSchedulerPeriod = (val) => {
    setSchedulerPeriod(val);
  };

  const updateSchedulerTime = (val) => {
    setSchedulerTime(val);
  };

  const updateEnablePaymentDiscount = (val) => {
    setEnablePaymentDiscount(val);
  };

  const saveData = () => {
    const properties = {
      'fines.max_payment_value': maxPaymentValue,
      'fines.max_payment_threshold': maxPaymentThreshold,
      'fines.enable_payment_discount': enablePaymentDiscount,
      'fines.enable_installment_payment': enableInstallmentPayment,
      'fines.commission_type': commissionType,
      'fines.commission_value': commissionValue,
      'fines.scheduler_period': schedulerPeriod,
      'fines.scheduler_time':  schedulerTime,
    };

    setLoading(true);
    apiClient.setSystemProperties(properties)
      .then(res => {
        message.info("Изменения сохранены");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Рассрочки списания штрафов</h4>
        </Col>
        <Col span={4}>
          <Switch
            title={"Рассрочки списания штрафов"}
            onChange={(checked) => { setEnableInstallmentPayment(checked); }}
            checked={enableInstallmentPayment}
            style={{marginBottom: 8}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Максимальная сумма списаний в день по рассрочке</h4>
        </Col>
        <Col span={4}>
          <InputNumber
            disabled={!enableInstallmentPayment}
            allowClear={true}
            placeholder="Максимальная сумма списаний в день, руб"
            style={{width: 250, marginBottom: 8}}
            value={maxPaymentValue}
            onChange={(value) => { updateMaxPaymentValue(value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Максимальная сумма списания без рассрочки</h4>
        </Col>
        <Col span={4}>
          <InputNumber
            disabled={enableInstallmentPayment}
            allowClear={true}
            placeholder="Максимальная сумма списаний без рассрочки, руб"
            style={{width: 250, marginBottom: 8}}
            value={maxPaymentThreshold}
            onChange={(value) => { updateMaxPaymentThreshold(value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Тип коммисии</h4>
        </Col>
        <Col span={4}>
          <Select bordered
                  value={commissionType}
                  placeholder="Тип комиссии"
                  onChange={(e) => { updateCommissionType(e) }}
                  style={{width: 250, marginBottom: 8}}
          >
            <Option key='percent' value='percent'>Процент, %</Option>
            <Option key='value' value='value'>Сумма, руб</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Коммисия</h4>
        </Col>
        <Col span={4}>
          <Input
            allowClear={true}
            placeholder="Размер коммиссии, руб"
            style={{width: 250, marginBottom: 8}}
            value={commissionValue}
            onChange={(e) => { updateCommissionValue(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Удерживать с учетом скидки</h4>
        </Col>
        <Col span={4}>
          <Switch
            title={"Удерживать с учетом скидки"}
            onChange={(checked) => { updateEnablePaymentDiscount(checked); }}
            checked={enablePaymentDiscount}
            style={{marginBottom: 8}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Периодичность</h4>
        </Col>
        <Col span={10}>
          <Space direction="horizontal">
            <Select
              bordered
              placeholder="Выберите вариант из списка"
              value={schedulerPeriod}
              style={{width: 250, marginBottom: 8}}
              onChange={(e) => { updateSchedulerPeriod(e) }}
            >
              {Periodicity.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>)}
            </Select>
            <TimePicker onChange={(time, timeString) => { updateSchedulerTime(timeString) }}
                        value={moment(schedulerTime, 'HH:mm:ss')}
                        style={{width: 120, marginBottom: 8}} />,
          </Space>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={13}>
          <Button type="primary" loading={loading} onClick={() => { saveData() }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>
  )
}
import React, {useState} from "react";
import {Button, Form, Input} from 'antd';

const { TextArea } = Input;

export default function CommentEditor ({ onChange, onSubmit }) {
    let [value, setValue] = useState('');
    let [submitting, setSubmitting] = useState(false);
    const handleOnChange = (e) => {
        setValue(e.target.value);
        if(onChange) {
            onChange(e.target.value);
        }
    };
    const handleOnSubmit = () => {
        if(onSubmit) {
            setSubmitting(true);
            onSubmit(value)
                .then(() => { setValue(""); })
                .finally(() => { setSubmitting(false); })
        }
    };
    return (
        <>
            <Form.Item>
                <TextArea rows={4} onChange={handleOnChange} value={value} placeholder="Комментарий"/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" loading={submitting} onClick={handleOnSubmit} type="primary">
                    Добавить комментарий
                </Button>
            </Form.Item>
        </>
    );
}
import React, {useEffect, useState} from "react";
import {message, Space, Modal, Table, Input, Select, Radio} from "antd";
import {apiClient} from "../../../services/ApiClient";
import SearchSelect from "../../common/SearchSelect";
import {DriverStatusFired, DriverStatusWorking} from "../../common/AppEnums";

const {Search, TextArea} = Input;
const {Option} = Select;

export default function DriversPickerDialog({onOK, onCancel}) {

  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [recordsCount, setRecordsCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [filters, setFilters] = useState({"accountStatus": DriverStatusWorking, telegramStatus: "connected", driversNotify: "off"})
  const [vehicles, setVehicles] = useState([]);
  let [regions, setRegions] = useState([]);
  let [sorter, setSorter] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 200]
  });

  useEffect(() => {
    const filter = {
      ...filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    setLoading(true);
    apiClient.getDriversList(filter, 'simple')
      .then(res => {
        setRecords(res.drivers);
        setRecordsCount(res.drivers_count);
        setPagination(prevState => {return {...prevState, total: res.drivers_count}})
        setSelected([]);
      })
      .catch(err => {
        console.log(err)
        message.error(err);
      })
      .finally(() => { setLoading(false) })
  }, [filters, sorter])

  useEffect(() => {
    apiClient.getVehicles({owned: true, documents_disabled: false}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
  }, [])

  return (
    <Modal
      destroyOnClose={true}
      width={"60%"}
      style={{ top: 20 }}
      title={"Водители"}
      visible={true}
      onOk={() => {
        onOK(records.filter(el => selected.includes(el.id)));
      }}
      onCancel={onCancel}
    >
      <>
        <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
          Записей: {recordsCount}
        </div>
        <Space direction="horizontal" align={{width: '100%'}} wrap>
          <Search
            allowClear
            placeholder="Поиск по ФИО"
            onSearch={(value, event) => {
              setFilters(prevState => { return { ...prevState, search: value} });
              setPagination(prevState => { return {...prevState, current: 1} });
            }}
            enterButton
            style={{width: 400}}
          />
          <Radio.Group
            value={filters?.accountStatus}
            onChange={(e) => {
              setFilters(prevState => { return { ...prevState, accountStatus: e.target.value} });
              setPagination(prevState => { return {...prevState, current: 1} });
            }}>
            <Radio.Button value={DriverStatusWorking}>Работает</Radio.Button>
            <Radio.Button value={DriverStatusFired}>Уволен</Radio.Button>
          </Radio.Group>
          <Select
            allowClear
            mode="multiple"
            placeholder="Филиал"
            style={{width: 300}}
            onChange={(data) => {
              setFilters(prevState => { return { ...prevState, regionIds: data} });
              setPagination(prevState => { return {...prevState, current: 1} });
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
            }
          </Select>
          <SearchSelect
            placeholder="Авто"
            style={{width: 300}}
            options={vehicles.map(el => ({id: el.id, title: `${el.brand} ${el.model} ${el.reg_number}`}))}
            onSelect={(el) => {
              setFilters(prevState => { return { ...prevState, vehicle: el?.id} });
              setPagination(prevState => {return { ...prevState, current: 1} });
            }}
          />
        </Space>
        <Table
          dataSource={records}
          scroll={{x: 700, y: 600}}
          sticky
          style={{margin: '10px 0'}}
          columns={[
            {
              title: "",
              align: "center",
              fixed: 'left',
              width: 10,
            },
            Table.SELECTION_COLUMN,
            {
              title: "ФИО",
              width: 200,
              dataIndex: "fio",
              sorter: true,
              render: (text, record, index) => {
                return (
                  <Space direction="horizontal">
                    {record.fio}
                  </Space>
                );
              }
            },
            {
              title: "Телефон",
              align: "center",
              width: 150,
              dataIndex: "phone",
              sorter: true,
            },
            {
              title: "Telegram",
              align: "center",
              width: 150,
              dataIndex: "telegram_id",
              sorter: true,
            },
            {
              title: "",
              align: "center",
              width: 10,
            },
          ]}
          size='small'
          rowKey="id"
          onChange={(pagination, filters, sorter) => {
            setPagination(pagination);
            setSorter(sorter);
          }}
          rowSelection={{
            selectedRecords: selected,
            onChange: (selectedRowKeys) => {
              setSelected(selectedRowKeys)
            }
          }}
          pagination={pagination}
        />
      </>
    </Modal>
  )
}
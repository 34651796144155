import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table, Input, Select} from "antd";
import {SchedulerPeriods, TemplateScheduleType} from "../../../common/AppEnums";
import {apiClient} from "../../../../services/ApiClient";
import {EditFilled, QuestionOutlined} from "@ant-design/icons";
import moment from "moment";
import WorkConditionDialog from "./WorkConditionDialog";
const {Search} = Input;

export default function WorkConditionsTab() {
  let [loading, setLoading] = useState(false);
  let [dsLoading, setDsLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [ds, setDs] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EditFilled/>} onClick={() => {setEditedRecord(record);}}/>
          </Space>
        );
      }
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
    },
    {
      title: 'Порядок списания',
      dataIndex: 'schedule_type',
      align: "center",
      render: (text, record, index) => {
        return TemplateScheduleType.filter(el => el.value === record.schedule_type)[0].text
      },
    },
    {
      title: 'Периодичность списаний',
      dataIndex: 'regular_period',
      align: "center",
      render: (text, record, index) => {
        if(record.schedule_type === "regular") {
          const period = SchedulerPeriods.filter(el => el.value == record.regular_period);
          if(period && period.length > 0) {
            return period[0].text
          }
          return record.regular_period
        }
        return "";
      },
    },
    {
      title: 'Шаблон выполнения',
      dataIndex: 'pattern_run_days',
      align: "center",
      render: (text, record, index) => {
        if(record.schedule_type === "pattern") {
          return `${record.pattern_run_days} д через ${record.pattern_skip_days} д, начиная с ${record.delay_days} д`;
        }
        return ""
      },
    },
    {
      title: 'Время',
      dataIndex: 'execution_time',
      align: "center",
    },
    {
      title: 'Шаблонов',
      dataIndex: 'update_dt',
      align: "center",
      render: (text, record, index) => {
        return `${record.templates.length}`;
      },
    },
    {
      title: 'Водителей',
      dataIndex: 'update_dt',
      align: "center",
      render: (text, record, index) => {
        return `${record.drivers.length}`;
      },
    },
    {
      title: 'Обновлено',
      dataIndex: 'update_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.update_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const updateRecord = record => {
    setLoading(true);
    if(record.id > 0) {
      apiClient.updateWorkCondition(record.id, record)
        .then(res => {
          message.success("Условия обновлены");
          setUpdate(prevState => prevState + 1);
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      apiClient.addWorkCondition(record)
        .then(res => {
          message.success("Условия сохранены");
          setUpdate(prevState => prevState + 1);
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

  };

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selectedRecords.length} записей?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        let proms = selectedRecords.map(el => apiClient.removeWorkCondition(el));

        Promise.all(proms)
          .catch(err => {
            message.error(err);
          })
          .finally(() => {
            setSelectedRecords([]);
            setUpdate(prevState => prevState + 1);
          });
      }
    });
  };

  useEffect(() => {
    setDsLoading(true);
    apiClient.getWorkConditions("default", {search: search})
      .then(res => {
        setDs(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список");
      })
      .finally(() => {
        setDsLoading(false);
      });
  }, [update, search]);

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedRecords.length > 0 ? `Выбрано ${selectedRecords.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>
            <Search
              enterButton
              placeholder={"Поиск по наименованию"}
              onSearch={(value, event) => { setSearch(value); }}
              allowClear={true}
              style={{width: 400}}/>
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <Button type="primary" onClick={() => { setEditedRecord({id: 0}) }} loading={loading}>
              Создать
            </Button>
            {
              selectedRecords.length > 0
                ? (<Button type={"danger"} onClick={() => { removeSelected() }} loading={loading}>
                    Удалить
                  </Button>
                ) : null
            }
          </Space>
        </div>
      </div>
      <Table rowSelection={{selectedRecords, onChange: (selectedRowKeys) => { setSelectedRecords(selectedRowKeys) }}}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        size="small"
        columns={cols}
        dataSource={ds}
        scroll={{x: 1300, y: 600}}
        sticky
        loading={dsLoading}
        rowKey="id"
      />
      <WorkConditionDialog title={"Изменить запись"}
        visible={editedRecord !== null}
        record={editedRecord}
        onOk={(record) => {
          updateRecord(record);
          setEditedRecord(null);
        }}
        onCancel={() => { setEditedRecord(null) }}/>
      </>
  )
}

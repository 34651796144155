import React, {useEffect, useState} from "react";
import {Col, Input, Modal, Row, Select, message, Space, TimePicker, Table, Button} from "antd";
import {
  TemplateScheduleType,
  SchedulerPeriods
} from "../../../common/AppEnums";
import { isMobile } from "react-device-detect";
import moment from "moment"
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined, InfoOutlined} from "@ant-design/icons";
import TransactionTemplateDialog from "./txtemplates/TransactionTemplateDialog";
import '../../ModalStyle.css';

const { Option } = Select;

const emptyTemplate = {
  id: 0,
  title: '',
  schedule_type: TemplateScheduleType[0].value,
  regular_period: SchedulerPeriods[0].value,
  pattern_run_days: 1,
  pattern_skip_days: 1,
  delay_days: 0,
  execution_time: moment("12:00:00", 'HH:mm:ss'),
  templates: []
};
const rowStyle = {padding: '8px'};

export default function WorkConditionDialog({visible, record, onOk, onCancel, title}) {
  let [template, setTemplate] = useState(emptyTemplate);
  let [allTemplates, setAllTemplates] = useState([]);
  let [showInfo, setShowInfo] = useState(null);

  useEffect(() => {
    if(record) {
      setTemplate(r => {
        return {
          ...r,
          id: record.id,
          title: record.title || emptyTemplate.title,
          schedule_type: record.schedule_type || emptyTemplate.schedule_type,
          regular_period: record.regular_period ? String(record.regular_period) : emptyTemplate.regular_period,
          pattern_run_days: record.pattern_run_days || emptyTemplate.pattern_run_days,
          pattern_skip_days: record.pattern_skip_days || emptyTemplate.pattern_skip_days,
          delay_days: record.delay_days || emptyTemplate.delay_days,
          execution_time: (record.execution_time ? moment(record.execution_time, 'HH:mm:ss') : emptyTemplate.execution_time) || emptyTemplate.execution_time,
          templates: record.templates || emptyTemplate.templates
        }
      });
    } else {
      setTemplate(emptyTemplate);
    }
  }, [record]);

  useEffect(() => {
    apiClient.getTransactionTemplates()
      .then(res => {
        setAllTemplates(res.records)
      });
  }, [record]);

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="size-for-middle-window"
        style={{top: 10}}
        title={title || "Новая запись"}
        visible={visible}
        onOk={() => {
          if(!template.title) {
            message.error("Укажите наименование");
            return;
          }

          onOk({
            ...template,
            regular_period: parseInt(template.regular_period),
            execution_time: moment(template.execution_time).format('HH:mm:ss'),
          });

          setTemplate(emptyTemplate);
        }}
        onCancel={onCancel}>

        {/* Наименование */}
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Наименование</Col>
          <Col span={18}>
            <Input
              allowClear={true}
              placeholder="Наименование"
              required
              value={template.title}
              onChange={(e) => { setTemplate({...template, title: e.target.value}) }}/>
          </Col>
        </Row>

        {/* Порядок списания */}
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Порядок списания</Col>
          <Col span={18}>
            <Select
              value={template.schedule_type}
              style={{width: '100%'}}
              onChange={(val, option) => {
                setTemplate({...template, schedule_type: val})
              }}>
              {
                TemplateScheduleType.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
              }
            </Select>
          </Col>
        </Row>

        {
          template.schedule_type === "regular"
            ? (
              // периодические списания
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Периодичность списаний</Col>
                <Col span={18}>
                  <Select
                    value={template.regular_period}
                    style={{width: '100%'}}
                    onChange={(val, option) => {
                      setTemplate({...template, regular_period: val})
                    }}>
                    {
                      SchedulerPeriods
                        .filter(e => e.visible)
                        .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                    }
                  </Select>
                </Col>
              </Row>
            )
            : (
              // списания по шаблону
              <>
                <Row gutter={[0, 16]} style={rowStyle}>
                  <Col span={6}>Периодичность списаний</Col>
                  <Col span={18}>
                    <Space direction={"horizontal"}>
                      <Input allowClear={true}
                             value={template.pattern_run_days}
                             onChange={(e) => { setTemplate({...template, pattern_run_days: e.target.value}) }}
                             placeholder="Дней списаний"
                             type={"number"}/>
                      <span>через</span>
                      <Input allowClear={true}
                             value={template.pattern_skip_days}
                             onChange={(e) => { setTemplate({...template, pattern_skip_days: e.target.value}) }}
                             placeholder="Дней пропуска"
                             type={"number"}/>
                    </Space>
                  </Col>
                </Row>
              </>
            )
        }

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Отсрочка списаний</Col>
          <Col span={18}>
            <Input allowClear={true}
                   value={template.delay_days}
                   onChange={(e) => { setTemplate({...template, delay_days: e.target.value}) }}
                   placeholder="Отсрочка списаний, д"
                   type={"number"}/>
          </Col>
        </Row>

        {/* Время списания */}
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Время списания</Col>
          <Col span={18}>
            <TimePicker allowClear={true}
                        value={template.execution_time}
                        onChange={(time, timeString) => { setTemplate({...template, execution_time: moment(timeString, 'HH:mm:ss')}) }}
                        placeholder="Время списания"/>
          </Col>
        </Row>

        {/* Шаблоны списаний */}
        <Row style={rowStyle}>
          <Col span={6}>Шаблоны</Col>
          <Col span={18}>
            <Select
              showSearch
              allowClear
              style={{ width: 400 }}
              placeholder="Шаблон списаний"
              onChange={(value) => {
                if(value) {
                  let selectedTemplate = allTemplates.filter(el => el.id === value)[0];
                  if(selectedTemplate) {
                    if(template.templates.filter(el => el.id === selectedTemplate.id).length > 0) {
                      message.warn("Шаблон уже добавлен");
                      return;
                    }
                    setTemplate({...template, templates: [...template.templates, selectedTemplate]});
                  }
                }
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                allTemplates.map(s => <Option key={s.id} value={s.id}>{`${s.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={24}>
            <div style={{marginTop: 10}}>
              <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                     size={"small"}
                     sticky
                     columns={[
                       {
                         title: "",
                         align: "center",
                         width: 30,
                       },
                       {
                         title: "",
                         width: 80,
                         align: "center",
                         dataIndex: "id",
                         render: function(text, record, index) {
                           return (
                             <Space direction={"horizontal"}>
                               <Button
                                 type="link"
                                 icon={<DeleteOutlined/>}
                                 onClick={
                                   () => {
                                     setTemplate({...template, templates: template.templates.filter(el => el.id !== record.id)})
                                   }
                                 }/>
                               <Button
                                 type="link"
                                 icon={<InfoOutlined/>}
                                 onClick={
                                   () => {
                                     setShowInfo(record);
                                   }
                                 }/>
                             </Space>
                           );
                         }
                       },
                       {
                         title: 'Наименование',
                         dataIndex: 'title',
                       },
                       {
                         title: 'Время',
                         dataIndex: 'execution_time',
                       },
                       {
                         title: "",
                         align: "center",
                         width: 20,
                       }
                     ]}
                     dataSource={template.templates}
                     rowKey="id"/>
            </div>
          </Col>
        </Row>
      </Modal>
      <TransactionTemplateDialog title={"Шаблон списания"}
                                 readOnly={true}
                                 visible={showInfo !== null}
                                 record={showInfo}
                                 onOk={(record) => { setShowInfo(null); }}
                                 onCancel={() => { setShowInfo(null) }}/>

    </>
  )
}
import React from "react";
import {Popover, Switch} from "antd";

export default function TrackerItem({item, onBlockedChange}) {
    return (
        <div className="tracker">
            <div className="label">{item.label}</div>
            <Switch title="Изменить статус блокировки" className="block-control" checked={item.is_blocked} onChange={onBlockedChange} />
            <Popover content={item.connection_state}>
                <div className={`state state-${item.connection_state}`}>&nbsp;</div>
            </Popover>
        </div>
    );
}
import React from "react";
import {Table} from "antd";
import moment from "moment";
import {TemplatePaymentSource} from "../../../../common/AppEnums";

export default function PaymentsTab({payments, loading}) {

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата',
      dataIndex: 'create_dt',
      width: 200,
      render: (text, record, index) => {
        return record.create_dt ? `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}` : "";
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      width: 130,
    },
    {
      title: "Источник",
      dataIndex: "payment_source",
      width: 180,
      render: (text, record, index) => {
        return TemplatePaymentSource.find(el => el.value === record.payment_source)?.text
      }
    },
    {
      title: "Описание",
      dataIndex: "description"
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <Table
      dataSource={payments}
      columns={columns}
      size="small"
      rowKey="uid"
      loading={loading}
      scroll={{x: 1000}}
      sticky
      pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
    />
  )
}
import React, {useState} from "react";
import MenuButton from "../../../buttons/MenuButton";
import ServiceSummaryTypeStatusPage from "./ServiceSummaryTypeStatusPage";
import ServiceSummaryModelStatusPage from "./ServiceSummaryModelStatusPage";

export default function ServiceSummaryStatusPage() {

  let [selectedTab, setSelectedTab] = useState("type");

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <MenuButton value = "По типу ТС" statusBtn={selectedTab === "type"} onClick={() => setSelectedTab("type")}/>
        <MenuButton value = "По модели" statusBtn={selectedTab === "model"} onClick={() => setSelectedTab("model")}/>
      </div>

      {selectedTab === "type" && (
        <ServiceSummaryTypeStatusPage/>
      )}

      {selectedTab === "model" && (
        <ServiceSummaryModelStatusPage/>
      )}

    </>
  )
}
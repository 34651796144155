import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, DatePicker, Dropdown, Menu, message, PageHeader, Select, Space, Table} from "antd";

import './DowntimeReport.css'
import {ParkStatuses, VehicleType} from "../../../common/AppEnums";
import numeral from "numeral"
import TypeDowntimeDetailsDialog from "./TypeDowntimeDetailsDialog";
import moment from "moment/moment";
import ServiceSummaryTypeParkingDetailsDialog from "./ServiceSummaryTypeParkingDetailsDialog";
import {DownOutlined, FileExcelOutlined} from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CMD_EXPORT = "export"

export default function TypeDowntimeReport({update}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState([]);
  let [selectedTypes, setSelectedTypes] = useState(null);
  let [reportStartDate, setReportStartDate] = useState(null);
  let [reportEndDate, setReportEndDate] = useState(null);
  let [vehicleStatusFilter, setVehicleStatusFilter] = useState([]);
  let [summary, setSummary] = useState({});
  let [detailsRecords, setDetailsRecords] = useState(null);
  let [alertLevels, setAlertLevels] = useState({});
  let [idle, setIdle] = useState([]);
  let [totalVehicles, setTotalVehicles] = useState(0);
  let [longParkingDetails, setLongParkingDetails] = useState(null);
  let [minIdlePeriod, setMinIdlePeriod] = useState("");
  let [maxIdlePeriod, setMaxIdlePeriod] = useState("");

  useEffect(() => {
    setLoading(true);
    const params = {
      "status": vehicleStatusFilter,
      "region": selectedRegionId,
      "group": selectedGroupId,
      "vehicleTypes": selectedTypes,
      "from_dt": reportStartDate,
      "to_dt": reportEndDate,
    };
    if(isHistoryView()) {
      apiClient.getServiceDowntimeTypeStatusHistory(params)
        .then(res => {
          setRecords(res.records);
          setSummary(res.summary);
          setTotalVehicles(res.total_vehicles);
          setIdle([]);
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); })
    } else {
      apiClient.getServiceDowntimeTypeStatus(params)
        .then(res => {
          setRecords(res.records);
          setSummary(res.summary);
          setIdle(res.idle ? res.idle : []);
          setTotalVehicles(res.total_vehicles);
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); })
    }

  }, [
    update,
    selectedRegionId,
    selectedGroupId,
    selectedTypes,
    vehicleStatusFilter,
    reportStartDate,
    reportEndDate,
  ]);

  useEffect(() => {
    apiClient.getSystemProperty("parkmanager.downtime_status_alerts.*")
      .then(res => {
        if(res.values) {
          setAlertLevels(res.values);
        }
      })
      .catch(err => { message.error(err); });
    apiClient.getSystemProperty("parkmanager.typeSummary.*")
      .then(res => {
        if(res.values) {
          if(res.values["parkmanager.typeSummary.minIdlePeriod"]) {
            setMinIdlePeriod(res.values["parkmanager.typeSummary.minIdlePeriod"]);
          }
          if(res.values["parkmanager.typeSummary.maxIdlePeriod"]) {
            setMaxIdlePeriod(res.values["parkmanager.typeSummary.maxIdlePeriod"]);
          }
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [update]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => {
        setRegions(res.records);
      })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const executeSelectedRecordsOperation = key => {
    if(key === CMD_EXPORT) {
      const params = {
        "status": vehicleStatusFilter,
        "region": selectedRegionId,
        "group": selectedGroupId,
        "vehicleTypes": selectedTypes,
        "from_dt": reportStartDate,
        "to_dt": reportEndDate,
        "export": true
      };
      if(isHistoryView()) {
        apiClient.getServiceDowntimeTypeStatusHistory(params)
          .then(res => {
            window.open(res.link);
          })
          .catch(err => message.error(err));
      } else {
        apiClient.getServiceDowntimeTypeStatus(params)
          .then(res => {
            window.open(res.link);
          })
          .catch(err => message.error(err));
      }
    }
  };


  const isHistoryView = () => {
    return reportStartDate && reportEndDate;
  }

  const showRowDetails = (typeId, status) => {
    const params = {
      "status": status === 'all' ? vehicleStatusFilter : status,
      "region": selectedRegionId,
      "group": selectedGroupId,
      "vehicleTypes": selectedTypes
    };
    apiClient.getServiceDowntimeTypeStatusDetails(typeId, params)
      .then(res => {
        setDetailsRecords(res.records);
      })
      .catch(err => { message.error(err) });
  };

  const showLongInStatusDetails = (status, minDays, maxDays) => {
    setLoading(true);
    apiClient.getServiceSummaryTypeLongInStatusDetails(status, minDays, maxDays, {
      regionId: selectedRegionId,
      groupId: selectedGroupId,
    })
      .then(res => {
        setLongParkingDetails({records: res.records, status: status, minDays: minDays, maxDays: maxDays});
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  };


  const dataCellColor = (typeId, status, value) => {
    const summaryCell = typeId === 'summary' || status === 'total';
    const hasDetails = value.count > 0 && !summaryCell && !isHistoryView();
    if(value.count < 1) {
      return '#9c9c9c';
    }
    if(isHistoryView() || summaryCell) {
      return '#3f3d56';
    }
    return '#58d3a5';
  }

  const dataCell = (typeId, status, value) => {
    if(!value) {
      value = {count: 0, rate: '0'}
    }
    const rate = numeral(value.rate);
    const summaryCell = typeId === 'summary' || status === 'total';
    const hasDetails = value.count > 0 && !summaryCell && !isHistoryView();
    let style = {
      textAlign: 'center',
      fontSize: '0.9em',
      color: dataCellColor(typeId, status, value),
      cursor: hasDetails ? 'pointer' : 'auto'
    };
    if(alertLevels && alertLevels[`parkmanager.downtime_status_alerts.${status}`]) {
      const alertLevel = numeral(alertLevels[`parkmanager.downtime_status_alerts.${status}`]);
      if(rate.value() > alertLevel.value()) {
        style.color = '#972f2f';
      }
    }
    return (
      <div
        style={style}
        onClick={() => {
          if(hasDetails) {
            showRowDetails(typeId, status)
          }
        }}
      >
        {rate.format('0.00')}%
        <br/>
        {value.count > 0 ? <span>({value.count})</span> : null}
      </div>
    )
  };

  const summaryCell = (value, status) => {
    if(!value) {
      value = {count: 0, rate: '0'}
    }
    const rate = numeral(value.rate);
    let style = {textAlign: 'center', fontSize: '0.9em'};
    return (
      <>
        <div
          style={style}
        >
          {rate.format('0.00')}%
          <br/>
          <span>({value.count})</span>
        </div>
        {
          status !== "working" && (
            <div style={{textAlign: 'center'}}>
              {
                idle[status] && (
                  <span style={{color: '#ffc876'}}>
               <Button
                 type={"link"}
                 style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 3px'}}
                 onClick={() => {
                   showLongInStatusDetails(status, minIdlePeriod, maxIdlePeriod)
                 }}>
                {idle[status]?.min_idle}
                </Button>
              </span>
                )
              }
              {
                idle[status] && (
                  <span style={{color: 'darkred'}}>
                <Button
                  type={"link"}
                  style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 3px'}}
                  onClick={() => {
                    showLongInStatusDetails(status, maxIdlePeriod, 99999)
                  }}>
                  {idle[status]?.max_idle}
                </Button>
              </span>
                )
              }
            </div>
          )
        }
      </>
    )
  };

  const totalCell = (value) => {
    if (!value) {
      value = {count: 0, rate: '0'}
    }
    const rate = numeral(value.rate);
    let style = {textAlign: 'center', fontSize: '0.9em'};
    return (
      <div
        style={style}
      >
        {rate.format('0.00')}%
        <br/>
        {value.count > 0 ? <span>({value.count})</span> : null}
      </div>
    )
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Простои по типам ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Типов ТС: ${records.length}`}</span>
              <Dropdown
                overlay={(
                  <Menu onClick={(e) => {
                    executeSelectedRecordsOperation(e.key);
                  }}>
                    <Menu.Item key={CMD_EXPORT} icon={<FileExcelOutlined/>}>
                      Выгрузка в Excel
                    </Menu.Item>
                  </Menu>
                )}>
                <Button style={{textAlign: "left"}}>
                  Действия <DownOutlined/>
                </Button>
              </Dropdown>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          allowClear
          mode="multiple"
          placeholder="Статус"
          value={vehicleStatusFilter}
          style={{width: 300}}
          onChange={(data) => {
            setVehicleStatusFilter(data);
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            ParkStatuses
              .filter(el => el.enabled)
              .map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Филиал"
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          mode={"multiple"}
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Тип ТС"
          style={{width: 300}}
          onChange={(data) => { setSelectedTypes(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            VehicleType.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Space style={{margin: '10px 0'}}>
        <RangePicker
          format="DD.MM.YYYY"
          disabledDate={(dt) => {
            return dt && dt > moment().startOf('day');
          }}
          style={{width: 350}}
          onChange={(dates, dateStrings) => {
            if(dates) {
              setReportStartDate(dates[0].format('YYYY-MM-DD'));
              setReportEndDate(dates[1].format('YYYY-MM-DD'));
            } else {
              setReportStartDate(null);
              setReportEndDate(null);
            }
          }}
        />
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1300}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
            fixed: 'left',
          },
          {
            title: 'Тип ТС',
            dataIndex: 'type_label',
            align: "center",
            width: 100,
          },
          {
            title: 'Всего',
            dataIndex: 'count',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.type_id, 'all', {count: record.count, rate: '100'});
            },
          },
          ...ParkStatuses
            .filter(el => el.enabled && (vehicleStatusFilter.length < 1 || vehicleStatusFilter.includes(el.id)))
            .map((el) => {
              return {
                title: el.title,
                dataIndex: el.id,
                align: "center",
                render: (text, record, index) => {
                  return dataCell(record.type_id, el.id, record[el.id]);
                },
              }
            }),
          {
            title: 'Простой от общего кол-ва ТС',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return totalCell(record.full_total);
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
            fixed: 'right',
          }
        ]}
        dataSource={records}
        rowKey="brand_model"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let i = 0;
          let cells = [
            <Table.Summary.Cell index={i++}></Table.Summary.Cell>,
            <Table.Summary.Cell index={i++}><b>Обратить внимание на простои</b></Table.Summary.Cell>,
            <Table.Summary.Cell index={i++}><div style={{textAlign: 'center', fontSize: '0.9em'}}>{totalVehicles}</div></Table.Summary.Cell>
          ];
          cells.push(
            ...ParkStatuses
              .filter(el => el.enabled && (vehicleStatusFilter.length < 1 || vehicleStatusFilter.includes(el.id)))
              .map((el) => {
                return <Table.Summary.Cell index={i++}>{summaryCell(summary[el.id], el.id)}</Table.Summary.Cell>
              })
          );
          cells.push(...[
            <Table.Summary.Cell index={i++}>
              {
                summaryCell({
                  count: totalVehicles - parseInt(summary?.working?.count ?? 0),
                  rate: 100 - parseFloat(summary?.working?.rate ?? 0),
                })
              }
            </Table.Summary.Cell>,
          ]);
          return loading ? null : (
            <>
              <Table.Summary.Row>
                {cells}
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        detailsRecords && (
          <TypeDowntimeDetailsDialog
            data={detailsRecords}
            onClose={() => {
              setDetailsRecords(null);
            }}/>
        )
      }
      {
        longParkingDetails && (
          <ServiceSummaryTypeParkingDetailsDialog
            data={longParkingDetails.records}
            status={longParkingDetails.status}
            minDays={longParkingDetails.minDays}
            maxDays={longParkingDetails.maxDays}
            onClose={() => { setLongParkingDetails(null); }}
          />
        )
      }

    </>
  )
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Col, Input, message, Modal, Row} from "antd";

const rowStyle = {padding: '8px'};

export default function FinesPayerEditDialog({title, visible, recordId, onOk, onCancel}) {

  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(recordId) {
      apiClient.getFinesPayer(recordId)
        .then(res => {
          setRecord(res.record)
        })
        .catch(err => { message.error(err); });
    } else {
      setRecord({id: 0});
    }
  }, [recordId]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        const rules = [
          {prop: "full_title", message: "Укажите значение Плательщик (полн)"},
          {prop: "short_title", message: "Укажите значение Плательщик (кратк)"},
          {prop: "payment_account", message: "Укажите значение Плательщик Счет"},
          {prop: "checking_account", message: "Укажите значение Плательщик Расч.Счет"},
          {prop: "inn", message: "Укажите значение Плательщик ИНН"},
          {prop: "kpp", message: "Укажите значение Плательщик КПП"},
          {prop: "bank_title", message: "Укажите значение Плательщик Банк"},
          {prop: "bank_bik", message: "Укажите значение Плательщик БИК"},
          {prop: "bank_acount", message: "Укажите значение Плательщик Корр.Счет"},
        ];
        for (let i = 0; i < rules.length; i++) {
          if(!record[rules[i].prop]) {
            message.warn(rules[i].message);
            return;
          }
        }
        onOk(record);
        setRecord({id: 0});
      }}
      onCancel={onCancel}
    >
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик (полн)</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.full_title}
            onChange={(e) => { setRecord({...record, full_title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик (кратк)</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.short_title}
            onChange={(e) => { setRecord({...record, short_title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик Счет</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.payment_account}
            onChange={(e) => { setRecord({...record, payment_account: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик Расч.Счет</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.checking_account}
            onChange={(e) => { setRecord({...record, checking_account: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик ИНН</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.inn}
            onChange={(e) => { setRecord({...record, inn: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик КПП</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.kpp}
            onChange={(e) => { setRecord({...record, kpp: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик Банк</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.bank_title}
            onChange={(e) => { setRecord({...record, bank_title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик БИК</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.bank_bik}
            onChange={(e) => { setRecord({...record, bank_bik: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Плательщик Корр.Счет</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={record.bank_acount}
            onChange={(e) => { setRecord({...record, bank_acount: e.target.value}) }}/>
        </Col>
      </Row>

    </Modal>
  );
}
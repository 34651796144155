import React, {useState} from "react";
import {
  message,
  Modal,
  Select
} from "antd";
import "../DriversPage.css"

const { Option } = Select;

export default function SelectWorkConditionsDialog({visible, onOk, onCancel, conditionsList}) {

  let [selectedWorkConditionId, setSelectedWorkConditionId] = useState(null);

  return (
    <Modal
      className="drivers-page-modal-style"
      title={"Условия работы"}
      visible={visible}
      width="400px"
      style={{ top: 20 }}
      onOk={() => {
        if(!selectedWorkConditionId) {
          message.warn("Выберите условия работы для назначения");
          return;
        }

        onOk(selectedWorkConditionId);
      }}
      onCancel={onCancel}
    >
      {
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Условия работы"
          onChange={(value) => {
            if(!value) {
              setSelectedWorkConditionId(null);
            } else {
              setSelectedWorkConditionId(value);
            }
          }}
          value={selectedWorkConditionId}
        >
          { conditionsList.map(s => <Option key={s.id} value={s.id}>{s.title}</Option>) }
        </Select>
      }
    </Modal>
  );
}
import React, {useState} from "react";
import {Input, Modal} from "antd";

export default function PasswordInputDialog({title, label, visible, onOk, onCancel, className}) {
  let [password, setPassword] = useState("");
  return (
    <Modal
      className={className}
      title={title}
      visible={visible}
      onOk={() => {
        const val = password;
        setPassword("");
        onOk(val);
      }}
      onCancel={() => {
        setPassword("");
        onCancel();
      }}>
      <label>{label}</label>
      <Input type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}/>
    </Modal>
  );
}
import React from "react";

const LinkButton = ({label, onClick, color}) => {
  return (
    <div
      style={{color: color ? color : '#58D3A5', cursor: 'pointer', textAlign: 'center'}}
      onClick={onClick}
    >
      {label}
    </div>
  );
}
export default LinkButton;
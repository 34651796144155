import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table, Input, Select} from "antd";

import {TemplateProcessingType, TemplatePaymentSource, TemplateCategory} from "../../../../common/AppEnums";
import TransactionTemplateDialog from "./TransactionTemplateDialog";
import {apiClient} from "../../../../../services/ApiClient";
import {EditFilled, QuestionOutlined} from "@ant-design/icons";

const {Search} = Input;
const { Option } = Select;

/**
 * @deprecated not use!
 */
export default function TransactionTemplatesTab() {
  let [loading, setLoading] = useState(false);
  let [dsLoading, setDsLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [ds, setDs] = useState([]);
  let [update, setUpdate] = useState(0);
  let [filter, setFilter] = useState({});
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      dataIndex: "id",
      width: 40,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EditFilled/>} onClick={() => { setEditedRecord(record); }}/>
          </Space>
        );
      }
    },
    {
      title: 'Наименование',
      width: 150,
      dataIndex: 'title',
    },
    {
      title: 'Счет',
      dataIndex: 'payment_source',
      align: "center",
      render: (text, record, index) => {
        return TemplatePaymentSource.find(el => el.value === record.payment_source)?.text
      },
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      align: "center",
      render: (text, record, index) => {
        return TemplateCategory.find(el => el.value === record.category)?.text;
      },
    },
    {
      title: 'Вид операции',
      dataIndex: 'processing_type',
      align: "center",
      render: (text, record, index) => {
        return TemplateProcessingType.find(el => el.value === record.processing_type)?.text
      },
    },
    {
      title: 'Ставка',
      dataIndex: 'rate',
      align: "center",
    },
    {
      title: 'Лимит',
      dataIndex: 'limit',
      align: "center",
      width: 100,
    },
    {
      title: 'Время',
      dataIndex: 'execution_time',
      align: "center",
    },
    {
      title: 'Комментарий',
      width: 200,
      dataIndex: 'comment',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const addRecord = record => {
    setLoading(true);
    apiClient.addTransactionTemplate(record)
      .then(res => {
        message.success("Шаблон добавлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Не удалось добавить шаблон");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateRecord = (recordId, params) => {
    setLoading(true);
    apiClient.updateTransactionTemplate(recordId, params)
      .then(res => {
        message.success("Шаблон обновлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Не удалось обновить шаблон");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selectedRecords.length} записей?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        let proms = selectedRecords.map(el => apiClient.removeTransactionTemplate(el));

        Promise.all(proms)
          .then(res => {
            setSelectedRecords([]);
          })
          .catch(err => {
            message.error(err);
          })
          .finally(() => {
            setUpdate(prevState => prevState + 1);
          });
      }
    });
  };

  useEffect(() => {
    setDsLoading(true);
    let params = {
      ...filter,
      search: search
    };
    apiClient.getTransactionTemplates(params)
      .then(res => {
        setDs(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список шаблонов");
      })
      .finally(() => {
        setDsLoading(false);
      });
  }, [update, search, filter]);

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedRecords.length > 0 ? `Выбрано ${selectedRecords.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap style={{marginBottom: 10}}>
            <Search
              enterButton
              placeholder={"Поиск по наименованию"}
              onSearch={(value, event) => { setSearch(value); }}
              allowClear={true}
              style={{width: 400}}/>

            <Select
              showSearch
              allowClear
              style={{ width: 250 }}
              placeholder="Счет"
              onChange={(value) => { setFilter({...filter, ...{paymentSource: value}}); }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                TemplatePaymentSource.map(el => <Option key={el.value} value={el.value}>{el.text}</Option>)
              }
            </Select>
            <Select
              showSearch
              allowClear
              style={{ width: 250 }}
              placeholder="Категория"
              onChange={(value) => { setFilter({...filter, ...{category: value}}); }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                TemplateCategory.map(el => <Option key={el.value} value={el.value}>{el.text}</Option>)
              }
            </Select>
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 10}} direction="horizontal">
            <Button type="primary" onClick={() => { setEditedRecord({id: 0}) }} loading={loading}>
              Создать
            </Button>
            {
              selectedRecords.length > 0
              ? (<Button type={"danger"} onClick={() => { removeSelected() }} loading={loading}>
                    Удалить
                  </Button>
                ) : null
            }
          </Space>
        </div>
      </div>
      <Table
        rowSelection={{
          selectedRecords, onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys)
          }
        }}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        size="small"
        columns={cols}
        dataSource={ds}
        scroll={{x: 1200, y: 600}}
        sticky
        loading={dsLoading}
        rowKey="id"
      />

      {
        editedRecord && (
          <TransactionTemplateDialog
            title={"Изменить запись"}
            visible={editedRecord !== null}
            record={editedRecord}
            onOk={({id, ...params}) => {
              if (id > 0) {
                updateRecord(id, params);
              } else {
                addRecord(params);
              }
              setEditedRecord(null);
            }}
            onCancel={() => {
              setEditedRecord(null)
            }}/>
        )
      }
    </>
  )
}
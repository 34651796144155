import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Input, Table} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import ParkEditDialog from "./ParkEditDialog";
import UpdateButton from "../../../buttons/UpdateButton";
const {Search} = Input;

export default function ParkManagementTab() {
  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Наименование',
      dataIndex: 'title',
      defaultSortOrder: 'descend',
    },
    {
      title: 'ID',
      dataIndex: 'park_id',
    },
    {
      title: "Действия",
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return <Button type="dashed" icon={<EditFilled/>} onClick={() => { setEditedRecord(record) }}>Изменить</Button>
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getParks({search: search})
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, search]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeParks(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            apiClient.getParks()
              .then(res => {
                setRecords(res.records);
              })
              .catch(err => message.error(err));
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAddPark = park => {
    apiClient.addPark(park)
      .then(res => {
        message.success("Таксопарк добавлен, водители уже загружаются");
        apiClient.getParks()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  const onUpdatePark = park => {
    apiClient.updatePark(park.id, {
      title: park.title,
      park_id: park.park_id,
      client_id: park.client_id,
      api_key: park.api_key,
      enable_sync: park.enable_sync,
      work_rules: park.work_rules,
    })
      .then(res => {
        message.success("Таксопарк обновлен");
        apiClient.getParks()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div style={{background: "rgb(88, 211, 165, 0.2)", color: "black", borderTop: "2px solid #58D3A5", width: "90%", padding: "10px"}}>
        Диспетчерская - это аккаунт яндекс-такси. Из диспетчерской мы подгружаем в CRM водителей, 
        транспортные средства и другую информацию.
      </div>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>
            {/* <Search
              enterButton
              placeholder={"Поиск по наименованию"}
              onChange={(e) => { setSearch(e.target.value) }}
              allowClear={true}
              style={{width: 400}}/> */}
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
            <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>
              Добавить
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить
            </Button>
          </Space>
        </div>
      </div>
      <Table rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          disabled: record.users_count > 0,
        }),
      }}
             columns={cols}
             size="small"
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
             scroll={{x: 1000}}
             sticky
             dataSource={records}
             rowKey="id"/>

      <ParkEditDialog visible={editedRecord !== null}
                    park={editedRecord}
                    onOk={(p) => {
                      if(p.id > 0){
                        onUpdatePark(p);
                      } else {
                        onAddPark(p);
                      }
                      setEditedRecord(null);
                    }}
                    onCancel={() => { setEditedRecord(null) }}
                    title={"Изменить запись"}/>
    </>
  )
}
import React, {useEffect, useState} from "react";
import {Modal, Col, Input, Row, Image, Empty, Checkbox, Space, Button, message} from "antd";
import moment from "moment";
import EmptyPage from "../../EmptyPage";
import {DriverPhotoControlTypes} from "../../../common/AppEnums";
import {apiClient} from "../../../../services/ApiClient";
import './ControlDetails.css';
import '../../ModalStyle.css';
import {AlertTwoTone, CheckOutlined, QuestionOutlined} from "@ant-design/icons";
import MenuButton from "../../../buttons/MenuButton";

const { TextArea } = Input;

const rowStyle = {padding: '8px'};

export default function ControlDetails({title, visible, record, onClose, onAccepted, onRejected, onItemPropertyChange}) {

  let [checkedPhotos, setCheckedPhotos] = useState([]);
  let [mileage, setMileage] = useState('');
  let [mileageAccepted, setMileageAccepted] = useState(false);
  let [item, setItem] = useState(null);
  let [selectedTab, setSelectedTab] = useState("1");

  const updatePhotoChecked = (photo, checked) => {
    if(checked) {
      setCheckedPhotos([...checkedPhotos, photo]);
    } else {
      setCheckedPhotos(p => p.filter(e => e.type !== photo.type));
    }
  };

  const onSendPhotosRequest = checkedPhotos => {
    apiClient.requestDriverPhotoControlPhotos(item.id, checkedPhotos.map(p => p.type))
      .then(res => {
        message.success("Запрос отправлен");
        setCheckedPhotos([]);
        onClose();
      })
      .catch(err => {
        message.error(err);
      });
  };

  const onAccept = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: "Вы уверены, что хотите принять фотоконтроль?",
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        apiClient.acceptDriverPhotoControl(item.id)
          .then(res => {
            message.success("Фотоконтроль принят");
            setCheckedPhotos([]);
            onAccepted();
          })
          .catch(err => {
            message.error(err);
          });
      },
    });
  };

  const onReject = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: "Вы уверены, что хотите отклонить фотоконтроль? При отклонении информация будет удалена из системы",
      icon: <AlertTwoTone />,
      visible: true,
      onOk() {
        apiClient.rejectDriverPhotoControl(item.id)
          .then(res => {
            message.success("Фотоконтроль отклонен");
            setCheckedPhotos([]);
            onRejected();
          })
          .catch(err => {
            message.error(err);
          });
      },
    });
  };

  const handleMileageInput = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: "Установить значение пробега?",
      icon: <AlertTwoTone />,
      visible: true,
      onOk() {
        apiClient.updatePhotoControl(item.id, {mileage: mileage})
          .then(res => {
            message.success("Фотоконтроль обновлен");
            setMileageAccepted(true);
            onItemPropertyChange('mileage', mileage);
          })
          .catch(err => {
            message.error(err);
          });
      },
    });
  };

  useEffect(() => {
    if(item) {
      setMileage(item.mileage);
      if(item.mileage) {
        setMileageAccepted(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if(record && record.id) {
      apiClient.getPhotoControlDetails(record.id)
        .then(res => {
          if(visible) {
            setItem(res.record);
          }
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      setItem(null);
      setMileage('');
      setMileageAccepted(false);
    }
  }, [record, visible]);

  const renderPhoto = photo => {
    return (
      <div style={{
        padding: '10px 10px',
        display: 'inline-block',
        border: photo.processing === "dashboard" && !mileageAccepted ? "3px solid darkred" : ""
      }} key={photo.id}>
        {
          photo.processing === "dashboard" && !mileageAccepted
            ? (<>
              <div style={{fontWeight: 'bold', fontSize: '1.3em', color: 'darkred'}}>Укажите пробег!</div>
              <div style={{padding: '5px 0'}}>
                <Space direction="horizontal">
                  <Input placeholder="Пробег" value={mileage} onChange={(e) => { setMileage(e.target.value) }} />
                  <Button icon={<CheckOutlined/>} onClick={() => handleMileageInput()}/>
                </Space>
              </div>
              </>
            ) : null
        }
        <Image
          about={photo.type}
          title={photo.title ? photo.title : DriverPhotoControlTypes[photo.type]}
          width={200}
          src={photo.url}
          className={`photo-${photo.status}`}
          style={{borderRadius: 20}}
        />
        <div>
          {
            photo.status === "requested"
              ? null
              : <Checkbox checked={checkedPhotos.indexOf(photo) >= 0} onChange={(e) => { updatePhotoChecked(photo, e.target.checked) }}/>
          }
          <span style={{margin: '0 5px'}}>{photo.title ? photo.title : DriverPhotoControlTypes[photo.type]}</span>
        </div>
      </div>
    );
  };

  const isAcceptDisabled = () => {
    return !item.photos || item.photos.some(photo => photo.processing === "dashboard" && !mileageAccepted)
  };

  function drawContent() {
    return (
      <>
        <MenuButton value = {item.photos && item.photos.length > 0 ? `Фото (${item.photos.length})` : 'Фото'} statusBtn={selectedTab === "1"} onClick={() => setSelectedTab("1")}/>
        {(item.feedback_video || item.feedback)
          ? (item.feedback_video
            ? (<MenuButton value = "Обращение" statusBtn={selectedTab === "2.1"} onClick={() => setSelectedTab("2.1")}/>)
            : (<MenuButton value = "Обращение" statusBtn={selectedTab === "2.2"} onClick={() => setSelectedTab("2.2")}/>)) : null}
        <MenuButton value = "Данные" statusBtn={selectedTab === "3"} onClick={() => setSelectedTab("3")}/>

        {selectedTab === "1" && (
          <div>
            <div style={{textAlign: 'right', margin: '10px 0'}}>
              <Space direction="horizontal">
                {
                  checkedPhotos.length > 0 ? (
                    <Button type={"primary"} onClick={() => onSendPhotosRequest(checkedPhotos) }>Запросить {checkedPhotos.length} фото</Button>
                  ) : null
                }
                {
                  item.status === "complete"
                    ? null
                    : (
                      <>
                        <Button disabled={isAcceptDisabled()} onClick={() => onAccept() }>Принять фотоконтроль</Button>
                        <Button type="danger" onClick={() => onReject() }>Отклонить фотоконтроль</Button>
                      </>
                    )
                }
              </Space>
            </div>
            {
              item.photos && item.photos.length > 0
                ? (<div style={{textAlign: 'center'}}>
                  <Image.PreviewGroup>
                    {
                      item.photos.map(photo => renderPhoto(photo))
                    }
                  </Image.PreviewGroup>
                </div>) : <Empty description={"Нет фотографий"} />
            }
          </div>
        )}
        {selectedTab === "2.1" && (
          <div style={{textAlign: 'center'}}>
            <video width={400} controls="controls">
              <source src={item.feedback_video.url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
          </div>
        )}
        {selectedTab === "2.2" && (
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col span={3}>Обращение</Col>
            <Col span={9}>
              <TextArea
                value={item.feedback}
                readOnly={true} rows={3}
              />
            </Col>
          </Row>
        )}
        {selectedTab === "3" && (
          <div>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Дата</Col>
              <Col span={4}>
                <Input
                  value={moment(item.create_dt).format('DD.MM.YYYY HH:mm')}
                  bordered={false}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Водитель</Col>
              <Col span={9}>
                <Input
                  value={`${item.driver_info?.last_name} ${item.driver_info?.first_name} ${item.driver_info?.middle_name} (${item.driver_info?.phone})`}
                  bordered={false}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Транспортное средство</Col>
              <Col span={9}>
                <Input
                  value={item.vehicle_number ? `${item.vehicle_brand} ${item.vehicle_model} (${item.vehicle_number})` : ""}
                  bordered={false}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Пробег</Col>
              <Col span={9}>
                <Input
                  value={mileage}
                  bordered={false}
                  readOnly={true}/>
              </Col>
            </Row>
            {
              item.messages?.map(el => {
                return (
                  <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                    <Col span={3}>{el.title}</Col>
                    <Col span={9}>{el.message}</Col>
                  </Row>
                )
              })
            }
          </div>
        )}
      </>
    );
  }

  return (
    <Modal
      title={title}
      visible={visible}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        item ? drawContent() : <EmptyPage/>
      }
    </Modal>
  );
}
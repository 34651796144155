import React, {useState} from "react";
import {Col, DatePicker, message, Modal, Row} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";

const rowStyle = {padding: '8px'};

export default function AssignMaintenanceDateDialog({onOk, onCancel, visible, selectedRecords}) {

  let [dt, setDt] = useState(null);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      title={"Назначить дату тех.работ"}
      visible={visible}
      onOk={() => {
        apiClient.assignServiceMaintenanceTime(selectedRecords, {dt})
          .then(res => {
            onOk();
          })
          .catch(err => {
            message.warn(err);
          });
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={3}>Дата</Col>
        <Col md={18}>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            value={dt ? moment(dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setDt(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: '100%'}}
          />
        </Col>
      </Row>

    </Modal>
  );

}
import React, {useEffect, useState} from "react";
import {Modal, Table, message, Col, Input, Row, Checkbox, Image, Select, Button} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import EmptyPage from "../../EmptyPage";
import MenuButton from "../../../buttons/MenuButton";
import {QuestionOutlined} from "@ant-design/icons";
import '../../ModalStyle.css';
import './FineDetails.css'
import {TemplatePaymentSource} from "../../../common/AppEnums";
import {windowService} from "../../../../services/WindowService";

const { TextArea } = Input;
const { Option } = Select;

const rowStyle = {padding: '8px'};

export default function FineDetails({title, visible, item, onClose, onUpdate}) {

  let [loading, setLoading] = useState(false);
  let [finePayments, setFinePayments] = useState([]);
  let [finePaymentsLoading, setFinePaymentsLoading] = useState(false);
  let [finePhotos, setFinePhotos] = useState([]);
  let [selectedTabKey, setSelectedTabKey] = useState("1");
  let [drivers, setDrivers] = useState([]);
  let [driversSearch, setDriversSearch] = useState("");

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата',
      dataIndex: 'create_dt',
      width: 200,
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      width: 100,
    },
    {
      title: 'Источник',
      dataIndex: 'payment_source',
      width: 100,
      render: (text, record, index) => {
        return TemplatePaymentSource.find(el => el.value === record.payment_source)?.text;
      },
    },
    {
      title: "Плательщик",
      width: 400,
      render: (text, record, index) => {
        return (
          <Button
            type={"link"}
            style={{padding: 0}}
            onClick={() => {
              windowService.openRouteWindow(`cp/drivers/${record.driver.id}`);
            }}
          >
            {
              <>
                <div>{record.driver ? record.driver.title: ""}</div>
              </>
            }
          </Button>
        )
      },
    },
    {
      title: "Описание",
      dataIndex: "description"
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(item) {
      setLoading(false);

      apiClient.getFinePhotos(item.id)
        .then(res => {
          setFinePhotos(res.records);
        })
        .catch(err => {
          message.error(err);
        });
      apiClient.getFinePayments(item.id)
        .then(res => {
          setFinePayments(res.records);
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => {
          setFinePaymentsLoading(false);
        });
    } else {
      setLoading(true);
      setFinePayments([]);
      setFinePhotos([]);
    }
  }, [item]);

  useEffect(() => {
    if(item) {
      if(item.driver) {
        setDrivers([item.driver]);
      }
      apiClient.getDriversList({ accountStatus: 'all', search: driversSearch }, 'simple')
        .then(res => {
          setDrivers(res.drivers);
        })
        .catch(err => { console.error(err) });
    } else {
      setDrivers([]);
    }
  }, [item, driversSearch]);

  function drawContent() {
    return (
      <div className={loading ? "disabledContent" : ""}>

        <MenuButton value="Данные" statusBtn={selectedTabKey === "1"} onClick={() => setSelectedTabKey("1")}/>
        <MenuButton value={finePhotos ? `Фото (${finePhotos?.length})` : `Фото`} statusBtn={selectedTabKey === "2"} onClick={() => setSelectedTabKey("2")}/>
        <MenuButton value={finePayments ? `История платежей (${finePayments?.length})` : `История платежей`} statusBtn={selectedTabKey === "3"} onClick={() => setSelectedTabKey("3")}/>

        {selectedTabKey === "1" && (
          <>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Номер протокола</Col>
              <Col span={9}>
                <div><Input placeholder="УИН"
                            value={item.uin}
                            readOnly={true}/>
                </div>
              </Col>
              <Col span={2}>Дата протокола</Col>
              <Col span={4}>
                <Input
                  value={moment(item.decision_dt).format('DD.MM.YYYY')}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Транспортое средство</Col>
              <Col span={9}>
                <Input
                  placeholder="Рег. номер и СТС транспортного средства"
                  value={item.car_certificate ? `${item.car_number} - ${item.car_certificate}` : ""}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Водитель</Col>
              <Col span={9}>
                <Select
                  showSearch
                  value={item?.driver?.id}
                  style={{width: 400}}
                  onChange={(value) => {
                    if (value) {
                      if (!item.driver) {
                        Modal.confirm({
                          title: "Подтверждение",
                          content: `Назначить штраф выбранному водителю?`,
                          icon: <QuestionOutlined/>,
                          visible: true,
                          onOk() {
                            setLoading(true);
                            onUpdate({driver: value})
                          }
                        });
                      } else {
                        const prevDriverPayments = finePayments.filter(el => el.operation_type === 'debit' && el.driver && el.driver.id === item.driver.id);
                        if(prevDriverPayments.length > 0) {
                          Modal.confirm({
                            title: "Подтверждение",
                            width: '70%',
                            content: (
                              <>
                                <p>При назначении нового водителя на штраф, средства, списанные с водителя '{item.driver.last_name} {item.driver.first_name} {item.driver.middle_name}', будут возвращены на тот же баланс, с которого были списаны. Продолжить?</p>
                                <Table
                                  rowKey="id"
                                  columns={[
                                    {
                                      title: "",
                                      align: "center",
                                      width: 10,
                                    },
                                    {
                                      title: 'Дата',
                                      dataIndex: 'create_dt',
                                      width: 150,
                                      render: (text, record, index) => {
                                        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
                                      },
                                      defaultSortOrder: 'descend',
                                      sorter: (a, b) => {
                                        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
                                      },
                                    },
                                    {
                                      title: "Сумма",
                                      dataIndex: "amount",
                                      width: 100,
                                    },
                                    {
                                      title: 'Источник',
                                      dataIndex: 'payment_source',
                                      width: 100,
                                      render: (text, record, index) => {
                                        return TemplatePaymentSource.find(el => el.value === record.payment_source)?.text;
                                      },
                                    },
                                    {
                                      title: "Описание",
                                      dataIndex: "description"
                                    },
                                  ]}
                                  size="small"
                                  scroll={{x: 1000}}
                                  sticky
                                  dataSource={prevDriverPayments}
                                />
                              </>
                            ),
                            icon: <QuestionOutlined/>,
                            visible: true,
                            onOk() {
                              setLoading(true);
                              onUpdate({driver: value})
                            }
                          });
                        } else {
                          Modal.confirm({
                            title: "Подтверждение",
                            content: `Назначить штраф выбранному водителю?`,
                            icon: <QuestionOutlined/>,
                            visible: true,
                            onOk() {
                              setLoading(true);
                              onUpdate({driver: value})
                            }
                          });
                        }
                      }
                    }
                  }}
                  onSearch={(value) => {
                    setDriversSearch(value);
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    drivers.map(el => <Option key={el.id}
                                              value={el.id}>{`${el.last_name} ${el.first_name} ${el.middle_name} (${el.is_working ? 'работает' : 'уволен'})`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Сумма</Col>
              <Col span={3}>
                <Input
                  placeholder="руб"
                  value={item.amount_to_pay}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Статья</Col>
              <Col span={9}>
                <TextArea
                  rows={3}
                  placeholder=""
                  value={item.article_description}
                  readOnly={true}/>
              </Col>
              <Col span={1}>Код</Col>
              <Col span={2}>
                <Input
                  placeholder="руб"
                  value={item.article}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Дата нарушения</Col>
              <Col span={4}>
                <Input
                  placeholder="руб"
                  value={item.violation_dt && item.violation_dt !== '0001-01-01T00:00:00'
                    ? moment(item.violation_dt).format('DD.MM.YYYY HH:mm') :
                    "нет данных"
                  }
                  readOnly={true}/>
              </Col>
              <Col span={3}>Возможна оплата 50%</Col>
              <Col span={1}>
                <Checkbox
                  checked={item.is_discounted && item.discount_amount > 0}
                  disabled={true}
                  readOnly={true}/>
              </Col>
              <Col span={3}>Дата окончания скидки</Col>
              <Col span={2}>
                <Input
                  value={
                    item.is_discounted && item.discount_dt
                      ? moment(item.discount_dt).format('DD.MM.YYYY')
                      : "нет данных"
                  }
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Место нарушения</Col>
              <Col span={9}>
                <TextArea
                  rows={3}
                  placeholder=""
                  value={item.offense_place}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Орган власти</Col>
              <Col span={21}>
                <Input
                  placeholder=""
                  value={item.division_name}
                  readOnly={true}/>
              </Col>
            </Row>
            <Row gutter={[8, 0]} align="middle" style={rowStyle}>
              <Col span={3}>Дата загрузки</Col>
              <Col span={4}>
                <Input
                  placeholder="руб"
                  value={item.create_dt ? moment(item.create_dt).format('DD.MM.YYYY HH:mm') : ""}
                  readOnly={true}/>
              </Col>
              <Col span={2}>Дата обновления</Col>
              <Col span={4}>
                <Input
                  placeholder="руб"
                  value={item.upadte_dt ? moment(item.upadte_dt).format('DD.MM.YYYY HH:mm') : ""}
                  readOnly={true}/>
              </Col>
            </Row>
          </>
        )}
        {selectedTabKey === "2" && (
          finePhotos && finePhotos.length > 0
            ? <div style={{textAlign: 'center'}}>
              <Image.PreviewGroup>
                {
                  finePhotos.map(e => <span style={{padding: '0 10px'}}><Image width={600} src={e.url}/></span>)
                }
              </Image.PreviewGroup>
            </div> : <EmptyPage/>
        )}
        {selectedTabKey === "3" && (
          <Table
            dataSource={finePayments}
            columns={columns}
            size="small"
            rowKey="uid"
            scroll={{x: 1000}}
            sticky
            loading={finePaymentsLoading}
            pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
          />
        )}
      </div>
    );
  }

  return (
    <Modal
      title={title}
      visible={visible}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        item ? drawContent() : <EmptyPage/>
      }
    </Modal>
  );
}
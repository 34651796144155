import React, {useEffect, useState} from "react";
import {Button, DatePicker, message, Select, Space, Table} from "antd";
import {apiClient} from "../../../../../services/ApiClient";
import TransactionTemplatesStatisticsDetails from "./TransactionTemplatesStatisticsDetails";
import {TemplateCategory} from "../../../../common/AppEnums";

import moment from "moment";
import UnassignedDriversDetails from "./UnassignedDriversDetails";
import UpdateButton from "../../../../buttons/UpdateButton";

const { Option } = Select;

export default function TransactionTemplatesStatisticsTab() {

  let [loading, setLoading] = useState(false);
  let [driversCount, setDriversCount] = useState(0);
  let [unassignedDriversCount, setUnassignedDriversCount] = useState(0);
  let [selectedCategories, setSelectedCategories] = useState([]);
  let [selectedDate, setSelectedDate] = useState(moment());
  let [summaryData, setSummaryData] = useState([]);
  let [summaryDataUpdate, setSummaryDataUpdate] = useState(0);
  let [detailsData, setDetailsData] = useState(null);
  let [showUnassignedDrivers, setShowUnassignedDrivers] = useState(false);

  useEffect(() => {
    if(selectedDate) {
      setLoading(true);
      apiClient.getTransactionTemplateStatistics({
        "dt": selectedDate.format("YYYY-MM-DD"),
        "categories": selectedCategories.join(),
      })
        .then(res => {
          setSummaryData(res.records);
          if(res.header) {
            setDriversCount(res.header.drivers_total_count);
            setUnassignedDriversCount(res.header.drivers_unassigned_count);
          }
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false); })
    } else {
      setSummaryData([]);
    }
  }, [selectedDate, summaryDataUpdate, selectedCategories]);

  function showDetails(record, section) {
    setLoading(true);
    apiClient.getTransactionTemplateStatisticsDetails({
      "dt": selectedDate.format("YYYY-MM-DD"),
      "schedule_type": record.schedule_type,
      "pattern_run_days": record.pattern_run_days,
      "pattern_skip_days": record.pattern_skip_days,
      "section": section,
      "categories": selectedCategories.join(),
    })
      .then(res => {
        setDetailsData(res.records);
      })
      .catch(err => {
        message.error(err)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <>
      <Space direction={"horizontal"} style={{width: "100%", marginBottom: 12}}>
        <span>{`Водителей всего: ${driversCount}`}</span>
        <span>{`Водителей без условий работ: `}</span>
        <span style={{cursor: "pointer", "color": "#1891FF"}} onClick={() => {setShowUnassignedDrivers(true)}}>{unassignedDriversCount}</span>
      </Space>
      <Space direction={"horizontal"} style={{marginBottom: 10}}>
        <Select
          mode={"multiple"}
          value={selectedCategories}
          placeholder={"Категория"}
          style={{width: 220}}
          maxTagCount={2}
          allowClear={true}
          onChange={(val, option) => { setSelectedCategories(val); }}>
          {
            TemplateCategory.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
          }
        </Select>
        <DatePicker format="DD.MM.YYYY"
                    value={selectedDate}
                    onChange={(val) => {
                      if(val) {
                        val.hour(0);
                        val.minute(0);
                        val.second(0);
                      }
                      setSelectedDate(val);
                    }}
                    style={{ width: 220 }}/>
        
      </Space>
      <Space direction={"horizontal"} style={{float: "right", marginBottom: 11}}>
        <UpdateButton onClick={ () => setSummaryDataUpdate(prevState => prevState + 1) }/>
      </Space>
      <Table dataSource={summaryData}
          scroll={{y: 600}}
            sticky
             columns={[
              {
                title: "",
                align: "center",
                width: 30,
              },
               {
                 title: 'График списаний',
                 dataIndex: 'id',
                 align: "center",
                 render: (text, record, index) => {
                   return record.schedule_type === "regular"
                     ? "Ежедневно"
                     : `${record.pattern_run_days}/${record.pattern_skip_days}`;
                 },
               },
               {
                 title: 'Запланировано',
                 dataIndex: 'plan_count',
                 align: "center",
                 render: (text, record, index) => {
                   return (
                     <Button type={"link"}
                             onClick={() => {
                               if(record.plan_count > 0) {
                                 showDetails(record, "plan")
                               } else {
                                 message.info("Нет данных");
                               }
                             }}>{record.plan_count}</Button>
                   )
                 },
               },
               {
                 title: 'Списано',
                 dataIndex: 'complete_count',
                 align: "center",
                 render: (text, record, index) => {
                   return (
                     <Button type={"link"}
                             onClick={() => {
                               if(record.complete_count > 0) {
                                 showDetails(record, "complete")
                               } else {
                                 message.info("Нет данных");
                               }
                             }}>{record.complete_count}</Button>
                   )
                 },
               },
               {
                 title: 'Не списано',
                 align: "center",
                 render: (text, record, index) => {
                   return (
                     <Button type={"link"}
                             onClick={() => {
                               if(record.skip_count > 0) {
                                 showDetails(record, "skip")
                               } else {
                                 message.info("Нет данных");
                               }
                             }}>{record.skip_count}</Button>
                   )
                 },
               },
               {
                 title: 'Неуспешно',
                 dataIndex: 'fail_count',
                 align: "center",
                 render: (text, record, index) => {
                   return (
                     <Button type={"link"}
                             onClick={() => {
                               if(record.fail_count > 0) {
                                 showDetails(record, "fail")
                               } else {
                                 message.info("Нет данных");
                               }
                             }}>{record.fail_count}</Button>
                   )
                 },
               },
               {
                 title: "",
                 align: "center",
                 width: 20,
               }
             ]}
             size="small"
             rowKey="id"
             loading={loading}
             pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
             />

             <TransactionTemplatesStatisticsDetails visible={detailsData != null}
                                                    data={detailsData}
                                                    onClose={() => { setDetailsData(null) }} />
             <UnassignedDriversDetails visible={showUnassignedDrivers}
                                       onClose={() => { setShowUnassignedDrivers(false) }}/>
    </>

  )
}
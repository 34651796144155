import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Space, Table, Tabs} from "antd";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import StringInputDialog from "../common/StringInputDialog";

const { TabPane } = Tabs;

export default function WaybillSettingsPage() {
  let [wayBillQrLink, setWayBillQrLink] = useState(null);
  let [transferTypes, setTransferTypes] = useState([]);
  let [stringInputQuery, setStringInputQuery] = useState(null);

  useEffect(() => {
    apiClient.getSystemProperty("waybill_qr_link")
      .then(res => {
        setWayBillQrLink(res.value);
      });
    apiClient.getValueLists(["waybill_transfer_type"])
      .then(res => {
        setTransferTypes(res.records["waybill_transfer_type"])
      })
  }, []);

  const updateWayBillQrLink = () => {
    apiClient.setSystemProperty("waybill_qr_link", {
      value: wayBillQrLink
    })
      .then(res => {
        message.success("Изменения сохранены")
      })
      .catch(err => {
        message.error(err);
      });
  };

  const removeTransferType = (record) => {
    apiClient.removeValueListItem("waybill_transfer_type", record.id)
      .then(res => {
        setTransferTypes(res.records)
      })
  };

  return (
    <>
      <Tabs tabPosition={"left"}>
        <TabPane tab="Общие" key="8-2">
          <>
            <Row gutter={[0, 8]} style={{margin: '10px 0', marginBottom: 10}}>
              <Col span={3}>
                Ссылка QR-кода
              </Col>
              <Col span={18}>
                <Input value={wayBillQrLink} placeholder="https://..." style={{width: '100%'}} onChange={(e) => setWayBillQrLink(e.target.value)}/>
              </Col>
              <Col span={2}>
                <Button style={{marginLeft: 5}} type="dashed" icon={<SaveOutlined/>} onClick={updateWayBillQrLink}/>
              </Col>
            </Row>
          </>
        </TabPane>
        <TabPane tab="Виды перевозки">
          <Space style={{margin: '10px 0'}}>
            <Button type="primary" onClick={() => {
              setStringInputQuery({
                "title": "Добавить",
                "onOK": (title) => {
                  apiClient.addValueListItem("waybill_transfer_type", title)
                    .then(res => {
                      setTransferTypes(res.records)
                    })
                    .catch(err => message.error(err))
                }
              });
            }}>Добавить</Button>
          </Space>
          <Table
            pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
            size="small"
            columns={[
              {
                title: "",
                align: "center",
                width: 30,
              },
              {
                title: "",
                width: 50,
                align: "center",
                dataIndex: "id",
                render: function(text, record, index) {
                  return (
                    <Space direction={"horizontal"}>
                      <Button type="link" icon={<DeleteOutlined/>} onClick={() => {removeTransferType(record);}}/>
                    </Space>
                  );
                }
              },
              {
                title: 'Наименование',
                dataIndex: 'title',
              },
            ]}
            dataSource={transferTypes}
            rowKey="id"/>
        </TabPane>
      </Tabs>

      {
        stringInputQuery !== null
          ? (
            <StringInputDialog
              title={stringInputQuery.title}
              onOK={(val) => {
                stringInputQuery.onOK(val);
                setStringInputQuery(null);
              }}
              onCancel={() => setStringInputQuery(null)}
            />
          ) : null
      }
    </>
  )
}
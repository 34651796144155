import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, message, Modal, Switch, Table} from "antd";
import {windowService} from "../../../../services/WindowService";
import {CheckCircleTwoTone} from "@ant-design/icons";

export default function VINDuplicatesDialog({onOK, onCancel, filter}) {

  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getVehiclesWithFilter("VINDuplicates", filter)
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      width={"60%"}
      title={"Дубликаты VIN"}
      visible={true}
      onOk={() => {
        onOK();
      }}
      onCancel={onCancel}>
      <>
        <Table
          dataSource={records}
          scroll={{x: 1000}}
          sticky
          columns={[
            {
              title: "",
              align: "center",
              width: 5,
            },
            {
              title: 'VIN',
              children: [
                {
                  title: "VIN",
                  dataIndex: 'vin',
                },
                {
                  title: 'Гос.номер',
                  dataIndex: 'reg_number',
                  width: 120,
                  render: (text, record, index) => {
                    return (
                      <Button
                        type={"link"}
                        onClick={() => {
                          windowService.openRouteWindow(`cp/park/vehicles/${record.id}`);
                        }}
                      >
                        {
                          <>
                            <div>
                              {record.reg_number}
                            </div>
                          </>
                        }
                      </Button>
                    );
                  },
                },
                {
                  title: 'СТС',
                  dataIndex: 'registration_cert',
                },
                {
                  title: 'Марка/Модель',
                  render: (text, record, index) => {
                    return `${record.brand} ${record.model}`;
                  }
                },
                {
                  title: 'Год выпуска',
                  width: 120,
                  render: (text, record, index) => {
                    return record.year;
                  }
                },
                {
                  title: 'Во владении',
                  width: 80,
                  render: (text, record, index) => {
                    return record.is_owned
                      ? <CheckCircleTwoTone twoToneColor="#52c41a" />
                      : <CheckCircleTwoTone twoToneColor="ff0000"/>;
                  }
                },
              ]
            },
            {
              title: "",
              align: "center",
              width: 5,
            },
          ]}
          size="small"
          style={{margin: '10px 0', marginTop: 40}}
          rowKey="id"
          loading={loading}
          pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
        />
      </>
    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table, Input} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import TenantDialog from "./TenantDialog";
import UpdateButton from "../../../buttons/UpdateButton";
const {Search} = Input;

export default function TenantManagementTab({hash, onUpdated}) {

  let [loading, setLoading] = useState(false);
  let [addTenantVisible, setAddTenantVisible] = useState(false);
  let [editedTenant, setEditedTenant] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Контактное лицо',
      dataIndex: 'contact',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      align: "center",
    },
    {
      title: 'Кол-во пользователей',
      dataIndex: 'users_count',
      align: "center",
    },
    {
      title: 'Статус',
      dataIndex: 'is_active',
      render: (text, record, index) => {
        return record.is_active ? "Активна" : "Не активна";
      }
    },
    {
      title: "Действия",
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return <Button type="dashed" icon={<EditFilled/>} onClick={() => { setEditedTenant(record) }}>Изменить</Button>
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    setLoading(true);
    let params = {
      search: search
    };
    apiClient.getTenants(params)
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, hash, search]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeTenants(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            onUpdated();
            apiClient.getTenants()
              .then(res => {
                setRecords(res.records);
              })
              .catch(err => message.error(err));
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAddTenant = (tenant) => {
    apiClient.addTenant(tenant)
      .then(res => {
        message.success("Организация добавлена");
        onUpdated();
        apiClient.getTenants()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  const onUpdateTenant = (tenant) => {
    apiClient.updateTenant(tenant.id, {
      is_active: tenant.is_active,
      code: tenant.code,
      name: tenant.name,
      contact: tenant.contact,
      phone: tenant.phone,
      navixy_login: tenant.navixy_login,
      navixy_password: tenant.navixy_password,
      driverbot_support_link: tenant.driverbot_support_link,
      driverbot_repair_link: tenant.driverbot_repair_link,
      driverbot_departures_link: tenant.driverbot_departures_link,
      comment: tenant.comment,
      scopes: tenant.scopes
    })
      .then(res => {
        onUpdated();

        apiClient.getTenants()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));

        message.success("Организация обновлена");
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}}>
          <Space direction={"horizontal"} wrap>
            <Search
              enterButton
              placeholder={"Поиск по наименованию"}
              onSearch={(value, event) => {
                setSearch(value);
              }}
              allowClear={true}
              style={{width: 400}}
            />
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}}>
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton loading={loading} onClick={() => {
              setUpdate(u => u + 1)
            }}/>
            <Button type="primary" loading={loading} onClick={() => {
              setAddTenantVisible(true)
            }}>
              Создать организацию
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить организацию
            </Button>
          </Space>
        </div>
      </div>
      <Table rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          disabled: record.users_count > 0,
        }),
      }}
             columns={cols}
             size="small"
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
             scroll={{x: 1000}}
             sticky
             dataSource={records}
             rowKey="id"/>

      {
        addTenantVisible
          ? (
            <TenantDialog
              visible={true}
              onOk={(tenant) => {
                onAddTenant(tenant);
                setAddTenantVisible(false);
              }}
              onCancel={() => {
                setAddTenantVisible(false)
              }}
            />
          ) : null
      }
      {
        editedTenant !== null
          ? (
            <TenantDialog
              visible={editedTenant !== null}
              record={editedTenant}
              onOk={(tenant) => {
                onUpdateTenant(tenant);
                setEditedTenant(null);
              }}
              onCancel={() => {
                setEditedTenant(null)
              }}
              title={"Изменить запись"}
            />
          ) : null
      }
    </>
  )
}
class WindowService {
  openRouteWindow(route) {
    window.open(`${process.env.REACT_APP_WEB_HOST}/${route}`, '_blank').focus();
  }
  openWindow(url) {
    window.open(url, '_blank').focus();
  }
}

const windowService = new WindowService();

export {windowService};
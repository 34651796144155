import React, {useState, useEffect} from "react";
import {Button, Card, Form, Input, Table, message, Space, Checkbox} from "antd";
import { Tabs } from 'antd';
import {LockOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
// import { EditorState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import ReactQuill from 'react-quill';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

const { TabPane } = Tabs;


export default function CreateNotificationPage({active}) {
  // let [editorState, setEditorState] = useState(() => EditorState.createEmpty(),);
  let [convertedContent, setConvertedContent] = useState(null);

  // useEffect(() => {
  //   let html = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(html);
  // }, [editorState]);

  // const createMarkup = html => {
  //   return {
  //     __html: DOMPurify.sanitize(html)
  //   }
  // };

  const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

  return (
      <ReactQuill  modules={modules} theme="snow" placeholder="Content goes here..."  />
  );

}
import React, {useContext, useEffect, useState} from "react";
import {Col, DatePicker, Input, message, Modal, Popover, Row, Select, Space, Table} from "antd";
import {LoadingOutlined, QuestionOutlined} from "@ant-design/icons";
import {RentContractStatus, RentContractType} from "../../../common/AppEnums";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import MenuButton from "../../../buttons/MenuButton";
import './RentContract.css'
import UserProfileContext from "../../../../services/UserProfileContext";
import '../../../controlpanel/ModalStyle.css'

const { Option } = Select;

const rowStyle = {padding: '4px'};
const inputErrorStyle = {border: "2px solid darkred", borderRadius: 20};

const EmptyRecord = {
  id: 0,
  driver: null,
  vehicle: null,
  contract_dt: moment().format("YYYY-MM-DD"),
  contract_start_dt: null,
  contract_end_dt: null,
  status: RentContractStatus[0].id,
  type: RentContractType[0].id,
  rent_prepay: 0,
  rent_day_price: 0,
  deposit: 0,
  deposit_initial_payment_price: 0,
  ransom_amount: 0,
  ransom_day_price: 0,
};

export default function RentContractEdit({title, visible, contract, vehicle, driver, onOk, onClose}) {
  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({id: 0});
  let [vehicleOwnersLoading, setVehicleOwnersLoading] = useState(false);
  let [vehicleOwners, setVehicleOwners] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [driversLoading, setDriversLoading] = useState(false);
  let [vehicles, setVehicles] = useState([]);
  let [vehiclesLoading, setVehiclesLoading] = useState(false);
  let [historyRecords, setHistoryRecords] = useState([]);
  let [workConditions, setWorkConditions] = useState([]);
  let [workConditionsLoading, setWorkConditionsLoading] = useState(false);
  let [selectedTabKey, setSelectedTabKey] = useState("1");
  let [formErrors, setFormErrors] = useState([]);

  let userProfile = useContext(UserProfileContext);

  useEffect(() => {
    if(visible) {
      if(contract) {
        const recordToEdit = {
          ...EmptyRecord,
          driver: driver,
          vehicle: vehicle,
          responsible: {
            id: userProfile.id,
            title: `${userProfile.first_name} (${userProfile.username})`,
            letter_of_attorney: userProfile.letter_of_attorney
          },
          ...contract
        };
        setRecord(recordToEdit);
        if(driver) {
          setDrivers([driver])
        }
        if(vehicle) {
          setVehicles([vehicle]);
        }
        if(contract.owner) {
          setVehicleOwners([{id: contract.owner.id, fio: contract.owner.name}])
        }
        if(contract.work_condition) {
          setWorkConditions([contract.work_condition])
        }
      } else {
        setRecord(null);
      }

      setVehicleOwnersLoading(true);
      apiClient.getVehicleOwners()
        .then(res => {
          setVehicleOwners(res.records);
        })
        .catch(err => { message.error(err) })
        .finally(() => { setVehicleOwnersLoading(false);});

      setDriversLoading(true);
      apiClient.getDriversList({ accountStatus: 'working' }, 'simple')
        .then(res => {
          setDrivers(res.drivers);
        })
        .catch(err => { message.error(err) })
        .finally(() => { setDriversLoading(false) });

      setVehiclesLoading(true);
      apiClient.getVehicles({owned: true, documents_disabled: false})
        .then(res => {
          setVehicles(res.records);
          if(vehicle?.id) {
            let v = res.records.find(el => el.id === vehicle?.id);
            console.log("loaded vehicle record: ", v);
            if(v && v.owner){
              setRecord(prevState => { return {...prevState, owner: v.owner} })
            }
          }
        })
        .catch(err => message.error(err))
        .finally(() => { setVehiclesLoading(false) });

      setWorkConditionsLoading(true);
      apiClient.getWorkConditions("minimal")
        .then(res => {
          setWorkConditions(res.records);
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => { setWorkConditionsLoading(false) })
    }
  }, [visible, contract, driver, vehicle]);

  useEffect(() => {
    if(contract && contract.id > 0 && selectedTabKey === "2") {
      setLoading(true);
      apiClient.getRentContractHistory(contract.id)
        .then(res => {
          setHistoryRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false))
    } else {
      setHistoryRecords([]);
    }
  }, [contract, selectedTabKey]);

  const buildStyle = (attribute, style = {}) => {
    return formErrors.indexOf(attribute) >= 0
      ? {
        ...style,
        ...inputErrorStyle
      } :
      style;
  };

  const form = () => {
    return (
      <>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>
            Статус
          </Col>
          <Col span={18}>
            <Select
              value={record.status}
              style={{width: 400}}
              onChange={(value) => {
                if(value) {
                  setRecord({...record, status: value})
                }
              }}
            >
              {
                RentContractStatus.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Номер</Col>
          <Col span={6}>
            <Input
              allowClear={true}
              style={{width: 400}}
              placeholder="Номер"
              value={record.contract_number}
              disabled={true}
            />
          </Col>
          <Col span={7} style={{textAlign: "right", padding: 4, marginLeft: 5}}>Дата</Col>
          <Col span={5}>
            <DatePicker
              allowClear={false}
              format="DD.MM.YYYY"
              placeholder="Дата"
              disabled={true}
              value={record.contract_dt ? moment(record.contract_dt, "YYYY-MM-DD") : moment()}
              style={{width: 220}}/>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>ТС</Col>
          <Col span={18}>
            <Select
              showSearch
              allowClear
              loading={vehiclesLoading}
              value={record.vehicle?.id}
              placeholder="ТС"
              style={buildStyle("vehicle", {width: 400})}
              onChange={(value) => {
                if(value) {
                  const vehicle = vehicles.find(el => el.id === value);

                  let owner = null;
                  if(vehicle.owner) {
                    owner = vehicleOwners.find(el => el.id === vehicle.owner.id);
                  }

                  let driver = record.driver;
                  if(record.id > 0) {
                    if(vehicle.drivers.length > 0) {
                      // за ТС закреплен водитель - уведомить пользователя об изменении
                      Modal.confirm({
                        title: "Подтверждение",
                        icon: <QuestionOutlined/>,
                        visible: true,
                        content: (
                          <div>
                            <p>ТС `{vehicle.reg_number}` уже арендуется водителями:</p>
                            <ul>
                              {
                                vehicle.drivers.map(el => <li key={el.id}>{el.name}</li>)
                              }
                            </ul>
                            <p>Продолжить?</p>
                          </div>
                        ),
                        onOk() {
                          setRecord({...record, vehicle: vehicle, owner: owner, driver: driver});
                        }
                      });
                    } else {
                      // за ТС не закреплено водителей, просто меняем ТС в этом договоре
                      setRecord({...record, vehicle: vehicle, owner: owner, driver: driver});
                    }
                  } else {
                    // для нового договора логика работы старая
                    if(!driver && vehicle.drivers.length > 0) {
                      driver = vehicle.drivers[0];
                    }
                    setRecord({...record, vehicle: vehicle, owner: owner, driver: driver});
                  }
                } else {
                  setRecord({...record, vehicle: null, owner: null});
                }
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                vehicles.map(el => <Option key={el.id} value={el.id}>{`${el.brand} ${el.model} ${el.reg_number}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Организация</Col>
          <Col span={6}>
            <Input
              allowClear={true}
              style={{width: 400}}
              placeholder="Организация"
              value={record.company}
              onChange={(e) => { setRecord({...record, company: e.target.value}) }}/>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>
            Арендодатель
          </Col>
          <Col span={20}>
            <Select
              showSearch
              allowClear
              disabled
              loading={vehicleOwnersLoading}
              value={record.owner?.id}
              style={buildStyle("owner", {width: '100%'})}
            >
              {
                (vehicleOwners.length > 0 ? vehicleOwners : record.owner ? [record.owner] : [])
                  .map(el => <Option key={el.id} value={el.id}>{`${el.fio || el.full_title || el.short_title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>
            Тип договора
          </Col>
          <Col span={20}>
            <Select
              value={record.type}
              style={{width: 400}}
              onChange={(value) => {
                if(value) {
                  setRecord({...record, type: value})
                }
              }}
            >
              {
                RentContractType.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Водитель</Col>
          <Col span={20}>
            <Select
              showSearch
              allowClear
              loading={driversLoading}
              placeholder="Водитель"
              value={record.driver?.id}
              style={buildStyle("driver", {width: 400})}
              onChange={(value) => {
                if(value) {
                  setRecord({...record, driver: drivers.find(el => el.id === value)});
                  let vehicle = vehicles
                    .filter(v => v.drivers.length > 0)
                    .find(v => v.drivers.find(d => d.id === value));
                  if(vehicle) {
                    setRecord({...record, vehicle: vehicle});
                  }
                } else {
                  setRecord({...record, driver: null});
                }
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                drivers.map(el => <Option key={el.id} value={el.id}>{`${el.last_name} ${el.first_name} ${el.middle_name}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Условия работы</Col>
          <Col span={6}>
            <Select
              showSearch
              allowClear
              loading={workConditionsLoading}
              value={record.work_condition?.id}
              placeholder="Условия работы"
              style={buildStyle("work_condition", {width: 400})}
              onChange={(value) => {
                if(value) {
                  const workCondition = workConditions.filter(el => el.id === value)[0];
                  const rent = workCondition.templates.find(el => el.category === "rent");
                  const deposit = workCondition.templates.find(el => el.category === "deposit");
                  const insurance = workCondition.templates.find(el => el.category === "insurance");
                  setRecord({
                    ...record,
                    work_condition: workCondition,
                    deposit: deposit ? deposit.limit : "",
                    rent_day_price: rent ? rent.rate : "",
                    insurance_rate: insurance ? insurance.rate : "",
                  });
                } else {
                  setRecord({...record, work_condition: null})
                }
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                workConditions.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
          </Col>
          <Col span={7} style={{textAlign: "right", padding: 4, marginLeft: 5}}>Дата начала</Col>
          <Col span={5}>
            <DatePicker
              allowClear={false}
              format="DD.MM.YYYY"
              placeholder="Дата"
              value={record.work_condition_dt ? moment(record.work_condition_dt, "YYYY-MM-DD") : null}
              onChange={(val) => {
                if(!val) {
                  setRecord({...record, work_condition_dt: null });
                } else {
                  setRecord({
                    ...record,
                    work_condition_dt: val.format("YYYY-MM-DD"),
                  });
                }
              }}
              style={{width: 220}}/>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Договор-основание</Col>
          <Col span={20}>
            <Input
              width={400}
              allowClear={true}
              value={record.base_contract}
              onChange={(e) => { setRecord({...record, base_contract: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Стоимость аренды в сутки</Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.rent_day_price}
              onChange={(e) => { setRecord({...record, rent_day_price: e.target.value}) }}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Предоплата
          </Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.rent_prepay}
              onChange={(e) => { setRecord({...record, rent_prepay: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Депозит</Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.deposit}
              onChange={(e) => { setRecord({...record, deposit: e.target.value}) }}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Взнос
          </Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.deposit_initial_payment_price}
              onChange={(e) => { setRecord({...record, deposit_initial_payment_price: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4} style={{textAlign: "left", paddingTop: 6, fontsize: '0.8em'}}>
            <Popover content={"Ежедневный платеж по выкупу"}>
              <span style={{fontStyle: 'underline'}}>Платеж</span>
            </Popover>
          </Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.ransom_day_price}
              onChange={(e) => { setRecord({...record, ransom_day_price: e.target.value}) }}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Страховой платеж
          </Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.insurance_rate}
              onChange={(e) => { setRecord({...record, insurance_rate: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Сумма выкупа</Col>
          <Col span={4}>
            <Input
              allowClear={true}
              type={"number"}
              style={{width: 215}}
              value={record.ransom_amount}
              onChange={(e) => { setRecord({...record, ransom_amount: e.target.value}) }}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Способ оплаты
          </Col>
          <Col span={8}>
            <Input
              allowClear={true}
              style={{width: '100%'}}
              value={record.deposit_initial_payment_type}
              onChange={(e) => { setRecord({...record, deposit_initial_payment_type: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Начало действия</Col>
          <Col span={4}>
            <DatePicker
              allowClear={true}
              format="DD.MM.YYYY"
              placeholder="Дата"
              value={record.contract_start_dt ? moment(record.contract_start_dt, "YYYY-MM-DD") : null}
              onChange={(val) => {
                if(!val) {
                  setRecord({...record, contract_start_dt: null });
                } else {
                  setRecord({
                    ...record,
                    contract_start_dt: val.format("YYYY-MM-DD"),
                    contract_end_dt: val.add(3, 'months').format("YYYY-MM-DD")
                  });
                }
              }}
              style={buildStyle("contract_start_dt", {width: 220})}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Место выдачи
          </Col>
          <Col span={11}>
            <Input
              allowClear={true}
              style={{width: '100%'}}
              value={record.issue_place}
              onChange={(e) => { setRecord({...record, issue_place: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Окончание действия</Col>
          <Col span={4}>
            <DatePicker
              allowClear={true}
              format="DD.MM.YYYY"
              placeholder="Дата"
              value={record.contract_end_dt ? moment(record.contract_end_dt, "YYYY-MM-DD") : null}
              onChange={(val) => { setRecord({...record, contract_end_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              style={buildStyle("contract_end_dt", {width: 220})}
            />
          </Col>
          <Col span={5} style={{textAlign: "left", padding: '4px 4px 4px 80px'}}>
            Место возврата
          </Col>
          <Col span={11}>
            <Input
              allowClear={true}
              style={{width: '300'}}
              value={record.return_place}
              onChange={(e) => { setRecord({...record, return_place: e.target.value}) }}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={4}>Ответственный за договор</Col>
          <Col span={6}>
            <Input
              disabled={true}
              allowClear={true}
              style={{width: 220}}
              value={record.responsible?.title}
            />
          </Col>
          <Col span={3}>Доверенность</Col>
          <Col span={11}>
            <Input
              style={{width: '100%'}}
              disabled={true}
              allowClear={true}
              value={record.responsible?.letter_of_attorney}
            />
          </Col>
        </Row>
      </>
    )
  };

  const history = () => {
    return (
      <>
        <Table
          loading={loading}
          size={'small'}
          scroll={{x: 1000}}
          sticky
          columns={[
            {
              title: "",
              align: "center",
              width: 30,
            },
            {
              title: 'Дата изменения',
              render: (text, record, index) => {
                return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
              }
            },
            {
              title: 'Реквизит',
              render: (text, record, index) => {
                return record.attr_name;
              }
            },
            {
              title: 'Старое значение',
              render: (text, record, index) => {
                return record.attr_old_value;
              }
            },
            {
              title: 'Новое значение',
              render: (text, record, index) => {
                return record.attr_new_value;
              }
            },
            {
              title: 'Пользователь',
              render: (text, record, index) => {
                return record.created_by ? record.created_by.username : "Система";
              }
            },
            {
              title: "",
              align: "center",
              width: 20,
            }
          ]}
          dataSource={historyRecords}
          rowKey="id"
        />
      </>
    );
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-max-window"
      title={title}
      visible={visible}
      onOk={() => {
        let errors = [];
        if(!record.driver) {
          errors.push("driver");
          message.warn("Поле Водитель не заполнено");
        }
        if(!record.vehicle) {
          errors.push("vehicle");
          message.warn("Поле ТС не заполнено");
        }
        if(!record.owner) {
          errors.push("owner");
          message.warn("Поле Арендодатель не заполнено");
        }
        if(!record.work_condition) {
          errors.push("work_condition");
          message.warn("Поле Условия работы не заполнено");
        }
        if(!record.contract_start_dt) {
          errors.push("contract_start_dt");
          message.warn("Поле Начало действия не заполнено");
        }
        if(!record.contract_end_dt) {
          errors.push("contract_end_dt");
          message.warn("Поле Окончание действия не заполнено");
        }
        if(errors.length > 0) {
          setFormErrors(errors);
          return;
        }
        onOk(record)
      }}
      onCancel={onClose}
    >
      {
        visible && record
          ? (
            <>
              <Space direction={"horizontal"}>
                <MenuButton value = "Информация" statusBtn={selectedTabKey === "1"} onClick={() => setSelectedTabKey("1")}/>
                {contract?.id > 0 && (<MenuButton value = "История" statusBtn={selectedTabKey === "2"} onClick={() => setSelectedTabKey("2")}/>)}
              </Space>
              {(selectedTabKey === "1") && form()}
              {(selectedTabKey === "2") && history()}
            </>
          )
          : (
            <div style={{margin: '20px auto'}}><LoadingOutlined /></div>
          )
      }

    </Modal>
  )
}
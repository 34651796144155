import React, {useEffect, useState} from "react";
import {
  PageHeader,
  Divider,
  DatePicker,
  Space,
  Button,
  Checkbox,
  Radio,
  message,
  Row,
  Col, Switch, Input, Modal, Select
} from "antd";

import './WayBillPage.css'
import {PrinterOutlined, ReloadOutlined} from "@ant-design/icons";
import DoctorSection from "./components/DoctorSection";
import MechanicSection from "./components/MechanicSection";
import OrganizationSection from "./components/OrganizationSection";
import DriverSection from "./components/DriverSection";
import CarSection from "./components/CarSection";
import {apiClient} from "../../../services/ApiClient";
import {TransferConnections} from "../../common/AppEnums";
import moment from "moment";

const { RangePicker } = DatePicker;
const { confirm, info } = Modal;
const { Option } = Select;

export default function WayBillPage() {
  let [period, setPeriod] = useState([moment().startOf('week').hours(12), moment().endOf('week').hours(12)]);
  let [doctor, setDoctor] = useState(null);
  let [mechanic, setMechanic] = useState(null);
  let [organization, setOrganization] = useState(null);
  let [driver, setDriver] = useState(null);
  let [car, setCar] = useState(null);
  let [withoutStamp, setWithoutStamp] = useState(false);
  let [addStampDate, setAddStampDate] = useState(false);
  let [addStampTime, setAddStampTime] = useState(false);
  let [stampTimePeriod, setStampTimePeriod] = useState(1);
  let [addMileage, setAddMileage] = useState(false);
  let [mileageType, setMileageType] = useState(1);
  let [mileageValue, setMileageValue] = useState("");
  let [doctorNotes, setDoctorNotes] = useState("");
  let [exchangeProcessing, setExchangeProcessing] = useState(false);
  let [withoutDoctorStamp, setWithoutDoctorStamp] = useState(false);
  let [withoutMechanicStamp, setWithoutMechanicStamp] = useState(false);
  let [disableDriverSign, setDisableDriverSign] = useState(false);
  let [disableDriverLicense, setDisableDriverLicense] = useState(false);
  let [transferType, setTransferType] = useState([]);
  let [transferTypes, setTransferTypes] = useState([]);
  let [transferConnectionType, setTransferConnectionType] = useState([]);
  let [enablePeriodSave, setEnablePeriodSave] = useState(false);
  let [enableTransferTypeSave, setEnableTransferTypeSave] = useState(false);
  let [enableTransferConnectionTypeSave, setEnableTransferConnectionTypeSave] = useState(false);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const printReport = () => {
    if(!doctor) {
      message.warn("Выберите мед.работника");
      return;
    }
    if(!mechanic) {
      message.warn("Выберите механика");
      return;
    }
    if(!organization) {
      message.warn("Выберите организацию");
      return;
    }
    if(!driver) {
      message.warn("Выберите водителя");
      return;
    }
    if(!car) {
      message.warn("Выберите автомобиль");
      return;
    }
    if(!selectedRegionId) {
      message.warn("Выберите филиал");
      return;
    }

    message.info("Печать...");

    apiClient.executeReport('waybill', {
      period: period || null,
      doctor: doctor.id,
      mechanic: mechanic.id,
      organization: organization.id,
      driver: driver.id,
      car: car.id,
      stampDisabled: withoutStamp,
      stampDate: addStampDate,
      stampTime: addStampTime,
      stampTimePeriod: stampTimePeriod,
      doctorNotes: doctorNotes,
      addMileage: addMileage,
      mileageType: mileageType,
      mileageValue: mileageValue,
      withoutDoctorStamp: withoutDoctorStamp,
      withoutMechanicStamp: withoutMechanicStamp,
      withoutDriverSign: disableDriverSign,
      withoutDriverLicense: disableDriverLicense,
      regionId: selectedRegionId,
      transferType: transferType.length > 0 ? transferType.map(v => {
        return transferTypes.find(tt => tt.id === v).title;
      }).join(", ") : "",
      transferConnectionType: transferConnectionType.length > 0 ? transferConnectionType.map(v => {
        return TransferConnections.find(tt => tt.value === v).text;
      }).join(", ") : "",
    })
      .then(res => {
        if(res.data) {
          let blob = new Blob([res.data], { type: 'text/html; charset=utf-8' }),
          url = window.URL.createObjectURL(blob);
          window.open(url)
        }
      })
  };

  const onStampTimePeriodChanged = (e) => {
    setStampTimePeriod(e.target.value);
    localStorage.setItem('WayBillPage.stampTimePeriod', e.target.value)
  };

  const awaitTask = task_id => {
    apiClient.getWaybillSourceExchangeStatus(task_id)
      .then(res => {
        if(!res.info?.finish_dt) {
          setTimeout(() => { awaitTask(task_id); }, 1000);
        } else {
          setExchangeProcessing(false);
          info({
            title: 'Выполнено',
            content: (
              <table style={{width: '100%'}}>
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th style={{textAlign: 'center'}}>Добавлено</th>
                  <th style={{textAlign: 'center'}}>Обновлено</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Организаций</td>
                  <td style={{textAlign: 'center'}}>{res.info.org_created}</td>
                  <td style={{textAlign: 'center'}}>{res.info.org_updated}</td>
                </tr>
                <tr>
                  <td>Авто</td>
                  <td style={{textAlign: 'center'}}>{res.info.cars_created}</td>
                  <td style={{textAlign: 'center'}}>{res.info.cars_updated}</td>
                </tr>
                <tr>
                  <td>Медиков</td>
                  <td style={{textAlign: 'center'}}>{res.info.medic_created}</td>
                  <td style={{textAlign: 'center'}}>{res.info.mechanic_updated}</td>
                </tr>
                <tr>
                  <td>Контролеров</td>
                  <td style={{textAlign: 'center'}}>{res.info.mechanic_created}</td>
                  <td style={{textAlign: 'center'}}>{res.info.mechanic_updated}</td>
                </tr>
                <tr>
                  <td>Водителей</td>
                  <td style={{textAlign: 'center'}}>{res.info.driver_created}</td>
                  <td style={{textAlign: 'center'}}>{res.info.driver_updated}</td>
                </tr>
                </tbody>
              </table>
            ),
            onOk() {},
          });
        }
      })
      .catch(err => message.error(err));
  };

  const onDoExchange = () => {
    confirm({
      title:  "Обновление данных",
      content: "Синхронизировать со справочниками Водители, ТС и Собственники?",
      icon: <ReloadOutlined/>,
      visible: true,
      onOk() {
        setExchangeProcessing(true);
        apiClient.executeWaybillSourceExchange()
          .then((res) => {
            if(res.info?.task_id) {
              awaitTask(res.info.task_id);
            } else {
              message.error("Не удалось выполнить синхронизацию");
              setExchangeProcessing(false);
            }
          })
          .catch(err => {
            message.error(err);
            setExchangeProcessing(false);
          });
      },
    });
  };

  useEffect(() => {
    // remove
    apiClient.getValueLists(["waybill_transfer_type"])
      .then(res => {
        setTransferTypes(res.records["waybill_transfer_type"])
      })
      .catch(err => message.error(err));
    // local
    if(localStorage.getItem(`WayBillPage.enablePeriodSave`) === null) {
      setEnablePeriodSave(false);
    } else {
      const enabled = localStorage.getItem('WayBillPage.enablePeriodSave') === "true";
      if(enabled) {
        setPeriod([moment(new Date(localStorage.getItem('WayBillPage.periodStart'))), moment(new Date(localStorage.getItem('WayBillPage.periodEnd')))]);
      }
      setEnablePeriodSave(enabled);
    }
    //
    if(localStorage.getItem(`WayBillPage.enableTransferTypeSave`) === null) {
      setEnableTransferTypeSave(false);
    } else {
      setEnableTransferTypeSave(localStorage.getItem('WayBillPage.enableTransferTypeSave') === "true");
      const rec = localStorage.getItem('WayBillPage.transferType');
      if(rec) {
        setTransferType(JSON.parse(rec));
      }
    }
    //
    if(localStorage.getItem(`WayBillPage.enableTransferConnectionTypeSave`) === null) {
      setEnableTransferConnectionTypeSave(false);
    } else {
      setEnableTransferConnectionTypeSave(localStorage.getItem('WayBillPage.enableTransferConnectionTypeSave') === "true");
      const rec = localStorage.getItem('WayBillPage.transferConnectionType');
      if(rec) {
        setTransferConnectionType(JSON.parse(rec));
      }
    }
    //
    if(localStorage.getItem(`WayBillPage.withoutDoctorStamp`) !== null) {
      setWithoutDoctorStamp(localStorage.getItem('WayBillPage.withoutDoctorStamp') === "true");
    }
    if(localStorage.getItem(`WayBillPage.withoutMechanicStamp`) !== null) {
      setWithoutMechanicStamp(localStorage.getItem('WayBillPage.withoutMechanicStamp') === "true");
    }
    if(localStorage.getItem(`WayBillPage.addStampDate`) !== null) {
      setAddStampDate(localStorage.getItem('WayBillPage.addStampDate') === "true");
    }
    if(localStorage.getItem(`WayBillPage.addStampTime`) !== null) {
      setAddStampTime(localStorage.getItem('WayBillPage.addStampTime') === "true");
    }
    if(localStorage.getItem(`WayBillPage.stampTimePeriod`) !== null) {
      setStampTimePeriod(parseInt(localStorage.getItem('WayBillPage.stampTimePeriod')));
    }
    if(localStorage.getItem(`WayBillPage.mileageType`) !== null) {
      setMileageType(parseInt(localStorage.getItem('WayBillPage.mileageType')));
    }
    if(localStorage.getItem(`WayBillPage.mileageValue`) !== null) {
      setMileageValue(localStorage.getItem('WayBillPage.mileageValue'));
    }
    if(localStorage.getItem(`WayBillPage.addMileage`) !== null) {
      setAddMileage(localStorage.getItem('WayBillPage.addMileage') === "true");
    }
    if(localStorage.getItem(`WayBillPage.disableDriverSign`) !== null) {
      setDisableDriverSign(localStorage.getItem('WayBillPage.disableDriverSign') === "true");
    }
    if(localStorage.getItem(`WayBillPage.disableDriverLicense`) !== null) {
      setDisableDriverLicense(localStorage.getItem('WayBillPage.disableDriverLicense') === "true");
    }
    if(localStorage.getItem(`WayBillPage.doctorNotes`) !== null) {
      setDoctorNotes(localStorage.getItem('WayBillPage.doctorNotes'));
    }
  }, []);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) });
  }, []);

  const updatePeriod = val => {
    setPeriod(val);
    if(enablePeriodSave && val && val.length > 0) {
      localStorage.setItem('WayBillPage.periodStart', val[0].toJSON());
      localStorage.setItem('WayBillPage.periodEnd', val[1].toJSON());
    }
  };

  const updateTransferType = (val) => {
    setTransferType(val);
    if(enableTransferTypeSave) {
      localStorage.setItem('WayBillPage.transferType', JSON.stringify(val));
    }
  };

  const updateTransferConnectionType = (val) => {
    setTransferConnectionType(val);
    if(enableTransferConnectionTypeSave) {
      localStorage.setItem('WayBillPage.transferConnectionType', JSON.stringify(val));
    }
  };

  const updateSavePeriod = (checked) => {
    localStorage.setItem('WayBillPage.enablePeriodSave', String(checked));
    if(!checked) {
      localStorage.removeItem('WayBillPage.periodStart');
      localStorage.removeItem('WayBillPage.periodEnd');
    } else if(period) {
      localStorage.setItem('WayBillPage.periodStart', period[0].toJSON());
      localStorage.setItem('WayBillPage.periodEnd', period[1].toJSON());
    }
    setEnablePeriodSave(checked);
  };

  const updateSaveTransferType = (checked) => {
    localStorage.setItem('WayBillPage.enableTransferTypeSave', String(checked));
    if(!checked) {
      localStorage.removeItem('WayBillPage.transferType');
    } else if(transferType) {
      localStorage.setItem('WayBillPage.transferType', JSON.stringify(transferType));
    }
    setEnableTransferTypeSave(checked);
  };

  const updateSaveTransferConnectionType = (checked) => {
    localStorage.setItem('WayBillPage.enableTransferConnectionTypeSave', String(checked));
    if(!checked) {
      localStorage.removeItem('WayBillPage.transferConnectionType');
    } else if(transferType) {
      localStorage.setItem('WayBillPage.transferConnectionType', JSON.stringify(transferConnectionType));
    }
    setEnableTransferConnectionTypeSave(checked);
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Путевые листы"
        extra={<Button type="primary" icon={<ReloadOutlined />} loading={exchangeProcessing} onClick={ () => onDoExchange() }>Обновить справочники</Button>}
      />
      <Divider />
      <Row align="middle" gutter={[16, 16]}>
        <Col span={3}>
          <h3>Филиал</h3>
        </Col>
        <Col span={6}>
          <Select
            showSearch
            allowClear
            style={{width: '100%'}}
            value={selectedRegionId}
            onChange={(value) => {
              setSelectedRegionId(value)
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
            }
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row align="middle" gutter={[16, 16]}>
        <Col span={3}>
          <h3>Выбор периода</h3>
        </Col>
        <Col span={6}>
          <RangePicker value={period} format="DD.MM.YYYY" style={{width: '100%'}} onChange={(val) => updatePeriod(val)}/>
        </Col>
        <Col span={15} style={{textAlign: "right"}}>
          <Switch checked={enablePeriodSave}
                  style={{width: 120}}
                  checkedChildren="Сохранять"
                  unCheckedChildren="Не сохранять"
                  onChange={(checked, event) => { updateSavePeriod(checked) }}/>
        </Col>
      </Row>
      <Divider />
      <CarSection onSelect={(record) => {
        setCar(record);
        if(record) {
          if(record.doctor) {
            setDoctor(record.doctor);
          }
          if(record.mechanic) {
            setMechanic(record.mechanic);
          }
          if(record.organization) {
            setOrganization(record.organization);
          }
          if(record.driver) {
            setDriver(record.driver);
          }
        }
      }}/>
      <Divider/>
      <DoctorSection
        onSelect={(record) => { setDoctor(record); }}
        onNotesUpdate={(notes) => {
          localStorage.setItem('WayBillPage.doctorNotes', notes);
          setDoctorNotes(notes)
        }}
        value={doctor}
        notesValue={doctorNotes}
      />
      <Divider />
      <MechanicSection onSelect={(record) => { setMechanic(record); }} value={mechanic}/>
      <Divider />
      <OrganizationSection onSelect={(record) => { setOrganization(record); }} value={organization}/>
      <Divider />
      <DriverSection onSelect={(record) => { setDriver(record); }} value={driver}/>
      <Divider />
      <Row align="middle" style={{margin: '8px 0'}}>
        <Col span={3}>
          <h3>Вид перевозки:</h3>
        </Col>
        <Col span={10}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Виды перевозки"
            value={transferType}
            onChange={(value) => { updateTransferType(value); }}
          >
            {transferTypes.map(tt => <Option key={tt.id}>{tt.title}</Option>)}
          </Select>
        </Col>
        <Col span={11} style={{ textAlign: "right"}}>
          <Switch checked={enableTransferTypeSave}
                  checkedChildren="Сохранять"
                  unCheckedChildren="Не сохранять"
                  style={{width: 120}}
                  onChange={(checked, event) => { updateSaveTransferType(checked) }}/>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <h3>Вид сообщения:</h3>
        </Col>
        <Col span={10}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Виды сообщения"
            value={transferConnectionType}
            onChange={(value) => { updateTransferConnectionType(value); }}
          >
            {TransferConnections.map(tt => <Option key={tt.value}>{tt.text}</Option>)}
          </Select>
        </Col>
        <Col span={11} style={{ textAlign: "right"}}>
          <Switch checked={enableTransferConnectionTypeSave}
                  checkedChildren="Сохранять"
                  unCheckedChildren="Не сохранять"
                  style={{width: 120}}
                  onChange={(checked, event) => { updateSaveTransferConnectionType(checked) }}/>
        </Col>
      </Row>
      <Divider />
        <Space direction="vertical">
          <p><Switch checkedChildren="С ЭЦП" unCheckedChildren="Без ЭЦП" defaultChecked onChange={(checked) => { setWithoutStamp(!checked); }} /></p>
          <Checkbox checked={withoutDoctorStamp} onChange={(e) => {
            setWithoutDoctorStamp(e.target.checked);
            localStorage.setItem('WayBillPage.withoutDoctorStamp', String(e.target.checked))
          }}>Не печатать штампы для медика</Checkbox>
          <Checkbox checked={withoutMechanicStamp} onChange={(e) => {
            setWithoutMechanicStamp(e.target.checked);
            localStorage.setItem('WayBillPage.withoutMechanicStamp', String(e.target.checked));
          }}>Не печатать штампы для механика</Checkbox>
          <Checkbox checked={addStampDate} onChange={(e) => {
            setAddStampDate(e.target.checked);
            localStorage.setItem('WayBillPage.addStampDate', String(e.target.checked))
          }}>Добавить дату на печать?</Checkbox>
          <Space direction="horizontal">
            <Checkbox checked={addStampTime} onChange={(e) => {
              setAddStampTime(e.target.checked);
              localStorage.setItem('WayBillPage.addStampTime', String(e.target.checked));
            }}>Добавлять время в печатях?</Checkbox>
            {addStampTime ?
              (
                <Radio.Group onChange={onStampTimePeriodChanged} value={parseInt(stampTimePeriod)}>
                  <Radio value={1}>утро</Radio>
                  <Radio value={2}>день</Radio>
                  <Radio value={3}>ночь</Radio>
                  <Radio value={4}>12/12</Radio>
                </Radio.Group>
              ) : null}
          </Space>
          <Checkbox checked={addMileage} onChange={(e) => {
            setAddMileage(e.target.checked);
            localStorage.setItem('WayBillPage.addMileage', String(e.target.checked));
          }}>Добавить пробег</Checkbox>
          { addMileage ?
              (
                <>
                <Radio.Group onChange={(e) => {
                  setMileageType(e.target.value);
                  localStorage.setItem('WayBillPage.mileageType', e.target.value);
                }} value={mileageType} style={{marginLeft: 20}}>
                  <Radio value={1} style={radioStyle}>
                    Указать пробег
                    {mileageType === 1
                      ? (<Input style={{ width: 100, marginLeft: 10 }}
                                value={mileageValue}
                                placeholder="Пробег"
                                onChange={(e) => {
                                  setMileageValue(e.target.value);
                                  localStorage.setItem('WayBillPage.mileageValue', e.target.value);
                                }}
                                allowClear={true} />)
                      : null}
                  </Radio>
                  <Radio style={radioStyle} value={2}>Предсменный</Radio>
                </Radio.Group>
                </>
              ) : null }
          <Checkbox checked={disableDriverSign} onChange={(e) => {
            setDisableDriverSign(e.target.checked);
            localStorage.setItem('WayBillPage.disableDriverSign', String(e.target.checked));
          }}>Не печатать блок водителя</Checkbox>
          <Checkbox checked={disableDriverLicense} onChange={(e) => {
            setDisableDriverLicense(e.target.checked);
            localStorage.setItem('WayBillPage.disableDriverLicense', String(e.target.checked));
          }}>Не печатать серию/номер ВУ, лицензию</Checkbox>
        </Space>
      <Divider />
      <Button type="primary" icon={<PrinterOutlined/>} onClick={printReport}>На печать</Button>
    </>
  );
}
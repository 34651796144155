import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, Col, InputNumber, message, Row} from "antd";
import {VehicleType} from "../../../common/AppEnums";

const PROP_SUPPLY_HOURS_DAYPLAN = "driversmanager.supplyHoursDayPlan";
const PROP_SUPPLY_HOURS_WEEKPLAN = "driversmanager.supplyHoursWeekPlan";
const PROP_SUPPLY_HOURS_DAY_PENALTY = "driversmanager.supplyHoursDayPenalty";

export default function SupplyHoursTab({active}) {

  let [loading, setLoading] = useState(false);
  let [supplyHoursDayPlan, setSupplyHoursDayPlan] = useState("");
  let [supplyHoursWeekPlan, setSupplyHoursWeekPlan] = useState("");
  let [supplyHoursDayPenalty, setSupplyHoursDayPenalty] = useState({});

  const saveData = () => {
    const properties = {};
    properties[PROP_SUPPLY_HOURS_DAYPLAN] = supplyHoursDayPlan;
    properties[PROP_SUPPLY_HOURS_WEEKPLAN] = supplyHoursWeekPlan;
    properties[PROP_SUPPLY_HOURS_DAY_PENALTY] = JSON.stringify(supplyHoursDayPenalty);

    setLoading(true);
    apiClient.setSystemProperties(properties)
      .then(res => {
        message.info("Изменения сохранены");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSupplyHoursDayPenalty = (vehicleType) => {
    return supplyHoursDayPenalty[vehicleType] || "";
  };

  const updateSupplyHoursDayPenalty = (vehicleType, value) => {
    setSupplyHoursDayPenalty(prevState => {
      let state = {...prevState};
      state[vehicleType] = value;
      return state;
    });
  };

  useEffect(() => { console.log(`[DEBUG] supplyHoursDayPenalty:`, supplyHoursDayPenalty) }, [supplyHoursDayPenalty]);

  useEffect(() => {
    apiClient.getSystemProperty(PROP_SUPPLY_HOURS_DAYPLAN)
      .then(res => {
        setSupplyHoursDayPlan(res.value);
      })
      .catch(err => message.error(err));
    apiClient.getSystemProperty(PROP_SUPPLY_HOURS_WEEKPLAN)
      .then(res => {
        setSupplyHoursWeekPlan(res.value);
      })
      .catch(err => message.error(err));
    apiClient.getSystemProperty(PROP_SUPPLY_HOURS_DAY_PENALTY)
      .then(res => {
        if(res.value && res.value[0] === '{') {
          setSupplyHoursDayPenalty(JSON.parse(res.value));
        }
      })
      .catch(err => message.error(err));
  }, [active]);

  return (
    <>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Норма часов в день</h4>
        </Col>
        <Col span={16}>
          <InputNumber
            allowClear={true}
            style={{marginBottom: 8}}
            value={supplyHoursDayPlan}
            onChange={(value) => { setSupplyHoursDayPlan(value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Норма часов в неделю</h4>
        </Col>
        <Col span={16}>
          <InputNumber
            allowClear={true}
            style={{marginBottom: 8}}
            value={supplyHoursWeekPlan}
            onChange={(value) => { setSupplyHoursWeekPlan(value) }}/>
        </Col>
      </Row>
      {
        VehicleType.map(el => { return (
          <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
            <Col span={5}>
              <h4>Сумма списаний в день ({el.title})</h4>
            </Col>
            <Col span={16}>
              <InputNumber
                allowClear={true}
                style={{marginBottom: 8}}
                value={getSupplyHoursDayPenalty(el.id)}
                onChange={(value) => { updateSupplyHoursDayPenalty(el.id, value) }}/>
            </Col>
          </Row>
        ) })
      }
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Сумма списаний в день (по умолчанию)</h4>
        </Col>
        <Col span={16}>
          <InputNumber
            allowClear={true}
            style={{marginBottom: 8}}
            value={getSupplyHoursDayPenalty("default")}
            onChange={(value) => { setSupplyHoursDayPenalty(value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={13}>
          <Button type="primary" loading={loading} onClick={() => { saveData() }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>
  )
}
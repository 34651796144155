import React, {useEffect, useState} from "react";
import moment from "moment";
import {Table, message, Space, Button} from "antd";
import {apiClient} from "../../../../../services/ApiClient";
import {ReloadOutlined} from "@ant-design/icons";
import UpdateButton from "../../../../buttons/UpdateButton";

export default function AuditTab({driver}) {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [update, setUpdate] = useState(0);


  useEffect(() => {
    if(driver) {
      setLoading(true);
      apiClient.getDriverAudit(driver.id, {categories: ["work_conditions", "sync_yandex", "contracts", "system", "fines", "ui"].join()})
        .then(res => setRecords(res.records))
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [driver, update]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата',
      dataIndex: 'create_dt',
      width: 200,
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
      },
    },
    {
      title: "Пользователь",
      dataIndex: "user",
      width: 200,
      render: (text, record) => {
        if(!record.user) {
          return "system";
        }
        return record.user.login;
      }
    },
    {
      title: "Сообщение",
      dataIndex: "comment"
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <div style={{marginBottom: 16}}>
      <Space style={{float: "right", marginBottom: 16}} direction={"horizontal"}>
        <UpdateButton onClick={() => setUpdate(prevState => prevState + 1)} />
      </Space>
      <Table dataSource={records}
             columns={columns}
             size="small"
             scroll={{x: 1000}}
             sticky
             rowKey="id"
             loading={loading}
             pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
      />
    </div>
  )
}
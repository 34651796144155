import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table, Input, Select} from "antd";
import {DeleteOutlined, DownloadOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import PrintFormEditDialog from "./PrintFormEditDialog";
import {PrintFormType, UnitTypes} from "../../../common/AppEnums";
import UpdateButton from "../../../buttons/UpdateButton";

const { Search } = Input;
const { Option } = Select;

export default function PrintFormsTab({active}) {
  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);

  const downloadRecord = record => {
    window.open(record.template, '_blank')
  };

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 100,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" onClick={() => { setEditedRecord(record) }}><EditFilled/></Button>
            <Button disabled={!record.template} type="link" onClick={() => { downloadRecord(record) }}><DownloadOutlined/></Button>
          </Space>
        )
      }
    },
    {
      title: 'Имя документа',
      width: 150,
      dataIndex: 'model_class',
      align: "center",
      render: (text, record, index) => {
        return PrintFormType.filter(el => el.id === record.model_class)[0].title
      }
    },
    {
      title: 'Имя формы',
      dataIndex: 'title',
            align: "center",
    },
    {
      title: 'Тип собственника',
      dataIndex: 'unit_type',
      align: "center",
      render: (text, record, index) => {
        return UnitTypes.filter(el => el.id === record.unit_type)[0].title
      }
    },
    {
      title: "Имя файла",
      dataIndex: "filename",
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    if(active) {
      setLoading(true);
      let params = {
        search: search,
        regionIds: selectedRegionId
      };
      apiClient.getPrintTemplates(params)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false); });
    } else {
      setRecords([]);
    }
  }, [active, search, selectedRegionId, update]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) });
  }, []);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removePrintTemplates(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(u => u + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAdd = ({title, model_class, unit_type, work_region_id, file}) => {
    setLoading(true);
    apiClient.addPrintTemplate({title, model_class, unit_type, work_region_id})
      .then(res => {
        apiClient.updatePrintTemplateContent(res.record.id, file)
          .then(res => {
            message.success("Форма добавлена");
            setUpdate(u => u + 1);
          })
          .catch(err => message.error(err))
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      })

  };

  const onUpdate = (formId, {title, model_class, unit_type, work_region_id, file}) => {
    apiClient.updatePrintTemplate(formId, { title, model_class, unit_type, work_region_id })
      .then(res => {
        if(file) {
          apiClient.updatePrintTemplateContent(res.record.id, file)
            .then(res => { message.success("Форма обновлена"); })
            .catch(err => message.error(err))
            .finally(() => {
              setLoading(false);
              setUpdate(u => u + 1);
            });
        } else {
          message.success("Форма обновлена");
          setUpdate(u => u + 1);
        }
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
          <div style={{width: '60%', float: 'left'}} >
            <Space direction={"horizontal"} wrap>
              <Search
                enterButton
                placeholder={"Поиск по наименованию"}
                onSearch={(value, event) => { setSearch(value); }}
                allowClear={true}
                style={{width: 400}}/>
              <Select
                allowClear
                mode="multiple"
                placeholder="Филиал"
                value={selectedRegionId}
                style={{width: 300}}
                onChange={(data) => { setSelectedRegionId(data); }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                }
              </Select>
            </Space>
          </div>
          <div style={{width: '40%', float: 'right'}} >
            <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
              <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
              <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>
                Добавить
              </Button>
              <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
                Удалить
              </Button>
            </Space>
          </div>
      </div>
      <Table
        pagination={
          {
            position: 'topRight',
            defaultPageSize: 50,
            pageSizeOptions: [25, 50, 75]
          }
        }
        rowSelection={{
          selectedRowKeys: selected,
          onChange: onSelectChange,
        }}
        loading={loading}
        size={'small'}
        columns={cols}
        scroll={{x: 1000}}
        sticky
        dataSource={records}
        rowKey="id"
      />

      {
        editedRecord !== null ? (
          <PrintFormEditDialog
            visible={true}
            template={editedRecord}
            onOk={(p) => {
              if (p.id > 0) {
                onUpdate(p.id, {...p});
              } else {
                onAdd(p);
              }
              setEditedRecord(null);
            }}
            onCancel={() => {
              setEditedRecord(null)
            }}
            title={"Печатная форма"}/>
        ) : null
      }

    </>
  )
}
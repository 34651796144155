import React, {useState} from "react";
import {Col, Input, InputNumber, Modal, Row} from "antd";
import './AddFineManualPaymentsDialog.css';

const { TextArea } = Input;

const rowStyle = {padding: '8px'};

export default function AddFineManualPaymentsDialog({visible, onOk, onCancel}) {

  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");

  return (
    <Modal
      className="add-fines-modal-style"
      title="Рассрочка штрафа"
      visible={visible}
      okText={'Внести'}
      destroyOnClose={true}
      onOk={() => {
        onOk(amount, comment);
        setAmount(0);
        setComment("");
      }}
      onCancel={onCancel}
    >
      <div>
        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col span={5}>Сумма</Col>
          <Col md={4}>
            <InputNumber value={amount} onChange={(value) => { setAmount(value) }}/>
          </Col>
        </Row>
        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col span={5}>Комментарий</Col>
          <Col md={19}>
            <TextArea
              value={comment}
              rows={3}
              onChange={(e) => { setComment(e.target.value) }}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );

}
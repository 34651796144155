import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Table} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import UpdateButton from "../../../buttons/UpdateButton";
import FinesPayerEditDialog from "./FinesPayerEditDialog";

export default function FinesPayersSettingsTab({active}) {

  let [loading, setLoading] = useState(false);
  let [editedId, setEditedId] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Полное наименование',
      dataIndex: 'full_title',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
    },
    {
      title: 'КПП',
      dataIndex: 'kpp',
    },
    {
      title: 'Банк',
      dataIndex: 'bank_title',
    },
    {
      title: "Действия",
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return <Button type="dashed" icon={<EditFilled/>} onClick={() => { setEditedId(record.id) }}>Изменить</Button>
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getFinesPayers()
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, active]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeFinesPayers(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAdd = (record) => {
    apiClient.addFinesPayer(record)
      .then(res => {
        message.success("Запись добавлена");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const onUpdate = (recordId, record) => {
    apiClient.updateFinesPayer(recordId, record)
      .then(res => {
        message.success("Запись обновлена");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}}>

        </div>
        <div style={{width: '40%', float: 'right'}}>
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton loading={loading} onClick={() => {
              setUpdate(u => u + 1)
            }}/>
            <Button type="primary" loading={loading} onClick={() => { setEditedId(0) }}>
              Добавить
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
              Удалить
            </Button>
          </Space>
        </div>
      </div>
      <Table
        rowSelection={{
          selectedRowKeys: selected,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled: record.users_count > 0,
          }),
        }}
        columns={cols}
        size="small"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        scroll={{x: 1000}}
        sticky
        dataSource={records}
        rowKey="id"
      />
      {
        editedId !== null
          ? (
            <FinesPayerEditDialog
              visible={editedId !== null}
              recordId={editedId}
              onOk={(record) => {
                if(editedId === 0) {
                  onAdd(record)
                } else {
                  onUpdate(editedId, record);
                }
                setEditedId(null);
              }}
              onCancel={() => {
                setEditedId(null)
              }}
              title={editedId > 0 ? "Изменить запись" : "Новая запись"}
            />
          ) : null
      }
    </>
  )
}
import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row} from "antd";
import {apiClient} from "../../../../services/ApiClient";

export default function SpectrumIntegrationsTab({active}) {
  let [loading, setLoading] = useState(false);
  let [spectrumDataLogin, setSpectrumDataLogin] = useState("");
  let [spectrumDataPassword, setSpectrumDataPassword] = useState("");
  let [spectrumDataPasswordRepeat, setSpectrumDataPasswordRepeat] = useState("");

  const saveData = () => {
    if(spectrumDataPassword !== spectrumDataPasswordRepeat) {
      message.warn("Введенные пароли не совпадают");
      return;
    }
    const properties = {
      'core.spectrum_data.login': spectrumDataLogin,
      'core.spectrum_data.password': spectrumDataPassword,
    };

    setLoading(true);
    apiClient.setSpectrumDataSettings(properties)
      .then(res => {
        message.info("Изменения сохранены");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    apiClient.getSpectrumDataSettings("core.spectrum_data.login")
      .then(res => {
        if(res.values["core.spectrum_data.login"]) {
          setSpectrumDataLogin(res.values["core.spectrum_data.login"]);
        }
        if(res.values["core.spectrum_data.password"]) {
          setSpectrumDataPassword(res.values["core.spectrum_data.password"]);
          setSpectrumDataPasswordRepeat(res.values["core.spectrum_data.password"]);
        }
      })
      .catch(err => message.error(err));
  }, [active]);

  return (
    <>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Логин</h4>
        </Col>
        <Col span={8}>
          <Input
            allowClear={true}
            placeholder="Логин"
            style={{width: 300, marginBottom: 8}}
            value={spectrumDataLogin}
            onChange={(e) => { setSpectrumDataLogin(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Пароль</h4>
        </Col>
        <Col span={4}>
          <Input
            type="password"
            allowClear={true}
            placeholder="Пароль"
            style={{width: 300, marginBottom: 8}}
            value={spectrumDataPassword}
            onChange={(e) => { setSpectrumDataPassword(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Повторите пароль</h4>
        </Col>
        <Col span={4}>
          <Input
            type="password"
            allowClear={true}
            placeholder="Повторите пароль"
            style={{width: 300, marginBottom: 8}}
            value={spectrumDataPasswordRepeat}
            onChange={(e) => { setSpectrumDataPasswordRepeat(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={13}>
          <Button type="primary" loading={loading} onClick={() => { saveData() }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>
  )
}
import React, {useContext, useEffect, useState} from "react";
import {Button, message, Space, Table, Modal, Input, Select} from "antd";
import UserDialog from "./UserDialog";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import UpdateButton from "../../../buttons/UpdateButton";
import UserProfileContext from "../../../../services/UserProfileContext";

const {Option} = Select;
const {Search} = Input;

export default function UsersManagementTab({active}) {
  const userProfile = useContext(UserProfileContext);

  let [loading, setLoading] = useState(false);
  let [addUserVisible, setAddUserVisible] = useState(false);
  let [editedUser, setEditedUser] = useState(null);
  let [selectedUsers, setSelectedUsers] = useState([]);
  let [usersDs, setUsersDs] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");
  let [groups, setGroups] = useState([]);
  let [tenants, setTenants] = useState([]);
  let [filters, setFilters] = useState({});

  const usersCols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Логин',
      dataIndex: 'username',
      align: "center",
    },
    {
      title: 'ФИО',
      dataIndex: 'first_name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      align: "center",
    },
    {
      title: 'Организация',
      dataIndex: 'tenant',
      align: "center",
      render: (text, record, index) => {
        return record.tenant ? record.tenant.name : null;
      },
    },
    {
      title: 'Роль',
      dataIndex: 'group',
      align: "center",
      render: (text, record, index) => {
        return record.group ? record.group.name : null;
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'date_joined',
      align: "center",
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.date_joined).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.date_joined).valueOf() - moment(b.date_joined).valueOf();
      }
    },
    {
      title: "Действия",
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return <Button type="dashed" icon={<EditFilled/>} onClick={() => { setEditedUser(record) }}>Изменить</Button>
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelectedUsers(selectedRowKeys);
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getUsers({
      search: search,
      group: filters.group,
      tenant: filters.tenant,
    })
      .then(res => {
        setUsersDs(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); })
  }, [active, update, search, filters]);

  useEffect(() => {
    if(userProfile.tenant.is_root) {
      apiClient.getGroups()
        .then(res => {
          setGroups(res.records);
        })
        .catch(err => {
          message.error(err);
        });
      apiClient.getTenants()
        .then(res => {
          setTenants(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [userProfile]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeUsers(selectedUsers)
          .then(res => {
            message.success("Пользователь удален");
            setUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAddUser = user => {
    apiClient.addUser(user)
      .then(res => {
        message.success("Пользователь добавлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const onUpdateUser = editedUser => {
    apiClient.updateUser(editedUser.id, {
      username: editedUser.username,
      first_name: editedUser.first_name,
      email: editedUser.email,
      password: editedUser.password,
      is_staff: editedUser.is_staff,
      group_id: editedUser.group ? editedUser.group.id : null,
      tenant_id: editedUser.tenant ? editedUser.tenant.id : null,
      permissions: editedUser.permissions,
      max_balance_debit: editedUser.max_balance_debit,
      max_balance_credit: editedUser.max_balance_credit,
      birthday_dt: editedUser.birthday_dt,
      birthday_message: editedUser.birthday_message,
      letter_of_attorney: editedUser.letter_of_attorney,
      short_name: editedUser.short_name,
    })
      .then(res => {
        message.success("Пользователь обновлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedUsers.length > 0 ? `Выбрано ${selectedUsers.length} записей` : ''}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <Space style={{float: "left", marginBottom: 16}} direction="horizontal">
          <Search
            enterButton
            placeholder={"Поиск по ФИО"}
            onSearch={(value, event) => { setSearch(value); }}
            allowClear={true}
            style={{width: 400}}/>
          <Select
            showSearch
            allowClear
            defaultValue={[]}
            style={{width: 400}}
            placeholder="Организация"
            onChange={(value) => {
              setFilters({...filters, "tenant": value})
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              tenants.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)
            }
          </Select>
          <Select
            showSearch
            allowClear
            defaultValue={[]}
            style={{width: 400}}
            placeholder="Роль"
            onChange={(value) => {
              setFilters({...filters, "group": value})
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              groups.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)
            }
          </Select>
        </Space>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <UpdateButton onClick={() => setUpdate(prevState => prevState + 1)}/>
            <Button type="primary" loading={loading} onClick={() => { setAddUserVisible(true) }}>
              Создать
            </Button>
            <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selectedUsers.length === 0}>
              Удалить
            </Button>
          </Space>
        </div>
      </div>

      <Table
        size={"small"}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        loading={loading}
        scroll={{x: 1000}}
        sticky
        rowSelection={{selectedUsers, onChange: onSelectChange}}
        columns={usersCols}
        dataSource={usersDs}
        rowKey="id"
      />

      {
        addUserVisible && (
          <UserDialog visible={addUserVisible}
                      record={{id: 0}}
                      onOk={(user) => {
                        setAddUserVisible(false);
                        onAddUser(user);
                      }}
                      onCancel={() => {
                        setAddUserVisible(false)
                      }}/>
        )
      }

      {
        editedUser !== null && (
          <UserDialog visible={editedUser !== null}
                      record={editedUser}
                      onOk={(user) => { onUpdateUser(user); setEditedUser(null); }}
                      onCancel={() => { setEditedUser(null) }}
                      title={"Изменить запись"}/>
        )
      }
    </>
  )
}
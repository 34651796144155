import React, {useEffect, useState} from "react";
import {Button, Space, Table, message} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {EyeOutlined} from "@ant-design/icons";
import InspectionDetails from "../../photocontrol/components/InspectionDetails";
import UpdateButton from "../../../buttons/UpdateButton";
import {windowService} from "../../../../services/WindowService";
import {InspectionType} from "../../../common/AppEnums";

export default function VehicleInspectionsHistory({vehicle, active, inspectionType}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [selectedId, setSelectedId] = useState(null);
  let [selected, setSelected] = useState(null);

  useEffect(() => {
    if(vehicle && active) {
      setLoading(true);
      apiClient.getVehicleInspectionsHistory(vehicle.id, inspectionType)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [active, vehicle, update]);

  useEffect(() => {
    if(selectedId) {
      apiClient.getInspectionControlDetails(selectedId)
        .then(res => {
          setSelected(res.record);
        })
        .catch(err => message.error(err));
    } else {
      setSelected(null);
    }
  }, [selectedId]);

  return (
    <>
      <div style={{marginBottom: 16}}>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: '',
            width: 40,
            render: (text, record, index) => {
              return (<Button type="link" icon={<EyeOutlined/>} onClick={() => {setSelectedId(record.id);}}/>)
            }
          },
          {
            title: 'Дата',
            width: 200,
            align: "center",
            render: (text, record, index) => {
              return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
            }
          },
          {
            title: 'Пробег',
            width: 200,
            dataIndex: 'mileage',
          },
          {
            title: 'Причина осмотра',
            width: 300,
            align: "center",
            dataIndex: 'inspection_type',
            render: (text, record, index) => {
              const inspectionType = InspectionType.find(el => el.id === record['inspection_type']);
              return inspectionType
                ? inspectionType.title
                : record['inspection_type']
            }
          },
          {
            title: 'Водитель',
            width: 450,
            render: (text, record, index) => {
              return record.driver
                ? (
                  <Button
                    type={"link"}
                    onClick={() => { windowService.openRouteWindow(`cp/drivers/${record.driver.id}`); }}
                  >
                    {record.driver.name}
                  </Button>
                ) : "";
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />
      <InspectionDetails
        title={"Инспекция"}
        visible={selected !== null}
        record={selected}
        onClose={() => { setSelectedId(null) }}
      />
    </>
  )
}
import React, {useContext, useEffect, useState} from "react";

import {Button, Col, DatePicker, Input, message, Modal, Row, Select, Space, Switch, TimePicker} from "antd";
import {
  SchedulerPeriods,
  TemplatePaymentSource,
  TemplateProcessingType,
  TemplateScheduleType
} from "../../../common/AppEnums";
import UserProfileContext from "../../../../services/UserProfileContext";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";

const { Option } = Select;
const { TextArea } = Input;

const emptyTemplate = {
  id: 0,
  title: '',
  comment: '',
  enable_custom_schedule: false,
  schedule_type: TemplateScheduleType[0].value,
  regular_period: SchedulerPeriods[0].value,
  pattern_run_days: 1,
  pattern_skip_days: 1,
  execution_time: null,
  start_dt: moment().format('YYYY-MM-DD'),
  rate: 0,
  limit: 0,
};
const rowStyle = {padding: '8px'};

export default function AddPaymentExecutionDialog({onOk, onClose, paymentLimit}) {
  const userProfile = useContext(UserProfileContext);

  let [template, setTemplate] = useState(emptyTemplate);
  let [suggestedTriggerTime, setSuggestedTriggerTime] = useState("");

  useEffect(() => {
    setTemplate({...emptyTemplate, limit: paymentLimit});
  }, []);

  useEffect(() => {
    console.log("retrieving time suggestion", template);
    setSuggestedTriggerTime("");
    apiClient.getTransactionTemplateAssignmentTriggerTimeSuggestion(template)
      .then(res => {
        if(res.data) {
          setSuggestedTriggerTime(moment(res.data).format("DD.MM.YYYY HH:mm:ss"));
        }
      })
      .catch(err => {
        console.warn(err);
      });
  }, [template]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Добавить списания на остаток долга"}
      visible={true}
      onCancel={() => { onClose(); }}
      footer={[
        <Button key="submit"
                type="primary"
                onClick={() => {
                  if(!template.rate) {
                    message.error("Укажите ставку");
                    return;
                  }
                  if(template.enable_custom_schedule) {
                    if(!template.schedule_type) {
                      message.error("Укажите порядок списания");
                      return;
                    }
                    if(template.schedule_type === "regular" && !template.regular_period) {
                      message.error("Укажите период списания");
                      return;
                    }
                    if(template.schedule_type === "pattern" && (!template.pattern_run_days || !template.pattern_skip_days)) {
                      message.error("Укажите параметры периодичности списаний");
                      return;
                    }
                  }
                  if(!template.execution_time) {
                    message.error("Укажите время списания");
                    return;
                  }

                  onOk(template);
                }}>
          OK
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            onClose();
          }}>
          Отмена
        </Button>,
      ]}
    >

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Баланс</Col>
        <Col span={18}>
          <Select
            disabled={true}
            value={"summary"}
            style={{width: '100%'}}>
            {
              TemplatePaymentSource.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
        </Col>
      </Row>

      <div>Сумма долга будет списана со сводного баланса, долг будет погашаться с Основного баланса по указанным ниже настройкам.</div>

      {/* Ставка */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Ставка, руб</Col>
        <Col span={18}>
          <Input allowClear={true}
                 value={template.rate ? template.rate : null}
                 onChange={(e) => { setTemplate({...template, rate: e.target.value}) }}
                 placeholder="Сумма, руб" type={"number"}/>
        </Col>
      </Row>

      {/* Лимит */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Лимит, руб</Col>
        <Col span={18}>
          <Input disabled={true}
                 value={template.limit ? template.limit : null}
                 placeholder="Сумма, руб" type={"number"}/>
        </Col>
      </Row>

      {
        userProfile.tenant.is_root && (
          <>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Изменить порядок списания</Col>
              <Col span={18}>
                <Switch
                  onChange={(checked) => {
                    setTemplate({...template, enable_custom_schedule: checked})
                  }}
                  checked={template?.enable_custom_schedule}
                />
              </Col>
            </Row>

            {
              template?.enable_custom_schedule && (
                <>
                  {/* Порядок списания */}
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Порядок списания</Col>
                    <Col span={18}>
                      <Select
                        value={template.schedule_type}
                        style={{width: '100%'}}
                        onChange={(val, option) => {
                          setTemplate({...template, schedule_type: val})
                        }}>
                        {
                          TemplateScheduleType.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>

                  {
                    template.schedule_type === "regular"
                      ? (
                        // периодические списания
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Периодичность списаний</Col>
                          <Col span={18}>
                            <Select
                              value={template.regular_period}
                              style={{width: '100%'}}
                              onChange={(val, option) => {
                                setTemplate({...template, regular_period: val})
                              }}>
                              {
                                SchedulerPeriods
                                  .filter(e => e.visible)
                                  .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                              }
                            </Select>
                          </Col>
                        </Row>
                      )
                      : (
                        // списания по шаблону
                        <>
                          <Row gutter={[0, 16]} style={rowStyle}>
                            <Col span={6}>Периодичность списаний</Col>
                            <Col span={18}>
                              <Space direction={"horizontal"}>
                                <Input allowClear={true}
                                       value={template.pattern_run_days}
                                       onChange={(e) => { setTemplate({...template, pattern_run_days: e.target.value}) }}
                                       placeholder="Дней списаний"
                                       type={"number"}/>
                                <span>через</span>
                                <Input allowClear={true}
                                       value={template.pattern_skip_days}
                                       onChange={(e) => { setTemplate({...template, pattern_skip_days: e.target.value}) }}
                                       placeholder="Дней пропуска"
                                       type={"number"}/>
                              </Space>
                            </Col>
                          </Row>
                        </>
                      )
                  }
                </>
              )
            }
          </>
        )
      }

      {/* Дата начала */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата начала</Col>
        <Col span={18}>
          <DatePicker
            style={{width: 200}}
            format="DD.MM.YYYY"
            value={template.start_dt ? moment(template.start_dt) : null}
            disabledDate={(dt) => {
              return dt && dt < moment().startOf('day')
            }}
            onChange={(val) => {
              if (val) {
                setTemplate({...template, start_dt: val.format('YYYY-MM-DD')});
              } else {
                setTemplate({...template, start_dt: null});
              }
            }}
          />
        </Col>
      </Row>

      {/* Время списания */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Время списания</Col>
        <Col span={18}>
          <TimePicker allowClear={true}
                      style={{width: 200}}
                      value={template.execution_time ? moment(template.execution_time, 'HH:mm:ss') : null}
                      onChange={(time, timeString) => { setTemplate({...template, execution_time: timeString }) }}
                      placeholder="Время списания"/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Пропустить первый день</Col>
        <Col span={18}>
          <Switch onChange={(checked) => { setTemplate({...template, skip_first: checked }) }}/>
        </Col>
      </Row>

      {/* комментарий */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комментарий</Col>
        <Col span={18}>
          <TextArea rows={3}
                    onChange={(e) => { setTemplate({...template, comment: e.target.value}); }}
                    value={template.comment}
                    placeholder="Комментарий"/>
        </Col>
      </Row>

      {
        suggestedTriggerTime
          ? (<div>Планируемое списание по шаблону: {suggestedTriggerTime}</div>)
          : (<div>Планируемое списание по шаблону: <i>заполните форму</i></div>)
      }

    </Modal>
  )
}
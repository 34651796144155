import React from "react";
import {Modal, PageHeader, Space, Table} from "antd";

import moment from "moment";

export default function TransactionTemplatesStatisticsDetails({visible, data, onClose}) {
  return (
    <Modal
      destroyOnClose={true}
      width={"80%"}
      title={"Детализация"}
      visible={visible}
      onOk={() => { onClose(); }}
      onCancel={() => { onClose(); }}>
      <>
        <PageHeader
          className="site-page-header"
          title="Детализация"
          extra={
            <div style={{textAlign: "right"}}>
              <Space direction={"horizontal"} style={{marginBottom: 10}}>
                <span>{`Записей: ${data ? data.length : 0}`}</span>
              </Space>
            </div>
          }
        />
        <Table dataSource={data}
              scroll={{x: 1000}}
              sticky
               columns={[
                {
                  title: "",
                  align: "center",
                  width: 30,
                },
                 {
                   title: 'Водитель',
                   dataIndex: 'driver_title',
                 },
                 {
                   title: 'Условия работы',
                   dataIndex: 'work_condition_title',
                 },
                 {
                   title: 'Шаблон',
                   dataIndex: 'template_title',
                 },
                 {
                   title: 'Время',
                   dataIndex: 'event_dt',
                   render: (text, record) => {
                     if(!record.event_dt) {
                       return "-";
                     }
                     return moment(record.event_dt).format("DD.MM.YYYY HH:mm")
                   }
                 },
                 {
                   title: 'Сумма',
                   dataIndex: 'payment_rate',
                 },
                 {
                   title: "",
                   align: "center",
                   width: 20,
                 }
               ]}
               size="small"
               rowKey="id"
               pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
        />
      </>
    </Modal>
  )
}
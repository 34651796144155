import React, {useState} from "react";
import {Input, Modal} from "antd";

const { TextArea } = Input;

export default function CommentDialog(props) {
  let [comment, setComment] = useState("");
  return (
    <Modal
      className={props.className}
      title={props.title}
      visible={props.visible}
      onOk={() => {
        props.onOk(comment);
        setComment("");
      }}
      onCancel={() => {
        props.onCancel();
        setComment("");
      }}>
      <label>{props.label}</label>
      <TextArea placeholder={props.label} rows={4}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}/>
    </Modal>
  );
}
import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Select, Space, TimePicker} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";

const {Option} = Select;
const PAYER_PROPS = [
  {name: "title_1", title: "Плательщик (полн)"},
  {name: "title_2", title: "Плательщик (кратк)"},
  {name: "bill_number", title: "Плательщик Счет"},
  {name: "account_number", title: "Плательщик Расч.Счет"},
  {name: "inn", title: "Плательщик ИНН"},
  {name: "bank_title", title: "Плательщик Банк"},
  {name: "bank_bik", title: "Плательщик БИК"},
  {name: "bank_corr", title: "Плательщик Корр.Счет"},
  {name: "bank_kpp", title: "Плательщик КПП"},
];

export default function TollRoadDebtsSettingsTab({active}) {
  let [payer, setPayer] = useState({});
  let [schedulerTime, setSchedulerTime] = useState('00:00:00');
  let [debitBalance, setDebitBalance] = useState("deposit");
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    apiClient.getSystemProperty("roadDebts.*")
      .then(res => {
        if(res.values) {
          if(res.values["roadDebts.scheduler_time"]) {
            setSchedulerTime(res.values["roadDebts.scheduler_time"]);
          }
          if(res.values["roadDebts.debit_balance"]) {
            setDebitBalance(res.values["roadDebts.debit_balance"]);
          }
          // payer opts
          let payerState = {};
          PAYER_PROPS.forEach(el => {
            if(res.values[`roadDebts.payer.${el.name}`]) {
              payerState[el.name] = res.values[`roadDebts.payer.${el.name}`];
            }
          });
          setPayer(payerState);
        }
      })
      .catch(err => message.error(err));
  }, [active]);

  const updateSchedulerTime = (val) => {
    setSchedulerTime(val);
  };

  const saveData = () => {
    const properties = {
      'roadDebts.scheduler_time':  schedulerTime,
      'roadDebts.debit_balance':  debitBalance,
    };
    PAYER_PROPS.forEach(el => {
      properties[`roadDebts.payer.${el.name}`] = payer[el.name];
    });

    setLoading(true);
    apiClient.setSystemProperties(properties)
      .then(res => {
        message.info("Изменения сохранены");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Время списания</h4>
        </Col>
        <Col span={10}>
          <Space direction="horizontal">
            <TimePicker onChange={(time, timeString) => { updateSchedulerTime(timeString) }}
                        value={moment(schedulerTime, 'HH:mm:ss')}
                        style={{width: 120, marginBottom: 8}} />,
          </Space>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Баланс списания</h4>
        </Col>
        <Col span={4}>
          <Select
            bordered
            value={debitBalance}
            placeholder="Баланс списания"
            onChange={(e) => {
              setDebitBalance(e)
            }}
            style={{width: 250, marginBottom: 8}}
          >
            <Option key='deposit' value='deposit'>Депозит</Option>
            <Option key='balance' value='balance'>Основной</Option>
            <Option key='summary' value='summary'>Сводный</Option>
          </Select>
        </Col>
      </Row>
      {
        PAYER_PROPS.map(el => (
          <Row key={el.name} gutter={[0, 8]} style={{margin: '9px 0'}}>
            <Col span={5}>
              <h4>{el.title}</h4>
            </Col>
            <Col span={10}>
              <Input
                allowClear={true}
                placeholder={el.title}
                style={{marginBottom: 8}}
                value={payer[el.name]}
                onChange={(e) => {
                  setPayer(prevState => {
                    let ps = {...prevState};
                    ps[el.name] = e.target.value;
                    return ps;
                  });
                }}/>
            </Col>
          </Row>
        ))
      }
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={13}>
          <Button type="primary" loading={loading} onClick={() => { saveData() }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>
  )
}
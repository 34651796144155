import React, {useState} from "react";
import {Card, DatePicker, Input, Modal, Space, Switch, TimePicker} from "antd";
import moment from "moment";

const { TextArea } = Input;

export default function CheckTrackerAlertDialog(props) {
  let [comment, setComment] = useState("");
  let [date, setDate] = useState(null);
  let [time, setTime] = useState(null);
  let [completed, setCompleted] = useState(true);
  let pendingOk = null;

  const handleDateUpdated = (val) => {
    if(val) {
      val.hour(0);
      val.minute(0);
      val.second(0);
    }
    setDate(val);
  };
  const handleTimeUpdated = (val) => {
    setTime(val);
  };
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      destroyOnClose={true}
      onOk={() => {
        if(pendingOk) {
          clearTimeout(pendingOk);
        }
        pendingOk = setTimeout(() => {
          if(completed) {
            props.onRemove(comment);
            setDate(null);
            setTime(null);
            return;
          }
          let dt = null;
          if(date) {
            dt = date;
            if(time) {
              dt.hour(time.hour());
              dt.minute(time.minute());
              dt.second(time.second());
            }
          }
          props.onOk(dt, comment);
        }, 500);
      }}
      onCancel={() => {
        props.onCancel();
        setDate(null);
        setTime(null);
      }}>

      <p>
        <TextArea placeholder={props.label} rows={4}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}/>
      </p>

      <p>
        <Space direction="horizontal">
          <label>Решено:</label>
          <Switch defaultChecked onChange={(checked) => { setCompleted(checked) }} />
        </Space>
      </p>

      <Card size="small" title="Создать уведомление">
        <Space style={{width: '100%'}}>
          <DatePicker
            disabled={completed}
            format="DD.MM.YYYY"
            disabledDate={(dt) => {
              return dt && dt < moment().startOf('day')
            }}
            onChange={handleDateUpdated}
            style={{width: 220}}/>
          <TimePicker disabled={completed} onChange={handleTimeUpdated} style={{width: 220}}/>
        </Space>
      </Card>

    </Modal>
  );
}
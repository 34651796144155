import React from 'react';
import ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd';
import App from './App';

import 'moment/locale/ru';
import ruRu from 'antd/es/locale/ru_RU';

import * as Sentry from '@sentry/react';

import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://394162decd1547efa3c1d2f94a77a7a9@o44315.ingest.sentry.io/5397466"});

ReactDOM.render(
  <ConfigProvider locale={ruRu}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

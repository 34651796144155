import React, {useEffect, useState} from "react";
import {Button, Col, Input, Modal, Row, Select, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import '../../../ModalStyle.css';

const { Option } = Select;
const { TextArea } = Input;

const rowStyle = {padding: '4px'};

export default function WorkflowStepEdit({step, visible, onOk, onCancel}) {
  let [record, setRecord] = useState({});
  let [replyOption, setReplyOption] = useState("");

  useEffect(() => {
    if (visible) {
      setRecord({...step})
    } else {
      setRecord({});
    }
  }, [step, visible]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Этап осмотра"}
      visible={visible}
      onOk={() => {
        if(!record.text) {
          message.info("Укажите Описание");
          return;
        }
        if(!record.menuItem) {
          message.info("Укажите Наименование");
          return;
        }
        if(!record.replyTypes) {
          message.info("Укажите Типы ответа");
          return;
        }
        onOk(record);
      }}
      onCancel={() => {
        onCancel();
      }}>
      <>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={5}>Наименование</Col>
          <Col span={16}>
            <Input
              placeholder={"Наименование"}
              value={record.menuItem}
              onChange={(e) => { setRecord({...record, menuItem: e.target.value} ) }}
              allowClear={true}
              width={"100%"}/>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={5}>Описание</Col>
          <Col span={16}>
            <TextArea placeholder={"Описание"}
                      rows={3}
                      width={"100%"}
                      value={record.text}
                      onChange={(e) => { setRecord({...record, text: e.target.value} ) }}/>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={5}>Тип обработки</Col>
          <Col span={16}>
            <Select
              style={{ width: '100%' }}
              value={record?.processingType}
              onChange={(value) => {setRecord({...record, processingType: value})}}
            >
              <Option key={"none"} value={"none"}>{"Без обработки"}</Option>
              <Option key={"dashboard"} value={"dashboard"}>{"Ввод пробега"}</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={5}>Типы ответа</Col>
          <Col span={16}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={record?.replyTypes ? record.replyTypes : []}
              onChange={(value) => {setRecord({...record, replyTypes: value})}}
            >
              <Option key={"photo"} value={"photo"}>{"Фото"}</Option>
              <Option key={"video"} value={"video"}>{"Видео"}</Option>
              <Option key={"callback"} value={"callback"}>{"Варианты ответа"}</Option>
              <Option key={"text"} value={"text"}>{"Ввод текста"}</Option>
            </Select>
          </Col>
        </Row>
        {
          record?.replyTypes?.some(el => el === "callback")
          ? (
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={5}>Варианты ответа</Col>
                <Col span={16}>
                  <Input.Group compact>
                    <Input allowClear style={{ width: 'calc(100% - 200px)' }} value={replyOption} onChange={(e) => { setReplyOption(e.target.value) }}/>
                    <Button type="primary" onClick={() => {
                      if(replyOption) {
                        setRecord({...record, keyboard: [...(record.keyboard ? record.keyboard : []), replyOption]});
                        setReplyOption("");
                      }
                    }}>Добавить</Button>
                  </Input.Group>
                  <div style={{margin: 10}}>
                    <ol>
                      {
                        record?.keyboard?.map(el => {
                          return (
                            <li>
                              <Button type="link" icon={<DeleteOutlined/>} onClick={() => {setRecord({...record, keyboard: record.keyboard.filter(kb => kb !== el) });}}/>
                              {el}
                            </li>
                          );
                        })
                      }
                    </ol>
                  </div>
                </Col>
              </Row>
            ) : null
        }
      </>
    </Modal>
  )
}
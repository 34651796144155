import React, {useEffect, useState} from "react";
import {apiClient} from "../../../services/ApiClient";
import {
  Button,
  DatePicker,
  Dropdown, Input,
  Menu,
  message,
  Modal,
  PageHeader,
  Radio,
  Select,
  Space,
  Table,
  Tooltip
} from "antd";
import {
  CheckCircleTwoTone,
  ClearOutlined, DownOutlined, ExportOutlined,
  EyeOutlined, EditFilled,
  MinusCircleTwoTone, PictureOutlined, QuestionOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {windowService} from "../../../services/WindowService";
import CkadDebtDetails from "./CkadDebtDetails";
import SearchSelect from "../../common/SearchSelect";
import LinkButton from "../../buttons/LinkButton";

import numeral from "numeral";
import CkadDebtPhotos from "./CkadDebtPhotos";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

export default function CkadDebtPage({}) {

  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [assignStatus, setAssignStatus] = useState('all');
  let [payedStatus, setPayedStatus] = useState('all');
  let [billStatus, setBillStatus] = useState('all');
  let [period, setPeriod] = useState(null);
  let [selectedFine, setSelectedFine] = useState(null);
  let [photosFine, setPhotosFine] = useState(null);
  let [filterByDriver, setFilterByDriver] = useState(null);
  let [uinSearchString, setUinSearchString] = useState(null);
  let [vehicle, setVehicle] = useState('');
  let [vehicleSearchText, setVehicleSearchText] = useState(null);
  let [selectedRows, setSelectedRows] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [update, setUpdate] = useState(0);
  let [visibilityStatus, setVisibilityStatus] = useState('visible');
  let [exportStatus, setExportStatus] = useState('all');
  let [pagination, setPagination] = useState({
    position: ['bottomRight', 'topRight'],
    defaultPageSize: 50,
    hideOnSinglePage: false,
    current: 1,
    pageSize: 50,
    pageSizeOptions: [25, 50, 75, 100, 200]
  });
  let [exportSelectedPayerId, setExportSelectedPayerId] = useState(null);
  // let [sorter, setSorter] = useState({});

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      align: "center",
      dataIndex: "id",
      width: 60,
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"} size={"middle"}>
            <LinkButton label={<EditFilled/>} onClick={() => {setSelectedFine(record);}}/>
            {
              record.has_photo
                ? <Tooltip title="Посмотреть фото"><LinkButton label={<PictureOutlined />} onClick={() => {setPhotosFine(record);}}/></Tooltip>
                : <Tooltip title="Фотоматериалы отсутствуют"><LinkButton label={<PictureOutlined style={{ opacity: '0.3'}}/>}  onClick={() => { }}/></Tooltip>
            }
          </Space>
        );
      }
    },
    {
      title: 'ID проезда',
      dataIndex: 'provider_uid',
      align: "center",
    },
    {
      title: 'Дата задолженности',
      width: 180,
      align: "center",
      dataIndex: 'debt_dt',
      render: (text, record, index) => {
        return `${moment(record.debt_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.debt_dt).valueOf() - moment(b.debt_dt).valueOf();
      }
    },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle_reg_number',
      align: "center",
      render: (text, record, index) => {
        return (
          <LinkButton
            label={record.vehicle_reg_number}
            onClick={() => {
              const vehicle = vehicles.find(el => el.reg_number == record.vehicle_reg_number);
              if(vehicle) {
                setVehicle(vehicle.id);
                setVehicleSearchText(vehicle.reg_number);
              }
            }}
          />
        )
      },
    },
    {
      title: 'Сумма задолженности',
      dataIndex: 'amount',
      align: "center",
    },
    {
      title: 'Сумма к списанию',
      dataIndex: 'amount_to_pay',
      align: "center",
    },
    {
      title: 'ТС',
      dataIndex: 'vehicle',
      align: "center",
      width: 200,
      render: (text, record, index) => {
        if(!record.vehicle) {
          return "";
        }
        return (
          <div style={{color: '#58D3A5', cursor: 'pointer'}} onClick={() => { windowService.openRouteWindow(`cp/park/vehicles/${record.vehicle.id}`); }}>
            {record.vehicle.title}
          </div>
        );
      },
    },
    {
      title: 'Водитель',
      dataIndex: 'id',
      align: "center",
      width: 250,
      render: (text, record, index) => {
        return (
          <div
            style={{color: '#58D3A5', cursor: 'pointer', textAlign: 'center'}}
            onClick={() => { windowService.openRouteWindow(`cp/drivers/${record.driver.id}`); }}
          >
            {record?.driver?.title}
          </div>
        );
      },
    },
    {
      title: 'Выгружен',
      align: "center",
      dataIndex: 'export_dt',
      render: (text, record, index) => {
        return record.export_dt
          ? <CheckCircleTwoTone twoToneColor="#52c41a" />
          : <MinusCircleTwoTone twoToneColor="#c43500"/>;
      },
    },
    {
      title: 'Дата выгрузки',
      width: 150,
      align: "center",
      dataIndex: 'export_dt',
      render: (text, record, index) => {
        return record.export_dt ? `${moment(record.export_dt).format('DD.MM.YYYY')}` : null;
      },
    },
    {
      title: 'Списан',
      align: "center",
      dataIndex: 'is_payed',
      render: (text, record, index) => {
        return record.is_payed
          ? <CheckCircleTwoTone twoToneColor="#52c41a" />
          : <MinusCircleTwoTone twoToneColor="#c43500"/>;
      },
    },
    {
      title: 'Оплачен',
      align: "center",
      dataIndex: 'status',
      render: (text, record, index) => {
        return record?.status === 1
          ? <CheckCircleTwoTone twoToneColor="#52c41a" />
          : (
              record?.status === 2 ? <MinusCircleTwoTone twoToneColor="#ffc700"/> : <MinusCircleTwoTone twoToneColor="#c43500"/>
          );
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    setLoading(true);
    apiClient.getCkadDebts({
      vehicle,
      period,
      assignStatus,
      payedStatus,
      billStatus,
      visibilityStatus,
      exportStatus,
      uinSearchString,
      driver: filterByDriver,
    })
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [vehicle, assignStatus, payedStatus, billStatus, period, filterByDriver, visibilityStatus, exportStatus, uinSearchString, update]);

  useEffect(() => {
    apiClient.searchDirectoryRecord('taxiDriver', '')
      .then(res => {
        setDrivers(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  const executeSelectedRecordsOperation = key => {
    if(key === "exportBankClient") {
      apiClient.getFinesPayers()
        .then(res => {
          Modal.confirm({
            title: "Выбор плательщика",
            width: '800px',
            content: (
              <>
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="..."
                  onChange={(value) => {
                    setExportSelectedPayerId(value);
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {res.records.map(s => <Option key={s.id} value={s.id}>{`${s.full_title}, ИНН ${s.inn}`}</Option>)}
                </Select>
              </>
            ),
            icon: <QuestionOutlined/>,
            visible: true,
            onOk() {
              if(!exportSelectedPayerId) {
                message.error("Необходимо выбрать плательщика");
                return;
              }
              apiClient.getCkadDebtClientBankFile(selectedRows.map(el => el.id), exportSelectedPayerId)
                .then(res => {
                  window.open(res.link);

                  setUpdate(prevState => prevState + 1);
                  setSelectedRows([]);
                  setExportSelectedPayerId(null);
                })
                .catch(err => message.error(err));
            }
          })
        })
        .catch(err => message.error("Не удалось получить список плательщиков"));
    } else if(key === "changeVisibility") {
      apiClient.updateCkadDebts(selectedRows.map(el => el.id), {
        "is_visible": (!(visibilityStatus === "visible" || visibilityStatus === "all"))
      })
        .then(res => {
          setUpdate(prevState => prevState + 1);
        })
        .catch(err => message.error(err));
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Платные дороги"
        subTitle="Здесь можно посмотреть задолженности за проезд по платным дорогам"
        extra={(
          <>
            <div>{`Сумма задолженности: ${numeral(selectedRows.map(el => parseFloat(el.amount)).reduce((a, b) => a + b, 0)).format('0.00')}`}</div>
            <Space direction={"horizontal"}>
              <Button
                icon={<ClearOutlined/>}
                onClick={ () => {
                  setAssignStatus("all");
                  setPayedStatus("all");
                  setVehicle(null);
                  setVehicleSearchText(null);
                  setPeriod(null);
                  setFilterByDriver(null);
                  setExportStatus("all");
                  setVisibilityStatus("visible");
                }}>
                  Очистить фильтры
                </Button>
                <Dropdown
                  style={{width: "210px"}}
                  disabled={selectedRows.length < 1}
                  overlay={(
                    <Menu onClick={(e) => {
                      executeSelectedRecordsOperation(e.key);
                    }}>
                      <Menu.Item key="exportBankClient" icon={<ExportOutlined/>}>
                        Выгрузить в банк-клиент
                      </Menu.Item>
                      <Menu.Item key="changeVisibility" icon={<EyeOutlined/>}>
                        Скрыть/Показать
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button  style={{width: "190px", textAlign: "left"}}>
                    {`С выбранными...   `} <DownOutlined/>
                  </Button>
                </Dropdown>
            </Space>
          </>
        )}
      />
      <div style={{marginBottom: 16}}>
        <Space direction="horizontal" wrap>
          <SearchSelect
            placeholder="Гос.номер"
            value={vehicleSearchText}
            style={{width: 300}}
            options={vehicles.map(el => ({id: el.id, title: `${el.reg_number}`}))}
            onSelect={(el) => {
              setVehicle(el?.id);
              setVehicleSearchText(el?.reg_number);
            }}
          />

          <Radio.Group value={assignStatus} onChange={(e) => {
            setAssignStatus(e.target.value);
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="unassigned">Нераспределенные</Radio.Button>
          </Radio.Group>

          <Radio.Group value={payedStatus} onChange={(e) => {
            setPayedStatus(e.target.value);
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="payed">Списанные</Radio.Button>
            <Radio.Button value="unpayed">Не списанные</Radio.Button>
          </Radio.Group>

          <Radio.Group value={billStatus} onChange={(e) => {
            setBillStatus(e.target.value);
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="billed">Оплаченные</Radio.Button>
            <Radio.Button value="unbilled">Не оплаченные</Radio.Button>
          </Radio.Group>

          <RangePicker format="DD.MM.YYYY" style={{width: 350}} onChange={(val) => setPeriod(val)}/>

          <Select
            showSearch
            allowClear
            style={{ width: 350 }}
            placeholder="Водитель"
            onChange={(value) => {
              setFilterByDriver(value);
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {drivers.map(s => <Option key={s.id} value={s.id}>{`${s.last_name} ${s.first_name} ${s.middle_name} (${s.phone})`}</Option>)}
          </Select>

          <Radio.Group value={exportStatus} onChange={(e) => {
            setExportStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="exported">Выгруженные</Radio.Button>
            <Radio.Button value="unexported">Не выгруженные</Radio.Button>
          </Radio.Group>

          <Radio.Group value={visibilityStatus} onChange={(e) => {
            setVisibilityStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="visible">Видимые</Radio.Button>
            <Radio.Button value="hidden">Скрытые</Radio.Button>
          </Radio.Group>

          <Search
            enterButton
            allowClear
            placeholder="ID проезда"
            // value={uinSearchString}
            // onChange={(e) => { setUinSearchString(e.target.value) }}
            onSearch={(value) => {
              setUinSearchString(value);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
            style={{width: 300}}
          />
        </Space>
      </div>
      <Table
        loading={loading}
        columns={cols}
        size="small"
        scroll={{x: 2000}}
        sticky
        dataSource={records}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(el => el.id)
        }}
        onChange={(pagination, filters, sorter) => {
          setPagination(pagination);
        }}
        pagination={pagination}
        rowKey="id"
      />
      <CkadDebtDetails
        visible={selectedFine !== null}
        recordId={selectedFine?.id}
        onClose={(updated) => {
          if(updated) {
            setUpdate(prevState => prevState + 1);
          }
          setSelectedFine(null);
        }}
      />
      {
        photosFine !== null && (
          <CkadDebtPhotos
            title={"Фото"}
            visible={photosFine !== null}
            item={photosFine}
            onClose={() => { setPhotosFine(null) }}/>
        )
      }
    </>
  )
}
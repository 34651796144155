import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Col, InputNumber, message, Modal, Row, Spin} from "antd";

const rowStyle = {padding: '8px'};

export default function ServiceSummaryTypeStatusSettingsDialog({onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [minIdlePeriod, setMinIdlePeriod] = useState("");
  let [maxIdlePeriod, setMaxIdlePeriod] = useState("");

  useEffect(() => {
    setLoading(true);
    apiClient.getSystemProperty("parkmanager.typeSummary.*")
      .then(res => {
        if(res.values) {
          if(res.values["parkmanager.typeSummary.minIdlePeriod"]) {
            setMinIdlePeriod(res.values["parkmanager.typeSummary.minIdlePeriod"]);
          }
          if(res.values["parkmanager.typeSummary.maxIdlePeriod"]) {
            setMaxIdlePeriod(res.values["parkmanager.typeSummary.maxIdlePeriod"]);
          }
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Настройки"}
      visible={true}
      mask={loading}
      onOk={() => {
        const properties = {
          'parkmanager.typeSummary.minIdlePeriod': minIdlePeriod,
          'parkmanager.typeSummary.maxIdlePeriod': maxIdlePeriod,
        };
        setLoading(true);
        apiClient.setSystemProperties(properties)
          .then(res => {
            message.info("Изменения сохранены");
            onOK();
          })
          .catch(err => {
            message.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      onCancel={onCancel}>

      <div>
        <div hidden={!loading} style={{textAlign: 'center'}}>
          <Spin />
        </div>
        <div hidden={loading}>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={8}>Мин период статуса</Col>
            <Col md={16}>
              <InputNumber
                min={0}
                style={{width: 200}}
                value={minIdlePeriod}
                onChange={(value) => { setMinIdlePeriod(value) }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={8}>Макс период статуса</Col>
            <Col md={16}>
              <InputNumber
                style={{width: 200}}
                min={0}
                value={maxIdlePeriod}
                onChange={(value) => { setMaxIdlePeriod(value) }}
              />
            </Col>
          </Row>
        </div>
      </div>

    </Modal>
  );
}
import React from "react";
import {Button, Modal, Space, Table} from "antd";
import {ParkStatuses, VehicleGearboxTypes} from "../../../common/AppEnums";
import {windowService} from "../../../../services/WindowService";
import '../../ModalStyle.css';
import moment from "moment";

export default function ServiceSummaryTypeParkingDetailsDialog({data, status, minDays, maxDays, onClose}) {
  return (
    <Modal
      title={"Информация"}
      visible={true}
      width={1024}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <>
      {
        status && (
          <div>
            {`${data ? data.length : 0} ТС находятся в статусе ${ParkStatuses.find(el => el.id === status).title} более ${minDays} дней`}
          </div>
        )
      }
        <div style={{textAlign: "right"}}>
          <Space direction={"horizontal"} style={{marginBottom: 10, marginRight: 20}}>
            <span>{`Записей: ${data ? data.length : 0}`}</span>
          </Space>
        </div>
        <Table
          className="size-for-middle-window"
          dataSource={data}
          columns={[
            {
              title: 'Гос.номер',
              dataIndex: 'reg_number',
              width: 120,
              render: (text, record, index) => {
                return (
                  <Button
                    type={"link"}
                    onClick={() => {
                      windowService.openRouteWindow(`cp/park/vehicles/${record.id}`);
                    }}
                  >
                    {
                      <>
                        <div>
                          {record.reg_number}
                        </div>
                      </>
                    }
                  </Button>
                );
              },
            },
            {
              title: "Марка",
              dataIndex: 'brand',
            },
            {
              title: "Модель",
              dataIndex: 'model',
            },
            {
              title: "Год выпуска",
              dataIndex: 'year',
              width: 100,
            },
            {
              title: "Тип КПП",
              dataIndex: 'gearbox_type',
              width: 130,
              render: (text, record, index) => {
                return VehicleGearboxTypes.find(el => el.id === record.gearbox_type)?.title || record.gearbox_type
              }
            },
            {
              title: "Пробег",
              dataIndex: 'last_maintenance_mileage',
              width: 120,
            },
            {
              title: "Дата статуса",
              align: 'center',
              render: (text, record, index) => {
                return `${moment(record.park_status_dt).format('DD.MM.YYYY HH:mm')}`;
              },
              width: 120,
            },
          ]}
          size="small"
          pagination={{position: 'bottomRight', pageSizeOptions: [10, 25, 50], showSizeChanger: true}}
          rowKey="id"
        />
      </>
    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {Col, Modal, Row, message, Space, Button, Table, Select, Input} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined} from "@ant-design/icons";
import '../../ModalStyle.css';

const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function TeamEditDialog({visible, record, onOk, onClose}) {

  let [team, setTeam] = useState(null);
  let [allDrivers, setAllDrivers] = useState([]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<DeleteOutlined/>} onClick={() => { setTeam({...team, drivers: team.drivers.filter(el => el.id !== record.id)}) }}/>
          </Space>
        );
      }
    },
    {
      title: 'Водитель',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: 'Марка',
      dataIndex: 'car_model',
      width: 200,
    },
    {
      title: 'Номер',
      dataIndex: 'car_number',
    },
    {
      title: 'Коррекция',
      dataIndex: 'correction',
      width: 150,
      render: (text, record, index) => {
        return <Input type={"number"}
                      placeholder={"Сумма коррекицц, руб"}
                      defaultValue={record.correction}
                      onChange={(e) => {
                        record.correction = e.target.value;
                      }}/>;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(visible) {
      apiClient.getCompetitionDriversList()
        .then(res => {
          setAllDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [visible]);

  useEffect(() => {
    if(record) {
      setTeam(record);
    } else {
      setTeam(null);
    }
  }, [record]);

  function render() {
    return (
      <>
        <Row style={rowStyle}>
          <Col span={5}>Наименование команды</Col>
          <Col span={16}>
            <Input
              placeholder={"Наименование команды"}
              value={team.title}
              onChange={(e) => { setTeam({...team, title: e.target.value} ) }}
              allowClear={true}
              width={"100%"}/>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Добавить участника</Col>
          <Col span={16}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Водитель"
                onChange={(value) => {
                  if(value) {
                    let driver = allDrivers.filter(el => el.id === value)[0];
                    if(driver) {
                      setTeam({...team, drivers: [...team.drivers, driver]})
                    }
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {allDrivers.map(s => <Option key={s.id} value={s.id}>{`${s.name}, ${s.phone}`}</Option>)}
              </Select>

            </Space>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={team.drivers}
                 rowKey="id"/>

        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Команда"}
      visible={visible}
      destroyOnClose={true}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => {
        if(!team.title) {
          message.warn("Укажите наименование команды");
          return;
        }
        if(!team.drivers || team.drivers.length === 0) {
          message.warn("Добавьте водителей");
          return;
        }

        onOk(team);
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        team ? render() : null
      }
    </Modal>
  );

}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {message, Table} from "antd";
import moment from "moment";

export default function FineTransactionExecutionHistoryTab({driver, record}) {

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getPaymentTemplateExecutionHistory(driver.id, record.id)
      .then(res => {
        setPayments(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      })

  }, [driver, record]);

  return (
    <div style={{margin: '0 10px 0 10px'}}>
      <Table
        loading={loading}
        dataSource={payments}
        rowClassName={(record, index) => {
          return `status-${record.execution_status}`
        }}
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            width: 150,
            title: "Дата",
            dataIndex: "execution_dt",
            render: (text, record, index) => {
              return <div style={{textAlign: "left"}}>{record.execution_dt ? `${moment(record.execution_dt).format('DD.MM.YYYY HH:mm:ss')}` : `${moment(record.event_dt).format('DD.MM.YYYY')}`}</div>;
            },
          },
          {
            width: 120,
            title: "Сумма",
            dataIndex: "paid",
          },
          {
            title: "Комментарий",
            dataIndex: "execution_code",
          },
        ]}
        size="small"
        scroll={{x: 1000}}
        sticky
        rowKey="id"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
      />
    </div>
  )
}
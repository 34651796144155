import React, {useContext, useState} from "react";
import {Button} from "antd";
import {PlusCircleFilled, MinusCircleFilled} from "@ant-design/icons";
import AddDriverTransactionDialog from "./AddDriverTransactionDialog";
import UserProfileContext from "../../../../../services/UserProfileContext";

export default function BalanceInfo({title,
                                      code,
                                      value,
                                      loading,
                                      maxCredit,
                                      maxDebit,
                                      driverId,
                                      onAddTransaction}) {

  let userProfile = useContext(UserProfileContext);
  let [newTransaction, setNewTransaction] = useState(null);

  const canDebit = () => {
    return userProfile.permissions.some(el => el === "balance_debit");
  };

  const catCredit = () => {
    return userProfile.permissions.some(el => el === "balance_credit");
  };

  return (
    <div className={'driver-balance'}>
      <div>{title}</div>
      <div>

        <span>
          <Button
            loading={loading}
            disabled={!canDebit()}
            onClick={() => setNewTransaction({type: 'debit'})}
            icon={<MinusCircleFilled style={{color: "#D35858"}} />}
            type={'link'}
            />
        </span>

        <span style={{color: value < 0 ? 'darkred' : 'darkgreen' }}>{`${value} р.`}</span>

        <span>
          <Button
            loading={loading}
            disabled={!catCredit()}
            onClick={() => setNewTransaction({type: 'credit'})}
            icon={<PlusCircleFilled />}
            type={'link'}
          />
        </span>

      </div>

      <AddDriverTransactionDialog
        visible={newTransaction !== null}
        title={`${title} (${value} р.)`}
        srcBalance={code}
        maxCredit={maxCredit}
        maxDebit={maxDebit}
        driverId={driverId}
        transaction={newTransaction}
        onOK={(values) => {
          setNewTransaction(null);
          onAddTransaction(values);
        }}
        onCancel={() => {
          setNewTransaction(null)
        }}/>
    </div>
  );
}
import React, {useEffect, useState} from "react";
import {Button, message, Modal, PageHeader, Space, Table} from "antd";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import ServiceEditDialog from "./serviceAndPartner/ServiceEditDialog";
import UpdateButton from "../../buttons/UpdateButton";

import "./VehiclesPage.css"


export default function PartnersAndServices() {
  let [loading, setLoading] = useState(false);
  let [editedRecordId, setEditedRecordId] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let ownStatus = "owned";
  let [ownedCount, setOwnedCount] = useState(0);

  const cols = [
    {
      title: "",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
        title: "",
        width: 50,
        render: (text, record, index) => {
          return (
            <Space direction={"horizontal"}>
              <Button type="link" onClick={() => { setEditedRecordId(record.id) }}><EditFilled/></Button>
            </Space>
          )
        }
      },
    {
      title: "Название тех. центра",
      width: 250
    },
    {
      title: 'Адрес'
    },
    {
      title: 'Номер телефона'
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    setLoading(true);
    apiClient.getPartnersAndServices()
      .then(res => {
        setRecords(res.records);
        if(res.owned_count) {
          setOwnedCount(res.owned_count);
        }
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, ownStatus]);

  const deleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить выбранные записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        setLoading(true);
        Promise.all(selected.map(serviceId => apiClient.removeVehicle(serviceId)))
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error("Ошибка"))
          .finally(() => setLoading(false));
      },
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Автомобили"
        extra={
          <div style={{textAlign: "right"}}>
            Во владении: {ownedCount}
          </div>
        }
      />
      <div style={{marginBottom: 16}}>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
            <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
              <UpdateButton disabled={loading} onClick={() => { setUpdate(u => u + 1) }}/>
              <Button disabled={loading} onClick={() => { setEditedRecordId(0) }} type={"primary"}>Добавить</Button>
              {selected.length > 0
                ? <Button disabled={loading} type={"danger"} onClick={deleteSelected}>Удалить</Button>
                : null
              }
            </Space>
      <Table
        columns={cols}
        scroll={{x: 1000}}
        sticky
        dataSource={records}
        loading={loading}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected,
          onChange: (selectedRowKeys) => {
            setSelected(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            disabled: record.yandex_service_id || record.citymobil_service_id || record.gett_service_id || record.cartaxi_service_id
          }),
        }}
        rowClassName={(record, index) => {
          let className = "";
          if(record.alert_msg) {
            className += " with-alert";
          }
          return className;
        }}
      />
      <ServiceEditDialog
        visible={editedRecordId !== null}
        serviceId={editedRecordId}
        onClose={() => {
          setEditedRecordId(null);
          setUpdate(prevState => prevState + 1);
        }}
      />
    </>
  );
}
import React, {useEffect, useState} from "react";
import {
  DatePicker,
  message,
  PageHeader,
  Space,
  Table,
  Input,
  Dropdown,
  Menu,
  Button,
  Modal,
  Upload, Select
} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import UpdateButton from "../../../buttons/UpdateButton";
import numeral from "numeral";
import {
  DeleteOutlined,
  DownOutlined, EditFilled,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ExpenseEditDialog from "./ExpenseEditDialog";

const { RangePicker } = DatePicker;
const { Option } = Select;

const CMD_EXPORT = "export";
const CMD_DELETE = "delete";

export default function ExpensesPage() {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 200, 300]
  });
  let [sorter, setSorter] = useState({});
  let [selectedRows, setSelectedRows] = useState([]);
  let [editedRecordId, setEditedRecordId] = useState(null);
  let [filters, setFilters] = useState({});
  let [categories, setCategories] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [owners, setOwners] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getPaymentExpensesImportStatus()
      .then(res => {
        if(res.record?.uid) {
          waitForData(res.record?.uid);
          message.info("Выполняется импорт файла, пожалуйста подождите")
        } else {
          apiClient.getPaymentExpenses(filters)
            .then(res => {
              setRecords(res.records);
            })
            .catch(err => { message.error(err); })
            .finally(() => { setLoading(false); });
        }
      });
  }, [recordsStamp, filters]);

  useEffect(() => {
    apiClient.getExpenseCategories()
      .then(res => {
        setCategories(res.records);
      })
      .catch(err => message.error(err));
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleOwners({"view": "minimal"})
      .then(res => { setOwners(res.records); })
      .catch(err => { message.error(err) });
  }, []);

  useEffect(() => {
    console.log("filters", filters);
  }, [filters]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: '',
      width: 50,
      render: (text, record, index) => {
        const eventDate = moment(record.event_dt);
        const isOutdated = eventDate.isBefore(moment().startOf("month")) && moment().date() > 15;

        return !isOutdated ? (
          <Button icon={<EditFilled/>} onClick={() => {setEditedRecordId(record.id);}} type="link"/>
        ) : null
      }
    },
    {
      title: 'Дата',
      dataIndex: 'event_dt',
      align: "center",
      width: 120,
      render: (text, record, index) => {
        return record.event_dt ? `${moment(record.event_dt).format('DD.MM.YYYY')}` : `${moment(record.create_dt).format('DD.MM.YYYY')}`;
      },
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: "ТС",
      width: 250,
      render: (text, record, index) => {
        return record.taxi_car?.title;
      },
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      align: "center",
      width: 180,
      render: (text, record, index) => {
        return numeral(record.amount).format('0.00')
      },
      sorter: true,
    },
    {
      title: "Категория",
      width: 250,
      render: (text, record, index) => {
        return record.category?.title;
      },
    },
    {
      title: "Группа",
      width: 250,
      render: (text, record, index) => {
        return record.taxi_car_group?.title;
      },
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
    },
    {
      title: "",
      align: "center",
      width: 20,
    },
    {
      title: 'Дата создания',
      dataIndex: 'create_dt',
      align: "center",
      width: 200,
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];

  const executeSelectedRecordsOperation = key => {
    if(key === CMD_EXPORT) {
      setLoading(true);
      apiClient.getPaymentExpensesExport(selectedRows.map(el => el.id))
        .then(res => {
          window.open(res.link);
          setSelectedRows([]);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else if(key === CMD_DELETE) {
      setLoading(true);
      apiClient.deletePaymentExpenses(selectedRows.map(el => el.id))
        .then(res => {
          setSelectedRows([]);
          setRecordsStamp(prevState => prevState + 1);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    }
  };

  const createExpenseRecord = record => {
    apiClient.createPaymentExpense(record)
      .then(res => {
        setRecordsStamp(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const updateExpenseRecord = (editedRecordId, record) => {
    apiClient.updatePaymentExpense(editedRecordId, record)
      .then(res => {
        setRecordsStamp(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const waitForData = (uid) => {
    apiClient.getBackgroundTaskStatus(uid)
      .then(res => {
        if(!res.end_dt) {
          setTimeout(() => { waitForData(uid) }, 1000);
          return;
        }

        console.log(res);

        if(res.error_message) {
          message.error(res.error_message);
          if(res.error_details?.details) {
            Modal.error({
              title: 'Ошибка импорта',
              width: 800,
              content: (
                <>
                  <table style={{width: '100%'}}>
                    <thead>
                    <tr>
                      <th>Строка</th>
                      <th>Ошибка</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      res.error_details?.details.map(row => {
                        return (
                          <tr>
                            <td>{row.row}</td>
                            <td>{row.message}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </>
              ),
              onOk() {},
            });
          } else if(res.error_details?.message) {
            message.error(res.error_details?.message);
          }
        } else {
          message.info("Операция импорта завершена");
        }

        setLoading(false);
        setRecordsStamp(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Расходы"
        extra={
          (
            <div style={{textAlign: "right"}}>
              <Space direction={"vertical"} size={"small"}>
                <span>Сумма: {numeral(selectedRows.map(el => parseFloat(el.amount)).reduce((a, b) => a + b, 0)).format('0.00')} руб.</span>
                <span>{`Всего записей: ${records.length}`}</span>
              </Space>
            </div>
          )
        }
      />

      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'left'}}>
          <Space direction={"horizontal"} wrap style={{marginBottom: 10}}>
            <Select
              allowClear
              bordered
              mode={"multiple"}
              placeholder="ТС"
              showSearch
              style={{width: 300}}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(val, option) => {
                setFilters({...filters, vehicles: val});
                setPagination(prevState => { return {...prevState, current: 1} });
              }}
            >
              {
                vehicles?.map((el) => <Option key={el.id}>{`${el.reg_number}`}</Option>)
              }
            </Select>
            <RangePicker
              showTime={false}
              format="DD.MM.YYYY"
              style={{width: 350}}
              onChange={(dates) => setFilters({...filters, fromDate: dates ? dates[0].format("YYYY-MM-DD") : null, toDate: dates ? dates[1].format("YYYY-MM-DD") : null})}
            />
            <Select
              allowClear
              bordered
              placeholder="Категория"
              showSearch
              style={{width: 300}}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(val, option) => {
                setFilters({...filters, category: val});
                setPagination(prevState => { return {...prevState, current: 1} });
              }}
            >
              {
                categories.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
            <Select
              allowClear
              showSearch
              placeholder="Группа ТС"
              style={{width: 300}}
              onChange={(val) => {
                setFilters({...filters, paymentGroup: val});
                setPagination(prevState => { return {...prevState, current: 1} });
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
            <Select
              allowClear
              showSearch
              mode={"multiple"}
              placeholder="Собственники"
              style={{width: 400}}
              onChange={(val) => {
                setFilters({...filters, owners: val});
                setPagination(prevState => { return {...prevState, current: 1} });
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                owners.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Space>
        </div>
        <div style={{width: '20%', float: 'right'}}>
          <Space style={{float: "right", marginBottom: 10}}>
            <Button disabled={loading} onClick={() => { setEditedRecordId(0) }} type={"primary"}>Добавить</Button>
            <Upload
              name={"file"}
              action={apiClient.actionUrl(`payments/expenses/import`)}
              headers={apiClient.requestConfig().headers}
              multiple={false}
              showUploadList={false}
              onChange={(info) => {
                console.log(info.file.status, info);
                if (info.file.status === 'uploading') {
                  setLoading(true);
                } else if (info.file.status === 'done') {
                  message.success(`Файл загружен, ожидайте окончания импорта`);
                  apiClient.getPaymentExpensesImportStatus()
                    .then(res => {
                      if(res.record?.uid) {
                        setLoading(true);
                        waitForData(res.record?.uid);
                        message.info("Выполняется импорт файла, пожалуйста подождите")
                      } else {
                        setLoading(false);
                        setRecordsStamp(prevState => prevState + 1);
                        message.warning("Не найдено активных задач на обновление данных")
                      }
                    });
                } else if (info.file.status === 'error') {
                  message.error("Ошибка загрузки файла");
                  setRecordsStamp(prevState => prevState + 1);
                  setLoading(false);
                }
              }}
            >
              <Button disabled={loading} type="primary" icon={<UploadOutlined />}>Загрузить</Button>
            </Upload>
            <UpdateButton disabled={loading} onClick={ () => setRecordsStamp(prevState => prevState + 1) }/>
            {
              selectedRows.length > 0
                ? (
                  <>
                    <Dropdown
                      overlay={(
                        <Menu onClick={(e) => {
                          executeSelectedRecordsOperation(e.key);
                        }}>
                          <Menu.Item key={CMD_EXPORT} icon={<FileExcelOutlined/>}>
                            Выгрузка в Excel
                          </Menu.Item>
                          <Menu.Item key={CMD_DELETE} icon={<DeleteOutlined/>}>
                            Удалить
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button style={{textAlign: "left"}}>
                        С выбранными <DownOutlined/>
                      </Button>
                    </Dropdown>
                  </>
                ) : null
            }
          </Space>
        </div>
      </div>

      <Table
        dataSource={records}
        columns={columns}
        size="small"
        sticky
        style={{margin: '10px 0', marginTop: 40}}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        scroll={{x: 1500}}
        onChange={(pagination, filters, sorter) => {
          setPagination(pagination);
          setSorter(sorter);
          setRecordsStamp(prevState => prevState + 1)
        }}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(el => el.id)
        }}
      />

      {
        editedRecordId !== null && (
          <ExpenseEditDialog
            recordId={editedRecordId}
            onOK={(record) => {
              if(editedRecordId < 1) {
                createExpenseRecord(record);
                setEditedRecordId(null);
              } else {
                updateExpenseRecord(editedRecordId, record);
                setEditedRecordId(null);
              }
            }}
            onCancel={() => { setEditedRecordId(null) }}
          />
        )
      }

    </>
  );
}
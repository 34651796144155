import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import FinesMainSettingsTab from "./FinesMainSettingsTab";
import FinesPayersSettingsTab from "./FinesPayersSettingsTab";

const { TabPane } = Tabs;


export default function FinesSettingsTab({active}) {

  let [selectedSection, setSelectedSection] = useState("main");

  useEffect(() => {

  }, [active]);

  return (
    <Tabs tabPosition={"left"} onChange={(key) => { setSelectedSection(key) }}>
      <TabPane tab="Основные" key="main">
        <FinesMainSettingsTab active={selectedSection === "main"}/>
      </TabPane>
      <TabPane tab="Реквизиты списаний" key="payer">
        <FinesPayersSettingsTab active={selectedSection === "payer"}/>
      </TabPane>
    </Tabs>
  )
}
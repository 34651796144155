import React, {useState} from "react";
import {Space, Switch, Button} from "antd";
import {DeleteFilled, EditFilled} from "@ant-design/icons";

export default function GSpreadsheetEmbedPage({id, title, code, readOnlyUrl, editableUrl, onDelete, onUpdate}) {
  let [editMode, setEditMode] = useState(localStorage.getItem(`${code}.editMode`) == null ? false : localStorage.getItem(`${code}.editMode`) === "true");
  let iframe = (<iframe title={"spreadsheet view"} style={{width: '100%', border: 0, height: 768}} src={readOnlyUrl}></iframe>);
  if(editMode) {
    iframe = (<iframe title={"spreadsheet edit"} style={{width: '100%', border: 0, height: 768}} src={editableUrl}></iframe>);
  }

  const handleEditModeChanged = (checked) => {
    localStorage.setItem(`${code}.editMode`, checked);

    setEditMode(checked);
  };

  const onUpdateReport = () => {
    onUpdate({
      id, readOnlyUrl, editableUrl, title
    })
  };

  const onDeleteReport = () => {
    onDelete(id);
  };

  return (
    <div>
      <div style={{margin: '10px 0', float: 'right'}}>
        <Space direction="horizontal">
          {
            editableUrl
              ? <Switch checkedChildren="Редактирование" unCheckedChildren="Просмотр" defaultChecked={editMode} onChange={handleEditModeChanged} />
              : null
          }
          <Button title={"Изменить параметры"} type="link" icon={<EditFilled/>} onClick={onUpdateReport}/>
          <Button title={"Удалить отчет"} type="link" icon={<DeleteFilled/>} onClick={onDeleteReport}/>
        </Space>
      </div>
      <div>{iframe}</div>
    </div>
  )
}
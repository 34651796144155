import React, {useEffect, useState} from "react";
import {Col, Input, Modal, Row} from "antd";
import "../../ModalStyle.css";

const { TextArea } = Input;

const rowStyle = {padding: '8px'};

export default function ServiceKaskoStatusEdit({visible, item, onOk, onCancel, title}) {

  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(item) {
      setRecord(item);
    } else {
      setRecord({id: 0});
    }
  }, [item]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>ТС</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.car?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Владелец</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.owner?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комментарий</Col>
        <Col span={18}>
          <TextArea
            rows={3}
            placeholder=""
            value={record.comment}
            onChange={(e) => { setRecord({...record, comment: e.target.value}) }}
          />
        </Col>
      </Row>

    </Modal>
  );
}
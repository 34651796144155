import axios from 'axios';
import moment from "moment";
import * as Sentry from '@sentry/react';

const API_KEY_FIELD = "apiKey";
const RESOURCE_TOKENS_FIELD = "resourceTokens";
const API_BASE = `${process.env.REACT_APP_API_HOST}/api`;
const REFRESH_TIMEOUT = 1000 * 30;

class ApiClient {

  actionUrl(name) {
    return `${API_BASE}/${name}`;
  }

  requestConfig() {
    const config = {
      responseType: 'json',
      headers: {}
    };
    // check auth status
    const key = window.localStorage.getItem(API_KEY_FIELD);
    if (key) {
      config.headers['Authorization'] = `Bearer ${key}`;
    }
    return config;
  }

  _get(url, params) {
    return new Promise((resolve, reject) => {
      try {
        let config = this.requestConfig();
        if (params) {
          params['_'] = Date.now();
          config.params = params;
        } else {
          config.params = {
            '_': Date.now()
          }
        }
        axios.get(url, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    });
  }

  _post(url, data) {
    return new Promise(((resolve, reject) => {
      try {
        axios.post(url, data, this.requestConfig())
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  _postForm(url, data) {
    return new Promise(((resolve, reject) => {
      try {
        let cfg = this.requestConfig();
        let bodyFormData = new FormData();
        for (let key in data) {
          bodyFormData.append(key, data[key]);
        }
        axios({
          method: "post",
          url: url,
          data: bodyFormData,
          headers: {...cfg.headers, "Content-Type": "multipart/form-data"},
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  _put(url, data) {
    return new Promise(((resolve, reject) => {
      try {
        axios.put(url, data, this.requestConfig())
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  _download(url, data) {
    let config = this.requestConfig();
    config.responseType = 'arraybuffer';
    return new Promise(((resolve, reject) => {
      try {
        axios.post(url, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  _delete(url, params, data) {
    return new Promise(((resolve, reject) => {
      try {
        let config = this.requestConfig();
        if (params) {
          params['_'] = Date.now();
          config.params = params;
        } else {
          config.params = {
            '_': Date.now()
          }
        }
        if(data) {
          config.data = data;
        }
        axios.delete(url, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  _patch(url, data) {
    return new Promise(((resolve, reject) => {
      try {
        axios.patch(url, data, this.requestConfig())
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.detail) {
              reject(err.response.data.detail);
            } else {
              reject(err.message);
            }
          });
      } catch (e) {
        Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  isAuthenticated() {
    return window.localStorage.getItem(API_KEY_FIELD) !== null;
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  authenticate(username, password) {
    let req = this._post(this.actionUrl('auth/login'), {username, password});

    req.then(res => {
      window.localStorage.setItem(API_KEY_FIELD, res.token);
    });

    return req;
  }

  logout() {
    return new Promise(resolve => {
      window.localStorage.removeItem(API_KEY_FIELD);
      window.localStorage.removeItem(RESOURCE_TOKENS_FIELD);
      resolve();
    });
  }

  getDriversList(filters, view) {
    return this._get(this.actionUrl('drivers/list'), {...filters, view});
  }

  getDriversSummary(filters) {
    return this._get(this.actionUrl('drivers/summary'), {...filters});
  }

  getDriverDetails(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}`));
  }

  createDriver(props) {
    return this._post(this.actionUrl(`drivers/list`), props);
  }

  /**
   * Удаление водителя
   * @param driverId Идентификатор записи
   */
  removeDriver(driverId) {
    return this._delete(this.actionUrl(`drivers/${driverId}`))
  }

  updateDriverLimit(driverId, limit) {
    return this._post(this.actionUrl(`drivers/${driverId}/limit`), {limit});
  }

  updateDriverWorkCondition(driverId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/workCondition`), {...params});
  }

  updateDriverAvatar(driverId, file) {
    return this._postForm(this.actionUrl(`drivers/${driverId}/avatar`), {avatar: file})
  }

  updateDriverPark(driverId, selectedPark, selectedWorkRule) {
    return this._post(this.actionUrl(`drivers/${driverId}/parks`), {park_id: selectedPark, work_rule_uid: selectedWorkRule});
  }

  getDriverComments(driverId, type) {
    return this._get(this.actionUrl(`drivers/${driverId}/comments`), {type});
  }

  addDriverComment(driverId, value, type) {
    return this._post(this.actionUrl(`drivers/${driverId}/comments`), {comment: value, type: type ? type : ''});
  }

  getDriverVehicleHistory(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}/vehicle-history`))
  }

  /**
   * Исполненные шаблоны списаний
   * @param driverId
   * @param params
   * @param pagination
   */
  getDriverPaymentExecutionsHistory(driverId, params, pagination) {
    return this._get(this.actionUrl(`drivers/${driverId}/work/executions/history`), {...params, ...pagination});
  }

  getDriverPaymentManual(driverId, params, pagination) {
    return this._get(this.actionUrl(`drivers/${driverId}/payments/manual`), {...params, ...pagination});
  }

  /**
   * Выполнение возврата списания
   * @param driverId
   * @param executionHistoryId
   * @param params
   */
  refundDriverPaymentExecution(driverId, executionHistoryId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/executions/history/${executionHistoryId}/refund`), params);
  }

  refundDriverPaymentManual(driverId, paymentId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/payments/manual/${paymentId}/refund`), params);
  }

  sendDriversCommentsReport(driverIds, type) {
    return this._post(this.actionUrl(`drivers/reports/latestComments`), {driver_ids: driverIds, type: type});
  }

  setDriversWorkConditionAlertSkip(driverIds, args) {
    return this._post(this.actionUrl(`drivers/work/conditions/skipAlert`), {driver_ids: driverIds, ...args});
  }

  updateDriverComment(driverId, commentId, comment) {
    return this._post(this.actionUrl(`drivers/${driverId}/comments/${commentId}`), {comment});
  }

  deleteDriverComment(driverId, commentId) {
    return this._delete(this.actionUrl(`drivers/${driverId}/comments/${commentId}`));
  }

  blockDriver(driverId) {
    return this._post(this.actionUrl(`drivers/${driverId}/block`), {});
  }

  addDriverTransaction(driverId, values) {
    return this._post(this.actionUrl(`drivers/${driverId}/transactions`), {...values});
  }

  getDriverTransactionsById(driverId, filter, pagination) {
    return this._get(this.actionUrl(`drivers/${driverId}/transactions`), {...filter, ...pagination});
  }

  getDriverRentContracts(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}/rent-contracts`));
  }

  getDriverTransactions(filter) {
    return this._get(this.actionUrl(`drivers/transactions`), {...filter});
  }

  getDriverPayments(filter) {
    return this._get(this.actionUrl(`payments`), {...filter});
  }

  updateDriverPayment(id, props) {
    return this._post(this.actionUrl(`payments/${id}`), {...props});
  }

  getDriverPaymentsExport(ids) {
    return this._post(this.actionUrl(`payments/export`), {ids});
  }

  getDriversWithoutWorkConditions() {
    return this._get(this.actionUrl(`drivers/reports/driversWithoutWorkConditions`));
  }

  getDriverSupplyHours(driverId, filters) {
    return this._get(this.actionUrl(`drivers/${driverId}/supply-hours`), {...filters})
  }

  subscribeTrackersState(regionIds, callback) {
    const url = this.actionUrl('trackers/list');
    const fn = () => {
      this._get(url, {regionIds: regionIds})
        .then(data => callback(data.trackers))
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, REFRESH_TIMEOUT);
  }

  unsubscribeTrackersState(subscription) {
    clearInterval(subscription);
  }

  subscribeTrackerEvents(filter, callback) {
    const url = this.actionUrl('trackers/events/unseen');
    const fn = () => {
      console.log(`GET =>`, url, filter);
      this._get(url, filter)
        .then(data => {
          if (data.events) {
            callback(data.events);
          }
        })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, REFRESH_TIMEOUT);
  }

  unsubscribeTrackerEvents(subscription) {
    clearInterval(subscription);
  }

  markNotificationSeen(notificationId) {
    return this._post(this.actionUrl(`trackers/events/${notificationId}/seen`, {event: notificationId}));
  }

  removeTrackerEventNotification(notificationId) {
    return this._delete(this.actionUrl(`trackers/events/${notificationId}/notification`));
  }

  updateTrackerEventNotification(notificationId, dt) {
    return this._post(this.actionUrl(`trackers/events/${notificationId}/notification`), {"dt": dt.format("YYYY-MM-DDTHH:mm:ss")});
  }

  updateTrackerBlocked(trackerId, blocked, comment) {
    return this._post(this.actionUrl(`trackers/${trackerId}/immobilizer`), {blocked, comment});
  }

  addTrackerComment(trackerId, value) {
    return this._post(this.actionUrl(`trackers/${trackerId}/comments`), {comment: value});
  }

  getTrackerComment(trackerId) {
    return this._get(this.actionUrl(`trackers/${trackerId}/comments`));
  }

  addCarReturnRequest(data) {
    return this._post(this.actionUrl(`carReturnRequest/create`), { ...data });
  }

  getCarReturnRequestLimts(params) {
    return this._get(this.actionUrl('carReturnRequest/limits'), {...params});
  }

  getCarReturnRequestCalendar(params) {
    return this._get(this.actionUrl('carReturnRequest/calendar'), {...params});
  }

  addCarReturnRequestCalendarPeriod(params) {
    return this._post(this.actionUrl('carReturnRequest/calendar'), {...params});
  }

  updateCarReturnRequestCalendarPeriod(id, params) {
    return this._post(this.actionUrl(`carReturnRequest/calendar/${id}`), {...params});
  }

  deleteCarReturnRequestCalendarPeriods(ids) {
    return this._delete(this.actionUrl('carReturnRequest/calendar'), {"ids": ids.join(',')});
  }

  getCarReturnRequests(filter, sorting) {
    return this._get(this.actionUrl('carReturnRequest/list'), {...filter, ...sorting});
  }

  deleteCarReturnRequests(ids) {
    return this._delete(this.actionUrl(`carReturnRequest/list?ids=${ids.join()}`));
  }

  updateCarReturnRequests(request) {
    return this._post(this.actionUrl(`carReturnRequest/${request.id}`), {
      "info": {
        "fio": request.fio,
        "carNumber": request.car_number,
        "returnReason": request.reason_id,
        "returnDate": moment(request.planned_return_dt).format('YYYY-MM-DD')
      }
    });
  }

  addCarReturnRequestComment(requestId, comment) {
    return this._post(this.actionUrl(`carReturnRequest/${requestId}/comments`), {comment});
  }

  getCarReturnRequestComments(requestId) {
    return this._get(this.actionUrl(`carReturnRequest/${requestId}/comments`));
  }

  removeCarReturnRequestComment(requestId, commentId) {
    return this._post(this.actionUrl(`carReturnRequest/${requestId}/comments/${commentId}/delete`));
  }

  changeUserPassword(password) {
    return this._post(this.actionUrl(`auth/change-password`), {password});
  }

  getCurrentUserProfile() {
    return this._get(this.actionUrl('auth/profile'));
  }

  updateCurrentUserUIState(pageId, settings) {
    return this._post(this.actionUrl(`auth/profile/ui/${pageId}`), {state: settings});
  }

  getCurrentUserUIState(pageId) {
    return this._get(this.actionUrl(`auth/profile/ui/${pageId}`));
  }

  setDriverNotification(driverId, notifyDt, comment, earlyHours) {
    return this._post(this.actionUrl(`drivers/${driverId}/notification`), {
      "dt": notifyDt.format("YYYY-MM-DDTHH:mm:ss"),
      "comment": comment,
      "early_time": earlyHours
    });
  }

  removeDriverNotification(driverId) {
    return this._delete(this.actionUrl(`drivers/${driverId}/notification`));
  }

  checkResourceAccess(domain) {
    let tokens = window.localStorage.getItem(RESOURCE_TOKENS_FIELD);
    if (!tokens) {
      tokens = "";
    }
    return this._post(this.actionUrl(`auth/domain/check-access`), {domain, tokens: tokens.split(",")});
  }

  addResourceAccess(domain, password) {
    let p = this._post(this.actionUrl(`auth/domain/add-access`), {domain, password});
    p.then(res => {
      if (res.permitted) {
        let tokens = window.localStorage.getItem(RESOURCE_TOKENS_FIELD);
        if (!tokens) {
          tokens = "";
        }
        let tokensList = tokens.split(",")

        tokensList.push(res.token);

        window.localStorage.setItem(RESOURCE_TOKENS_FIELD, tokensList.join());
      }
    });
    return p;
  }

  getProtectedResources() {
    return this._get(this.actionUrl(`auth/domain/resources`));
  }

  updateResourceAccess(ids, password) {
    return this._post(this.actionUrl(`auth/domain/update-access`), {ids, password});
  }

  searchDirectoryRecord(recordType, filter) {
    return this._get(this.actionUrl(`directory/${recordType}`), {filter});
  }

  createDirectoryRecord(recordType, args) {
    return this._post(this.actionUrl(`directory/${recordType}`), {...args});
  }

  updateDirectoryRecord(recordType, id, args) {
    return this._put(this.actionUrl(`directory/${recordType}?id=${id}`), {...args});
  }

  removeDirectoryRecord(recordType, id) {
    return this._delete(this.actionUrl(`directory/${recordType}?id=${id}`));
  }

  async executeReport(report, args) {
    let res = await this._post(this.actionUrl(`reports/${report}`), {...args});
    return this._download(this.actionUrl(`reports/${report}/render`), {ids: res.ids});
  }

  async printReportRecords(report, ids) {
    return this._download(this.actionUrl(`reports/${report}/render`), {ids: ids});
  }

  getReportRecords(report, filter) {
    return this._get(this.actionUrl(`reports/${report}`), {...filter});
  }

  updateReportRecords(report, filter) {
    return this._post(this.actionUrl(`reports/${report}/refresh`), {...filter});
  }

  getActiveReportRecordsUpdate(report) {
    return this._get(this.actionUrl(`reports/${report}/refresh`));
  }

  getSpectrumDataSettings() {
    return this._get(this.actionUrl(`directory/spectrum-data/settings`));
  }

  setSpectrumDataSettings(props) {
    return this._post(this.actionUrl(`directory/spectrum-data/settings`), props);
  }

  getSystemProperty(name) {
    return this._get(this.actionUrl(`directory/properties?name=${name}`));
  }

  setSystemProperty(name, params) {
    return this._post(this.actionUrl(`directory/properties/${name}`), {...params});
  }

  setSystemProperties(properties) {
    return this._post(this.actionUrl(`directory/properties`), {...properties});
  }

  executeWaybillSourceExchange() {
    return this._post(this.actionUrl(`reports/waybill/sync`), {});
  }

  getWaybillSourceExchangeStatus(taskId) {
    return this._get(this.actionUrl(`reports/waybill/sync/status?task_id=${taskId}`));
  }

  getUsers(args) {
    return this._get(this.actionUrl("auth/domain/users"), {...args});
  }

  getUserBirthdays() {
    return this._get(this.actionUrl('auth/domain/users/birthdays'));
  }

  getGroups(search) {
    return this._get(this.actionUrl("auth/domain/groups"), {...search});
  }

  removeUsers(selectedUsers) {
    return this._delete(this.actionUrl(`auth/domain/users?ids=${selectedUsers.join()}`))
  }

  removeGroups(selectedUsers) {
    return this._delete(this.actionUrl(`auth/domain/groups?ids=${selectedUsers.join()}`))
  }

  addUser(user) {
    return this._post(this.actionUrl("auth/domain/users"), {...user});
  }

  addGroup(group) {
    return this._post(this.actionUrl("auth/domain/groups"), {...group});
  }

  updateUser(id, attributes) {
    return this._put(this.actionUrl(`auth/domain/users?id=${id}`), {...attributes});
  }

  updateGroup(id, attributes) {
    return this._put(this.actionUrl(`auth/domain/groups?id=${id}`), {...attributes});
  }

  getMenuItems(moduleId) {
    return this._get(this.actionUrl(`auth/menu/${moduleId}`));
  }

  getRootMenuItems() {
    return this._get(this.actionUrl(`auth/menu`));
  }

  createMenuItem(moduleId, params) {
    return this._post(this.actionUrl(`auth/menu/${moduleId}`), {...params});
  }

  updateMenuItem(moduleId, params) {
    return this._put(this.actionUrl(`auth/menu/${moduleId}?id=${params.id}`), {...params});
  }

  removeMenuItem(moduleId, id) {
    return this._delete(this.actionUrl(`auth/menu/${moduleId}?id=${id}`));
  }

  /**
   * Запрос списка штрафов с фильтрацией
   * @param filters
   * @returns {Promise<unknown>}
   */
  getFines(filters) {
    return this._get(this.actionUrl(`fines/list`), {...filters});
  }

  updateFine(fineId, updates) {
    return this._post(this.actionUrl(`fines/${fineId}`), {updates: updates});
  }

  updateFinesVisibility(ids, visibility) {
    return this._post(this.actionUrl(`fines/visibility`), {ids, visibility});
  }

  getFinesExportLink(fineIds, payerId) {
    return this._post(this.actionUrl(`fines/export/bank_client_file/prepare`), {ids: fineIds, payerId: payerId});
  }

  getCkadDebts(params) {
    return this._get(this.actionUrl(`fines/ckad`), {...params});
  }

  updateCkadDebts(ids, args) {
    return this._post(this.actionUrl(`fines/ckad`), {"ids": ids, "params": args});
  }

  getCkadDebt(recordId) {
    return this._get(this.actionUrl(`fines/ckad/${recordId}`));
  }

  getCkadDebtPhotos(recordId) {
    return this._get(this.actionUrl(`fines/ckad/${recordId}/photos`));
  }

  getCkadDebtClientBankFile(recordIds, payerId) {
    return this._post(this.actionUrl(`fines/ckad/export/bank_client`), {ids: recordIds, payerId: payerId});
  }

  updateCkadDebt(recordId, args) {
    return this._post(this.actionUrl(`fines/ckad/${recordId}`), {...args});
  }

  /**
   * Обновление атрибутов указанного водителя
   *
   * @param driverId идентификатор водителя
   * @param props атрибуты для обновления
   */
  updateDriverDetails(driverId, props) {
    return this._post(this.actionUrl(`drivers/${driverId}`), {...props});
  }

  /**
   * Список платежей по штрафам
   * @param driverId
   */
  getDriverPaymentsBill(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}/fines/payments`));
  }

  addDriverTransactionTemplate(driverId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/payments`), {...params});
  }

  updateDriverTransactionTemplate(driverId, recordId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/payments/${recordId}`), {...params});
  }

  addDriverPaymentExecution(driverId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/executions`), {...params});
  }

  removeDriverPaymentExecution(driverId, assignmentId) {
    return this._delete(this.actionUrl(`drivers/${driverId}/work/executions/${assignmentId}`));
  }

  updateDriverPaymentExecution(driverId, assignmentId, params) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/executions/${assignmentId}`), {...params});
  }

  updateDriverPaymentExecutionProperties(driverId, assignmentId, changes) {
    return this._put(this.actionUrl(`drivers/${driverId}/work/executions/${assignmentId}`), {...changes});
  }

  updateDriverPaymentExecutionSuspension(driverId, executionId, suspendDate, resumeDate) {
    let args = {};
    if (suspendDate) {
      args.suspend_dt = suspendDate.format("YYYY-MM-DD");
    }
    if (resumeDate) {
      args.resume_dt = resumeDate.format("YYYY-MM-DD");
    }
    return this._post(this.actionUrl(`drivers/${driverId}/work/executions/${executionId}/suspension`), args);
  }

  getTransactionTemplateAssignmentTriggerTimeSuggestion(params) {
    return this._post(this.actionUrl(`drivers/work/trigger-time-suggest`), {...params});
  }

  getDriverAudit(driverId, filter) {
    return this._get(this.actionUrl(`drivers/${driverId}/audit`), {...filter});
  }

  getDriverFiles(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}/files`));
  }

  removeDriverFile(driverId, fileId) {
    return this._delete(this.actionUrl(`drivers/${driverId}/files/${fileId}`));
  }

  getFinePayments(fineId) {
    return this._get(this.actionUrl(`fines/${fineId}/payments`));
  }

  getFinePhotos(fineId) {
    return this._get(this.actionUrl(`fines/${fineId}/photos`));
  }

  getFinesPayers() {
    return this._get(this.actionUrl(`fines/payers`));
  }

  getFinesPayer(recordId) {
    return this._get(this.actionUrl(`fines/payers/${recordId}`));
  }

  addFinesPayer(props) {
    return this._post(this.actionUrl(`fines/payers`), props)
  }

  updateFinesPayer(recordId, props) {
    return this._post(this.actionUrl(`fines/payers/${recordId}`), props)
  }

  removeFinesPayers(ids) {
    return this._delete(this.actionUrl(`fines/payers?ids=${ids.join()}`))
  }

  getPhotoControlSummary(status) {
    return this._get(this.actionUrl(`bots/ui/summary`), {status});
  }

  getPhotoControlDetails(recordId) {
    return this._get(this.actionUrl(`bots/ui/drivers/records/${recordId}`));
  }

  /**
   * Запрос десткриптора процесса осмотра по коду
   * @param workflowId код процесса
   */
  getPhotoControlWorkflowDescriptor(workflowId) {
    return this._get(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}`));
  }

  addPhotoControlWorkflowDescriptorStep(workflowId, step) {
    return this._post(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}`), {step});
  }

  updatePhotoControlWorkflowDescriptorStep(workflowId, step) {
    return this._put(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}`), {step});
  }

  upPhotoControlWorkflowDescriptorStep(workflowId, step) {
    return this._post(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}/${step.code}/up`), {step});
  }

  downPhotoControlWorkflowDescriptorStep(workflowId, step) {
    return this._post(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}/${step.code}/down`), {step});
  }

  deletePhotoControlWorkflowDescriptorSteps(workflowId, steps) {
    return this._delete(this.actionUrl(`bots/ui/drivers/workflows/${workflowId}?steps=${steps.join(",")}`));
  }

  updatePhotoInspectionStep(inspection, stepId, props) {
    return this._post(this.actionUrl(`bots/ui/inspection/settings/steps/${inspection}/${stepId}`), props);
  }

  getPhotoInspectionSteps(inspection) {
    return this._get(this.actionUrl(`bots/ui/inspection/settings/steps/${inspection}`));
  }

  unsubscribe(subscription) {
    clearInterval(subscription);
  }

  /**
   * Запрос на экспорт записей удаленных фотоконтролей
   * Возвращает ссылку на скачивание документа
   */
  exportControlRecords(filters, format) {
    return this._post(this.actionUrl('bots/ui/drivers/records'), {...filters, format});
  }

  subscribeControlRecords(filters, callback) {
    const fn = () => {
      this._post(this.actionUrl('bots/ui/drivers/records'), {...filters})
        .then(res => { callback(res) })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, REFRESH_TIMEOUT);
  }

  /**
   * Запрос на экспорт записей инспекционного контроля
   * Возвращает ссылку на скачивание документа
   */
  exportInspectionRecords(filters, format) {
    return this._post(this.actionUrl('bots/ui/inspection'), {...filters, format});
  }

  subscribeInspectionRecords(filters, callback) {
    const fn = () => {
      this._post(this.actionUrl('bots/ui/inspection'), {...filters})
        .then(res => { callback(res) })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, REFRESH_TIMEOUT);
  }

  subscribeControlSummary(filter, callback) {
    const fn = () => {
      this._get(this.actionUrl('bots/ui/summary'), filter)
        .then(res => {
          callback(res.summary);
        })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, 1000 * 10);
  }

  prepareSbpTransaction(args) {
    return this._post(this.actionUrl(`processor/spb/create?_=${Date.now()}`), {...args});
  }

  checkSbpTransaction(qrcId) {
    return this._get(this.actionUrl(`processor/spb/check`), {"_": Date.now(), "qrcid": qrcId});
  }

  prepareTransaction(args) {
    return this._post(this.actionUrl(`processor/create?_=${Date.now()}`), {...args});
  }

  prepareTochkaAquiringTransaction(args) {
    return this._post(this.actionUrl(`processor/tochka/acquiring/payments?_=${Date.now()}`), {...args});
  }

  searchPaymentDriver(query, tenantCode) {
    return this._post(this.actionUrl(`processor/search?_=${Date.now()}`), {q: query, tid: tenantCode});
  }

  requestDriverPhotoControlPhotos(id, photoControlTypes) {
    return this._post(this.actionUrl(`bots/ui/drivers/upload/${id}/photos-request`), {types: photoControlTypes});
  }

  acceptDriverPhotoControl(recordId) {
    return this._post(this.actionUrl(`bots/ui/drivers/upload/${recordId}/accept`));
  }

  rejectDriverPhotoControl(recordId) {
    return this._post(this.actionUrl(`bots/ui/drivers/upload/${recordId}/reject`));
  }

  updatePhotoControl(id, params) {
    return this._post(this.actionUrl(`bots/ui/drivers/upload/${id}`), params);
  }

  getZoneRequests(args) {
    return this._get(this.actionUrl(`bots/ui/forms/leaveZoneRequests`), args)
  }

  subscribeZoneRequestsCount(callback) {
    const fn = () => {
      this._get(this.actionUrl(`bots/ui/forms/leaveZoneRequests/count`))
        .then(res => { callback(res) })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, 1000 * 60);
  }

  setZoneRequestStatus(formId, status) {
    return this._post(this.actionUrl(`bots/ui/forms/leaveZoneRequests/${formId}/status`), {status})
  }

  addInspectionControlCar(car) {
    return this._post(this.actionUrl(`bots/ui/inspection/settings/car`), {car});
  }

  getInspectionControlDetails(recordId) {
    return this._get(this.actionUrl(`bots/ui/inspection/${recordId}`))
  }

  getInspectionControlCars(filters) {
    return this._get(this.actionUrl(`bots/ui/inspection/settings/car`), {...filters});
  }

  removeInspectionControlCar(carId) {
    return this._delete(this.actionUrl(`bots/ui/inspection/settings/car/${carId}`));
  }

  getInspectionControlUsers(filters) {
    return this._get(this.actionUrl(`bots/ui/inspection/settings/user`), {...filters});
  }

  removeInspectionControlUser(userId) {
    return this._delete(this.actionUrl(`bots/ui/inspection/settings/user/${userId}`));
  }

  addInspectionControlUser(params) {
    return this._post(this.actionUrl(`bots/ui/inspection/settings/user`), params);
  }

  getGroupMenuAccess(groupId) {
    return this._get(this.actionUrl(`auth/domain/groups/${groupId}/menu`));
  }

  addGroupMenuAccess(groupId, menuId) {
    return this._post(this.actionUrl(`auth/domain/groups/${groupId}/menu`), {menuId});
  }

  removeGroupMenuAccess(groupId, menuId) {
    return this._delete(this.actionUrl(`auth/domain/groups/${groupId}/menu/${menuId}`));
  }

  removeTenants(ids) {
    return this._delete(this.actionUrl(`auth/domain/tenants?ids=${ids.join()}`))
  }

  getTenants(params) {
    return this._get(this.actionUrl("auth/domain/tenants"), {...params});
  }

  addTenant(tenant) {
    return this._post(this.actionUrl("auth/domain/tenants"), {...tenant});
  }

  checkTenant(params) {
    return this._post(this.actionUrl("auth/domain/tenants/check"), {...params});
  }

  updateTenant(tenantId, params) {
    return this._post(this.actionUrl(`auth/domain/tenants/${tenantId}`), {...params});
  }

  /**
   * Добавить scope для tenant по ID
   */
  addTenantScopes(tenantId, scopes) {
    return this._post(this.actionUrl(`auth/domain/tenants/${tenantId}/scopes`), {"scopes": scopes});
  }

  /**
   * Удалить scope для tenant по ID
   */
  removeTenantScopes(tenantId, scopes) {
    return this._delete(this.actionUrl(`auth/domain/tenants/${tenantId}/scopes`), {"scopes": scopes});
  }

  getTenantScopes() {
    return this._get(this.actionUrl(`auth/domain/tenants/scopes`));
  }

  getTransactionPaymentSourcesForDriver(driverId) {
    return this._get(this.actionUrl(`drivers/${driverId}/payment-sources`));
  }

  // шаблоны списаний
  getTransactionTemplates(params) {
    return this._get(this.actionUrl("drivers/work/payments"), {...params});
  }

  // статистика по шаблонам списаний
  getTransactionTemplateStatistics(params) {
    return this._get(this.actionUrl(`drivers/work/payments/statistics`), {...params});
  }

  // детализация статистики
  getTransactionTemplateStatisticsDetails(params) {
    return this._get(this.actionUrl(`drivers/work/payments/statistics/details`), {...params});
  }

  // добавлениие шаблона списаний
  addTransactionTemplate(props) {
    return this._post(this.actionUrl("drivers/work/payments"), {...props});
  }

  // удаление шаблона списаний
  removeTransactionTemplate(recordId) {
    return this._delete(this.actionUrl(`drivers/work/payments/${recordId}`));
  }

  // обновление шаблона списаний
  updateTransactionTemplate(recordId, params) {
    return this._post(this.actionUrl(`drivers/work/payments/${recordId}`), {...params});
  }

  getWorkConditions(viewName = "default", params) {
    return this._get(this.actionUrl("drivers/work/conditions"), {view: viewName, ...params});
  }

  addWorkCondition(params) {
    return this._post(this.actionUrl("drivers/work/conditions"), {...params});
  }

  updateWorkCondition(recordId, params) {
    return this._post(this.actionUrl(`drivers/work/conditions/${recordId}`), {...params});
  }

  removeWorkCondition(recordId) {
    return this._delete(this.actionUrl(`drivers/work/conditions/${recordId}`));
  }

  getDriverAssignedPaymentTemplates(driverId, params) {
    return this._get(this.actionUrl(`drivers/${driverId}/work/executions`), params);
  }

  /**
   * Получение истории внесений платежей для шаблона списаний
   */
  getPaymentTemplateExecutionPayments(driverId, executionId) {
    return this._get(this.actionUrl(`drivers/${driverId}/work/executions/${executionId}/payments`));
  }

  /**
   * Добавление внесения платежа по шаблону списаний
   */
  addPaymentTemplateExecutionPayments(driverId, executionId, amount, comment) {
    return this._post(this.actionUrl(`drivers/${driverId}/work/executions/${executionId}/payments`), {amount, comment});
  }

  /**
   * Получение истории списаний (автоматических исполнений) шаблона списаний
   */
  getPaymentTemplateExecutionHistory(driverId, executionId) {
    return this._get(this.actionUrl(`drivers/${driverId}/work/executions/${executionId}/history`));
  }

  getDriverBalanceHistory(driverId, targetDate) {
    return this._get(this.actionUrl(`drivers/${driverId}/balance-history/balance`), {dt: targetDate})
  }

  addCompetition(from, to, drivers) {
    return this._post(this.actionUrl(`competition/personal`), {from, to, drivers});
  }

  updateCompetition(recordId, props) {
    return this._post(this.actionUrl(`competition/personal/${recordId}`), {...props});
  }

  removeCompetition(recordId) {
    return this._delete(this.actionUrl(`competition/personal/${recordId}`));
  }

  getCompetitions() {
    return this._get(this.actionUrl(`competition/personal`));
  }

  getCompetitionResults(uid) {
    return this._get(this.actionUrl(`competition/personal/${uid}/result`));
  }

  getCompetitionParticipants(recordId) {
    return this._get(this.actionUrl(`competition/personal/${recordId}/participants`));
  }

  addCompetitionParticipant(recordId, driverId) {
    return this._post(this.actionUrl(`competition/personal/${recordId}/participants`), {driver: driverId});
  }

  removeCompetitionParticipant(competitionId, participantId) {
    return this._delete(this.actionUrl(`competition/personal/${competitionId}/participants/${participantId}`));
  }

  updateCompetitionParticipant(competitionId, participantId, props) {
    return this._post(this.actionUrl(`competition/personal/${competitionId}/participants/${participantId}`), {...props});
  }

  getCompetitionDriversList() {
    return this._get(this.actionUrl(`competition/drivers`));
  }

  recalculateCompetition(competitionId) {
    return this._post(this.actionUrl(`competition/personal/${competitionId}/update`));
  }

  getCompetitionTeams() {
    return this._get(this.actionUrl(`competition/teams`));
  }

  addCompetitionTeam(title, drivers) {
    return this._post(this.actionUrl(`competition/teams`), {title, drivers});
  }

  updateCompetitionTeam(id, params) {
    return this._post(this.actionUrl(`competition/teams/${id}`), {...params});
  }

  removeCompetitionTeam(id) {
    return this._delete(this.actionUrl(`competition/teams/${id}`));
  }

  updateCompetitionTeamMember(teamId, driverId, props) {
    return this._post(this.actionUrl(`competition/teams/${teamId}/${driverId}`), {...props});
  }

  removeTeamCompetition(id) {
    return this._delete(this.actionUrl(`competition/team/${id}`));
  }

  getTeamCompetitions() {
    return this._get(this.actionUrl(`competition/team`));
  }

  addTeamCompetition(from, to, teams) {
    return this._post(this.actionUrl(`competition/team`), {from, to, teams});
  }

  getTeamCompetition(id) {
    return this._get(this.actionUrl(`competition/team/${id}`));
  }

  updateTeamCompetition(id, props) {
    return this._post(this.actionUrl(`competition/team/${id}`), {...props});
  }

  removeTeamCompetitionParticipant(competitionId, teamId) {
    return this._delete(this.actionUrl(`competition/team/${competitionId}/teams/${teamId}`));
  }

  recalculateTeamCompetition(id) {
    return this._post(this.actionUrl(`competition/team/${id}/update`));
  }

  getTeamCompetitionResults(uid) {
    return this._get(this.actionUrl(`competition/team/${uid}/result`));
  }

  getParks(search) {
    return this._get(this.actionUrl(`park/parks`), {search: search})
  }

  updatePark(id, props) {
    return this._post(this.actionUrl(`park/parks/${id}`), {...props})
  }

  addPark(props) {
    return this._post(this.actionUrl(`park/parks`), {...props})
  }

  removeParks(ids) {
    return this._delete(this.actionUrl(`park/parks?ids=${ids.join()}`), )
  }

  getParkWorkRules(clientId, apiKey, parkId) {
    return this._post(this.actionUrl(`directory/yataxi/work-rules`), {
      client_id: clientId,
      api_key: apiKey,
      park_id: parkId
    })
  }

  getParkWorkRulesForDriver(driverId) {
    return this._get(this.actionUrl(`directory/yataxi/driver-work-rules`), {driver_id: driverId})
  }

  getVehicleOwners(props) {
    return this._get(this.actionUrl(`park/owners`), {...props})
  }

  getVehicleOwner(id) {
    return this._get(this.actionUrl(`park/owners/${id}`))
  }

  getVehicleOwnerFiles(id) {
    return this._get(this.actionUrl(`park/owners/${id}/files`))
  }

  removeVehicleOwnerFile(id, fileId) {
    return this._delete(this.actionUrl(`park/owners/${id}/files/${fileId}`))
  }

  removeVehicleOwners(ids) {
    return this._delete(this.actionUrl(`park/owners?ids=${ids.join()}`), )
  }

  exportVehicleOwners(props) {
    return this._post(this.actionUrl(`park/owners/export/create`), {...props})
  }

  addVehicleOwner(props) {
    return this._post(this.actionUrl(`park/owners`), {...props})
  }

  updateVehicleOwner(id, props) {
    return this._post(this.actionUrl(`park/owners/${id}`), {...props})
  }

  getVehicles(params, view) {
    return this._get(this.actionUrl(`park/vehicles`), {...params, view})
  }

  getVehiclesWithFilter(filterName, params) {
    return this._get(this.actionUrl(`park/vehicles/filtered/${filterName}`), {view: "minimal", ...params})
  }

  getVehiclesFilters(filters=[]) {
    return this._get(this.actionUrl(`park/vehicles/filters`), {filter: filters.join()})
  }

  getVehicleReport(reportName) {
    return this._get(this.actionUrl(`park/vehicles/reports/${reportName}`))
  }

  getVehicleExportCode(params) {
    return this._post(this.actionUrl(`park/vehicles/export/create`), {...params})
  }

  getVehicleById(vehicleId) {
    return this._get(this.actionUrl(`park/vehicles/${vehicleId}`))
  }

  updateVehicle(id, props) {
    return this._post(this.actionUrl(`park/vehicles/${id}`), {...props})
  }

  updateVehiclePark(vehicleId, selectedPark) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/parks`), {park_id: selectedPark})
  }

  getVehicleOsago(vehicleId) {
    return this._get(this.actionUrl(`park/vehicles/${vehicleId}/osago`))
  }

  createVehicleOsago(vehicleId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/osago`), {...params})
  }

  updateVehicleOsago(vehicleId, policyId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/osago/${policyId}`), {...params})
  }

  deleteVehicleOsago(vehicleId, policyId) {
    return this._delete(this.actionUrl(`park/vehicles/${vehicleId}/osago/${policyId}`))
  }

  getVehicleOsgop(vehicleId) {
    return this._get(this.actionUrl(`park/vehicles/${vehicleId}/osgop`))
  }

  createVehicleOsgop(vehicleId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/osgop`), {...params})
  }

  updateVehicleOsgop(vehicleId, policyId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/osgop/${policyId}`), {...params})
  }

  deleteVehicleOsgop(vehicleId, policyId) {
    return this._delete(this.actionUrl(`park/vehicles/${vehicleId}/osgop/${policyId}`))
  }

  getVehicleKasko(vehicleId) {
    return this._get(this.actionUrl(`park/vehicles/${vehicleId}/kasko`))
  }

  createVehicleKasko(vehicleId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/kasko`), {...params})
  }

  updateVehicleKasko(vehicleId, policyId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/kasko/${policyId}`), {...params})
  }

  deleteVehicleKasko(vehicleId, policyId) {
    return this._delete(this.actionUrl(`park/vehicles/${vehicleId}/kasko/${policyId}`))
  }

  getVehicleDiagnosticCards(vehicleId) {
    return this._get(this.actionUrl(`park/vehicles/${vehicleId}/diagnostic`))
  }

  createVehicleDiagnosticCard(vehicleId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/diagnostic`), {...params})
  }

  updateVehicleDiagnosticCard(vehicleId, policyId, params) {
    return this._post(this.actionUrl(`park/vehicles/${vehicleId}/diagnostic/${policyId}`), {...params})
  }

  deleteVehicleDiagnosticCard(vehicleId, policyId) {
    return this._delete(this.actionUrl(`park/vehicles/${vehicleId}/diagnostic/${policyId}`))
  }

  getVehicleBrandingOptions(search) {
    return this._get(this.actionUrl(`park/vehicle-brandings`), {search: search})
  }

  getVehicleLightBoxOptions(search) {
    return this._get(this.actionUrl(`park/vehicle-lightboxes`), {search: search})
  }

  getVehiclePassportTypeOptions(search) {
    return this._get(this.actionUrl(`park/vehicle-passport-types`), {search: search})
  }

  getVehiclePromoOptions(search) {
    return this._get(this.actionUrl(`park/vehicle-promo`), {search: search})
  }

  // Для таблицы тех. осмотров
  getVehiclesTO(params) {
    return this._get(this.actionUrl(`park/vehiclesTO`), {...params})
  }

  // Для таблицы ОСАГО
  getVehiclesOSAGO(params) {
    return this._get(this.actionUrl(`park/vehiclesOSAGO`), {...params})
  }

  // Для таблицы ДК
  getVehiclesDK(params) {
    return this._get(this.actionUrl(`park/vehiclesDK`), {...params})
  }

  // Для таблицы Сервисы и партнеры
  getPartnersAndServices() {
    return this._get(this.actionUrl(`park/partnersAndServices`))
  }

  //Для таблицы сортировки по моделям
  getVehiclesModel() {
    return this._get(this.actionUrl(`park/vehiclesModel`))
  }

  //Для таблицы сортировки по классам
  getVehiclesClass() {
    return this._get(this.actionUrl(`park/vehiclesClass`))
  }

  updateVehicles(ids, props) {
    return this._put(this.actionUrl(`park/vehicles`), {ids, props})
  }

  /**
   * Создание нового ТС
   */
  createVehicle(props) {
    return this._post(this.actionUrl(`park/vehicles`), {...props})
  }

  /**
   * Удаление ТС
   */
  removeVehicle(id) {
    return this._delete(this.actionUrl(`park/partnersAndServices/${id}`))
  }

  //Удаление записи из таблицы Сервисы и Партнеры
  removeService(id) {
    return this._delete(this.actionUrl(`park/vehicles/${id}`))
  }

  /**
   * Загрузка файла к ТС
   */
  getVehicleFiles(id) {
    return this._get(this.actionUrl(`park/vehicles/${id}/files`))
  }

  /**
   *
   * @param id
   * @param fileId
   * @returns {Promise<unknown>}
   */
  removeVehicleFile(id, fileId) {
    return this._delete(this.actionUrl(`park/vehicles/${id}/files/${fileId}`))
  }

  getVehicleRentHistory(id) {
    return this._get(this.actionUrl(`park/vehicles/${id}/rents`));
  }

  getVehicleGroupsHistory(id) {
    return this._get(this.actionUrl(`park/vehicles/${id}/groups`));
  }

  /**
   * Получение истории инспекций авто указанного типа
   *
   * @param id идентификатор записи авто
   * @param type тип записи инспекции (текущая, техническая)
   */
  getVehicleInspectionsHistory(id, type) {
    return this._get(this.actionUrl(`park/vehicles/${id}/inspections`), {"type": type, "order_by": "-create_dt"});
  }

  /**
   * Получение истории изменений атрибутов
   *
   * @param id идентификатор записи авто
   */
  getVehicleChangeHistory(id) {
    return this._get(this.actionUrl(`park/vehicles/${id}/change-history`));
  }

  /**
   * Получение договоров аренды ТС
   * @param id
   * @param params параметры фильтрации
   */
  getVehicleRentContracts(id, params) {
    return this._get(this.actionUrl(`park/vehicles/${id}/rent-contracts`), params);
  }

  /**
   * Получение информации о договоре аренды ТС
   * @param contractId идентификатор договора
   */
  getRentContract(contractId) {
    return this._get(this.actionUrl(`park/rent-contracts/${contractId}`));
  }

  /**
   *
   * @param ids
   */
  removeRentContracts(ids) {
    let queryArgs = ids.map(el => `ids[]=${el}`).join("&");
    return this._delete(this.actionUrl(`park/rent-contracts?${queryArgs}`));
  }

  /**
   * Обновить договор аренды
   * @param contractId ссылка на договор
   * @param params атрибуты договора
   * @returns {Promise<unknown>}
   */
  updateRentContract(contractId, params) {
    return this._post(this.actionUrl(`park/rent-contracts/${contractId}`), {...params});
  }

  /**
   * Создание договора аренды
   */
  createRentContract(params) {
    return this._post(this.actionUrl(`park/rent-contracts`), {...params});
  }

  /**
   * Договоры аренды
   */
  getRentContracts(params) {
    return this._get(this.actionUrl(`park/rent-contracts`), {...params});
  }

  /**
   * История договора
   */
  getRentContractHistory(contractId) {
    return this._get(this.actionUrl(`park/rent-contracts/${contractId}/changes`));
  }

  /**
   * Создание договора по указанному шаблону
   */
  renderRentContractTemplate(contractId, templateId) {
    return this._post(this.actionUrl(`park/rent-contracts/${contractId}/templates/${templateId}`));
  }

  createDriverCandidate(props) {
    return this._post(this.actionUrl(`drivers/candidates`), {...props})
  }

  createDriverCandidatePublic(props) {
    return this._post(this.actionUrl(`drivers/candidates/submit`), {...props})
  }

  updateDriverCandidate(id, props) {
    return this._post(this.actionUrl(`drivers/candidates/${id}`), {...props})
  }

  acceptDriverCandidate(id, params) {
    return this._post(this.actionUrl(`drivers/candidates/${id}/accept`), params)
  }

  getDriverCandidateComments(id) {
    return this._get(this.actionUrl(`drivers/candidates/${id}/comments`))
  }

  /**
   * Загрузка отчетов по кандидату
   * @param id
   * @returns {Promise<unknown>}
   */
  getDriverCandidateReports(id) {
    return this._get(this.actionUrl(`drivers/candidates/${id}/reports/spectrum-data`))
  }

  getDriverCandidateReportRegions(driverId) {
    return this._get(this.actionUrl(`drivers/candidates/${driverId}/reports/spectrum-data/dictionary/regions`))
  }

  runDriverCandidateReports(id) {
    return this._post(this.actionUrl(`drivers/candidates/${id}/reports/spectrum-data`))
  }

  deleteDriverCandidateReport(candidateId, reportId) {
    return this._delete(this.actionUrl(`drivers/candidates/${candidateId}/reports/spectrum-data/${reportId}`))
  }

  getDriverCandidateReport(candidateId, reportId) {
    return this._get(this.actionUrl(`drivers/candidates/${candidateId}/reports/spectrum-data/${reportId}`))
  }

  addDriverCandidateComment(id, comment) {
    return this._post(this.actionUrl(`drivers/candidates/${id}/comments`), {message: comment})
  }

  removeDriverCandidateComment(id, commentId) {
    return this._delete(this.actionUrl(`drivers/candidates/${id}/comments/${commentId}`))
  }

  getDriverCandidates(params) {
    return this._get(this.actionUrl(`drivers/candidates`), {...params})
  }

  getPrintTemplates(params) {
    return this._get(this.actionUrl(`directory/print-templates`), {...params});
  }

  addPrintTemplate(args) {
    return this._post(this.actionUrl(`directory/print-templates`), {...args});
  }

  updatePrintTemplate(templateId, args) {
    return this._post(this.actionUrl(`directory/print-templates/${templateId}`), {...args});
  }

  updatePrintTemplateContent(templateId, file) {
    return this._postForm(this.actionUrl(`directory/print-templates/${templateId}/content`), {"file": file});
  }

  removePrintTemplates(ids) {
    return this._delete(this.actionUrl(`directory/print-templates?ids=${ids.join()}`));
  }

  getPrintTemplateTags(params) {
    return this._get(this.actionUrl(`directory/print-templates/tags`), params);
  }

  getVehicleGroups(param) {
    return this._get(this.actionUrl(`directory/vehicle-groups`), param);
  }

  removeVehicleGroups(ids) {
    return this._delete(this.actionUrl(`directory/vehicle-groups`), {}, {ids});
  }

  createVehicleGroup(params) {
    return this._post(this.actionUrl(`directory/vehicle-groups`), params);
  }

  updateVehicleGroup(recordId, params) {
    return this._post(this.actionUrl(`directory/vehicle-groups/${recordId}`), params);
  }

  getRegions(params) {
    return this._get(this.actionUrl(`directory/regions`), params);
  }

  removeRegions(ids) {
    return this._delete(this.actionUrl(`directory/regions?ids=${ids.join()}`), );
  }

  createRegion(params) {
    return this._post(this.actionUrl(`directory/regions`), params);
  }

  updateRegion(recordId, params) {
    return this._put(this.actionUrl(`directory/regions/${recordId}`), params);
  }

  getTaxiLicenceAuthorities(params) {
    return this._get(this.actionUrl(`directory/taxiLicenceAuthority`), params);
  }

  removeTaxiLicenceAuthorities(ids) {
    return this._delete(this.actionUrl(`directory/taxiLicenceAuthority?ids=${ids.join()}`), );
  }

  createTaxiLicenceAuthority(params) {
    return this._post(this.actionUrl(`directory/taxiLicenceAuthority`), params);
  }

  updateTaxiLicenceAuthority(recordId, params) {
    return this._put(this.actionUrl(`directory/taxiLicenceAuthority/${recordId}`), params);
  }

  getValueLists(categories) {
    return this._post(this.actionUrl(`directory/value-lists`), {categories});
  }

  getValueList(category) {
    return this._get(this.actionUrl(`directory/value-lists/${category}`));
  }

  addValueListItem(category, title) {
    return this._post(this.actionUrl(`directory/value-lists/${category}`), {title: title});
  }

  removeValueListItem(category, uid) {
    return this._delete(this.actionUrl(`directory/value-lists/${category}/${uid}`));
  }

  getServiceMaintenanceStatusList(params) {
    return this._get(this.actionUrl(`park/service/maintenance`), params);
  }

  updateServiceMaintenanceStatus(recordId, params) {
    return this._put(this.actionUrl(`park/service/maintenance/${recordId}`), params);
  }

  assignServiceMaintenanceTime(ids, params) {
    return this._post(this.actionUrl(`park/service/maintenance/planned_maintenance?ids=${ids.join()}`), params);
  }

  assignServiceMaintenanceStatus(ids, params) {
    return this._post(this.actionUrl(`park/service/maintenance/workplan?ids=${ids.join()}`), params);
  }

  getServiceOsagoStatusList(params) {
    return this._get(this.actionUrl(`park/service/osago`), params);
  }

  updateServiceOsagoStatus(recordId, params) {
    return this._put(this.actionUrl(`park/service/osago/${recordId}`), params);
  }

  getServiceOsgopStatusList(params) {
    return this._get(this.actionUrl(`park/service/osgop`), params);
  }

  updateServiceOsgopStatus(recordId, params) {
    return this._put(this.actionUrl(`park/service/osgop/${recordId}`), params);
  }

  getServiceKaskoStatusList(params) {
    return this._get(this.actionUrl(`park/service/kasko`), params);
  }

  updateServiceKaskoStatus(recordId, params) {
    return this._put(this.actionUrl(`park/service/kasko/${recordId}`), params);
  }

  getServiceDiagnosticStatusList(params) {
    return this._get(this.actionUrl(`park/service/diagnostic`), params);
  }

  updateServiceDiagnosticStatus(recordId, params) {
    return this._put(this.actionUrl(`park/service/diagnostic/${recordId}`), params);
  }

  getServiceSummaryTypeStatus(params) {
    return this._get(this.actionUrl(`park/service/type-status`), params);
  }

  getServiceSummaryTypeStatusDetails(vehicleType, status, params) {
    return this._get(this.actionUrl(`park/service/type-status/${vehicleType}/${status}`), params);
  }

  getServiceSummaryTypeLongInStatusDetails(status, minDays, maxDays, params) {
    return this._get(this.actionUrl(`park/service/type-status/days-in-status/${status}/${minDays}/${maxDays}`), params);
  }

  getServiceSummaryModelStatus(params) {
    return this._get(this.actionUrl(`park/service/model-status`), params);
  }

  getServiceDowntimeTypeStatus(params) {
    return this._get(this.actionUrl(`park/service/downtime/type-status`), params);
  }

  getServiceDowntimeTypeStatusHistory(params) {
    return this._get(this.actionUrl(`park/service/downtime/history/type-status`), params);
  }

  getServiceDowntimeTypeStatusDetails(typeId, params) {
    return this._get(this.actionUrl(`park/service/downtime/type-status/details`), {typeId, ...params});
  }

  getServiceDowntimeModelStatus(params) {
    return this._get(this.actionUrl(`park/service/downtime/model-status`), params);
  }

  getServiceDowntimeModelStatusHistory(params) {
    return this._get(this.actionUrl(`park/service/downtime/history/model-status`), params);
  }

  getServiceDowntimeModelStatusDetails(model, params) {
    return this._get(this.actionUrl(`park/service/downtime/model-status/details`), {model, ...params});
  }

  subscribeRoadAccidentStatistics(callback) {
    const fn = () => {
      this._get(this.actionUrl(`roadAccident/stats`))
        .then(res => { callback(res) })
        .catch(err => console.error(err))
    };
    fn();
    return setInterval(fn, 1000 * 60);
  }

  getApplications(params) {
    return this._get(this.actionUrl(`roadAccident/accidentRequests`), params);
  }

  createApplication(params) {
    return this._post(this.actionUrl(`roadAccident/accidentRequests`), params);
  }

  updateApplication(id, params) {
    return this._patch(this.actionUrl(`roadAccident/accidentRequests/${id}`), params);
  }

  removeApplication(ids) {
    return this._delete(this.actionUrl(`roadAccident/accidentRequests?ids=${ids.join(",")}`));
  }

  getListsForDtp() {
    return this._get(this.actionUrl(`roadAccident/frontEnums`));
  }

  getDtp(id) {
    return this._get(this.actionUrl(`roadAccident/accidents/${id}`));
  }

  updateDtp(id, params) {
    return this._patch(this.actionUrl(`roadAccident/accidents/${id}`), params);
  }

  removeDtp(ids) {
    return this._delete(this.actionUrl(`roadAccident/accidents`), {}, {ids});
  }

  getDtpSummary(params) {
    return this._get(this.actionUrl(`roadAccident/accidents/summary`), params);
  }

  getAllDtp(params) {
    return this._get(this.actionUrl(`roadAccident/accidents`), params);
  }

  createDtp(params) {
    return this._post(this.actionUrl(`roadAccident/accidents`), params);
  }

  getDtpPaymentExecutions(dtpId) {
    return this._get(this.actionUrl(`roadAccident/accidents/${dtpId}/payment-executions`));
  }

  addDtpPaymentExecution(dtpId, params) {
    return this._post(this.actionUrl(`roadAccident/accidents/${dtpId}/payment-executions`), params);
  }

  getCrmUser() {
    return this._get(this.actionUrl(`auth/domain/users`));
  }

  getServiceSummaryModelStatusDetails(brand, model, status, params) {
    return this._get(this.actionUrl(`park/service/model-status/details`), {...params, brand, model, status});
  }

  getExpenseCategories(params) {
    return this._get(this.actionUrl(`payments/expense-categories`), {...params});
  }

  removeExpenseCategories(ids) {
    return this._delete(this.actionUrl(`payments/expense-categories?ids=${ids.join()}`), {ids});
  }

  createExpenseCategory(params) {
    return this._post(this.actionUrl(`payments/expense-categories`), params);
  }

  updateExpenseCategory(id, params) {
    return this._post(this.actionUrl(`payments/expense-categories/${id}`), params);
  }

  getCompensationCategories(params) {
    return this._get(this.actionUrl(`payments/compensation-categories`), {...params});
  }

  removeCompensationCategories(ids) {
    return this._delete(this.actionUrl(`payments/compensation-categories?ids=${ids.join()}`), {ids});
  }

  createCompensationCategory(params) {
    return this._post(this.actionUrl(`payments/compensation-categories`), params);
  }

  updateCompensationCategory(id, params) {
    return this._post(this.actionUrl(`payments/compensation-categories/${id}`), params);
  }

  getTransactionGroups(param) {
    return this._get(this.actionUrl(`payments/transaction-groups`), param);
  }

  removeTransactionGroups(ids) {
    return this._delete(this.actionUrl(`payments/transaction-groups?ids=${ids.join()}`), {ids});
  }

  createTransactionGroup(params) {
    return this._post(this.actionUrl(`payments/transaction-groups`), params);
  }

  getFiles(id) {
    return this._get(this.actionUrl(`roadAccident/accidentFiles?accident_common_id=${id}`));
  }

  getTransactions(commonInfoId) {
    return this._get(this.actionUrl(`roadAccident/transactions/${commonInfoId}`));
  }

  createTransaction(commonInfoId, params) {
    return this._post(this.actionUrl(`roadAccident/transactions/${commonInfoId}`), params);
  }

  removeTransaction(id) {
    return this._delete(this.actionUrl(`roadAccident/transactions`), {}, {"ids": [id]});
  }

  removeFile(id) {
    return this._delete(this.actionUrl(`roadAccident/accidentFiles?ids=${id}`))
  }

  getNotification(params) {
    return this._get(this.actionUrl(`roadAccident/accidentNotifications`), params);
  }

  getExcelFile(uid) {
    return this._get(this.actionUrl(`roadAccident/accidents/export/download?uid=${uid}`));
  }

  getNotificationCampaings(filter) {
    return this._get(this.actionUrl(`auth/domain/notifications/telegram`));
  }

  updateNotificationCampaign(id, params) {
    return this._patch(this.actionUrl(`auth/domain/notifications/telegram/${id}`), {...params});
  }

  createNotificationCampaign(params, recipients) {
    return this._post(this.actionUrl(`auth/domain/notifications/telegram`), {...params, recipients: recipients});
  }

  removeNotificationCampaigns(ids) {
    return this._delete(this.actionUrl(`auth/domain/notifications/telegram`), {}, {ids});
  }

  getNotificationCampaingRecipients(id) {
    return this._get(this.actionUrl(`auth/domain/notifications/telegram/${id}/recipients`));
  }

  updateNotificationCampaingRecipients(id, recipients) {
    return this._post(this.actionUrl(`auth/domain/notifications/telegram/${id}/recipients`), {ids: recipients});
  }

  getBackgroundTaskStatus(uid) {
    return this._get(this.actionUrl(`core/bgtask/${uid}`));
  }

  getPaymentExpenses(filters) {
    return this._get(this.actionUrl(`payments/expenses`), filters);
  }

  createPaymentExpense(params) {
    return this._post(this.actionUrl(`payments/expenses`), {...params});
  }

  getPaymentExpense(id) {
    return this._get(this.actionUrl(`payments/expenses/${id}`));
  }

  updatePaymentExpense(id, params) {
    return this._post(this.actionUrl(`payments/expenses/${id}`), {...params});
  }

  deletePaymentExpenses(ids) {
    return this._delete(this.actionUrl(`payments/expenses?ids=${ids.join()}`));
  }

  getPaymentExpensesExport(ids) {
    return this._post(this.actionUrl(`payments/expenses/export`), {ids});
  }

  getPaymentExpensesImportStatus() {
    return this._get(this.actionUrl(`payments/expenses/import`));
  }

  getPaymentCompensations(filters) {
    return this._get(this.actionUrl(`payments/compensations`), filters);
  }

  getPaymentCompensation(id) {
    return this._get(this.actionUrl(`payments/compensations/${id}`));
  }

  createPaymentCompensation(params) {
    return this._post(this.actionUrl(`payments/compensations`), params);
  }

  updatePaymentCompensation(id, params) {
    return this._post(this.actionUrl(`payments/compensations/${id}`), params);
  }

  deletePaymentCompensations(ids) {
    return this._delete(this.actionUrl(`payments/compensations?ids=${ids.join()}`));
  }

  getPaymentCompensationsExport(ids) {
    return this._post(this.actionUrl(`payments/compensations/export`), {ids});
  }

  getPaymentCompensationsImportStatus() {
    return this._get(this.actionUrl(`payments/compensations/import`));
  }
}

const apiClient = new ApiClient();

export {apiClient};
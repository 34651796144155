import React, {useState} from "react";
import {Tabs} from "antd";
import MessageTemplates from "./photocontrol/MessageTemplates";
import WorkflowStepsEditor from "./photocontrol/WorkflowStepsEdit";
import NotificationCampaings from "./photocontrol/NotificationCampaings";

const { TabPane } = Tabs;

export default function PhotocontrolBotTab({active}) {

  let [activeTabKey, setActiveTabKey] = useState("1");

  const onTabChange = async ({key}) => {
    setActiveTabKey(key);
  };

  return (
    <Tabs onChange={onTabChange} tabPosition={"left"}>
      <TabPane tab="Рассылка уведомлений" key="1">
        <NotificationCampaings active={active && activeTabKey === "1"}/>
      </TabPane>
      <TabPane tab="Шаблоны сообщений" key="2">
        <MessageTemplates active={activeTabKey === "2"}/>
      </TabPane>
      <TabPane tab="Осмотр: такси" key="3">
        <WorkflowStepsEditor title={"Осмотр: такси"} process={"passenger_taxi"} active={activeTabKey === "3"}/>
      </TabPane>
      <TabPane tab="Осмотр: эвакуатор" key="4">
        <WorkflowStepsEditor title={"Осмотр: эвакуатор"} process={"tow_truck"} active={activeTabKey === "4"}/>
      </TabPane>
    </Tabs>
  );
}
import React, {useEffect, useState} from "react";
import {message, Modal, Table} from "antd";
import {apiClient} from "../../../services/ApiClient";

export default function PrintFormSelectDialog({visible, title, modelClass, unitType, onOk, onCancel}) {
  let [templates, setTemplates] = useState([]);
  let [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if(visible) {
      apiClient.getPrintTemplates({model: modelClass, type: unitType})
        .then(res => {
          setTemplates(res.records);
        })
        .catch(err => message.error(err));
    } else {
      setTemplates([]);
    }
  }, [visible, modelClass, unitType]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      width={800}
      title={title || ""}
      visible={visible}
      onOk={() => {
        onOk(selectedTemplate);
      }}
      onCancel={onCancel}>

      <div>
        <Table
          sticky
          style={{border: '1px solid #eee', borderRadius: 5}}
          pagination={{ pageSize: 10 }}
          showHeader={false}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              if(selectedRows && selectedRows.length > 0) {
                setSelectedTemplate(selectedRows[0]);
              } else {
                setSelectedTemplate(null);
              }
            },
          }}
          size={'small'}
          columns={[
            {
              title: "",
              align: "center",
              width: 30,
            },
            Table.SELECTION_COLUMN,
            {
              title: 'Шаблон',
              dataIndex: 'title',
            },
            {
              title: "",
              align: "center",
              width: 20,
            }
          ]}
          dataSource={templates}
          rowKey="id"
        />

      </div>
    </Modal>
  );
}
import React, {useEffect, useState} from "react";
import {
  Col,
  Collapse,
  Input,
  message,
  Modal,
  Row,
} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";

const rowStyle = {padding: '4px'};
const { Panel } = Collapse;

export default function ServiceEditDialog({visible, vehicleId, onClose}) {
  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({id: 0});
  let [vehicleOwners, setVehicleOwners] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [taxiparks, setTaxiparks] = useState([]);
  let [brandingOptions, setBrandingOptions] = useState([]);
  let [lightBoxOptions, setLightBoxOptions] = useState([]);
  let [promoOptions, setPromoOptions] = useState([]);
  let [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    setFormErrors([]);

    if(vehicleId && vehicleId > 0) {
      apiClient.getVehicleById(vehicleId)
        .then(res => {
          setRecord(res.record);

          if(!res.record.branding) {
            apiClient.getVehicleBrandingOptions()
              .then(res => setBrandingOptions(res.records))
              .catch(err => console.error(err));
          }
          if(!res.record.lightbox) {
            apiClient.getVehicleLightBoxOptions()
              .then(res => setLightBoxOptions(res.records))
              .catch(err => console.error(err));
          }
          if(!res.record.promo) {
            apiClient.getVehiclePromoOptions()
              .then(res => setPromoOptions(res.records))
              .catch(err => console.error(err));
          }
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false) });
    } else {
      setRecord({id: 0})
    }
  }, [vehicleId]);

  useEffect(() => {
    apiClient.getVehicleOwners()
      .then(res => { setVehicleOwners(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getDriversList({ accountStatus: 'working' }, 'simple')
      .then(res => { setDrivers(res.drivers) })
      .catch(err => { console.error(err) });
    apiClient.getParks()
      .then(res => { setTaxiparks(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const vehicleForm = () => {
    return (
      <>
        <Collapse style={{marginTop: 16}} defaultActiveKey={['1']}>
          
          <Panel header="Общие" key="1">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col md={6}>Название тех. центра</Col>
              <Col md={6}>
                <Input
                  allowClear={true}
                  placeholder={"Название тех. центра"}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col md={6}>Адрес</Col>
              <Col md={6}>
                <Input
                  allowClear={true}
                  placeholder={"Адрес"}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col md={6}>Номер телефона</Col>
              <Col md={6}>
                <Input
                  allowClear={true}
                  placeholder={"Номер телефона"}/>
              </Col>
            </Row>
          </Panel>
        </Collapse>

      </>
    )
  };

  const form = () => {
    return (
        vehicleForm()
    )
  };

  const validateRecord = record => {
    return new Promise((resolve, reject) => {
      let errors = [].filter(attr => { return !record[attr] });

      if(!errors || errors.length < 1) {
        resolve(record);
      } else {
        reject(errors);
      }
    })
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      width={"80%"}
      title={record && record.id > 0 ? `${record.brand} ${record.model} ${record.reg_number}` : 'Сервис'}
      visible={visible}
      confirmLoading={loading}
      onOk={() => {
        validateRecord(record)
          .then(validated => {
            setLoading(true);
            if(vehicleId) {
              apiClient.updateVehicle(vehicleId, validated)
                .then(res => {
                  message.info("Запись обновлена");
                  onClose();
                })
                .catch(err => message.error(err))
                .finally(() => setLoading(false))
            } else {
              apiClient.createVehicle(validated)
                .then(res => {
                  message.info("Запись добавлена");
                  onClose();
                })
                .catch(err => message.error(err))
                .finally(() => setLoading(false))
            }
          })
          .catch(err => {
            setFormErrors(err);
            message.error("Заполните все необходимые поля")
          });
      }}
      onCancel={onClose}>

      {
        vehicleId !== null
          ? form()
          : (
            <div style={{margin: '20px auto'}}><LoadingOutlined /></div>
          )
      }

    </Modal>
  );
}
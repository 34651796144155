import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, message, PageHeader, Select, Space, Table} from "antd";

import './ServiceSummaryTypeStatusPage.css'
import ServiceSummaryTypeStatusDetailsDialog from "./ServiceSummaryTypeStatusDetailsDialog";

const { Option } = Select;

export default function ServiceSummaryModelStatusPage() {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState(null);
  let [cellDetails, setCellDetails] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceSummaryModelStatus({regionId: selectedRegionId, groupId: selectedGroupId})
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  }, [update, selectedRegionId, selectedGroupId]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => {
        setRegions(res.records);
      })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const showDetails = (brand, model, colName) => {
    setLoading(true);
    apiClient.getServiceSummaryModelStatusDetails(brand, model, colName, { regionId: selectedRegionId, groupId: selectedGroupId })
      .then(res => {
        setCellDetails(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Статусы по моделям ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Моделей ТС: ${records.length}`}</span>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Филиал"
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          {
            title: 'Модель ТС',
            dataIndex: 'brand_model',
            align: "center",
          },
          {
            title: 'Всего',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return (
                record.total > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'total') }}>{record.total}</Button>
                  : <div style={{textAlign: 'center'}}>{record.total}</div>
              )
            },
            sorter: (a, b) => {
              return a.total - b.total;
            },
          },
          {
            title: 'На линии',
            dataIndex: 'working',
            align: "center",
            render: (text, record, index) => {
              return (
                record.working > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'working') }}>{record.working}</Button>
                  : <div style={{textAlign: 'center'}}>{record.working}</div>
              )
            },
            sorter: (a, b) => {
              return a.working - b.working;
            },
          },
          {
            title: 'В парке',
            dataIndex: 'parked',
            align: "center",
            render: (text, record, index) => {
              return (
                record.parked > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'parked') }}>{record.parked}</Button>
                  : <div style={{textAlign: 'center'}}>{record.parked}</div>
              )
            },
            sorter: (a, b) => {
              return a.parked - b.parked;
            },
          },
          {
            title: 'ДТП',
            dataIndex: 'accident',
            align: "center",
            render: (text, record, index) => {
              return (
                record.accident > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'accident') }}>{record.accident}</Button>
                  : <div style={{textAlign: 'center'}}>{record.accident}</div>
              )
            },
            sorter: (a, b) => {
              return a.accident - b.accident;
            },
          },
          {
            title: 'Ожидание поставки запчастей',
            dataIndex: 'parts_waiting',
            align: "center",
            render: (text, record, index) => {
              return (
                record.parts_waiting > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'parts_waiting') }}>{record.parts_waiting}</Button>
                  : <div style={{textAlign: 'center'}}>{record.parts_waiting}</div>
              )
            },
            sorter: (a, b) => {
              return a.parts_waiting - b.parts_waiting;
            },
          },
          {
            title: 'Слесарный ремонт парк',
            dataIndex: 'locksmith_repair_park',
            align: "center",
            render: (text, record, index) => {
              return (
                record.locksmith_repair_park > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'locksmith_repair_park') }}>{record.locksmith_repair_park}</Button>
                  : <div style={{textAlign: 'center'}}>{record.locksmith_repair_park}</div>
              )
            },
            sorter: (a, b) => {
              return a.locksmith_repair_park - b.locksmith_repair_park;
            },
          },
          {
            title: 'Слесарный ремонт подрядчики',
            dataIndex: 'locksmith_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return (
                record.locksmith_repair_contractor > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'locksmith_repair_contractor') }}>{record.locksmith_repair_contractor}</Button>
                  : <div style={{textAlign: 'center'}}>{record.locksmith_repair_contractor}</div>
              )
            },
            sorter: (a, b) => {
              return a.locksmith_repair_contractor - b.locksmith_repair_contractor;
            },
          },
          {
            title: 'Требуется кузовной ремонт',
            dataIndex: 'need_body_repair',
            align: "center",
            render: (text, record, index) => {
              return (
                record.need_body_repair > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'need_body_repair') }}>{record.need_body_repair}</Button>
                  : <div style={{textAlign: 'center'}}>{record.need_body_repair}</div>
              )
            },
            sorter: (a, b) => {
              return a.need_body_repair - b.need_body_repair;
            },
          },
          {
            title: 'Кузовной ремонт парк',
            dataIndex: 'body_repair_park',
            align: "center",
            render: (text, record, index) => {
              return (
                record.body_repair_park > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'body_repair_park') }}>{record.body_repair_park}</Button>
                  : <div style={{textAlign: 'center'}}>{record.body_repair_park}</div>
              )
            },
            sorter: (a, b) => {
              return a.body_repair_park - b.body_repair_park;
            },
          },
          {
            title: 'Кузовной ремонт подрядчики',
            dataIndex: 'body_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return (
                record.body_repair_contractor > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'body_repair_contractor') }}>{record.body_repair_contractor}</Button>
                  : <div style={{textAlign: 'center'}}>{record.body_repair_contractor}</div>
              )
            },
            sorter: (a, b) => {
              return a.body_repair_contractor - b.body_repair_contractor;
            },
          },
          {
            title: 'Гарантийный ремонт',
            dataIndex: 'warranty_repair',
            align: "center",
            render: (text, record, index) => {
              return (
                record.warranty_repair > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'warranty_repair') }}>{record.warranty_repair}</Button>
                  : <div style={{textAlign: 'center'}}>{record.warranty_repair}</div>
              )
            },
            sorter: (a, b) => {
              return a.warranty_repair - b.warranty_repair;
            },
          },
          {
            title: 'Отдел подготовки',
            dataIndex: 'preparing',
            align: "center",
            render: (text, record, index) => {
              return (
                record.preparing > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'preparing') }}>{record.preparing}</Button>
                  : <div style={{textAlign: 'center'}}>{record.preparing}</div>
              )
            },
            sorter: (a, b) => {
              return a.preparing - b.preparing;
            },
          },
          {
            title: 'Подготовка к выдаче',
            dataIndex: 'licenseless',
            align: "center",
            render: (text, record, index) => {
              return (
                record.licenseless > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'licenseless') }}>{record.licenseless}</Button>
                  : <div style={{textAlign: 'center'}}>{record.licenseless}</div>
              )
            },
            sorter: (a, b) => {
              return a.licenseless - b.licenseless;
            },
          },
          {
            title: 'Возврат собственнику',
            dataIndex: 'owner_return',
            align: "center",
            render: (text, record, index) => {
              return (
                record.owner_return > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'owner_return') }}>{record.owner_return}</Button>
                  : <div style={{textAlign: 'center'}}>{record.owner_return}</div>
              )
            },
            sorter: (a, b) => {
              return a.owner_return - b.owner_return;
            },
          },
          {
            title: 'Перегон',
            dataIndex: 'transfer',
            align: "center",
            render: (text, record, index) => {
              return (
                record.transfer > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'transfer') }}>{record.transfer}</Button>
                  : <div style={{textAlign: 'center'}}>{record.transfer}</div>
              )
            },
            sorter: (a, b) => {
              return a.repair - b.repair;
            },
          },
          {
            title: 'Иное',
            dataIndex: 'other',
            align: "center",
            render: (text, record, index) => {
              return (
                record.other > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'other') }}>{record.other}</Button>
                  : <div style={{textAlign: 'center'}}>{record.other}</div>
              )
            },
            sorter: (a, b) => {
              return a.other - b.other;
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="brand_model"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let totalTotal = 0;
          let totalWorking = 0;
          let totalParked = 0;
          let totalAccident = 0;
          let totalPartsWaiting = 0;
          let totalLocksmithRepairPark = 0;
          let totalLocksmithRepairContractor = 0;
          let totalNeedBodyRepair = 0;
          let totalBodyRepairPark = 0;
          let totalBodyRepairContractor = 0;
          let totalWarrantyRepair = 0;
          let totalLicenseless = 0;
          let totalOwnerReturn = 0;
          let totalPreparing = 0;
          let totalRransfer= 0;
          let totalOther = 0;
          pageData.forEach(({ brand_model, total, working, parked, accident, parts_waiting, locksmith_repair_park,
                              locksmith_repair_contractor, need_body_repair, body_repair_park, body_repair_contractor,
                              warranty_repair, preparing, licenseless, owner_return, transfer, other }) => {
            totalTotal += total;
            totalWorking += working;
            totalParked += parked;
            totalAccident += accident;
            totalPartsWaiting += parts_waiting;
            totalLocksmithRepairPark += locksmith_repair_park;
            totalLocksmithRepairContractor += locksmith_repair_contractor;
            totalNeedBodyRepair += need_body_repair;
            totalBodyRepairPark += body_repair_park;
            totalBodyRepairContractor += body_repair_contractor;
            totalWarrantyRepair += warranty_repair;
            totalLicenseless += licenseless;
            totalOwnerReturn += owner_return;
            totalPreparing += preparing;
            totalRransfer += transfer;
            totalOther += other;
          });
          return loading ? null : (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}><div style={{textAlign: 'center'}}>{totalTotal}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><div style={{textAlign: 'center'}}>{totalWorking}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={4}><div style={{textAlign: 'center'}}>{totalParked}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={5}><div style={{textAlign: 'center'}}>{totalAccident}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalPartsWaiting}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalLocksmithRepairPark}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalLocksmithRepairContractor}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalNeedBodyRepair}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalBodyRepairPark}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalBodyRepairContractor}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalWarrantyRepair}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalLicenseless}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalOwnerReturn}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalPreparing}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalRransfer}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalOther}</div></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        cellDetails && (
          <ServiceSummaryTypeStatusDetailsDialog
            data={cellDetails}
            onClose={() => { setCellDetails(null); }}
          />
        )
      }
    </>
  )
}
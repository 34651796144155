import React from "react";
import {Col, Row, Alert} from "antd";
import {Link, useParams} from "react-router-dom";

export default function CarReturnPage(props) {

  let { tenantCode = 'default' } = useParams();

  return (
    <React.Fragment>
      <Row justify="space-around" align="middle">
        <Col xs={1} md={2}> </Col>
        <Col xs={22} md={20}>
          <br/>
          <br/>
          <br/>
          <div style={{textAlign: "center", margin: '50px 0'}}>
            <Alert message="Ваша заявка успешно зарегистрирована" type="success" />
            <p>
              <Link to={`/vozvrat/${tenantCode}`}>Назад</Link>
            </p>
          </div>
        </Col>
        <Col xs={1} md={2}> </Col>
      </Row>
    </React.Fragment>
  );
}
import React, {useEffect, useState} from "react";
import {Col, Modal, Row, DatePicker, message, Space, Button, Table, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function CompetitionsAddDialog({visible, onOk, onClose}) {

  let [period, setPeriod] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [allDrivers, setAllDrivers] = useState([]);
  let [selectedDriverId, setSelectedDriverId] = useState(null);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<DeleteOutlined/>} onClick={() => {setDrivers(drivers.filter(el => el.id !== record.id));}}/>
          </Space>
        );
      }
    },
    {
      title: 'Водитель',
      dataIndex: 'name',
    },
    {
      title: 'Марка',
      dataIndex: 'car_model',
    },
    {
      title: 'Номер',
      dataIndex: 'car_number',
    },
    {
      title: '',
      dataIndex: 'id',
      render: (text, record, index) => {
        return "";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(visible) {
      apiClient.getCompetitionDriversList()
        .then(res => {
          setAllDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [visible]);

  function render() {
    return (
      <>
        <Row style={rowStyle}>
          <Col span={3}>Даты</Col>
          <Col span={18}>
            <RangePicker showTime
                         style={{ width: 400 }}
                         onChange={(dates, dateStrings) => { setPeriod(dateStrings); }}
            />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={3}>Добавить участника</Col>
          <Col span={18}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Водитель"
                onChange={(value) => {
                  if(!value) {
                    setSelectedDriverId(null);
                  } else {
                    setSelectedDriverId(value);
                  }
                }}
                value={selectedDriverId}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {allDrivers.map(s => <Option key={s.id} value={s.id}>{`${s.name}, ${s.phone}`}</Option>)}
              </Select>
              <Button type={"link"}
                      disabled={!selectedDriverId}
                      onClick={() => {
                        let driver = allDrivers.filter(el => el.id == selectedDriverId)[0];
                        if(driver) {
                          setDrivers([...drivers, driver]);
                          setSelectedDriverId(null);
                        }
                      }}
              >Добавить</Button>
            </Space>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={drivers}
                 rowKey="id"/>

        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Конкурс"}
      visible={visible}
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{ top: 20 }}
      onOk={() => {
        if(!period || period.length === 0) {
          message.warn("Укажите период");
          return;
        }
        if(!drivers || drivers.length === 0) {
          message.warn("Добавьте водителей");
          return;
        }
        onOk(period, drivers);
        setPeriod([]);
        setDrivers([]);
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        render()
      }
    </Modal>
  );

}
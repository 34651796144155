import React from "react";
import {Button, Divider, Modal, Space} from "antd";
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  CheckOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";

import moment from "moment";
import {windowService} from "../../../services/WindowService";

export default function LeaveZoneRequest({item, onAccept, onDecline}) {

  const handleAcceptClick = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: "Вы уверены, что хотите РАЗРЕШИТЬ заявку?",
      icon: <CheckOutlined/>,
      visible: true,
      onOk() {
        onAccept()
      },
    });
  };

  const handleDeclineClick = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: "Вы уверены, что хотите ЗАПРЕТИТЬ заявку?",
      icon: <CloseCircleOutlined twoToneColor="#eb2f96" />,
      visible: true,
      onOk() {
        onDecline()
      },
    });
  };

  return (
    <>
      <div className={`zone-request`}>
        <div className="title">
          <Button
            type={"link"}
            style={{padding: 0}}
            onClick={() => { windowService.openRouteWindow(`cp/drivers/${item.driver?.id}`); }}
          >
            {item.driver?.full_name}
          </Button>
          <Divider type={"vertical"}/>
          <span style={{float: 'right', fontWeight: '300', lineHeight: 2.5}}>{item.request_type === "work" ? "По заказу" : "По личным делам"}</span>
        </div>
        <div className="message">
          <div>Дата: {moment(item.create_dt).format("DD.MM.YYYY HH:mm")}</div>
          <div>Выезд: {moment(item.planned_leave_dt).format("DD.MM.YYYY HH:mm")}</div>
          {
            item.planned_return_dt
              ? (<div>Возвращение: {moment(item.planned_return_dt).format("DD.MM.YYYY HH:mm")}</div>)
              : null
          }
          <div>Адрес: {item.address}</div>
        </div>
        {
          item.status
            ? (
              <div className="actions" style={{textAlign: 'right'}}>
                { item.status === "accepted" ? "РАЗРЕШЕНО" : "ЗАПРЕЩЕНО" }
              </div>
            ) : (
              <div className="actions" style={{textAlign: 'right'}}>
                <div className="date" style={{float: "left", paddingTop: 5}}>{item.display_event_dt}</div>
                <Space direction="horizontal" size="small">
                  <Button title={"Одобрить"} icon={<CheckCircleTwoTone twoToneColor="#82E0BB"/>} onClick={() => handleAcceptClick()} type="link"/>
                  <Button title={"Отклонить"} icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />} onClick={() => handleDeclineClick(item)} type="link"/>
                </Space>
              </div>
            )
        }
      </div>
    </>
  )
}
import React, {useEffect, useState} from "react";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {Button, DatePicker, message, PageHeader, Space, Table} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import UpdateButton from "../../../buttons/UpdateButton";

const { RangePicker } = DatePicker;

export default function RentPaymentsByDriverPage() {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [download, setDownload] = useState(false);
  let [transactionsFilter, setTransactionsFilter] = useState({type: ''});

  useEffect(() => {
    setLoading(true);
    let filter = {};
    if(transactionsFilter.period && transactionsFilter.period.length > 0) {
      filter.from_dt = moment(transactionsFilter.period[0]).format('YYYY-MM-DD');
      filter.to_dt = moment(transactionsFilter.period[1]).format('YYYY-MM-DD');
    }
    if(download) {
      filter.download = true;
      setDownload(false);
    }
    apiClient.getReportRecords("rent-payments/drivers", filter)
      .then(res => {
        if(res.records) {
          setRecords(res.records);
        } else if(res.uid) {
          window.open(apiClient.actionUrl(`reports/download?uid=${res.uid}`), "_blank");
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [recordsStamp, transactionsFilter]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Аренда по водителю"
      />
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'left', marginBottom: 40}}>
          <Space direction={"horizontal"} wrap>
            <RangePicker format="DD.MM.YYYY" style={{width: 350}} onChange={(val) => setTransactionsFilter({...transactionsFilter, ...{period: val}})}/>
          </Space>
        </div>
        <div style={{width: '20%', float: 'right'}}>
          <Space style={{float: "right"}}>
            <Button icon={<DownloadOutlined/>} onClick={ () => {
              setDownload(true);
              setRecordsStamp(prevState => prevState + 1)
            }}>Скачать</Button>
            <UpdateButton onClick={ () => setRecordsStamp(prevState => prevState + 1) }/>
          </Space>
        </div>
      </div>

      <Table dataSource={records}
            scroll={{x: 1000}}
            sticky
             columns={[
               {
                 title: "",
                 align: "center",
                 width: 30,
               },
               {
                 title: 'Водитель',
                 dataIndex: 'display_name',
               },
               {
                 title: "Сумма",
                 dataIndex: "paid_sum",
                 width: 200,
                 render: (text, record, index) => {
                   return record.paid_sum ? record.paid_sum : "0";
                 },
                 sorter: (a, b) => {
                   return a.paid_sum - b.paid_sum;
                 },
               },
               {
                 title: "",
                 align: "center",
                 width: 20,
               }
             ]}
             size="small"
             style={{margin: '10px 0', marginTop: 40}}
             rowKey="id"
             loading={loading}
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
    </>
  );
}
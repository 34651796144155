import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Input, message, Modal, Row, Select, Space, Table} from "antd";
import {PrintFormType, UnitTypes} from "../../../common/AppEnums";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import '../../ModalStyle.css';

const rowStyle = {padding: '8px'};
const EmptyRecord = {
  id: 0,
  title: '',
  model_class: PrintFormType[0].id,
  unit_type: UnitTypes[0].id,
};

const { Option } = Select;

export default function PrintFormEditDialog({visible, template, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});
  let [tags, setTags] = useState([]);
  let [tagsLoading, setTagsLoading] = useState(false);
  let [regions, setRegions] = useState([]);
  let [loading, setLoading] = useState(false);

  const fileInput = useRef(null);

  useEffect(() => {
    if(template) {
      setRecord({
        ...EmptyRecord,
        ...template
      });
    } else {
      setRecord(EmptyRecord);
    }
  }, [template]);

  useEffect(() => {
    setLoading(true);
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) })
      .finally(() => { setLoading(false); });
  }, []);

  useEffect(() => {
    setTagsLoading(true);
    apiClient.getPrintTemplateTags({document: record.model_class})
      .then(res => {
        setTags(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setTagsLoading(false); })
  }, [template, record.model_class]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.warn("Укажите имя формы");
          return;
        }
        if(record.id < 1 && fileInput.current.files.length < 1) {
          message.warn("Необходимо добавить файл шаблона");
          return;
        }

        onOk({...record, file: fileInput.current.files[0]});

        setRecord(EmptyRecord);
        setTags([]);
      }}
      onCancel={() => {
        setRecord(EmptyRecord);
        setTags([]);
        onCancel();
      }}>

      <>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Имя документа</Col>
          <Col span={18}>
            <Select
              placeholder={"Имя документа"}
              style={{width: '100%'}}
              options={PrintFormType.map(e => { return {label: e.title, value: e.id} })}
              value={record.model_class}
              onChange={(newValue) => {
                setRecord({...record, model_class: newValue})
              }}
            />
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Имя формы</Col>
          <Col span={18}>
            <Input
              allowClear={true}
              placeholder="Наименование"
              value={record.title}
              onChange={(e) => { setRecord({...record, title: e.target.value}) }}/>
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Тип собственника</Col>
          <Col span={18}>
            <Select
              placeholder={"Тип собственника"}
              style={{width: '100%'}}
              options={UnitTypes.map(e => { return {label: e.title, value: e.id} })}
              value={record.unit_type}
              onChange={(newValue) => {
                setRecord({...record, unit_type: newValue})
              }}
            />
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Филиал</Col>
          <Col span={18}>
            <Select
              showSearch
              allowClear
              style={{width: '100%'}}
              value={loading ? null : record.work_region_id}
              onChange={(value) => {
                setRecord({...record, work_region_id: value})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Файл</Col>
          <Col span={18}>
            <input type={'file'} ref={fileInput}/>
          </Col>
        </Row>

        <h3>Тэги печатной формы</h3>

        <Table
          loading={tagsLoading}
          size={'small'}
          sticky
          pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [25, 50, 75]}}
          columns={[
            {
              title: "",
              align: "center",
              width: 30,
            },
            {
              title: 'Имя',
              dataIndex: 'name',
              render: (text, record, index) => {
                return (
                  <Space direction={"horizontal"}>
                    <Button type={"link"} icon={<CopyOutlined/>} onClick={() => {
                      navigator.clipboard.writeText(`{{ ${record.name} }}`)
                        .then(() => {
                          message.info("Значение скопировано в буфер обмена");
                        })
                        .catch(err => {
                          message.warning(err);
                        });
                    }}/>
                    {record.name}
                  </Space>
                )
              }
            },
            {
              title: 'Описание',
              dataIndex: 'description',
            },
            {
              title: "",
              align: "center",
              width: 20,
            }
          ]}
          dataSource={tags}
          rowKey="id"
        />
      </>

    </Modal>
  );
}
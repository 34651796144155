import React, {useEffect, useState} from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import {apiClient} from "../../services/ApiClient";
import {Button, Col, Form, Input, notification, Row} from "antd";
import {LockOutlined} from "@ant-design/icons";

export default function PrivateProtectedRoute({domain, children, ...rest}) {
  let [permitted, setPermitted] = useState(false);
  const [form] = Form.useForm();

  const onFinish = ({ password }) => {
    apiClient.addResourceAccess(domain, password)
      .then(res => {
        form.setFieldsValue({ password: '' });
        if(res.permitted) {
          setPermitted(res.permitted);
        } else {
          notification.error({ message: 'Пароль указан неверно' });
        }
      })
      .catch(err => {
        form.setFieldsValue({ password: '' });
        notification.error({ message: 'Пароль указан неверно' });
      });
  };

  const authForm = (
    <React.Fragment>
      <Row justify="space-around" align="middle">
        <Col md={4}> </Col>
        <Col md={16}>
          <div>
            <Form
              form={form}
              name="normal_login"
              className="auth-form"
              onFinish={onFinish}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Укажите пароль',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon"/>}
                  type="password"
                  placeholder="Пароль"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="auth-form-button">
                  Открыть доступ
                </Button>
              </Form.Item>

            </Form>
          </div>
        </Col>
        <Col md={4}> </Col>
      </Row>
    </React.Fragment>
  );

  useEffect(() => {
    apiClient.checkResourceAccess(domain)
      .then(res => {
        setPermitted(res.permitted);
      })
      .catch(err => {
        setPermitted(false);
      });
  }, [domain]);

  return (
    <Route
      {...rest}
      render={({location}) =>
        apiClient.isAuthenticated() ? (
          // permitted ? children : <div style={{margin: '10px auto', width: 300, fontSize: '2em'}}>Нет доступа :(</div>
          permitted ? children : authForm
        ) : (
          <Redirect to={{pathname: "/login", state: {from: location}}}/>
        )
      }
    />
  );
}
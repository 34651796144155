import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {VehicleType} from "../../../../components/common/AppEnums";
import {Button, message, PageHeader, Select, Space, Table} from "antd";

import './ServiceSummaryTypeStatusPage.css'
import ServiceSummaryTypeStatusDetailsDialog from "./ServiceSummaryTypeStatusDetailsDialog";
import ServiceSummaryTypeParkingDetailsDialog from "./ServiceSummaryTypeParkingDetailsDialog";
import {SettingOutlined} from "@ant-design/icons";
import ServiceMaintenanceStatusSettingsDialog from "./ServiceMaintenanceStatusSettingsDialog";
import ServiceSummaryTypeStatusSettingsDialog from "./ServiceSummaryTypeStatusSettingsDialog";

const { Option } = Select;

export default function ServiceSummaryTypeStatusPage() {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [idle, setIdle] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);
  let [cellDetails, setCellDetails] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(null);
  let [longParkingDetails, setLongParkingDetails] = useState(null);
  let [showSettings, setShowSettings] = useState(false);
  let [minIdlePeriod, setMinIdlePeriod] = useState("");
  let [maxIdlePeriod, setMaxIdlePeriod] = useState("");
  let [idlePeriodUpdate, setIdlePeriodUpdate] = useState(0);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceSummaryTypeStatus({
      regionId: selectedRegionId,
      groupId: selectedGroupId,
    })
      .then(res => {
        setRecords(res.records);
        setIdle(res.idle);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  }, [update, selectedRegionId, selectedGroupId, idlePeriodUpdate]);

  useEffect(() => {
    apiClient.getSystemProperty("parkmanager.typeSummary.*")
      .then(res => {
        if(res.values) {
          if(res.values["parkmanager.typeSummary.minIdlePeriod"]) {
            setMinIdlePeriod(res.values["parkmanager.typeSummary.minIdlePeriod"]);
          }
          if(res.values["parkmanager.typeSummary.maxIdlePeriod"]) {
            setMaxIdlePeriod(res.values["parkmanager.typeSummary.maxIdlePeriod"]);
          }
        }
      })
      .catch(err => { message.error(err); });
  }, [idlePeriodUpdate]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const showDetails = (vehicleType, colName) => {
    setLoading(true);
    apiClient.getServiceSummaryTypeStatusDetails(vehicleType, colName, { regionId: selectedRegionId, groupId: selectedGroupId, })
      .then(res => {
        setCellDetails(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  };

  const showLongInStatusDetails = (status, minDays, maxDays) => {
    setLoading(true);
    apiClient.getServiceSummaryTypeLongInStatusDetails(status, minDays, maxDays, {
      regionId: selectedRegionId,
      groupId: selectedGroupId,
    })
      .then(res => {
        setLongParkingDetails(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Статусы по типу ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Типов ТС: ${records.length}`}</span>
              <Button type={"link"} icon={<SettingOutlined/>}
                      onClick={() => {
                        setShowSettings(true);
                      }}>
                Настройки
              </Button>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="Филиал"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          {
            title: 'Тип ТС',
            dataIndex: 'vehicle_type',
            align: "center",
            render: (text, record, index) => {
              return record.vehicle_type
                ? (
                  VehicleType.find(el => el.id === record.vehicle_type)?.title
              ) : null
            }
          },
          {
            title: 'Всего',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return (
                record.total > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'total') }}>{record.total}</Button>
                  : <div style={{textAlign: 'center'}}>{record.total}</div>
              )
            },
            sorter: (a, b) => {
              return a.total - b.total;
            },
          },
          {
            title: 'На линии',
            dataIndex: 'working',
            align: "center",
            render: (text, record, index) => {
              return (
                record.working > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'working') }}>{record.working}</Button>
                  : <div style={{textAlign: 'center'}}>{record.working}</div>
              )
            },
            sorter: (a, b) => {
              return a.working - b.working;
            },
          },
          {
            title: 'В парке',
            dataIndex: 'parked',
            align: "center",
            render: (text, record, index) => {
              return (
                record.parked > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'parked') }}>{record.parked}</Button>
                  : <div style={{textAlign: 'center'}}>{record.parked}</div>
              )
            },
            sorter: (a, b) => {
              return a.parked - b.parked;
            },
          },
          {
            title: 'ДТП',
            dataIndex: 'accident',
            align: "center",
            render: (text, record, index) => {
              return (
                record.accident > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'accident') }}>{record.accident}</Button>
                  : <div style={{textAlign: 'center'}}>{record.accident}</div>
              )
            },
            sorter: (a, b) => {
              return a.accident - b.accident;
            },
          },
          {
            title: 'Ожидание поставки запчастей',
            dataIndex: 'parts_waiting',
            align: "center",
            render: (text, record, index) => {
              return (
                record.parts_waiting > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'parts_waiting') }}>{record.parts_waiting}</Button>
                  : <div style={{textAlign: 'center'}}>{record.parts_waiting}</div>
              )
            },
            sorter: (a, b) => {
              return a.parts_waiting - b.parts_waiting;
            },
          },
          {
            title: 'Слесарный ремонт парк',
            dataIndex: 'locksmith_repair_park',
            align: "center",
            render: (text, record, index) => {
              return (
                record.locksmith_repair_park > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'locksmith_repair_park') }}>{record.locksmith_repair_park}</Button>
                  : <div style={{textAlign: 'center'}}>{record.locksmith_repair_park}</div>
              )
            },
            sorter: (a, b) => {
              return a.locksmith_repair_park - b.locksmith_repair_park;
            },
          },
          {
            title: 'Слесарный ремонт подрядчики',
            dataIndex: 'locksmith_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return (
                record.locksmith_repair_contractor > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'locksmith_repair_contractor') }}>{record.locksmith_repair_contractor}</Button>
                  : <div style={{textAlign: 'center'}}>{record.locksmith_repair_contractor}</div>
              )
            },
            sorter: (a, b) => {
              return a.locksmith_repair_contractor - b.locksmith_repair_contractor;
            },
          },
          {
            title: 'Требуется кузовной ремонт',
            dataIndex: 'need_body_repair',
            align: "center",
            render: (text, record, index) => {
              return (
                record.need_body_repair > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'need_body_repair') }}>{record.need_body_repair}</Button>
                  : <div style={{textAlign: 'center'}}>{record.need_body_repair}</div>
              )
            },
            sorter: (a, b) => {
              return a.need_body_repair - b.need_body_repair;
            },
          },
          {
            title: 'Кузовной ремонт парк',
            dataIndex: 'body_repair_park',
            align: "center",
            render: (text, record, index) => {
              return (
                record.body_repair_park > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'body_repair_park') }}>{record.body_repair_park}</Button>
                  : <div style={{textAlign: 'center'}}>{record.body_repair_park}</div>
              )
            },
            sorter: (a, b) => {
              return a.body_repair_park - b.body_repair_park;
            },
          },
          {
            title: 'Кузовной ремонт подрядчики',
            dataIndex: 'body_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return (
                record.body_repair_contractor > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'body_repair_contractor') }}>{record.body_repair_contractor}</Button>
                  : <div style={{textAlign: 'center'}}>{record.body_repair_contractor}</div>
              )
            },
            sorter: (a, b) => {
              return a.body_repair_contractor - b.body_repair_contractor;
            },
          },
          {
            title: 'Гарантийный ремонт',
            dataIndex: 'warranty_repair',
            align: "center",
            render: (text, record, index) => {
              return (
                record.warranty_repair > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'warranty_repair') }}>{record.warranty_repair}</Button>
                  : <div style={{textAlign: 'center'}}>{record.warranty_repair}</div>
              )
            },
            sorter: (a, b) => {
              return a.warranty_repair - b.warranty_repair;
            },
          },
          {
            title: 'Отдел подготовки',
            dataIndex: 'preparing',
            align: "center",
            render: (text, record, index) => {
              return (
                record.preparing > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'preparing') }}>{record.preparing}</Button>
                  : <div style={{textAlign: 'center'}}>{record.preparing}</div>
              )
            },
            sorter: (a, b) => {
              return a.preparing - b.preparing;
            },
          },
          {
            title: 'Подготовка к выдаче',
            dataIndex: 'licenseless',
            align: "center",
            render: (text, record, index) => {
              return (
                record.licenseless > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'licenseless') }}>{record.licenseless}</Button>
                  : <div style={{textAlign: 'center'}}>{record.licenseless}</div>
              )
            },
            sorter: (a, b) => {
              return a.licenseless - b.licenseless;
            },
          },
          {
            title: 'Возврат собственнику',
            dataIndex: 'owner_return',
            align: "center",
            render: (text, record, index) => {
              return (
                record.owner_return > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'owner_return') }}>{record.owner_return}</Button>
                  : <div style={{textAlign: 'center'}}>{record.owner_return}</div>
              )
            },
            sorter: (a, b) => {
              return a.owner_return - b.owner_return;
            },
          },
          {
            title: 'Перегон',
            dataIndex: 'transfer',
            align: "center",
            render: (text, record, index) => {
              return (
                record.transfer > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'transfer') }}>{record.transfer}</Button>
                  : <div style={{textAlign: 'center'}}>{record.transfer}</div>
              )
            },
            sorter: (a, b) => {
              return a.transfer - b.transfer;
            },
          },
          {
            title: 'Иное',
            dataIndex: 'other',
            align: "center",
            render: (text, record, index) => {
              return (
                record.other > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.vehicle_type, 'other') }}>{record.other}</Button>
                  : <div style={{textAlign: 'center'}}>{record.other}</div>
              )
            },
            sorter: (a, b) => {
              return a.other - b.other;
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="vehicle_type"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let totalTotal = 0;
          let totalWorking = 0;
          let totalParked = 0;
          let totalAccident = 0;
          let totalPartsWaiting = 0;
          let totalLocksmithRepairPark = 0;
          let totalLocksmithRepairContractor = 0;
          let totalNeedBodyRepair = 0;
          let totalBodyRepairPark = 0;
          let totalBodyRepairContractor = 0;
          let totalWarrantyRepair = 0;
          let totalLicenseless = 0;
          let totalOwnerReturn = 0;
          let totalPreparing = 0;
          let totalRransfer= 0;
          let totalOther = 0;
          pageData.forEach(({ brand_model, total, working, parked, accident, parts_waiting, locksmith_repair_park,
                              locksmith_repair_contractor, need_body_repair, body_repair_park, body_repair_contractor,
                              warranty_repair, preparing, licenseless, owner_return, transfer, other }) => {
            totalTotal += total;
            totalWorking += working;
            totalParked += parked;
            totalAccident += accident;
            totalPartsWaiting += parts_waiting;
            totalLocksmithRepairPark += locksmith_repair_park;
            totalLocksmithRepairContractor += locksmith_repair_contractor;
            totalNeedBodyRepair += need_body_repair;
            totalBodyRepairPark += body_repair_park;
            totalBodyRepairContractor += body_repair_contractor;
            totalWarrantyRepair += warranty_repair;
            totalLicenseless += licenseless;
            totalOwnerReturn += owner_return;
            totalPreparing += preparing;
            totalRransfer += transfer;
            totalOther += other;
          });
          return loading ? null : (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}><div style={{textAlign: 'center'}}>{totalTotal}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><div style={{textAlign: 'center'}}>{totalWorking}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("parked", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["parked"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalParked}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("parked", maxIdlePeriod, 99999) }}>
                        {idle["parked"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("accident", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["accident"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalAccident}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("accident", maxIdlePeriod, 99999) }}>
                        {idle["accident"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("parts_waiting", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["parts_waiting"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalPartsWaiting}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("parts_waiting", maxIdlePeriod, 99999) }}>
                        {idle["parts_waiting"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("locksmith_repair_park", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["locksmith_repair_park"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalLocksmithRepairPark}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("locksmith_repair_park", maxIdlePeriod, 99999) }}>
                        {idle["locksmith_repair_park"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("locksmith_repair_contractor", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["locksmith_repair_contractor"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalLocksmithRepairContractor}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("locksmith_repair_contractor", maxIdlePeriod, 99999) }}>
                        {idle["locksmith_repair_contractor"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("need_body_repair", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["need_body_repair"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalNeedBodyRepair}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("need_body_repair", maxIdlePeriod, 99999) }}>
                        {idle["need_body_repair"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("body_repair_park", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["body_repair_park"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalBodyRepairPark}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("body_repair_park", maxIdlePeriod, 99999) }}>
                        {idle["body_repair_park"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("body_repair_contractor", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["body_repair_contractor"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalBodyRepairContractor}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("body_repair_contractor", maxIdlePeriod, 99999) }}>
                        {idle["body_repair_contractor"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("warranty_repair", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["warranty_repair"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalWarrantyRepair}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("warranty_repair", maxIdlePeriod, 99999) }}>
                        {idle["warranty_repair"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("preparing", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["preparing"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalPreparing}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("preparing", maxIdlePeriod, 99999) }}>
                        {idle["preparing"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("licenseless", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["licenseless"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalLicenseless}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("licenseless", maxIdlePeriod, 99999) }}>
                        {idle["licenseless"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("owner_return", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["owner_return"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalOwnerReturn}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("owner_return", maxIdlePeriod, 99999) }}>
                        {idle["owner_return"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("transfer", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["transfer"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalRransfer}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("transfer", maxIdlePeriod, 99999) }}>
                        {idle["transfer"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <div style={{textAlign: 'center'}}>
                    <span style={{color: '#ffc876'}}>
                      <Button
                        type={"link"}
                        style={{color: '#ffc876', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("other", minIdlePeriod, maxIdlePeriod) }}>
                        {idle["other"]?.min_idle}
                      </Button>
                    </span>
                    <span>{totalOther}</span>
                    <span style={{color: 'darkred'}}>
                      <Button
                        type={"link"}
                        style={{color: 'darkred', textAlign: 'center', display: 'inline-block', padding: '4px 7px'}}
                        onClick={() => { showLongInStatusDetails("other", maxIdlePeriod, 99999) }}>
                        {idle["other"]?.max_idle}
                      </Button>
                    </span>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        cellDetails && (
          <ServiceSummaryTypeStatusDetailsDialog
            data={cellDetails}
            onClose={() => { setCellDetails(null); }}
          />
        )
      }
      {
        longParkingDetails && (
          <ServiceSummaryTypeParkingDetailsDialog
            data={longParkingDetails}
            onClose={() => { setLongParkingDetails(null); }}
          />
        )
      }
      {
        showSettings && (
          <ServiceSummaryTypeStatusSettingsDialog
            onCancel={() => {
              setShowSettings(false);
            }}
            onOK={() => {
              setIdlePeriodUpdate(prevState => prevState + 1);
              setShowSettings(false);
            }}
          />
        )
      }
    </>
  )
}
import React, {useState} from "react";
import MenuButton from "../../buttons/MenuButton";
import ApplicationsTab from "./ApplicationsTab";
import DtpTab from "./DptTab";
import CanbanPage from "./CanbanPage";

export default function DptPage() {
  let [state, setState] = useState({activeTabKey: "1", update: 0});

  return (
    <>
      <div className="scrolling-wrapper-flexbox">
        <MenuButton className="card" value = "Заявки на ДТП" statusBtn={state.activeTabKey === "1"} onClick={() => {setState({...state, activeTabKey: "1", update: state.update + 1});}}/>
        <MenuButton className="card" value = "ДТП" statusBtn={state.activeTabKey === "2"} onClick={() => {setState({...state, activeTabKey: "2", update: state.update + 1});}}/>
        <MenuButton className="card" value = "Канбан" statusBtn={state.activeTabKey === "3"} onClick={() => {setState({...state, activeTabKey: "3", update: state.update + 1});}}/>
      </div>
      {state.activeTabKey === "1" && (
        <ApplicationsTab onOk={() => {setState({...state, activeTabKey: "2", update: state.update + 1});}}/>
      )}
      {state.activeTabKey === "2" && (
        <DtpTab/>
      )}
      {state.activeTabKey === "3" && (
        <CanbanPage/>
      )}
    </>
  );
}
import React, {useEffect, useState} from "react";
import moment from "moment";
import {
  message,
  Space,
  Table,
  Radio,
  PageHeader,
  Input,
  DatePicker,
  Button,
  Popover,
  Tooltip,
  Select,
} from "antd";
import {
  ExclamationCircleFilled,
  EyeOutlined,
  EditFilled,
  QuestionCircleOutlined,
  CheckCircleFilled,
  PictureOutlined,
  ExportOutlined,
  InfoCircleFilled,
  CheckCircleTwoTone,
  MinusCircleTwoTone, ClearOutlined, EyeInvisibleOutlined
} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import FineDetails from "./components/FineDetails";
import FinePhotos from "./components/FinePhotos";
import ExportConfirm from "./components/ExportConfirm";
import {windowService} from "../../../services/WindowService";
import SearchSelect from "../../common/SearchSelect";
import LinkButton from "../../buttons/LinkButton";
import PayerSelectionDialog from "./components/PayerSelectionDialog";

const {Search} = Input;
const { Option } = Select;

export default function FinesPage() {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [paymentStatus, setPaymentStatus] = useState('all');
  let [assignStatus, setAssignStatus] = useState('all');
  let [violationDateAssigned, setViolationDateAssigned] = useState('all');
  let [payedStatus, setPayedStatus] = useState('all');
  let [exportStatus, setExportStatus] = useState('all');
  let [autoAssigned, setAutoAssigned] = useState('0');
  let [visibilityStatus, setVisibilityStatus] = useState('visible');
  let [executionStatus, setExecutionStatus] = useState('all');
  let [searchString, setSearchString] = useState('');
  let [search, setSearch] = useState('');
  let [vehicle, setVehicle] = useState('');
  let [uinSearchString, setUinSearchString] = useState('');
  let [uinSearch, setUinSearch] = useState('');
  let [decisionDate, setDecisionDate] = useState(null);
  let [loadDate, setLoadDate] = useState(null);
  let [violationDate, setViolationDate] = useState(null);
  let [selectedFine, setSelectedFine] = useState(null);
  let [photosFine, setPhotosFine] = useState(null);
  let [filterByDriver, setFilterByDriver] = useState(null);
  let [filterByOwner, setFilterByOwner] = useState(null);
  let [filterByRegion, setFilterByRegion] = useState(null);
  let [selectedRows, setSelectedRows] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [owners, setOwners] = useState([]);
  let [regions, setRegions] = useState([]);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [25, 50, 75, 100, 200]
  });
  let [sorter, setSorter] = useState({});
  let [exportConfirmVisible, setExportConfirmVisible] = useState(false);
  let [exportSelectedVisible, setExportSelectedVisible] = useState(false);

  const exportSelectedRows = () => {
    setExportSelectedVisible(true);
  };

  const openFineBankFile = () => {
    if(selectedRows.some(el => el.is_bank_exported)) {
      setExportConfirmVisible(true);
    } else {
      exportSelectedRows();
    }
  };

  const updateSelectedVisibility = (visibility) => {
    apiClient.updateFinesVisibility(selectedRows.map(el => el.id), visibility)
      .then(res => {
        setSelectedRows([]);
        setRecordsStamp(prevState => prevState + 1);
      })
      .catch(err => message.error(err));
  };

  const openFinePhotos = record => {
    setPhotosFine(record);
  };

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      align: "center",
      dataIndex: "id",
      width: 100,
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EditFilled/>} onClick={() => {setSelectedFine(record);}}/>
            {
              record.has_photo
                ? <Tooltip title="Посмотреть фото"><Button type="link" icon={<PictureOutlined />}  onClick={() => {openFinePhotos(record);}}/></Tooltip>
                : <Tooltip title="Фотоматериалы отсутствуют"><Button type="link" icon={<PictureOutlined style={{ opacity: '0.3'}}/>}  onClick={() => { }}/></Tooltip>
            }
          </Space>
        );
      }
    },
    {
      title: 'Оплачен',
      width: 80,
      align: "center",
      dataIndex: 'pay_status',
      render: (text, record, index) => {
        if(record.pay_status === 1) {
          return <CheckCircleFilled style={{color: '#58D3A5', fontSize: '20px'}}/>;
        }
        if(record.pay_status === 4) {
          return <ExclamationCircleFilled style={{color: '#D35858', fontSize: '20px'}} />;
        }
        return <QuestionCircleOutlined />;
      },
    },
    {
      title: 'Выгружен',
      width: 80,
      align: "center",
      dataIndex: 'is_bank_exported',
      render: (text, record, index) => {
        if(record.is_bank_exported) {
          return <CheckCircleFilled style={{color: '#58D3A5', fontSize: '20px'}}/>;
        }
        return <ExclamationCircleFilled style={{color: '#D35858', fontSize: '20px'}} />;
      },
    },
    {
      title: 'Сумма',
      width: 120,
      dataIndex: 'amount',
      align: "center",
      sorter: (a, b) => {
        let aVal = a.amount;
        if(a.is_discounted && a.discount_amount) {
          const validTo = moment(a.discount_dt);
          if (a.is_payed || (validTo && moment().isBefore(validTo))) {
            aVal = a.discount_amount;
          }
        }

        let bVal = a.amount;
        if(b.is_discounted && b.discount_amount) {
          const validTo = moment(b.discount_dt);
          if (b.is_payed || (validTo && moment().isBefore(validTo))) {
            bVal = b.discount_amount;
          }
        }

        return aVal - bVal;
      },
      render: (text, record, index) => {
        if(record.is_discounted && record.discount_amount) {
          const validTo = moment(record.discount_dt);
          if(record.is_payed || (validTo && moment().isBefore(validTo))) {
            return (
              <div style={{display: 'flex'}}>
                <div style={{display: 'inline-block', fontSize: '0.9em'}}>
                  <span style={{textDecoration: 'line-through'}}>{record.amount}р</span>
                  <br/>
                  <span style={{color: '#5cb85c!important'}}>{record.amount - record.discount_amount}р</span>
                </div>
              </div>
            );
          }
        }
        return `${record.amount}р`;
      },
    },
    {
      title: '',
      dataIndex: 'icon',
      width: 50,
      render: (text, record, index) => {
        if(record.discount_amount > 0) {
          const validTo = moment(record.discount_dt);
          if(validTo && moment().isBefore(validTo)) {
            return (
              <div style={{display: 'flex'}}>
                <span style={{margin: 'auto 5px', color: '#0275d8!important'}}>
                  <Tooltip title={<div style={{textAlign: 'center'}}>{`Скидка действительна до ${validTo.format('DD.MM.YYYY')}`}</div>}>
                  <InfoCircleFilled style={{ color: '#58D3A5', fontSize: '20px' }} />
                  </Tooltip>
                </span>
              </div>
            );
          }
        }
        return null;
      },
    },
    {
      title: 'Постановление (УИН)',
      dataIndex: 'uin',
    },
    {
      title: 'Водитель',
      dataIndex: 'id',
      align: "center",
      width: 170,
      render: (text, record, index) => {
        return record.driver
          ? (
            <Button
              type={"link"}
              onClick={() => {
                windowService.openRouteWindow(`cp/drivers/${record.driver.id}`);
              }}
            >
              {
                <>
                  <div>
                    {record.driver.last_name} {record.driver.first_name?.substr(0, 1)?.toUpperCase()}.{record.driver.middle_name?.substr(0, 1)?.toUpperCase()}
                  </div>
                  <div>{record.driver.phone}</div>
                </>
              }
            </Button>
          ) : "";
        },
    },
    {
      title: 'ТС',
      align: "center",
      dataIndex: 'car_number',
      render: (text, record, index) => {
        const vehicle = vehicles.find(el => el.reg_number === record.car_number);
        return vehicle
          ? (
            <LinkButton
              onClick={() => {
                windowService.openRouteWindow(`cp/park/vehicles/${vehicle.id}`);
              }}
              label={record.car_number}
            />
          ) : record.car_number;
      },
    },
    {
      title: 'Номер СТС',
      dataIndex: 'car_certificate',
    },
    {
      title: 'Дата нарушения',
      align: "center",
      dataIndex: 'violation_dt',
      render: (text, record, index) => {
        return record.violation_dt === '0001-01-01T00:00:00'
          ? "нет данных"
          : `${moment(record.violation_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.violation_dt).valueOf() - moment(b.violation_dt).valueOf();
      }
    },
    {
      title: 'Дата постановления',
      align: "center",
      dataIndex: 'decision_dt',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.decision_dt).format('DD.MM.YYYY')}`;
      },
      sorter: (a, b) => {
        return moment(a.decision_dt).valueOf() - moment(b.decision_dt).valueOf();
      }
    },
    {
      title: 'Дата загрузки',
      align: "center",
      dataIndex: 'create_dt',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY')}`;
      },
      sorter: (a, b) => {
        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
      }
    },
    {
      title: 'Вид нарушения',
      align: "center",
      dataIndex: 'article',
      render: (text, record, index) => {
        let article = "";
        if(record.article_description) {
          article = `${record.article_description}`;
        } else if(record.article) {
          article = `${record.article}`;
        }
        return <Popover content={<div style={{width: 250}}>{article}</div>} title="Описание">
          <span style={{textDecoration: 'underline', textDecorationStyle: 'dashed', cursor: 'default'}}>{record.article}</span>
        </Popover>
      },
    },
    {
      title: 'Списан',
      align: "center",
      dataIndex: 'is_payed',
      render: (text, record, index) => {
        return record.is_payed
          ? <CheckCircleTwoTone twoToneColor="#52c41a" />
          : <MinusCircleTwoTone twoToneColor="#c43500"/>;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    let filters = {
      paymentStatus,
      assignStatus,
      vehicle,
      search,
      uinSearch,
      filterByDriver,
      filterByOwner,
      filterByRegion,
      violationDateAssigned,
      autoAssigned,
      payedStatus,
      exportStatus,
      visibilityStatus,
      executionStatus,
      loaded: loadDate,
      violation_dt: violationDate,
      decision_dt: decisionDate,
      ...{
        page: pagination.current,
        pageSize: pagination.pageSize
      },
      ...{
        sortField: sorter.field,
        sortOrder: sorter.order
      }
    };
    setLoading(true);
    apiClient.getFines(filters)
      .then(res => {
        console.log({res});
        setRecords(res.records);
        setPagination(prevState => {return {...prevState, total: res.total}});

        if(selectedFine) {
          setSelectedFine(res.records.find(el => el.id === selectedFine.id))
        }
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      })
  }, [
    paymentStatus,
    assignStatus,
    vehicle,
    search,
    uinSearch,
    filterByDriver,
    filterByOwner,
    filterByRegion,
    recordsStamp,
    violationDateAssigned,
    autoAssigned,
    payedStatus,
    visibilityStatus,
    executionStatus,
    decisionDate,
    loadDate,
    violationDate,
    exportStatus
  ]);

  useEffect(() => {
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
    apiClient.searchDirectoryRecord('taxiDriver', '')
      .then(res => {
        setDrivers(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getVehicleOwners({"view": "minimal"})
      .then(res => {
        setOwners(res.records);
      })
      .catch(err => { message.error(err) });
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Штрафы"
        extra={(
          <>
            <Button
              icon={<ClearOutlined/>}
              onClick={ () => {
                setPaymentStatus("all");
                setAssignStatus("all");
                setViolationDateAssigned("all");
                setPayedStatus("all");
                setExportStatus("all");
                setExecutionStatus("all");
                setVisibilityStatus("visible");
                setAutoAssigned("0");
                setSearchString("");
                setSearch("");
                setUinSearchString("");
                setUinSearch("");
                setLoadDate(null);
                setViolationDate(null);
                setDecisionDate(null);
                setFilterByDriver(null);
              }}>Очистить фильтры</Button>
            <Button icon={<ExportOutlined/>} disabled={selectedRows.length === 0} onClick={ () => openFineBankFile() }>БанкКлиент</Button>
            <Button
              icon={visibilityStatus === "hidden" ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
              disabled={selectedRows.length === 0}
              onClick={ () => updateSelectedVisibility(visibilityStatus === "hidden") }>
              {visibilityStatus === "hidden" ? "Показать" : "Скрыть"}
            </Button>
          </>
        )}
      />
      <div style={{marginBottom: 16}}>
        <Space direction="horizontal" wrap>
          <SearchSelect
            placeholder="Гос.номер"
            style={{width: 300}}
            options={vehicles.map(el => ({id: el.id, title: `${el.reg_number}`}))}
            onSelect={(el) => {
              setVehicle(el?.id);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
          />
          <Search
            enterButton
            allowClear
            placeholder="СТС"
            value={searchString}
            onChange={(e) => { setSearchString(e.target.value) }}
            onSearch={(value) => {
              setSearch(value);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
            style={{width: 300}}
          />
          <Search
            enterButton
            allowClear
            placeholder="УИН"
            value={uinSearchString}
            onChange={(e) => { setUinSearchString(e.target.value) }}
            onSearch={(value) => {
              setUinSearch(value);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
            style={{width: 300}}
          />
          <Radio.Group value={paymentStatus} onChange={(e) => {
            setPaymentStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="unpaid">Не оплачен</Radio.Button>
            <Radio.Button value="paid">Оплачен</Radio.Button>
          </Radio.Group>
          <Radio.Group value={assignStatus} onChange={(e) => {
            setAssignStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="unassigned">Нераспределенные</Radio.Button>
          </Radio.Group>
          <Radio.Group value={payedStatus} onChange={(e) => {
            setPayedStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="payed">Списанные</Radio.Button>
            <Radio.Button value="unpayed">Не списанные</Radio.Button>
          </Radio.Group>
          <Radio.Group value={exportStatus} onChange={(e) => {
            setExportStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="exported">Выгруженные</Radio.Button>
            <Radio.Button value="unexported">Не выгруженные</Radio.Button>
          </Radio.Group>
          <Radio.Group value={violationDateAssigned} onChange={(e) => {
            setViolationDateAssigned(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="unassigned">Без даты нарушения</Radio.Button>
          </Radio.Group>
          <Radio.Group value={autoAssigned} onChange={(e) => {
            setAutoAssigned(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="0">Все</Radio.Button>
            <Radio.Button value="1">Автоматически</Radio.Button>
            <Radio.Button value="2">Вручную</Radio.Button>
          </Radio.Group>
          <Radio.Group value={visibilityStatus} onChange={(e) => {
            setVisibilityStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="visible">Видимые</Radio.Button>
            <Radio.Button value="hidden">Скрытые</Radio.Button>
          </Radio.Group>
          <Radio.Group value={executionStatus} onChange={(e) => {
            setExecutionStatus(e.target.value);
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>
            <Radio.Button value="all">Все</Radio.Button>
            <Radio.Button value="paused">Приостановленные</Radio.Button>
          </Radio.Group>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            placeholder="Дата нарушения"
            value={violationDate ? moment(violationDate, "YYYY-MM-DD") : null}
            onChange={(val) => { setViolationDate(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: 220}}/>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            placeholder="Дата постановления"
            value={decisionDate ? moment(decisionDate, "YYYY-MM-DD") : null}
            onChange={(val) => { setDecisionDate(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: 220}}/>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            placeholder="Дата загрузки"
            value={loadDate ? moment(loadDate, "YYYY-MM-DD") : null}
            onChange={(val) => { setLoadDate(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: 220}}/>
          <Select
            showSearch
            allowClear
            style={{ width: 350 }}
            placeholder="Водитель"
            onChange={(value) => {
              setFilterByDriver(value);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {drivers.map(s => <Option key={s.id} value={s.id}>{`${s.last_name} ${s.first_name} ${s.middle_name} (${s.phone})`}</Option>)}
          </Select>
          <Select
            allowClear
            showSearch
            placeholder="Собственник"
            style={{width: 300}}
            onChange={(data) => {
              setFilterByOwner(data);
              setPagination(prevState => {
                return {
                  ...prevState,
                  current: 1,
                }
              });
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              owners.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Филиал"
            style={{width: 300}}
            onChange={(data) => { setFilterByRegion(data); }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {
              regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
            }
          </Select>
        </Space>
      </div>
      <Table
        loading={loading}
        columns={cols}
        size="small"
        scroll={{x: 1700}}
        sticky
        dataSource={records}
        pagination={pagination}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(el => el.id),
          getCheckboxProps: (record) => ({
            disabled: record.pay_status === 1,
          }),
          renderCell: (checked, record, index, originNode) => {
            if(record.pay_status === 1) {
              return (
                <Popover content={"Штраф оплачен"} trigger="hover">
                  {originNode}
                </Popover>
              );
            }
            return originNode;
          }
        }}
        rowKey="id"
        onChange={(pagination, filters, sorter) => {
          setPagination(pagination);
          setSorter(sorter);
          setRecordsStamp(prevState => prevState + 1)
        }}
      />

      {
        selectedFine !== null ? (
          <FineDetails
            title={"Штраф"}
            visible={selectedFine !== null}
            item={selectedFine}
            onClose={() => {
              setSelectedFine(null)
            }}
            onUpdate={(update) => {
              apiClient.updateFine(selectedFine.id, update)
                .then(res => {
                  setRecordsStamp(prevState => prevState + 1);
                })
                .catch(err => message.error(err));
            }}
          />
        ) : null
      }

      {
        photosFine !== null ? (
          <FinePhotos visible={photosFine !== null} item={photosFine} onClose={() => { setPhotosFine(null) }} />
        ) : null
      }

      {
        exportConfirmVisible ? (
          <ExportConfirm
            visible={exportConfirmVisible}
            selectedRows={selectedRows}
            onCancel={() => setExportConfirmVisible(false)}
            onExport={() => {
              setExportConfirmVisible(false);
              exportSelectedRows();
            }}
            onRowRemove={(row) => {
              setSelectedRows(prevState => prevState.filter(el => el.id !== row.id))
            }}
          />
        ) : null
      }

      {
        exportSelectedVisible ? (
          <PayerSelectionDialog
            onOK={(payerId) => {
              if(!payerId) {
                message.error("Необходимо выбрать плательщика");
                return;
              }
              apiClient.getFinesExportLink(selectedRows.map(el => el.id), payerId)
                .then(res => {
                  window.open(res.link);
                  setSelectedRows([]);
                  setRecordsStamp(prevState => prevState + 1);
                  setExportSelectedVisible(false);
                })
                .catch(err => message.error(err));
            }}
            onCancel={() => {}}
          />
        ) : null
      }
    </>
  )
}
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {apiClient} from "../../../../services/ApiClient";
import {message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import VehicleOwnerEditDialog from "./VehicleOwnerEditDialog";

export default function OwnerDetailsLoader() {

  let { uid } = useParams();

  let [owner, setOwner] = useState(null);

  useEffect(() => {
    if(uid) {
      apiClient.getVehicleOwner(uid)
        .then(res => { setOwner(res.record) })
        .catch(err => { message.error(err) });
    }
  }, [uid]);

  return (
    owner
      ? (
        <VehicleOwnerEditDialog
          visible={owner !== null}
          park={owner}
          onOk={(p) => {
            apiClient.updateVehicleOwner(uid, p)
              .then(res => {
                window.close();
              })
              .catch(err => message.error(err));
          }}
          onCancel={() => {
            window.close();
          }}
          title={"Собственник ТС"}
        />
      )
      : (
        <div style={{textAlign: "center", maring: "100px auto"}}><LoadingOutlined/></div>
      )
  )
}
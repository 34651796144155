import React, {useEffect, useState} from "react";
import {Col, Modal, Row, DatePicker, message, Space, Button, Table, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function TeamCompetitionsAddDialog({visible, onOk, onClose}) {

  let [period, setPeriod] = useState([]);
  let [teams, setTeams] = useState([]);
  let [allTeams, setAllTeams] = useState([]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<DeleteOutlined/>} onClick={() => {setTeams(teams.filter(el => el.id !== record.id));}}/>
          </Space>
        );
      }
    },
    {
      title: 'Команда',
      dataIndex: 'title',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(visible) {
      apiClient.getCompetitionTeams()
        .then(res => {
          setAllTeams(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [visible]);

  function render() {
    return (
      <>
        <Row style={rowStyle}>
          <Col span={5}>Даты</Col>
          <Col span={18}>
            <RangePicker showTime
                         style={{ width: 400 }}
                         onChange={(dates, dateStrings) => { setPeriod(dateStrings); }}
            />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Добавить команду</Col>
          <Col span={12}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Выберите команду"
                onChange={(value) => {
                  if(value) {
                    let team = allTeams.filter(el => el.id === value)[0];
                    if(team) {
                      setTeams([...teams, team]);
                    }
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  allTeams.map(s => <Option key={s.id} value={s.id}>{`${s.title}`}</Option>)
                }
              </Select>
            </Space>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={teams}
                 rowKey="id"/>

        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Конкурс"}
      visible={visible}
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{ top: 20 }}
      onOk={() => {
        if(!period || period.length === 0) {
          message.warn("Укажите период");
          return;
        }
        if(!teams || teams.length === 0) {
          message.warn("Добавьте водителей");
          return;
        }
        onOk(period, teams);
        setPeriod([]);
        setTeams([]);
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        render()
      }
    </Modal>
  );

}
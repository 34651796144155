import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {apiClient} from "../../../../../services/ApiClient";
import {message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import DriverDetails from "./DriverDetails";

export default function DriverDetailsLoader() {

  let { uid } = useParams();

  let [driver, setDriver] = useState(null);

  useEffect(() => {
    if(uid) {
      apiClient.getDriverDetails(uid)
        .then(res => { setDriver(res.driver) })
        .catch(err => { message.error(err) });
    }
  }, [uid]);

  return (
    driver
      ? (
        <DriverDetails
          title="Карточка водителя"
          visible={driver !== null}
          item={driver}
          onClose={(updated) => {
            window.close();
          }}/>
      )
      : (
        <div style={{textAlign: "center", maring: "100px auto"}}><LoadingOutlined/></div>
      )
  )
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../services/ApiClient";
import {Button, message, Modal, Popover, Space, Table, Tooltip} from "antd";
import {
  BellFilled,
  BellOutlined,
  EyeFilled,
  InfoCircleFilled,
  LinkOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import LinkButton from "../../buttons/LinkButton";
import {windowService} from "../../../services/WindowService";
import AddNotificationDialog from "../common/AddNotificationDialog";
import moment from "moment";

export default function DriversAlertsDialog({records, onCancel, onOK}) {

  let [drivers, setDrivers] = useState([]);
  let [addNotificationAction, setAddNotificationAction] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if(records) {
      let items = [...records];
      items.sort((a, b) => {
        if (a.notification_expired_dt || a.notification_early_expired_dt) {
          return -1;
        }
        if (b.notification_expired_dt || b.notification_early_expired_dt) {
          return 1;
        }
        return 0;
      });
      setDrivers(items);
    } else {
      setDrivers([]);
    }
  }, [records]);

  return (
    <Modal
      destroyOnClose={true}
      width={"90%"}
      title={"Уведомления"}
      visible={true}
      onOk={() => {
        onOK();
      }}
      onCancel={onCancel}>
      <>
      <div style={{margin: "10px 0", marginTop: 40, marginRight: 0}}>
        <Table
          showSorterTooltip={false}
          dataSource={drivers}
          className="select-text"
          columns={[
            {
              title: "",
              dataIndex: "notification_dt",
              align: "center",
              width: 100,
              render: (text, record, index) => {
                let widget = null;
                if (record.notification_dt) {
                  if (!record.notification_expired_dt && !record.notification_early_expired_dt) {
                    // show remove icon
                    widget = (
                      <Button title={`Изменить уведомление`}
                              type="link"
                              icon={<BellFilled/>}
                              onClick={() => { setAddNotificationAction({"driver": record}); }}/>
                    );
                  } else {
                    // show notification
                    widget = (
                      <Button title={`Изменить уведомление`}
                              type="link"
                              icon={<BellFilled style={{color: '#891514'}}/>}
                              onClick={() => { setAddNotificationAction({"driver": record}); }}/>
                    );
                  }
                } else {
                  // add notification
                  widget = (<Button
                    title="Добавить уведомление"
                    onClick={() => {
                      setAddNotificationAction({"driver": record});
                    }}
                    style={{opacity: '0.5'}}
                    type="link"
                    icon={<BellOutlined style={{color: '#B9B9B9'}}/>}
                  />);
                }
                return (
                  <Space direction="horizontal">
                    <Button icon={<EyeFilled/>} onClick={() => {
                      windowService.openRouteWindow(`cp/drivers/${record.id}`);
                    }} type="link"/>
                    {widget}
                  </Space>
                );
              }
            },
            {
              title: "ФИО",
              width: 300,
              dataIndex: "fio",
              sorter: true,
              render: (text, record, index) => {
                if(record.work_condition_alert) {
                  return (
                    <>
                      <WarningTwoTone twoToneColor={'#eb2f96'} title="Проверьте условия работы"/>
                      {record.fio}
                    </>
                  )
                }
                return (
                  <Space direction="horizontal">
                    {record.fio}
                  </Space>
                );
              }
            },
            {
              title: "Баланс",
              width: 100,
              dataIndex: "balance",
              align: "center",
              sorter: true,
            },
            {
              title: "Телефон",
              align: "center",
              width: 150,
              dataIndex: "phone",
              sorter: true,
            },
            {
              title: "Авто",
              align: "center",
              dataIndex: "car_full_details",
              render: (text, record, index) => {
                return record.current_rent && (
                  <LinkButton
                    label={<><LinkOutlined style={{width: "15px"}}/>  {record.car_full_details}</>}
                    onClick={() => {
                      windowService.openRouteWindow(`cp/park/vehicles/${record.current_rent?.id}`);
                    }}
                  />
                );

              }
            },
            {
              title: "Диспетчерская",
              width: 60,
              align: "center",
              render: (text, record, index) => {
                return record.taxipark
                  ? (
                    <Popover content={record.taxipark.title}>
                      <InfoCircleFilled style={{ color: '#58D3A5', fontSize: '20px' }}/>
                    </Popover>
                  )
                  : ""
              }
            },
            {
              title: "Условия работы",
              width: 150,
              dataIndex: "work_condition",
              align: "center",
              render: (text, record, index) => {
                return record.work_condition
                  ? (
                    <Popover content={record.work_condition.title}>
                      <InfoCircleFilled style={{ color: '#58D3A5', fontSize: '20px' }} />
                    </Popover>
                  )
                  : ""
              }
            },
            {
              title: "Уведомление",
              align: "left",
              width: 250,
              dataIndex: "notification_comment",
            },
            {
              title: "Дата",
              align: "left",
              width: 250,
              dataIndex: "notification_dt",
              render: (text, record, index) => {
                return moment(record.notification_dt).format('DD.MM.YYYY HH:mm')
              }
            },
          ]}
          size="small"
          rowKey="id"
          loading={loading}
          scroll={{x: 1300}}
          pagination={{position: 'bottomRight', pageSizeOptions: [10, 25, 50], showSizeChanger: true}}
          rowClassName={(record, index) => {
            let className = `row-${index % 2 === 0 ? "even" : "odd"}`;
            if (record.activity_status === 1) {
              className += " driver-activity-active";
            } else if (record.activity_status === 2) {
              className += " driver-activity-blocked";
            }
            if (record.work_condition_alert) {
              className += " driver-workcondition-alert";
            }
            return className;
          }}
        />
      </div>
        {
          // AddNotificationDialog
          addNotificationAction !== null
            ? (
              <AddNotificationDialog
                title="Уведомление"
                visible={true}
                item={addNotificationAction?.driver}
                onOk={(dt, comment, earlyHours) => {
                  const driver = addNotificationAction.driver;
                  if(dt) {
                    setLoading(true);
                    apiClient.setDriverNotification(driver.id, dt, comment, earlyHours)
                      .then(data => {
                        driver.notification_dt = dt;
                        driver.notification_comment = comment;
                        driver.notification_early_time = earlyHours;
                        driver.notification_expired_dt = null;
                        driver.notification_early_expired_dt = null;
                        setDrivers([driver, ...drivers.filter(e => e.id !== driver.id)]);
                        message.success("Уведомление обновлено");
                      })
                      .catch(err => message.error(err))
                      .finally(() => { setLoading(false); })
                  }
                  if(comment) {
                    apiClient.addDriverComment(driver.id, comment, 'drivers_page_notification')
                      .then(data => { message.success("Комментарий добавлен"); })
                      .catch(err => { message.error("Не удалось добавить комментарий. " + err); });
                  }
                  setAddNotificationAction(null);
                }}
                onCancel={() => { setAddNotificationAction(null); }}
                onDelete={() => {
                  const driver = addNotificationAction.driver;
                  setLoading(true);
                  apiClient.removeDriverNotification(driver.id)
                    .then(() => {
                      driver.notification_dt = null;
                      driver.notification_expired_dt = null;
                      driver.notification_early_expired_dt = null;
                      driver.notification_early_time = null;
                      driver.notification_comment = null;
                      setDrivers([driver, ...drivers.filter(e => e.id !== driver.id)]);
                    })
                    .catch((err) => { message.error(err); })
                    .finally(() => { setLoading(false); });
                  setAddNotificationAction(null);
                }}
              />
            ) : null
        }
      </>
    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {Checkbox, Col, DatePicker, Input, message, Modal, Row} from "antd";
import moment from "moment";

const rowStyle = {padding: '8px'};

export default function DiagnosticCardEditDialog({diagnosticCard, onOk, onCancel}) {

  let [record, setRecord] = useState({});

  useEffect(() => {
    setRecord(diagnosticCard);
  }, [diagnosticCard]);
  return (
    <Modal
      destroyOnClose={true}
      title={"Диагностическая карта"}
      visible={true}
      onOk={() => {
        let checks = {
          // "birth_dt": "Заполните дату рождения",
        };
        let errs = Object.keys(checks).filter(key => !record[key]);
        if(errs.length > 0) {
          errs.forEach(key => message.error(checks[key]));
          return;
        }
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Номер</Col>
        <Col md={18}>
          <Input
            allowClear={true}
            value={record.reg_number}
            onChange={(e) => { setRecord({...record, reg_number: e.target.value}) }}/>        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Дата выдачи</Col>
        <Col md={18}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.issue_dt ? moment(record.issue_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: '100%'}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Срок действия</Col>
        <Col md={18}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.due_dt ? moment(record.due_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, due_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: '100%'}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Действующий</Col>
        <Col md={6}>
          <Checkbox
            checked={record.is_current}
            onChange={(e) => {
              setRecord({...record, is_current: e.target.checked });
            }}
          />
        </Col>
      </Row>

    </Modal>
  )
}
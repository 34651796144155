import React from "react";
import moment from "moment";

export default function TrackerIdleItem({item}) {
    const trackUpdated = moment(item.actual_track_update);
    const diff = moment.duration(moment().diff(trackUpdated));
    let diffMsg = '';
    if(diff.days() > 0) {
        diffMsg += `${diff.days()} дн `
    }
    diffMsg += `${diff.hours()} ч ${diff.minutes()} мин`;
    return (
        <div className="tracker" style={{}}>
            <div className="label">{item.label}</div>
            <div className="idle-date">{`${diffMsg}, с ${trackUpdated.format('DD.MM.YYYY HH:mm')}`}</div>
        </div>
    );
}
import React, { useEffect, useState} from "react";
import { Button, Space, Table, message} from "antd";
import "../../ModalStyle.css";
import { format } from 'date-fns';
import {DeleteOutlined, EditFilled} from '@ant-design/icons';
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {SchedulerPeriods, TemplateScheduleType} from "../../../common/AppEnums";
import AddPaymentExecutionDialog from "./AddPaymentExecutionDialog";

export default function PaymentExecutionsTab({onCreate, onDelete, dtp, active, dataLoading}) {

  let [loading, setLoading] = useState(false);
  let [paymentExecutions, setPaymentExecutions] = useState([]);
  let [visible, setVisible] = useState(false);
  let [driversList, setDriversList] = useState([]);
  let [editedRecord, setEditedRecord] = useState(null);

  useEffect(() => {
    if(dtp && dtp?.id > 0) {
      setLoading(true);
      console.log("loading payment executions for accident: ", dtp);
      apiClient.getDtpPaymentExecutions(dtp.id)
        .then(res => {
          setPaymentExecutions(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setPaymentExecutions([]);
    }
  }, [dtp]);

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    // {
    //   title: "",
    //   width: 50,
    //   align: "center",
    //   dataIndex: "id",
    //   render: function(text, record, index) {
    //     return (
    //       <Space direction={"horizontal"}>
    //         <Button type="link" icon={<EditFilled/>} onClick={() => {setEditedRecord(record);}}/>
    //       </Space>
    //     );
    //   }
    // },
    {
      title: 'Создано',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
    },
    {
      title: 'Баланс',
      align: "center",
      render: (text, record, index) => {
        return `${record.paid}/${record.limit}`
      },
    },
    {
      title: 'Порядок списания',
      dataIndex: 'schedule_type',
      align: "center",
      render: (text, record, index) => {
        return TemplateScheduleType.find(el => el.value === record.schedule_type)?.text
      },
    },
    {
      title: 'Периодичность списаний',
      dataIndex: 'regular_period',
      align: "center",
      render: (text, record, index) => {
        if(record.schedule_type === "regular") {
          const period = SchedulerPeriods.filter(el => el.value == record.regular_period);
          if(period && period.length > 0) {
            return period[0].text
          }
          return record.regular_period
        }
        return "";
      },
    },
    {
      title: 'Шаблон выполнения',
      dataIndex: 'pattern_run_days',
      align: "center",
      render: (text, record, index) => {
        if(record.schedule_type === "pattern") {
          return `${record.pattern_run_days} д через ${record.pattern_skip_days} д, начиная с ${record.delay_days} д`;
        }
        return `каждые ${record.regular_period} дн`
      },
    },
    {
      title: 'Время',
      dataIndex: 'execution_time',
      align: "center",
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '20%', float: 'right'}} >
          <Space style={{float: "right"}} direction="horizontal">
            <Button type="primary" loading={loading} onClick={() => { setVisible(true); }}>
              Добавить списания
            </Button>
          </Space>
        </div>
      </div>
      <Table
        style={{width: '100%'}}
        loading={loading}
        columns={cols}
        dataSource={paymentExecutions}
        rowKey="id"
        size='small'
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
      {
        visible ? (
          <AddPaymentExecutionDialog
            paymentLimit={dtp.driver_debt}
            onClose={() => setVisible(false)}
            onOk={(params) => {
              setLoading(true);
              apiClient.addDtpPaymentExecution(dtp.id, params)
                .then(res => {
                  setVisible(false);
                })
                .catch(err => message.error(err))
                .finally(() => setLoading(false));
            }}
          />
        ) : null
      }
    </>
  );
};
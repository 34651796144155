import React, {useState} from "react";
import {Col, Input, message, Modal, Row} from "antd";
import {apiClient} from "../../../../services/ApiClient";

const { TextArea } = Input;

const rowStyle = {padding: '8px'};

export default function AssignMaintenanceStatusDialog({onOk, onCancel, visible, selectedRecords}) {

  let [workplan, setWorkplan] = useState(null);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      title={"Назначить описание тех.работ"}
      visible={visible}
      onOk={() => {
        apiClient.assignServiceMaintenanceStatus(selectedRecords, {workplan: workplan})
          .then(res => {
            onOk();
          })
          .catch(err => {
            message.warn(err);
          });
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={4}>Описание</Col>
        <Col md={17}>
          <TextArea
            rows={4}
            value={workplan}
            style={{width: '100%'}}
            onChange={(e) => {
              setWorkplan(e.target.value);
            }}/>
        </Col>
      </Row>
    </Modal>
  );

}
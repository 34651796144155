import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {ReloadOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import {Button, DatePicker, message, Space, Table, Popover} from "antd";
import UpdateButton from "../../../../buttons/UpdateButton";
import PaymentExecutionReturnDialog from "./PaymentExecutionReturnDialog";
import UserProfileContext from "../../../../../services/UserProfileContext";
import "../../../ModalStyle.css"

const { RangePicker } = DatePicker;

function PaymentAmount({amount, refunds}) {

  let [popupOpened, setPopupOpened] = useState(false);

  const finalAmount = () => {
    let value = parseFloat(amount);
    refunds.forEach(el => {
      value = value - parseFloat(el.amount).toFixed(2);
    });
    return value;
  };

  return !refunds || refunds.length < 1
    ? <div style={{display: 'inline-block', textAlign: 'center', width: 80}}>{`${amount}р`}</div>
    : (
      <div style={{display: 'flex'}}>
        <div style={{display: 'inline-block', textAlign: 'center', fontSize: '0.9em', width: 80}}>
          <span style={{textDecoration: 'line-through'}}>{amount}р</span>
          <br/>
          <span style={{color: '#5cb85c!important'}}>
            <Popover
              content={
                <Table
                  className="size-for-middle-window"
                  dataSource={refunds}
                  columns={[
                    {
                      title: "",
                      align: "center",
                      width: 30,
                    },
                    {
                      title: 'Дата',
                      dataIndex: 'create_dt',
                      width: 180,
                      render: (text, record, index) => {
                        return `${moment(record.event_dt).format('DD.MM.YYYY HH:mm')}`;
                      },
                    },
                    {
                      title: "Сумма",
                      dataIndex: "amount",
                    },
                    {
                      title: "Сотрудник",
                      dataIndex: "created_by",
                    },
                    {
                      title: "Комментарий",
                      dataIndex: "comment",
                    },
                  ]}
                  size="small"
                  rowKey="id"
                  sticky
                />
              }
              title="Информация о возвратах"
              trigger="click"
              open={popupOpened}
              onOpenChange={(newOpen) => {
                setPopupOpened(newOpen);
              }}
            >
              <Button type="link">{finalAmount()}р</Button>
            </Popover>
          </span>
        </div>
      </div>
    );
}

export default function PaymentsManualTab({needUpdate, driver, onUpdated}) {
  const userProfile = useContext(UserProfileContext);

  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(50);
  let [total, setTotal] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [filter, setFilter] = useState({});
  let [update, setUpdate] = useState(0);
  let [paymentToReturn, setPaymentToReturn] = useState(null);

  const canRefundPaymentExecution = () => {
    return userProfile.permissions.some(el => el === "refund_payment_execution");
  };

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      dataIndex: "id",
      render: (text, record, index) => {
        let actions = [];
        if(["fine"].indexOf(record.payment_execution?.payment_template?.category) < 0 && canRefundPaymentExecution()) {
          const isOutdated = moment(record.create_dt).isBefore(moment().startOf("month"));
          actions.push(
            <Popover content={isOutdated ? "Вернуть платеж можно только за текущий месяц" : ""}>
              <Button
                disabled={false}
                key="play"
                style={{border: "1px solid #3F3D56"}}
                size="middle"
                icon={<ReloadOutlined/>} onClick={() => { setPaymentToReturn(record); }}>
                Вернуть платеж
              </Button>
            </Popover>
          );
        }
        return actions.length > 0 ? (
          <Space direction={"horizontal"}>
            {
              actions.map(el => el)
            }
          </Space>
        ) : "";
      }
    },
    {
      title: 'Дата',
      dataIndex: 'create_dt',
      render: (text, record, index) => {
        if(!record.create_dt) {
          return `${moment(record.event_dt).format('DD.MM.YYYY')}`;
        }
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: "Сумма",
      render: (text, record, index) => {
        return (
          <PaymentAmount amount={record.amount} refunds={record.refunds}/>
        );
      },
    },
    {
      title: "Категория",
      render: (text, record, index) => {
        return record.category?.label;
      },
    },
    {
      title: "Комментарий",
      render: (text, record, index) => {
        return record.payment_execution?.payment_template?.comment;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(driver) {
      let pagination = {
        page: page,
        pageSize: pageSize
      };
      let params = {};
      if(filter.period && filter.period.length > 0) {
        params.from = moment(filter.period[0]).format('YYYY-MM-DD');
        params.to = moment(filter.period[1]).format('YYYY-MM-DD');
      }

      setLoading(true);
      apiClient.getDriverPaymentManual(driver.id, params, pagination)
        .then(res => {
          setRecords(res.records);
          setTotal(res.total);
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); });
    } else {
      setRecords([]);
    }
  }, [driver, needUpdate, update, page, pageSize, filter]);

  return (
    <>
      <div style={{width: '90%', float: 'left' }}>
        <Space direction={"horizontal"} style={{marginBottom: 10}} wrap>
          <RangePicker
            format="DD.MM.YYYY" style={{width: 350}}
            onChange={(val) => {
              setPage(1);
              setFilter({...filter, ...{period: val}});
            }}/>
        </Space>
      </div>
      <div style={{width: '10%', float: 'right' }}>
        <Space style={{float: 'right'}}>
          <UpdateButton onClick={() => setUpdate(prevState => prevState + 1)}/>
        </Space>
      </div>
      <div style={{margin: '10px 0'}}>
        <Table
          dataSource={records}
          columns={columns}
          size="small"
          rowKey="uid"
          sticky
          loading={loading}
          pagination={{
            position: 'topRight',
            pageSizeOptions: [25, 50, 75],
            defaultPageSize: pageSize,
            current: page,
            total: total,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }
          }}
        />
      </div>
      {
        paymentToReturn && (
          <PaymentExecutionReturnDialog
            record={paymentToReturn}
            onOK={(amount, comment) => {
              apiClient.refundDriverPaymentManual(driver.id, paymentToReturn.id, {amount, comment})
                .then(res => {
                  message.info("Возврат оформлен");
                  setUpdate(prevState => prevState + 1);
                  setPaymentToReturn(null);
                  onUpdated();
                })
                .catch(err => { message.error(err); })
            }}
            onCancel={() => { setPaymentToReturn(null); }}
          />
        )
      }
    </>
  )
}
import React from "react";
import {Badge, Button, Space} from 'antd';

const MenuButton = ({value, statusBtn, onClick, counter, style}) => {
    const state = statusBtn === true ? {...style, background: "#3F3D56", color: "#FFFFFF", margin: 8, marginBottom: 20} : {...style, background: "#F4F4F9", borderColor: "#F4F4F9", color: "#3F3D56", margin: 8, marginBottom: 20}
    return (
        <Button
            onClick={onClick}
            style={state}
        >
        <Space>
            {value}
            <Badge count={counter} showZero={false} />
        </Space>

        </Button>
    );
};
export default MenuButton;
import React, {useEffect, useState} from "react";
import {Col, Input, message, Modal, Row, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import '../../ModalStyle.css';

const rowStyle = {padding: '8px'};

export default function ParkEditDialog({visible, park, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});
  let [workRules, setWorkRules] = useState([]);

  useEffect(() => {
    if(park) {
      setRecord({
        id: park.id,
        title: park.title || "",
        client_id: park.client_id || "",
        api_key: park.api_key || "",
        park_id: park.park_id || "",
        enable_sync: park.enable_sync || true,
        work_rules: park.work_rules || []
      });
      if(park.client_id && park.api_key && park.park_id) {
        apiClient.getParkWorkRules(park.client_id, park.api_key, park.park_id)
          .then(res => {
            setWorkRules(res.records);
          })
          .catch(err => {
            message.error(err);
          });
      }
    } else {
      setRecord({id: 0, enable_sync: true, work_rules: []});
      setWorkRules([]);
    }
  }, [park]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.warn("Укажите наименование");
          return;
        }
        if(!record.park_id) {
          message.warn("Укажите ID");
          return;
        }
        if(!record.client_id) {
          message.warn("Укажите Client ID");
          return;
        }
        if(!record.api_key) {
          message.warn("Укажите API Key");
          return;
        }
        onOk(record);
        setRecord({name: "", id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Наименование</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Наименование"
            value={record.title}
            onChange={(e) => { setRecord({...record, title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>ID</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="ID"
            value={record.park_id}
            onChange={(e) => { setRecord({...record, park_id: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Client ID</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="client_id"
            value={record.client_id}
            onChange={(e) => { setRecord({...record, client_id: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>API Key</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="api_key"
            value={record.api_key}
            onChange={(e) => { setRecord({...record, api_key: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Условия работы</Col>
        <Col span={18}>
          <Select
            mode={"multiple"}
            size="middle"
            placeholder={"Условия работы"}
            style={{width: '100%'}}
            options={workRules.map(e => { return {label: e.name, value: e.id} })}
            value={record.work_rules}
            onChange={(newValue) => {
              setRecord({...record, work_rules: newValue})
            }}
            onDropdownVisibleChange={(open) => {
              if(open) {
                if(record.client_id && record.api_key && record.park_id) {
                  apiClient.getParkWorkRules(record.client_id, record.api_key, record.park_id)
                    .then(res => {
                      setWorkRules(res.records);
                    })
                    .catch(err => {
                      message.error(err);
                    });
                }
              }
            }}
          />
        </Col>
      </Row>

    </Modal>
  );}
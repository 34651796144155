import React, {useEffect, useState} from "react";
import {Col, Modal, Row, DatePicker, message, Space, Button, Table, Input, Select} from "antd";
import EmptyPage from "../../EmptyPage";
import {apiClient} from "../../../../services/ApiClient";
import moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import '../../ModalStyle.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function TeamCompetitionsEditDialog({visible, record, onOk, onClose}) {

  let [competition, setCompetition] = useState(null);
  let [allDrivers, setAllDrivers] = useState([]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button
              type="link"
              icon={<DeleteOutlined/>}
              onClick={
                () => {
                  setCompetition({...competition, teams: competition.teams.filter(el => el.id !== record.id)})
                }
              }/>
          </Space>
        );
      }
    },
    {
      title: 'Команда',
      dataIndex: 'title',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(record) {
      apiClient.getCompetitionTeams()
        .then(res => {
          setAllDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      setAllDrivers([]);
    }
  }, [record]);

  useEffect(() => {
    if(record) {
      apiClient.getTeamCompetition(record.id)
        .then(res => {
          setCompetition(res.record);
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      setCompetition(null);
    }
  }, [record]);

  const startCompetitionUpdate = () => {
    apiClient.recalculateTeamCompetition(competition.id)
      .then(res => {
        message.success("Результаты соревнования обновлены");
      })
      .catch(err => {
        message.error(err);
      });
  };

  function render() {
    return (
      <>
        <Row style={{width: '100%', textAlign: "right", marginBottom: 10}}>
          <Col offset={21}>
            <Button type="primary" icon={<ReloadOutlined/>} onClick={() => { startCompetitionUpdate() }}>Пересчитать</Button>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Адрес страницы</Col>
          <Col span={18}>
            <Input readOnly value={`${process.env.REACT_APP_WEB_HOST}/team-competitions/${competition.uid}`} />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Даты</Col>
          <Col span={18}>
            <RangePicker showTime
                         style={{ width: 400 }}
                         value={competition ? [moment(competition.from_dt), moment(competition.to_dt)] : []}
                         onChange={(dates, dateStrings) => { setCompetition({...competition, from_dt: dateStrings[0], to_dt: dateStrings[1]}); }}
            />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Добавить участника</Col>
          <Col span={18}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Команда"
                onChange={(value) => {
                  if(value) {
                    let team = allDrivers.filter(el => el.id === value)[0];
                    if(team) {
                      if(competition.teams.filter(el => el.id === team.id).length > 0) {
                        message.warn("Команда уже добавлена в конкурс");
                        return;
                      }
                      setCompetition({...competition, teams: [...competition.teams, team]});
                    }
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  allDrivers.map(s => <Option key={s.id} value={s.id}>{`${s.title}`}</Option>)
                }
              </Select>
            </Space>
          </Col>
        </Row>

        <div style={{marginTop: 10}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={competition.teams}
                 rowKey="id"/>
        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Конкурс"}
      destroyOnClose={true}
      visible={visible}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => { onOk(competition); }}
      onCancel={() => { onClose(); }}>
      {
        competition ? render() : <EmptyPage/>
      }
    </Modal>
  );

}
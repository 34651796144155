import React, {useEffect, useState} from "react";
import {Checkbox, Col, DatePicker, Input, message, Modal, Row} from "antd";
import moment from "moment";

const rowStyle = {padding: '8px'};

export default function KaskoPolicyEditDialog({policy, onOk, onCancel}) {

  let [record, setRecord] = useState({});

  useEffect(() => {
    setRecord(policy);
  }, [policy]);
  return (
    <Modal
      destroyOnClose={true}
      width="1024px"
      title={"Полис КАСКО"}
      visible={true}
      onOk={() => {
        if(!record.company_title) {
          message.warn("Заполните поле Страховщик");
          return;
        }
        if(!record.policy_number) {
          message.warn("Заполните поле Серия/номер полиса");
          return;
        }
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Страховщик</Col>
        <Col md={18}>
          <Input
            allowClear={true}
            value={record.company_title}
            onChange={(e) => { setRecord({...record, company_title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Серия/номер полиса</Col>
        <Col md={6}>
          <Input
            allowClear={true}
            value={record.policy_number}
            onChange={(e) => { setRecord({...record, policy_number: e.target.value}) }}/>        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Премия</Col>
        <Col md={6}>
          <Input
            allowClear={true}
            value={record.policy_premium}
            onChange={(e) => { setRecord({...record, policy_premium: e.target.value}) }}/>        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Франшиза</Col>
        <Col md={6}>
          <Input
            allowClear={true}
            value={record.policy_deductible}
            onChange={(e) => { setRecord({...record, policy_deductible: e.target.value}) }}/>        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Дата выдачи</Col>
        <Col md={6}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.policy_issue_dt ? moment(record.policy_issue_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, policy_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: 220}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Срок действия</Col>
        <Col md={6}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.policy_due_dt ? moment(record.policy_due_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, policy_due_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: 220}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Действующий</Col>
        <Col md={6}>
          <Checkbox
            checked={record.is_current}
            onChange={(e) => {
              setRecord({...record, is_current: e.target.checked });
            }}
          />
        </Col>
      </Row>

    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {message, Space, Table, Input, DatePicker, Button, Modal} from "antd";
import {DeleteOutlined, EditFilled} from '@ant-design/icons';
import {apiClient} from "../../../services/ApiClient";
import { format } from 'date-fns';
import ApplicationDialog from "./ApplicationDialog";
import moment from "moment";
import {windowService} from "../../../services/WindowService";

const {Search} = Input;
const { RangePicker } = DatePicker;

export default function ApplicationsTab({onOk}) {

  let [loading, setLoading] = useState(false);
  let [createApplication, setCreateApplication] = useState(false);
  let [changeApplication, setChangeApplication] = useState(null);
  let [applications, setApplications] = useState([]);
  let [filters, setFilters] = useState([]);
  let [selectedApplication, setSelectedApplication] = useState([]);
  let [ids, setIds] = useState("");

  const usersCols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      align: "center",
      width: 30,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <Button
              icon={<EditFilled/>}
              onClick={(event) => { showApplication(record) }}
              type="link"
            />
          </Space>
        );
      },
    },
    {
      title: 'Номер авто',
      align: "center",
      dataIndex: 'car_reg_number',
      render: (text, record, index) => {
        return (
          <Button
            type={"link"}
            onClick={() => {
              windowService.openRouteWindow(`cp/park/vehicles/${record.car_id}`);
            }}
          >
            {
              <>
                <div>
                  {record.car_reg_number}
                </div>
              </>
            }
          </Button>
        );
      },
      sorter: (a, b) => {
        return a.car_reg_number.localeCompare(b.car_reg_number);
      },
    },
    {
      title: 'Водитель',
      align: "center",
      render: (text, record, index) => {
        return (
          <Button
            type={"link"}
            style={{padding: 0}}
            onClick={() => {
              windowService.openRouteWindow(`cp/drivers/${record.driver?.id}`);
            }}
          >
            {record.driver?.display_name}
          </Button>
        );
      },
      sorter: (a, b) => {
        return a.driver?.display_name.localeCompare(b.driver?.display_name);
      },
    },
    {
      title: 'Дата, время заявки',
      align: "center",
      dataIndex: 'accident_date',
      render: (text, record, index) => {
        const date = new Date(record.accident_date);
        return format(date, "dd.MM.yyyy HH:mm");
      },
      sorter: (a, b) => {
        return moment(a.accident_date).valueOf() - moment(b.accident_date).valueOf();
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Источник ДТП',
      align: "center",
      dataIndex: 'request_source',
      sorter: (a, b) => {
        return a.request_source.localeCompare(b.request_source);
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const showApplication = (record) => {
    setChangeApplication(record);
  };

  const onAddApplication = (application) => {
    const params = {
      car_id: application.car_reg_number.id,
      driver_id: application.driver?.id,
      accident_date: application.accident_date,
      request_source: application.request_source
    };
    apiClient.createApplication(params)
      .then(res => {
        message.success("Заявка добавлена");
        setLoading(true);
        const params = {
          fio: filters.driver,
          reg_number: filters.vehicle,
          accident_date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
          accident_date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
        };
        apiClient.getApplications(params)
          .then(res => {
            setApplications(res.records);
          })
          .catch(err => message.error(err))
          .finally(() => { setLoading(false); })
      })
      .catch(err => message.error(err));
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedApplication(selectedRowKeys);
    setIds(selectedRowKeys);
  };

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeApplication(ids)
          .then(res => {
            message.success("Заявка удалена");
            setLoading(true);
            const params = {
              fio: filters.driver,
              reg_number: filters.vehicle,
              accident_date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
              accident_date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
            };
            apiClient.getApplications(params)
              .then(res => {
                setApplications(res.records);
              })
              .catch(err => message.error(err))
              .finally(() => { setLoading(false); })
          })
          .catch(err => message.error(err));
          setSelectedApplication([]);
      },
    });
  };

  const onUpdateApplication = (editedApplication) => {
    const params = {
      taxi_driver_id: editedApplication.driver?.id,
      accident_date: editedApplication.accident_date,
      request_source: editedApplication.request_source
    };
    apiClient.updateApplication(editedApplication.id, params)
      .then(res => {
        message.success("Заявка обновлена");
        setLoading(true);
        const params = {
          fio: filters.driver,
          reg_number: filters.vehicle,
          accident_date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
          accident_date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
        };
        apiClient.getApplications(params)
        .then(res => {
          setApplications(res.records);
        })
        .catch(err => message.error(err))
          .finally(() => { setLoading(false); })
      })
      .catch(err => message.error(err));
  };

  useEffect(() => {
    setLoading(true);
    const params = {
      fio: filters.driver,
      reg_number: filters.vehicle,
      accident_date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
      accident_date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
    };
    apiClient.getApplications(params)
      .then(res => {
        setApplications(res.records);
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => { setLoading(false); })
  }, [filters]);

  return (
    <>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedApplication.length > 0 ? `Выбрано ${selectedApplication.length} записей` : ''}
          </span>
        </span>
      </div>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "left"}}>
            {applications.length > 0 ? `Под выбранные фильтры подходит ${applications.length} записей` : 'Под выбранные фильтры подходит 0 записей'}
          </span>
        </span>
      </div>
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'left'}} >
          <Space direction={"horizontal"} wrap style={{marginBottom: 16, marginTop: 10}}>
            <Search
              enterButton
              placeholder={"Поиск по водителю"}
              onChange={(e) => { setFilters({...filters, ...{driver: e.target.value}}); }}
              allowClear={true}
              style={{width: 350}}
            />
            <Search
              enterButton
              placeholder={"Поиск по номеру авто"}
              onChange={(e) => { setFilters({...filters, ...{vehicle: e.target.value}}); }}
              allowClear={true}
              style={{width: 350}}
            />
            <RangePicker
              allowClear={true}
              style={{width: 350}} 
              onChange={(dates, dateStrings) => { setFilters({...filters, date_from: dateStrings[0], date_to: dateStrings[1]}); }}
            />
          </Space>
        </div>
        <div style={{width: '20%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            <Button type="primary" onClick={() => {setCreateApplication(true) }}>
              Добавить заявку
            </Button>
            {selectedApplication.length !== 0 && (
              <>
                <Button type="danger" onClick={onDeleteSelected}>
                  Удалить заявку
                </Button>
              </>
          )}
          </Space>
        </div>
      </div>
      <Table
        loading={loading}
        rowSelection={{selectedApplication, onChange: onSelectChange}}
        columns={usersCols}
        dataSource={applications}
        rowKey="id"
        size='small'
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
      {
        createApplication && (
          <ApplicationDialog
            visible={createApplication}
            onOk={(application) => { setCreateApplication(false); onAddApplication(application); }}
            onCancel={(dtpStatus) => {
              if (dtpStatus === true) {
                onOk();
              }
              setCreateApplication(false)
            }}
          />
        )
      }
      {
        changeApplication !== null && (
          <ApplicationDialog
            visible={changeApplication !== null}
            record={changeApplication}
            onOk={(application) => { setChangeApplication(null); onUpdateApplication(application); }}
            onCancel={(dtpStatus) => {
              if (dtpStatus === true) {
                onOk();
              }
              setChangeApplication(null);
              setLoading(true);
              const params = {
                fio: filters.driver,
                reg_number: filters.vehicle,
                accident_date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
                accident_date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
              };
              apiClient.getApplications(params)
                .then(res => {
                  setApplications(res.records);
                })
                .catch(err => message.error(err))
                .finally(() => { setLoading(false); })
            }}
          />
        )
      }
    </>
  )
}
import React, {useEffect, useState} from "react";
import {Button, DatePicker, Empty, Input, message, PageHeader, Select, Space, Table} from "antd";
import moment from "moment";
import {apiClient} from "../../../services/ApiClient";
import "./WayBillArchivePage.css"

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

export default function WayBillArchivePage() {
  let [dataSource, setDataSource] = useState([]);
  let [selectedRows, setSelectedRows] = useState([]);
  let [loading, setLoading] = useState(false);
  let [dataSourceLoading, setDataSourceLoading] = useState(false);
  let [filter, setFilter] = useState({
    dates: [],
    search: "",
    selectedRegionId: []
  });
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [empty, setEmpty] = useState(<span>Загрузка...</span>);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [25, 50, 75]
  });
  let [sorter, setSorter] = useState({});
  let [regions, setRegions] = useState([]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Создано',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
      },
    },
    {
      title: 'Дата',
      dataIndex: 'report_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.report_dt).format('DD.MM.YYYY')}`;
      },
      sorter: (a, b) => {
        return moment(a.report_dt).valueOf() - moment(b.report_dt).valueOf();
      },
    },
    {
      title: 'Водитель',
      dataIndex: 'driver',
      render: driver => driver ? `${driver.surname} ${driver.name} ${driver.patronymic} (${driver.license})` : "",
    },
    {
      title: 'Авто',
      dataIndex: 'car',
      align: "center",
      render: car => car ? `${car.model} (${car.number})` : "",
    },
    {
      title: 'Ответственный',
      dataIndex: 'created_by',
      align: "center",
      render: (text, record, index) => record?.created_by?.name,
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(  () => {
    let f = {
      regionIds: filter.selectedRegionId,
      ...{
        page: pagination.current,
        pageSize: pagination.pageSize
      },
      ...{
        sortField: sorter.field,
        sortOrder: sorter.order
      }
    };
    if(filter.dates && filter.dates.length > 0) {
      f.from = filter.dates[0].format("YYYY-MM-DD");
      f.to = filter.dates[1].format("YYYY-MM-DD");
    }
    if(filter.search) {
      f.search = filter.search;
    }
    setDataSourceLoading(true);
    apiClient.getReportRecords("waybill", f)
      .then(res => {
        setDataSource(res.records);
        setPagination(prevState => {return {...prevState, total: res.total}})
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setDataSourceLoading(false);
        setEmpty(<Empty />);
      });
  }, [filter, recordsStamp]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) });
  }, []);

  const onPrintSelected = (rows) => {
    setLoading(true);
    apiClient.printReportRecords("waybill", rows)
      .then(res => {
        if(res.data) {
          let blob = new Blob([res.data], { type: 'text/html; charset=utf-8' }),
            url = window.URL.createObjectURL(blob);
          window.open(url)
        }
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <>
      <PageHeader
        title="Архив путевых листов"
        extra={<Button loading={loading} disabled={selectedRows.length === 0} onClick={ () => onPrintSelected(selectedRows) }>Печать</Button>}
      />
      <Space direction="horizontal" style={{margin: '10px 0', marginBottom: 40}} wrap>
        <Search enterButton placeholder="Поиск по ФИО или номеру авто" onSearch={(val) => setFilter({...filter, "search": val})} style={{ width: 400 }} allowClear={true}/>
        <RangePicker format="DD.MM.YYYY" style={{width: 350}} onChange={(val) => setFilter({...filter, "dates": val})}/>
        <Select
          allowClear
          mode="multiple"
          placeholder="Филиал"
          value={filter.selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setFilter({...filter, selectedRegionId: data}); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <div style={{margin: '10px 0'}}>
        <Table dataSource={dataSource}
               locale={{ emptyText: empty}}
               columns={columns}
               size='small'
               rowKey="id"
               scroll={{x: 1000}}
               sticky
               loading={dataSourceLoading}
               pagination={pagination}
               rowSelection={{onChange: (selectedRowKeys, selectedRows) => { setSelectedRows(selectedRowKeys); }}}
               rowClassName={"cursor-pointer"}
               onChange={(pagination, filters, sorter) => {
                 setPagination(pagination);
                 setSorter(sorter);
                 setRecordsStamp(prevState => prevState + 1)
               }}
        />
      </div>
    </>
  );
}
import React, {useEffect, useState} from "react";
import {message, Modal, Spin, Empty, Image} from "antd";
import {apiClient} from "../../../services/ApiClient";
import '../ModalStyle.css';

export default function CkadDebtPhotos({title, visible, item, onClose}) {
  let [finePhotosLoading, setFinePhotosLoading] = useState(false);
  let [finePhotos, setFinePhotos] = useState([]);

  const drawLoading = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Spin />
      </div>
    )
  };

  const drawContent = () => {
    if(!finePhotos || finePhotos.length === 0) {
      return (
        <div style={{textAlign: 'center'}}>
          <Empty />
        </div>
      )
    }
    return (
      <div style={{textAlign: 'center'}}>
        <Image.PreviewGroup>
          {
            finePhotos.map(e => <span style={{padding: '0 10px'}}><Image key={e.id} width={600} title={e.filename} src={e.url}/></span>)
          }
        </Image.PreviewGroup>
      </div>
    )
  };

  useEffect(() => {
    if(item) {
      setFinePhotosLoading(true);
      apiClient.getCkadDebtPhotos(item.id)
        .then(res => {
          setFinePhotos(res.records);
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => {
          setFinePhotosLoading(false);
        })
    } else {
      setFinePhotos([]);
    }
  }, [item]);

  return (
    <Modal
      title={title}
      visible={visible}
      className="size-for-middle-window"
      style={{ top: 20 }}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        finePhotosLoading ? drawLoading() : drawContent()
      }
    </Modal>
  )
};
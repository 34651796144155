import React, {useEffect, useState} from "react";

import {apiClient} from "../../../../services/ApiClient";
import {Col, DatePicker, Input, message, Modal, Row, Select, Space, Switch} from "antd";
import moment from "moment";
import numeral from "numeral";
import {LoadingOutlined} from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const rowStyle = {padding: '4px'};

export default function CompesationEditDialog({recordId, onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({mode: "single"});
  let [categories, setCategories] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);

  useEffect(() => {
    console.log(record);
  }, [record]);

  useEffect(() => {
    if(recordId > 0) {
      setLoading(true);
      apiClient.getPaymentCompensation(recordId)
        .then(res => {
          setRecord(res.record);
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false); })
    }
  }, [recordId]);

  useEffect(() => {
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
    apiClient.getCompensationCategories()
      .then(res => {
        setCategories(res.records);
      })
      .catch(err => { message.error(err); });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => {
        setVehicleGroups(res.records)
      })
      .catch(err => message.error(err));
  }, []);

  const form = () => {
    return (
      <>
        {
          recordId === 0 ? (
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Тип</Col>
              <Col span={6}>
                <Switch
                  checkedChildren="Групповая"
                  unCheckedChildren="Индивидуальная"
                  defaultChecked={false}
                  onChange={(e) => {
                    setRecord({...record, mode: e ? "group" : "single" })
                  }}
                />
              </Col>
            </Row>
          ) : null
        }
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Дата</Col>
          <Col span={18}>
            <DatePicker
              allowClear={false}
              format="DD.MM.YYYY"
              placeholder="Дата"
              value={record.event_dt ? moment(record.event_dt, "YYYY-MM-DD") : null}
              onChange={(val) => { setRecord({...record, event_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              style={{width: 550}}
            />
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Категория</Col>
          <Col span={6}>
            <Select
              allowClear
              placeholder="Категория расхода"
              value={record?.category?.id}
              style={{width: 550}}
              onChange={(data) => {
                let category = categories.find(el => el.id === data);
                if(category) {
                  setRecord({...record, category: category});
                }
              }}
            >
              {
                categories.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Сумма</Col>
          <Col span={18}>
            <Space direction={"horizontal"}>
              <Input
                allowClear={true}
                type={"number"}
                placeholder="Сумма"
                value={record.amount}
                style={{width: 200}}
                onChange={(e) => { setRecord({...record, amount: e.target.value}) }}/>
              {
                record.mode === "group" ? (
                  <span>
                    {`Сумма для зачисления на ТС: ${record.taxi_car_group ? numeral(parseFloat(record.amount) / record.taxi_car_group.num_cars).format('0.00') : '-'}`}
                  </span>
                ) : null
              }
            </Space>
          </Col>
        </Row>
        {
          record.mode === "group" ? (
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Группа ТС</Col>
              <Col span={6}>
                <Select
                  allowClear
                  showSearch
                  value={record?.taxi_car_group?.id}
                  placeholder="Группа ТС"
                  style={{width: 550}}
                  onChange={(data) => {
                    let group = vehicleGroups.find(el => el.id === data);
                    if(group) {
                      setRecord({...record, taxi_car_group: group});
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title} (ТС в группе: ${el.num_cars})`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
          ) : (
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ТС</Col>
              <Col span={6}>
                <Select
                  allowClear
                  showSearch
                  value={record?.taxi_car?.id}
                  placeholder="ТС"
                  style={{width: 550}}
                  onChange={(data) => {
                    let vehicle = vehicles.find(el => el.id === data);
                    if(vehicle) {
                      setRecord({...record, taxi_car: vehicle});
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    vehicles.map(el => <Option key={el.id} value={el.id}>{`${el.brand} ${el.model} ${el.reg_number}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
          )
        }
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Комментарий</Col>
          <Col span={18}>
            <TextArea
              rows={3}
              style={{width: 550}}
              value={record.comment}
              onChange={(e) => { setRecord({...record, comment: e.target.value}) }}
            />
          </Col>
        </Row>
      </>
    )
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        style={{top: 10}}
        className="size-for-middle-window"
        title={"Расход"}
        visible={true}
        onOk={() => {
          console.log(record);
          if(record.mode === "group") {
            if(!record.event_dt || !record.category || !record.amount || !record.taxi_car_group) {
              message.warn("Все поля обязательны к заполнению");
              return
            }
          } else {
            if(!record.event_dt || !record.category || !record.amount || !record.taxi_car) {
              message.warn("Все поля обязательны к заполнению");
              return
            }
          }

          onOK(record);
        }}
        onCancel={() => { onCancel(); }}
      >
        {
          loading ? (
            <div style={{margin: '10px auto', textAlign: 'center'}}><LoadingOutlined/></div>
          ) : form()
        }

      </Modal>
    </>
  )
}
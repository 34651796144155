import React, {useContext, useEffect, useState} from "react";
import {Button, Comment, DatePicker, message, Modal, Space, TimePicker, Badge} from "antd";
import {BellFilled, BellTwoTone, CommentOutlined, DeleteOutlined} from "@ant-design/icons";
import moment from "moment";
import {apiClient} from "../../../services/ApiClient";
import UserProfileContext from "../../../services/UserProfileContext";

export default function TrackerEvent({item, onRemoveNotificationClick, onRemoveAlertClick, onAlertClick, onChangeAlertClick}) {
  let userProfile = useContext(UserProfileContext);

  let [comments, setComments] = useState([]);
  let [isNew, setIsNew] = useState(item.isNew);
  let [modalVisible, setModalVisible] = useState(false);
  let [commentsVisible, setCommentsVisible] = useState(false);
  let parts = item.message.split(':');
  let [date, setDate] = useState(null);
  let [time, setTime] = useState(null);
  let [notifyCtrl, setNotifyCtrl] = useState(null);
  let [notifyCheckCount, setNotifyCheckCount] = useState(0);

  const canRemoveNotification = () => {
    return userProfile.permissions.some(el => el === "car_tracker_event:mark_as_seen");
  };

  const handleDateUpdated = (val) => {
    if(val) {
      val.hour(0);
      val.minute(0);
      val.second(0);
    }
    setDate(val);
  };
  const handleTimeUpdated = (val) => {
    setTime(val);
  };

  const handleChangeAlertClick = () => {
    let dt = null;
    if(date) {
      dt = date;
      if(time) {
        dt.hour(time.hour());
        dt.minute(time.minute());
        dt.second(time.second());
      }
    }
    if(!dt) {
      message.error("Необходимо выбрать дату уведомления");
      return;
    }
    setModalVisible(false);
    onChangeAlertClick(dt);
  };

  const handleShowComments = (item) => {
    apiClient.getTrackerComment(item.tracker)
      .then(data => {
        setComments(data.comments);
        setCommentsVisible(true);
      })
      .catch((err) => {
        message.error("Ошибка");
      })
  };

  useEffect(() => {
    setTimeout(() => {
      setIsNew(false);
    }, 1000 * 2);
    let h = setInterval(() => {
      setNotifyCheckCount(prev => prev + 1);
    }, 1000);
    return () => clearTimeout(h);
  }, []);

  useEffect(() => {
    if(item && item.notification_dt) {
      const notifyDate = moment(item.notification_dt);
      if(notifyDate.isBefore(moment())) {
        // время нотификации просрочено
        setNotifyCtrl(<Button title="Отметить уведомление"
                             onClick={onAlertClick}
                             type="link"
                             icon={<BellTwoTone className="blink" twoToneColor="#eb2f96"/>}/>);
      } else {
        // время нотификации еще не наступило
        setNotifyCtrl(<Button type="link"
                             icon={<BellFilled/>}
                             onClick={() => setModalVisible(true) }
                             title={`Напоминание запланировано на ${notifyDate.format("DD.MM.YYYY HH:mm")}`} />);
      }
    } else {
      setNotifyCtrl(null);
    }
  }, [item, notifyCheckCount]);

  return (
    <>
      <div className={`history-event ${isNew ? 'new' : ''}`}>
        <div className="title">{parts[0]}</div>
        <div className="message">{parts[1]}</div>
        <div className="actions" style={{textAlign: 'right'}}>
          <div className="date" style={{float: "left", paddingTop: 5}}>{item.display_event_dt}</div>
          <Space direction="horizontal" size="small">
            {
              item.notification_count && item.notification_count > 1
                ? <Badge count={item.notification_count} title="Повтор уведомления" />
                : null
            }
            {notifyCtrl}
            <Button icon={<DeleteOutlined/>} onClick={() => onRemoveNotificationClick(item)} type="link" hidden={!canRemoveNotification()}/>
            <Button icon={<CommentOutlined/>} onClick={() => handleShowComments(item)} type="link"/>
          </Space>
        </div>
      </div>
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        title="Изменить уведомление"
        footer={[
          <Button key="back" danger onClick={() => {
            setModalVisible(false);
            onRemoveAlertClick();
          }}>
            Удалить
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            handleChangeAlertClick();
          }}>
            Изменить
          </Button>,
          <Button key="cancel" onClick={() => setModalVisible(false) }>
            Отмена
          </Button>
        ]}
      >
        <Space style={{width: '100%'}}>
          <DatePicker format="DD.MM.YYYY" disabledDate={(dt) => { return dt && dt < moment().startOf('day') }} onChange={handleDateUpdated} style={{width: 220}}/>
          <TimePicker onChange={handleTimeUpdated} style={{width: 220}} />
        </Space>
      </Modal>
      <Modal visible={commentsVisible}
             width={800}
             style={{ top: 20 }}
             destroyOnClose={true}
             onCancel={() => {
               setCommentsVisible(false);
             }}
             footer={[
               <Button key="submit" onClick={() => setCommentsVisible(false) }>
                 Закрыть
               </Button>
             ]}
             title="Комментарии">
        <div style={{margin: "0 10px"}}>
          <Space direction="vertical" size="small" style={{width: "100%"}}>
            {comments.length > 0 ? comments.map(e => <Comment
              className="driver-comment"
              key={e.id}
              content={<span>{e.comment}</span>}
              datetime={<span>{moment(e.create_dt).format("DD.MM.YYYY HH:mm")}</span>}
            />) : (<div style={{textAlign: "center", margin: "10px"}}>Нет записей</div>)}
          </Space>
        </div>
      </Modal>
    </>
  );
}
import React, {useEffect, useState} from 'react';
import {apiClient} from "../../../../services/ApiClient";
import {Col, InputNumber, message, Modal, Row, Spin, Tabs} from "antd";
import {ParkStatuses, ModelDowntimeCriticalLevels} from "../../../common/AppEnums";
const { TabPane } = Tabs;

const rowStyle = {padding: '8px'};

export default function DowntimeReportSettingsDialog({onClose}) {

  let [loading, setLoading] = useState(false);
  let [values, setValues] = useState({});
  let [activeTabKey, setActiveTabKey] = useState("");

  useEffect(() => {
    setLoading(true);
    apiClient.getSystemProperty("parkmanager.downtime_status_alerts.*")
      .then(res => {
        if(res.values) {
          setValues(prevState => {
            return {
              ...prevState,
              ...res.values
            }
          });
        }
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); });
    apiClient.getSystemProperty("parkmanager.model_downtime_levels.*")
      .then(res => {
        if(res.values) {
          setValues(prevState => {
            return {
              ...prevState,
              ...res.values
            }
          });
        }
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); });
    apiClient.getSystemProperty("parkmanager.typeSummary.*")
      .then(res => {
        if(res.values) {
          setValues(prevState => {
            return {
              ...prevState,
              ...res.values
            }
          });
        }
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })

  }, []);

  const onTabChange = (key) => {
    console.log('activeTabKey: ', key);
    setActiveTabKey(key);
  };

  const parkStatusSettingsRow = (el) => {
    return (
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={8}>{el.title}</Col>
        <Col md={16}>
          <InputNumber
            min={0}
            style={{width: 200}}
            value={values[`parkmanager.downtime_status_alerts.${el.id}`]}
            onChange={(value) => {
              setValues(prevState => {
                let state = {...prevState};
                state[`parkmanager.downtime_status_alerts.${el.id}`] = value;
                return state;
              })
            }}
          />
        </Col>
      </Row>
    )
  };

  /**
   * @param {({level: integer, title: string, min: integer, max: integer})} el
   * @returns {JSX.Element}
   */
  const modelDowntimeLevelSettingsRow = (el) => {
    return (
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={8}>{el.title}</Col>
        <Col md={16}>
          <InputNumber
            min={0}
            style={{width: 200}}
            value={values[`parkmanager.model_downtime_levels.${el.level}`]}
            onChange={(value) => {
              setValues(prevState => {
                let state = {...prevState};
                state[`parkmanager.model_downtime_levels.${el.level}`] = value;
                return state;
              })
            }}
          />
        </Col>
      </Row>
    )
  };

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Настройки"}
      visible={true}
      mask={loading}
      onOk={() => {
        apiClient.setSystemProperties(values)
          .then(res => {
            message.info("Изменения сохранены");
            onClose();
          })
          .catch(err => { message.error(err); })
      }}
      onCancel={onClose}>

      <div>
        <div hidden={!loading} style={{textAlign: 'center'}}>
          <Spin />
        </div>
        <div hidden={loading}>
          <Tabs onChange={onTabChange} tabPosition={"left"}>
            <TabPane tab="Статусы ТС" key="statusSettings">
              <h3>Настройка подсветки уровней простоев по статусам ТС</h3>
              {
                ParkStatuses
                  .filter(el => el.enabled)
                  .map((el) => parkStatusSettingsRow(el))
              }
            </TabPane>
            <TabPane tab="Критичности по моделям" key="modelSettings">
              <h3>Настройка подсветки критичности простоев по моделям ТС</h3>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col md={8}>Количество ТС</Col>
                <Col md={16}>Допустимый % простоя</Col>
              </Row>
              {
                ModelDowntimeCriticalLevels
                  .map((el) => modelDowntimeLevelSettingsRow(el))
              }
            </TabPane>
            <TabPane tab="Периоды простоя по типам ТС" key="typePeriodsSettings">
              <h3>Настройка периодов минимального и максимального простоя по типам ТС</h3>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col md={8}>Мин период статуса (дней)</Col>
                <Col md={16}>
                  <InputNumber
                    min={0}
                    style={{width: 200}}
                    value={values[`parkmanager.typeSummary.minIdlePeriod`]}
                    onChange={(value) => {
                      setValues(prevState => {
                        let state = {...prevState};
                        state[`parkmanager.typeSummary.minIdlePeriod`] = value;
                        return state;
                      })
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col md={8}>Макс период статуса (дней)</Col>
                <Col md={16}>
                  <InputNumber
                    style={{width: 200}}
                    min={0}
                    value={values[`parkmanager.typeSummary.maxIdlePeriod`]}
                    onChange={(value) => {
                      setValues(prevState => {
                        let state = {...prevState};
                        state[`parkmanager.typeSummary.maxIdlePeriod`] = value;
                        return state;
                      })
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
}
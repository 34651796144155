import React, {useEffect, useState} from "react";
import {Input, message, Switch, Table} from "antd";
import {apiClient} from "../../../../../services/ApiClient";

const { TextArea } = Input;

export default function InspectionStepsEditor({active, inspection}) {
  let [records, setRecords] = useState([]);

  useEffect(() => {
    if(active) {
      apiClient.getPhotoInspectionSteps(inspection)
        .then(res => { setRecords(res.records) })
        .catch(err => message.error(err));
    } else {
      setRecords([]);
    }
  }, [active, inspection]);

  return (
    <Table
      dataSource={records}
      scroll={{x: 1000, y: 800}}
      sticky
      columns={[
        {
          title: "",
          align: "center",
          width: 30,
        },
        {
          title: "Шаг",
          width: 200,
          dataIndex: 'title',
        },
        {
          title: 'Сообщение',
          dataIndex: 'label',
          render: (text, record, index) => {
            return (
              <TextArea placeholder={"Описание"}
                        rows={2}
                        width={"100%"}
                        value={record.label}
                        onChange={(e) => {
                          apiClient.updatePhotoInspectionStep(inspection, record.id, {"label": e.target.value})
                            .catch(err => console.error(err));
                          setRecords(prevState => {
                            return prevState.map(el => {
                              if(el.id === record.id) {
                                el.label = e.target.value;
                              }
                              return el;
                            })
                          });
                        }}/>
            )
          }
        },
        {
          title: 'Включено',
          dataIndex: 'enabled',
          width: 120,
          render: (text, record, index) => {
            return (
              record.switchable
              ? (<Switch
                    checked={record.enabled}
                    checkedChildren={"Отключить"}
                    unCheckedChildren={"Включить"}
                    onChange={(checked, event) => {
                      apiClient.updatePhotoInspectionStep(inspection, record.id, {"enabled": checked})
                        .then(res => setRecords(prevState => {
                          return prevState.map(el => {
                            if (el.id === record.id) {
                              el.enabled = checked;
                            }
                            return el;
                          })
                        }))
                        .catch(err => message.error(err));
                    }}/>
                ): null
            )
          },
        },
      ]}
      size='small'
      rowKey="id"
      pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
    />

  )
}
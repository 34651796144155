import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, DatePicker, Input, message, PageHeader, Space, Table} from "antd";
import moment from "moment";
import UpdateButton from "../../../buttons/UpdateButton";
import {DownloadOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const {Search} = Input;

export default function RentPaymentsByVehiclesPage() {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [download, setDownload] = useState(false);
  let [transactionsFilter, setTransactionsFilter] = useState({type: ''});
  let [search, setSearch] = useState("");

  useEffect(() => {
    setLoading(true);
    let filter = {
      search
    };
    if(transactionsFilter.period && transactionsFilter.period.length > 0) {
      filter.from_dt = moment(transactionsFilter.period[0]).format('YYYY-MM-DD');
      filter.to_dt = moment(transactionsFilter.period[1]).format('YYYY-MM-DD');
    }
    if(download) {
      filter.download = true;
      setDownload(false);
    }
    apiClient.getReportRecords("rent-payments/vehicles", filter)
      .then(res => {
        if(res.records) {
          setRecords(res.records);
        } else if(res.uid) {
          window.open(apiClient.actionUrl(`reports/download?uid=${res.uid}`), "_blank");
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [recordsStamp, transactionsFilter, search]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'ТС',
      dataIndex: 'reg_number',
    },
    {
      title: "Шаблон",
      dataIndex: "paid_sum",
      width: 200,
      render: (text, record, index) => {
        return record.paid_sum ? record.paid_sum : "0";
      },
      sorter: (a, b) => {
        return a.paid_sum - b.paid_sum;
      },
    },
    {
      title: "Офис",
      dataIndex: "manual_sum",
      width: 200,
      render: (text, record, index) => {
        return record.manual_sum ? record.manual_sum : "0";
      },
      sorter: (a, b) => {
        return a.manual_sum - b.manual_sum;
      },
    },
    {
      title: "Возврат",
      dataIndex: "refund_sum",
      width: 200,
      render: (text, record, index) => {
        return record.refund_sum ? record.refund_sum : "0";
      },
      sorter: (a, b) => {
        return a.refund_sum - b.refund_sum;
      },
    },
    {
      title: "Итог",
      dataIndex: "total",
      width: 200,
      render: (text, record, index) => {
        return record.total ? record.total : "0";
      },
      sorter: (a, b) => {
        return a.total - b.total;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Аренда по ТС"
      />
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'left', marginBottom: 40}}>
          <Space direction={"horizontal"} wrap>
            <RangePicker format="DD.MM.YYYY" style={{width: 350}} onChange={(val) => setTransactionsFilter({...transactionsFilter, ...{period: val}})}/>
            <Search
              enterButton
              placeholder={"Поиск по гос.номеру, СТС, VIN"}
              onSearch={(value) => { setSearch(value) }}
              allowClear={true}
              style={{width: 400}}/>
          </Space>
        </div>
        <div style={{width: '20%', float: 'right'}}>
          <Space style={{float: "right"}}>
            <Button icon={<DownloadOutlined/>} onClick={ () => {
              setDownload(true);
              setRecordsStamp(prevState => prevState + 1)
            }}>Скачать</Button>
            <UpdateButton onClick={ () => setRecordsStamp(prevState => prevState + 1) }/>
          </Space>
        </div>
      </div>

      <Table dataSource={records}
             columns={columns}
             size="small"
             scroll={{x: 1000}}
             sticky
             style={{margin: '10px 0', marginTop: 40}}
             rowKey="id"
             loading={loading}
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
    </>
  );
}
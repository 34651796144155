import React from "react";
import { Form, Input, Button, Checkbox, Layout } from 'antd';
import { Row, Col, Image } from 'antd';
import { notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Auth.css'
import { useHistory } from 'react-router-dom';
import {apiClient} from "../../services/ApiClient";
import imageBodyName from "../auth/pageBackground.svg";
import imageLogoName from "../auth/logo.svg";

export default function LoginPage(props) {
    const [form] = Form.useForm();
    const history = useHistory();
    const onFinish = ({ username, password }) => {
        apiClient.authenticate(username, password)
            .then(() => {
                history.replace('/')
            })
            .catch(err => {
                console.error(err);
                notification.error({ message: 'Вход не выполнен' });
                form.setFieldsValue({ password: '' });
            });
    };
    return (
        <React.Fragment>
            <Image src={imageBodyName} preview={false} className="img-background"/>
            <Row justify="space-around" align="middle">
                <Col md={4}> </Col>
                <Col md={16}>
                    <Layout style={{width: 360, margin: "auto", marginTop: 120, borderRadius: 20}} className="layout-from-page">
                    <div>
                        <Form
                            form={form}
                            name="normal_login"
                            className="auth-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            {/* <div style={{textAlign: 'center', marginBottom: 20}}><Image src={imageLogoName} preview={false} width="200px"/></div> */}
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите имя пользователя',
                                    },
                                ]}
                            >
                                <Input className="input-style" prefix={<UserOutlined/>} placeholder="Логин"/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите пароль',
                                    },
                                ]}
                            >
                                <Input
                                    className="input-style"
                                    prefix={<LockOutlined/>}
                                    type="password"
                                    placeholder="Пароль"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox style={{color: "#FFFFFF"}}>Запомнить меня</Checkbox>
                                </Form.Item>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="auth-form-button">
                                    Вход
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                    </Layout>
                </Col>
                <Col md={4}> </Col>
            </Row>
        </React.Fragment>
    );
}
import { Button } from 'antd';
import { useState } from 'react';

const CancelButton = ({value, onClick, icon}) => {
    const [state, setState] = useState({ background: "#FFFFFF", color: "#3F3D56" });
    return (
        <Button
            onMouseEnter={() => setState({...state, background: "#3F3D56", color: "#FFFFFF" })}
            onMouseLeave={() => setState({...state, background: "#FFFFFF", color: "#3F3D56" })}
            onClick={onClick}
            style={state}
            icon={icon}
        >
        {value}
        </Button>
    );
};
export default CancelButton;
import React, {useState} from "react";
import MenuButton from "../../../buttons/MenuButton";
import ModelDowntimeReport from "./ModelDowntimeReport";
import TypeDowntimeReport from "./TypeDowntimeReport";
import {SettingOutlined} from "@ant-design/icons";
import DowntimeReportSettingsDialog from "./DowntimeReportSettingsDialog";
import {Button} from "antd";

export default function DowntimeReportSelectorPage() {

  let [selectedTab, setSelectedTab] = useState("type");
  let [showSettings, setShowSettings] = useState(false);
  let [update, setUpdate] = useState(0);

  const reloadState = () => {
    setUpdate(prevState => prevState + 1);
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <MenuButton value = "По типу ТС" statusBtn={selectedTab === "type"} onClick={() => setSelectedTab("type")}/>
        <MenuButton value = "По модели" statusBtn={selectedTab === "model"} onClick={() => setSelectedTab("model")}/>
        <Button type={"link"} icon={<SettingOutlined/>} style={{marginTop: 10}}
                onClick={() => {
                  setShowSettings(true);
                }}>
          Настройки
        </Button>
      </div>

      {selectedTab === "type" && (
        <TypeDowntimeReport update={update}/>
      )}

      {selectedTab === "model" && (
        <ModelDowntimeReport update={update}/>
      )}
      {
        showSettings && (
          <DowntimeReportSettingsDialog
            onClose={() => {
              setShowSettings(false);
              reloadState();
            }}
          />
        )
      }
    </>
  )
}
import React, {useState} from "react";
import {Button, Card, Form, Input, Table, message, Space, Checkbox} from "antd";
import { Tabs } from 'antd';
import {LockOutlined} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import PasswordInputDialog from '../common/PasswordInputDialog';
import MenuButton from "../../buttons/MenuButton";
import CreateNotificationPage from "./components/CreateNotificationPage";

const { TabPane } = Tabs;


export default function ProfilePage({profile}) {
  let [protectedResources, setProtectedResources] = useState([]);
  let [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let [isPasswordDialogVisible, setPasswordDialogVisible] = useState(false);
  let [isPasswordChanging, setPasswordChanging] = useState(false);
  let [isPasswordResetting, setPasswordResetting] = useState(false);
  let [selectedTab, setSelectedTab] = useState("1");

  const [form] = Form.useForm();

  const onFinish = ({ password, password_check }) => {
    if(password !== password_check) {
      message.error("Пароли не совпадают");
      return;
    }
    apiClient.changeUserPassword(password)
      .then(() => {
        form.setFieldsValue({password: '', password_check: ''});
        message.success("Пароль изменен");
      })
      .catch(err => {
        message.error("Ошибка");
      });
  };

  const protectedResourcesCols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Имя ресурса',
      dataIndex: 'title',
    },
    {
      title: 'Пароль установлен',
      dataIndex: 'has_password',
      render: (text, record, index) => {
        return record.has_password ? "Да" : "Нет";
      },
    },
    {
      title: 'Дата обновления',
      dataIndex: 'update_dt_display',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const setPassword = (password) => {
    setPasswordChanging(true);
    setPasswordDialogVisible(false);
    apiClient.updateResourceAccess(selectedRowKeys, password)
      .then(res => {
        message.success("Пароль установлен");
        apiClient.getProtectedResources()
          .then(res => {
            setProtectedResources(res.resources);
          });
      })
      .catch(err => {
        message.error("Операция не выполнена");
      })
      .finally(() => {
        setPasswordChanging(false);
      });

  };

  const resetPassword = () => {
    setPasswordResetting(true);
    setPasswordDialogVisible(false);
    apiClient.updateResourceAccess(selectedRowKeys, "")
      .then(res => {
        message.success("Пароль очищен");
        apiClient.getProtectedResources()
          .then(res => {
            setProtectedResources(res.resources);
          });
      })
      .catch(err => {
        message.error("Операция не выполнена");
      })
      .finally(() => {
        setPasswordResetting(false);
      });

  };

  const onTabChange = (key) => {
    setSelectedTab(key);
    if(key === "2") {
      apiClient.getProtectedResources()
        .then(res => {
          setProtectedResources(res.resources);
        });
    }
  };

  const restricted = (permission, component) => {
    if(!profile) {
      return null;
    }
    return (profile.is_superuser || (profile.group && profile.group.id === permission))
      ? component
      : null;
  };

  return (
    <React.Fragment>
      <MenuButton value = "Профиль" statusBtn={selectedTab === "1"} key="1" onChange={onTabChange} onClick={() => setSelectedTab("1")}/>
      {restricted("reports-module", (
        <MenuButton value = "Настройка доступа к отчетам" statusBtn={selectedTab === "2"} key="2" onChange={onTabChange} onClick={() => setSelectedTab("2")}/>
      ))}
      {/* <MenuButton value = "Оповещения" statusBtn={selectedTab === "3"} key="3" onChange={onTabChange} onClick={() => setSelectedTab("3")}/> */}
      {/* <MenuButton value = "Уведомления" statusBtn={selectedTab === "4"} key="4" onChange={onTabChange} onClick={() => setSelectedTab("4")}/> */}
      {selectedTab === "1" && (
        <Card style={{margin: '0 0 20px 0', width: 400}} title="Сменить пароль">
        <Form
          form={form}
          name="normal_login"
          className="change-password-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Укажите пароль',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon"/>}
              type="password"
              placeholder="Пароль"
            />
          </Form.Item>

          <Form.Item
            name="password_check"
            rules={[
              {
                required: true,
                message: 'Укажите пароль еще раз',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon"/>}
              type="password"
              placeholder="Повторите пароль"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary " htmlType="submit" className="auth-form-button">
              Сменить
            </Button>
          </Form.Item>

        </Form>
      </Card>
      )}
      {selectedTab === "2" && (
        <div>
          <div>
            <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
              <Button type="primary" loading={isPasswordChanging} onClick={() => {setPasswordDialogVisible(true);} } disabled={selectedRowKeys.length === 0}>
                Задать пароль
              </Button>
              <Button type="primary" loading={isPasswordResetting} onClick={resetPassword} disabled={selectedRowKeys.length === 0}>
                Очистить пароль
              </Button>
            </Space>
            <span style={{ marginLeft: 8 }}>
              <span style={{float:"right"}}>
                {selectedRowKeys.length > 0 ? `Выбрано ${selectedRowKeys.length} ресурсов` : ''}
              </span>
            </span>
          </div>
          <Table rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }} scroll={{x: 1000}} sticky columns={protectedResourcesCols} dataSource={protectedResources} rowKey="id"/>
        </div>
      )}
      {/*{selectedTab === "3" && (*/}
      {/*  <div>*/}
      {/*      <Space style={{marginBottom: 16}} direction="vertical">*/}
      {/*        <Checkbox>*/}
      {/*          Сообщать о приближающемся ТО*/}
      {/*        </Checkbox>*/}
      {/*        <Checkbox>*/}
      {/*          Сообщать о приближении окончания действия ОСАГО*/}
      {/*        </Checkbox>*/}
      {/*        <Checkbox>*/}
      {/*          Сообщать о приближении окончания действия диагностических карт*/}
      {/*        </Checkbox>*/}
      {/*        <Checkbox>*/}
      {/*          Сообщать о приближении времени обслуживания ТС*/}
      {/*        </Checkbox>*/}
      {/*      </Space>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/* {selectedTab === "4" && (
        <CreateNotificationPage active={selectedTab === "4"}/>
      )} */}
      <PasswordInputDialog visible={isPasswordDialogVisible}
        title="Укажите пароль"
        label="Пароль для открытия страницы"
        onOk={setPassword}
        onCancel={() => { setPasswordDialogVisible(false
        ); }}/>
    </React.Fragment>
  );

}
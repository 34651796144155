import React, {useEffect, useState} from "react";
import {AutoComplete, Checkbox, Col, DatePicker, Input, message, Modal, Row} from "antd";
import moment from "moment";
import {daDataService} from "../../../../services/DaDataService";

const rowStyle = {padding: '8px'};

export default function OsgopPolicyEditDialog({policy, onOk, onCancel}) {

  let [record, setRecord] = useState({});
  let [suggestions, setSuggestions] = useState({});

  useEffect(() => {
    setRecord(policy);
  }, [policy]);
  return (
    <Modal
      destroyOnClose={true}
      width="1024px"
      title={"Полис ОСГОП"}
      visible={true}
      onOk={() => {
        if(!record.company_title) {
          message.warn("Заполните поле Страховщик");
          return;
        }
        if(!record.policy_number) {
          message.warn("Заполните поле Серия/номер полиса");
          return;
        }
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Страховщик</Col>
        <Col md={18}>
          <Input
            allowClear={true}
            placeholder={"Наименование страховщика"}
            value={record.company_title}
            onChange={(e) => { setRecord({...record, company_title: e.target.value}) }}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Адрес страховщика</Col>
        <Col md={18}>
          <AutoComplete
            allowClear={true}
            placeholder="Адрес страховщика"
            options={suggestions.company_address || []}
            value={record?.company_address}
            style={{width: "100%"}}
            onChange={(data) => {
              setRecord({...record, company_address: data});
              setSuggestions(prevState => { return {...prevState, company_address: []} })
            }}
            onSearch={(text) => {
              if(text && text.length > 1 && !text.endsWith(" ")) {
                daDataService.suggestAddress(text)
                  .then(res => {
                    setSuggestions(prevState => { return {...prevState, company_address: res.suggestions.map(el => {return {value: el.value}})} })
                  })
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Серия/номер полиса</Col>
        <Col md={6}>
          <Input
            allowClear={true}
            placeholder={"Серия/номер полиса"}
            value={record.policy_number}
            onChange={(e) => { setRecord({...record, policy_number: e.target.value}) }}/>        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Дата выдачи</Col>
        <Col md={6}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.policy_issue_dt ? moment(record.policy_issue_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, policy_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: 220}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Срок действия</Col>
        <Col md={6}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата выдачи"
            value={record.policy_due_dt ? moment(record.policy_due_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, policy_due_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: 220}}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col md={6}>Действующий</Col>
        <Col md={6}>
          <Checkbox
            checked={record.is_current}
            onChange={(e) => {
              setRecord({...record, is_current: e.target.checked });
            }}
          />
        </Col>
      </Row>

    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {Col, message, Modal, Row, Select} from "antd";

const { Option } = Select;

const rowStyle = {padding: '4px'};

export default function DriverParkChangeDialog({record, onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [taxiParks, setTaxiParks] = useState([]);
  let [selectedPark, setSelectedPark] = useState(null);
  let [workRules, setWorkRules] = useState([]);
  let [workRulesLoading, setWorkRulesLoading] = useState(false);
  let [selectedWorkRule, setSelectedWorkRule] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient.getParks()
      .then(res => { setTaxiParks(res.records); })
      .catch(err => { message.error(err); })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      title={"Перенос водителя"}
      visible={true}
      confirmLoading={loading}
      onOk={() => {
        onOK(selectedPark, selectedWorkRule)
      }}
      onCancel={onCancel}>
      <>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col md={6}>Диспетчерская</Col>
          <Col md={18}>
            <Select
              showSearch
              allowClear
              value={selectedPark}
              style={{width: "100%"}}
              onChange={(value) => {
                let park = taxiParks.find(el => el.id === value);
                if(park) {
                  setSelectedWorkRule(null);
                  setWorkRulesLoading(true);
                  apiClient.getParkWorkRules(park.client_id, park.api_key, park.park_id)
                    .then(res => {
                      setWorkRules(res.records.filter(el => park.work_rules.find(pwr => pwr === el.id)));
                    })
                    .catch(err => { message.error(err); })
                    .finally(() => { setWorkRulesLoading(false) });
                }
                setSelectedPark(value);
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                taxiParks.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col md={6}>Условия работы</Col>
          <Col md={18}>
            <Select
              showSearch
              allowClear
              loading={workRulesLoading}
              value={selectedWorkRule}
              style={{width: "100%"}}
              onChange={(value) => {
                setSelectedWorkRule(value)
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                workRules.map(el => <Option key={el.id} value={el.id}>{el.name}</Option>)
              }
            </Select>
          </Col>
        </Row>
      </>
    </Modal>
  )
}
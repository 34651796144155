import React, {useEffect, useState} from "react";
import {message, Modal, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";

const { Option } = Select;

export default function PayerSelectionDialog({onOK, onCancel}) {

  let [payers, setPayers] = useState([]);
  let [exportSelectedPayerId, setExportSelectedPayerId] = useState(null);

  useEffect(() => {
    apiClient.getFinesPayers()
      .then(res => {
        setPayers(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  return (
    <>
      <Modal
        destroyOnClose={true}
        title={"Выбор плательщика"}
        visible={true}
        width={800}
        onOk={() => { onOK(exportSelectedPayerId) }}
        onCancel={onCancel}
      >
        <>
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            placeholder="..."
            onChange={(value) => {
              setExportSelectedPayerId(value);
            }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {payers.map(s => <Option key={s.id} value={s.id}>{`${s.full_title}, ИНН ${s.inn}`}</Option>)}
          </Select>
        </>
      </Modal>

    </>
  )
}
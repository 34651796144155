import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, DatePicker, Dropdown, Menu, message, PageHeader, Select, Space, Table} from "antd";

import './ModelDowntimeReport.css'
import './DowntimeReport.css'
import {ModelDowntimeCriticalLevels, ParkStatuses} from "../../../common/AppEnums";
import TypeDowntimeDetailsDialog from "./TypeDowntimeDetailsDialog";
import numeral from "numeral"
import moment from "moment/moment";
import {DownOutlined, FileExcelOutlined} from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CMD_EXPORT = "export"

export default function ModelDowntimeReport({update}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [regions, setRegions] = useState([]);
  let [modelOptions, setModelOptions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState([]);
  let [selectedModels, setSelectedModels] = useState(null);
  let [reportStartDate, setReportStartDate] = useState(null);
  let [reportEndDate, setReportEndDate] = useState(null);
  let [vehicleStatusFilter, setVehicleStatusFilter] = useState([]);
  let [summary, setSummary] = useState({});
  let [vehiclesTotalCount, setVehiclesTotalCount] = useState(0);
  let [detailsRecords, setDetailsRecords] = useState(null);
  let [alertLevels, setAlertLevels] = useState({});
  let [modelDowntimeLevels, setModelDowntimeLevels] = useState({});

  useEffect(() => {
    setLoading(true);
    const params = {
      "status": vehicleStatusFilter,
      "region": selectedRegionId,
      "group": selectedGroupId,
      "models": selectedModels,
      "from_dt": reportStartDate,
      "to_dt": reportEndDate,
    };
    if(isHistoryView()) {
      apiClient.getServiceDowntimeModelStatusHistory(params)
        .then(res => {
          setRecords(res.records);
          setSummary(res.summary);
          setVehiclesTotalCount(res.total_vehicles);
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); })
    } else {
      apiClient.getServiceDowntimeModelStatus(params)
        .then(res => {
          setRecords(res.records);
          setSummary(res.summary);
          setVehiclesTotalCount(res.total_vehicles);
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false); })
    }
  }, [
    update,
    vehicleStatusFilter,
    selectedRegionId,
    selectedGroupId,
    selectedModels,
    reportStartDate,
    reportEndDate,
  ]);

  useEffect(() => {
    apiClient.getSystemProperty("parkmanager.downtime_status_alerts.*")
      .then(res => {
        if(res.values) {
          console.log(res.values);
          setAlertLevels(res.values);
        }
      })
      .catch(err => { message.error(err); });
    apiClient.getSystemProperty("parkmanager.model_downtime_levels.*")
      .then(res => {
        if(res.values) {
          setModelDowntimeLevels(res.values);
        }
      })
      .catch(err => { message.error(err); });
  }, [update]);

  useEffect(() => {
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehiclesFilters(["regions", "brands"])
      .then(res => {
        setModelOptions(res.brands);
        setRegions(res.regions);
      })
      .catch(err => message.error(err));
  }, []);

  const executeSelectedRecordsOperation = key => {
    if(key === CMD_EXPORT) {
      const params = {
        "status": vehicleStatusFilter,
        "region": selectedRegionId,
        "group": selectedGroupId,
        "models": selectedModels,
        "from_dt": reportStartDate,
        "to_dt": reportEndDate,
        "export": true
      };
      if(isHistoryView()) {
        apiClient.getServiceDowntimeModelStatusHistory(params)
          .then(res => {
            window.open(res.link);
          })
          .catch(err => message.error(err));
      } else {
        apiClient.getServiceDowntimeModelStatus(params)
          .then(res => {
            window.open(res.link);
          })
          .catch(err => message.error(err));
      }
    }
  };

  const isHistoryView = () => {
    return reportStartDate && reportEndDate;
  }

  const showRowDetails = (model, status) => {
    const params = {
      "status": status === 'all' ? vehicleStatusFilter : status,
      "region": selectedRegionId,
      "group": selectedGroupId,
      "models": selectedModels,
    };
    apiClient.getServiceDowntimeModelStatusDetails(model, params)
      .then(res => {
        setDetailsRecords(res.records);
      })
      .catch(err => { message.error(err) });
  };

  const modelTitleCellClass = (model) => {
    let modelRecord = records.find((record) => record.brand_model === model);
    if(!modelRecord) {
      return "";
    }

    let criticalLevel = ModelDowntimeCriticalLevels.find(el => modelRecord.count >= el.min && modelRecord.count < el.max);
    if(!criticalLevel || !modelDowntimeLevels[`parkmanager.model_downtime_levels.${criticalLevel.level}`]) {
      return "";
    }

    let levelValue = numeral(modelDowntimeLevels[`parkmanager.model_downtime_levels.${criticalLevel.level}`]);
    let currentLevel = parseFloat(modelRecord.parked?.rate ?? 0) + parseFloat(modelRecord.licenseless?.rate ?? 0)
    if(currentLevel < levelValue.value()) {
      return "";
    }

    return `model-level-critical model-level-${criticalLevel.level}`;
  }

  /**
   * @param {({brand_model: string, count: number})} record
   * @param {string} status
   * @param {({count: number, rate: string})} value
   * @returns string
   */
  const dataCellColor = (record, status, value) => {
    if(value.count < 1) {
      return '#9c9c9c';
    }
    if(isHistoryView()) {
      return '#3f3d56';
    }
    return '#58d3a5';
  }

  /**
   *
   * @param {({brand_model: string, count: number})} record
   * @param {string} status
   * @param {({count: number, rate: string})} value
   * @returns {JSX.Element}
   */
  const dataCell = (record, status, value) => {
    if(!value) {
      value = {count: 0, rate: '0'}
    }
    const rate = numeral(value.rate);
    const isTotalCol = status === 'total'
    const hasDetails = value.count > 0 && !isTotalCol && !isHistoryView();
    let style = {
      textAlign: 'center',
      fontSize: '0.9em',
      color: dataCellColor(record, status, value),
      cursor: hasDetails ? 'pointer' : 'auto'
    };
    if(alertLevels && alertLevels[`parkmanager.downtime_status_alerts.${status}`]) {
      const alertLevel = numeral(alertLevels[`parkmanager.downtime_status_alerts.${status}`]);
      if(rate.value() > alertLevel.value()) {
        style.color = '#972f2f';
      }
    }
    return (
      <div
        style={style}
        onClick={() => {
          if(hasDetails) {
            showRowDetails(record.brand_model, status)
          }
        }}
      >
        {rate.format('0.00')}%
        <br/>
        {value.count > 0 ? <span>({value.count})</span> : null}
      </div>
    )
  };

  const summaryCell = (value) => {
    if(!value) {
      value = {count: 0, rate: '0'}
    }
    const rate = numeral(value.rate);
    let style = {textAlign: 'center', fontSize: '0.9em'};
    return (
      <div style={style}>
        {rate.format('0.00')}%
        <br/>
        <span>({value.count})</span>
      </div>
    )
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Простои по моделям ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Моделей ТС: ${records.length}`}</span>
              <Dropdown
                overlay={(
                  <Menu onClick={(e) => {
                    executeSelectedRecordsOperation(e.key);
                  }}>
                    <Menu.Item key={CMD_EXPORT} icon={<FileExcelOutlined/>}>
                      Выгрузка в Excel
                    </Menu.Item>
                  </Menu>
                )}>
                <Button style={{textAlign: "left"}}>
                  Действия <DownOutlined/>
                </Button>
              </Dropdown>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          allowClear
          mode="multiple"
          placeholder="Статус"
          value={vehicleStatusFilter}
          style={{width: 300}}
          onChange={(data) => { setVehicleStatusFilter(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            ParkStatuses
              .filter(el => el.enabled)
              .map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Филиал"
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          mode={"multiple"}
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Модель ТС"
          style={{width: 300}}
          onChange={(data) => { setSelectedModels(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            modelOptions.map(el => <Option key={el} value={el}>{`${el}`}</Option>)
          }
        </Select>
      </Space>
      <Space style={{margin: '10px 0'}}>
        <RangePicker
          format="DD.MM.YYYY"
          disabledDate={(dt) => {
            return dt && dt > moment().startOf('day');
          }}
          style={{width: 350}}
          onChange={(dates, dateStrings) => {
            if(dates) {
              setReportStartDate(dates[0].format('YYYY-MM-DD'));
              setReportEndDate(dates[1].format('YYYY-MM-DD'));
            } else {
              setReportStartDate(null);
              setReportEndDate(null);
            }
          }}
        />
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1300}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
            fixed: 'left',
          },
          {
            title: 'Модель ТС',
            dataIndex: 'brand_model',
            align: "center",
            render: (text, record, index) => {
              return {
                props: {
                  className: modelTitleCellClass(record.brand_model),
                },
                children:  record.brand_model,
              };
            },
          },
          {
            title: 'Всего',
            dataIndex: 'count',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record, 'all', {count: record.count, rate: '100'});
            },
          },
          ...ParkStatuses
            .filter(el => el.enabled && (vehicleStatusFilter.length < 1 || vehicleStatusFilter.includes(el.id)))
            .map((el) => {
              return {
                title: el.title,
                dataIndex: el.id,
                align: "center",
                render: (text, record, index) => {
                  return dataCell(record, el.id, record[el.id]);
                },
              }
            }),
          {
            title: 'Простой от общего кол-ва ТС',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return summaryCell(record.full_total);
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
            fixed: 'right',
          }
        ]}
        dataSource={records}
        rowKey="brand_model"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let i = 0;
          let cells = [
            <Table.Summary.Cell index={i++}></Table.Summary.Cell>,
            <Table.Summary.Cell index={i++}></Table.Summary.Cell>,
            <Table.Summary.Cell index={i++}>
              <div style={{textAlign: 'center', fontSize: '0.9em'}}>{vehiclesTotalCount}</div>
            </Table.Summary.Cell>
          ];
          cells.push(
            ...ParkStatuses
              .filter(el => el.enabled && (vehicleStatusFilter.length < 1 || vehicleStatusFilter.includes(el.id)))
              .map((el) => {
                return <Table.Summary.Cell index={i++}>{summaryCell(summary[el.id])}</Table.Summary.Cell>
              })
          );
          cells.push(...[
            <Table.Summary.Cell index={i++}>
              {
                summaryCell({
                  count: vehiclesTotalCount - parseInt(summary?.working?.count ?? 0),
                  rate: 100 - parseFloat(summary?.working?.rate ?? 0),
                })
              }
            </Table.Summary.Cell>
          ]);
          return loading ? null : (
            <>
              <Table.Summary.Row>
                {cells}
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        detailsRecords && (
          <TypeDowntimeDetailsDialog data={detailsRecords} onClose={() => setDetailsRecords(null)} />
        )
      }
    </>
  )
}
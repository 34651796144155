import React, {useEffect, useState} from "react";
import {Button, Input, List, Space, Popconfirm, message, Tabs, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";

import './InspectionsBotTab.css'
import {apiClient} from "../../../../services/ApiClient";
import InspectionStepsEditor from "./inspection/InspectionStepsEditor";

const {Search} = Input;
const { TabPane } = Tabs;

export default function InspectionsBotTab() {
  let [users, setUsers] = useState([]);
  let [userSearch, setUserSearch] = useState('');
  let [userName, setUserName] = useState('');
  let [userLoading, setUserLoading] = useState(false);
  let [activeTabKey, setActiveTabKey] = useState("1");

  const addUser = () => {
    if(!userSearch || !userName) {
      message.warn("Укажите ID пользователя и имя");
      return;
    }

    setUserLoading(true);
    apiClient.addInspectionControlUser({uid: userSearch, label: userName})
      .then(res => {
        setUserSearch('');
        setUserName('');
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => { setUserLoading(false); })
  };

  const removeUser = item => {
    setUserLoading(true);
    apiClient.removeInspectionControlUser(item.id)
      .then(res => {
        setUsers(prev => prev.filter(p => p.id !== item.id));
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => { setUserLoading(false); });
  };

  useEffect(() => {
    setUserLoading(true);
    apiClient.getInspectionControlUsers({search: userSearch})
      .then(res => {
        setUsers(res.records);
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => { setUserLoading(false); })
  }, [userSearch]);

  const drawUserItem = item => {
    return (
      <div className="list-item">
        <div className="label">
          <div>{item.uid}</div>
        </div>
        <div className="label">
          <div>{item.label}</div>
        </div>
        <Popconfirm
          title="Удалить пользователя из базы?"
          onConfirm={(e) => { removeUser(item) }}
          onCancel={(e) => {}}
          okText="Да"
          cancelText="Нет"
        >
          <a href="#"><DeleteOutlined/></a>
        </Popconfirm>
      </div>
    );
  };

  const onTabChange = async (key) => {
    setActiveTabKey(key);
  };

  return (
    <Tabs onChange={onTabChange} tabPosition={"left"}>
      <TabPane tab="Доступ" key="1">
        <div style={{margin: '10px 0'}}>
          <Space direction="horizontal" size="middle" align="start">
            <List
              loading={userLoading}
              size="small"
              header={
                <div style={{textAlign: 'center'}}>
                  <h2>
                    Пользователи
                    <Tooltip
                      placement="top"
                      title='База данных пользователей, у которых есть доступ к боту-телеграмм "механик".'
                    >
                      <QuestionCircleOutlined style={{margin: "5px"}}/>
                    </Tooltip>
                  </h2>
                  
                  <Space direction="horizontal" align={"center"}>
                    <Search
                      allowClear
                      placeholder="ID"
                      value={userSearch}
                      onSearch={(e) => { if(!e) {setUserSearch(e)} }}
                      onChange={(e) => { setUserSearch(e.target.value) }}
                      style={{width: '100%'}}
                    />
                    <Input placeholder={"Имя"}
                           value={userName}
                           allowClear={true}
                           onChange={(e) => {setUserName(e.target.value)}}/>
                    <Button icon={<PlusCircleOutlined/>} type={"link"} onClick={() => addUser()}/>
                  </Space>
                </div>
              }
              bordered
              dataSource={users}
              renderItem={item => drawUserItem(item)}
              locale={{emptyText: 'Нет записей'}}
              style={{width: 500}}
              pagination={{
                size: "small",
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true
              }}
            />
          </Space>
        </div>
      </TabPane>
      <TabPane tab="Выдача ТС" key="2">
        <InspectionStepsEditor active={activeTabKey === "2"} inspection={"issue"}/>
      </TabPane>
      <TabPane tab="Возврат ТС" key="3">
        <InspectionStepsEditor active={activeTabKey === "3"} inspection={"return"}/>
      </TabPane>
      <TabPane tab="Осмотр ТС" key="4">
        <InspectionStepsEditor active={activeTabKey === "4"} inspection={"review"}/>
      </TabPane>
      <TabPane tab="ТО" key="5">
        <InspectionStepsEditor active={activeTabKey === "5"} inspection={"check"}/>
      </TabPane>
    </Tabs>
  );
}
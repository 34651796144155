import React, {useEffect, useState} from "react";
import {Col, InputNumber, message, Modal, Row, Spin} from "antd";
import {apiClient} from "../../../../services/ApiClient";

const rowStyle = {padding: '8px'};

export default function ServiceMaintenanceStatusSettingsDialog({onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [serviceInterval, setServiceInterval] = useState("");
  let [overrunInterval, setOverrunInterval] = useState("");

  useEffect(() => {
    setLoading(true);
    apiClient.getSystemProperty("parkmanager.maintenance.*")
      .then(res => {
        if(res.values) {
          if(res.values["parkmanager.maintenance.service_interval"]) {
            setServiceInterval(res.values["parkmanager.maintenance.service_interval"]);
          }
          if(res.values["parkmanager.maintenance.overrun_interval"]) {
            setOverrunInterval(res.values["parkmanager.maintenance.overrun_interval"]);
          }
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Настройки"}
      visible={true}
      mask={loading}
      onOk={() => {
        const properties = {
          'parkmanager.maintenance.service_interval': serviceInterval,
          'parkmanager.maintenance.overrun_interval': overrunInterval,
        };
        setLoading(true);
        apiClient.setSystemProperties(properties)
          .then(res => {
            message.info("Изменения сохранены");
            onOK();
          })
          .catch(err => {
            message.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      onCancel={onCancel}>

      <div>
        <div hidden={!loading} style={{textAlign: 'center'}}>
          <Spin />
        </div>
        <div hidden={loading}>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={8}>Интервал тех.обслуживания для новых ТС</Col>
            <Col md={16}>
              <InputNumber
                min={0}
                style={{width: 200}}
                value={serviceInterval}
                onChange={(value) => { setServiceInterval(value) }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={8}>Интервал перепробега (в сутки)</Col>
            <Col md={16}>
              <InputNumber
                style={{width: 200}}
                min={0}
                value={overrunInterval}
                onChange={(value) => { setOverrunInterval(value) }}
              />
            </Col>
          </Row>
        </div>
      </div>

    </Modal>
  );

}
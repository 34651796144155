import React, {useEffect, useState} from "react";
import {Col, Input, message, Modal, Row} from "antd";

const { TextArea } = Input;

const emptyMenuItem = {
  id: 0,
  readOnlyUrl: "",
  editableUrl: "",
  title: ""
};

export default function ReportsMenuEditDialog({title, visible, onOk, onCancel, menuItem, className}) {
  let [item, setItem] = useState(emptyMenuItem);

  useEffect(() => {
    if(menuItem) {
      setItem(r => {
        return {
          ...r,
          ...menuItem
        }
      });
    } else {
      setItem(emptyMenuItem);
    }
  }, [menuItem]);

  return (
    <Modal
      className={className}
      destroyOnClose={true}
      style={{top: 10}}
      title={title || "Отчет"}
      visible={visible}
      width={1000}
      onOk={() => {
        if(!item.title) {
          message.warn("Укажите наименование");
          return;
        }
        if(item.parentId && !item.readOnlyUrl) {
          message.warn("Укажите адрес документа в режиме чтения");
          return;
        }
        onOk(item);
        setItem(emptyMenuItem);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 8]}>
        <Col span={6}>Наименование отчета</Col>
          <Col md={18}>
          <Input
            allowClear={true}
            placeholder="Наименование"
            value={item.title}
            onChange={(e) => { setItem({...item, title: e.target.value}) }}/>
        </Col>
      </Row>

      {
        item.parentId ? (
          <>
            <Row gutter={[0, 8]}>
              <Col span={6}>URL режима чтения</Col>
              <Col md={18}>
                <TextArea
                  allowClear={true}
                  placeholder="URL режима чтения"
                  value={item.readOnlyUrl}
                  rows={2}
                  onChange={(e) => { setItem({...item, readOnlyUrl: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={6}>URL режима редактирования</Col>
              <Col md={18}>
                <TextArea
                  allowClear={true}
                  placeholder="URL режима редактирования"
                  value={item.editableUrl}
                  rows={2}
                  onChange={(e) => { setItem({...item, editableUrl: e.target.value}) }}/>
              </Col>
            </Row>
          </>
        ) : null
      }

    </Modal>
  )
}
import React, {useEffect, useState} from "react";
import {DatePicker, Input, message, PageHeader, Radio, Select, Space} from "antd";
import {apiClient} from "../../../services/ApiClient";
import ControlTab from "./components/ControlTab";
import InspectionTab from "./components/InspectionTab";
import MenuButton from "../../buttons/MenuButton";

const {Search} = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function DriversPhotoControlPage() {
  let [search, setSearch] = useState('');
  let [filterByDriver, setFilterByDriver] = useState(null);
  let [filterByRegion, setFilterByRegion] = useState(null);
  let [filterByCreateDate, setFilterByCreateDate] = useState({});
  let [drivers, setDrivers] = useState([]);
  let [regions, setRegions] = useState([]);
  let [status, setStatus] = useState("check");
  let [selectedTab, setSelectedTab] = useState("1");
  let [summary, setSummary] = useState(null);

  useEffect(() => {
    apiClient.searchDirectoryRecord('taxiDriver')
      .then(res => {
        setDrivers(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  useEffect(() => {
    apiClient.getPhotoControlSummary(status)
      .then(res => {
        setSummary(res.summary);
      })
      .catch(err => message.error(err));
  }, [status]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Фотоконтроль: Водители"
      />

      <div style={{marginBottom: 40, marginLeft: 10, marginRight: 10}}>
        <div style={{width: '90%', float: 'left'}}>
          <Space direction="horizontal" wrap>
            <Search
              enterButton
              allowClear
              placeholder="Поиск по гос.номеру и сотруднику"
              onSearch={(value) => setSearch(value)}
              style={{width: 350}}
            />
            <Radio.Group value={status} onChange={(e) => { setStatus(e.target.value) }}>
              <Radio.Button value="complete">Фотоконтроль</Radio.Button>
              <Radio.Button value="check">Ожидают проверки</Radio.Button>
            </Radio.Group>
            <Select
              showSearch
              allowClear
              style={{ width: 420 }}
              placeholder="Водитель"
              onChange={(value) => { setFilterByDriver(value) }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {drivers.map(s => <Option key={s.id} value={s.id}>{`${s.last_name} ${s.first_name} ${s.middle_name} (${s.phone})`}</Option>)}
            </Select>
            <Select
              allowClear
              mode="multiple"
              placeholder="Филиал"
              style={{width: 300}}
              onChange={(data) => { setFilterByRegion(data); }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
            <RangePicker
              placeholder={["Дата от", "Дата до"]}
              allowClear={true}
              style={{width: 350}}
              onChange={(dates, dateStrings) => {
                setFilterByCreateDate({from_dt: dateStrings[0], to_dt: dateStrings[1]});
              }}
            />
          </Space>
        </div>
      </div>

      <Space direction={"horizontal"}>
        <MenuButton value = "Удаленный" counter={summary?.control} statusBtn={selectedTab === "1"} onClick={() => setSelectedTab("1")} style={{width: 140}}/>
        <MenuButton value = "Выдача ТС" counter={summary?.inspection?.issue} statusBtn={selectedTab === "2"} onClick={() => setSelectedTab("2")}/>
        <MenuButton value = "Возврат ТС" counter={summary?.inspection?.return} statusBtn={selectedTab === "3"} onClick={() => setSelectedTab("3")}/>
        <MenuButton value = "Осмотр ТС" counter={summary?.inspection?.inspection} statusBtn={selectedTab === "4"} onClick={() => setSelectedTab("4")}/>
        <MenuButton value = "ТО" counter={summary?.inspection?.maintenance} statusBtn={selectedTab === "5"} onClick={() => setSelectedTab("5")}/>
        <MenuButton value = "Слесарный ремонт" counter={summary?.inspection?.repair} statusBtn={selectedTab === "6"} onClick={() => setSelectedTab("6")}/>
        <MenuButton value = "Кузовной ремонт" counter={summary?.inspection?.bodyRepair} statusBtn={selectedTab === "7"} onClick={() => setSelectedTab("7")}/>
      </Space>

      {selectedTab === "1" && (
        <ControlTab
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "1"}
        />
      )}
      {selectedTab === "2" && (
        <InspectionTab
          inspection={"vehicle_issue"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "2"}
        />
      )}
      {selectedTab === "3" && (
        <InspectionTab
          inspection={"vehicle_return"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "3"}
        />
      )}
      {selectedTab === "4" && (
        <InspectionTab
          inspection={"vehicle_inspection"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "4"}
        />
      )}
      {selectedTab === "5" && (
        <InspectionTab
          inspection={"vehicle_maintenance"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "5"}
        />
      )}
      {selectedTab === "6" && (
        <InspectionTab
          inspection={"vehicle_repair"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "6"}
        />
      )}
      {selectedTab === "7" && (
        <InspectionTab
          inspection={"vehicle_body_repair"}
          search={search}
          filterByDriver={filterByDriver}
          filterByRegion={filterByRegion}
          filterByCreateDate={filterByCreateDate}
          status={status}
          active={selectedTab === "7"}
        />
      )}
    </>
  );
}
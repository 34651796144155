import React, {useContext, useEffect, useState} from "react";
import {Button, Input, message, Modal, Space, Table} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import UpdateButton from "../../../../buttons/UpdateButton";
import TransactionGroupEditDialog from "./TransactionGroupEditDialog";
import UserProfileContext from "../../../../../services/UserProfileContext";

const {Search} = Input;

export default function TransactionGroupsSettingsTab({active}) {
  const userProfile = useContext(UserProfileContext);

  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Наименование',
      dataIndex: 'title',
    },
    {
      title: 'Тип платежа',
      dataIndex: 'transaction_type',
      render: (text, record, index) => {
        return [
          {id: "debit", title: "Списание"},
          {id: "credit", title: "Зачисление"},
        ].find(el => el.id === record.transaction_type)?.title || record.transaction_type;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const onSelectChange = selectedRowKeys => {
    setSelected(selectedRowKeys);
  };

  useEffect(() => {
    if(active) {
      setLoading(true);
      apiClient.getTransactionGroups({
        order: "title",
        search: search,
      })
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false); });
    } else {
      setRecords([]);
    }
  }, [active, search, update]);

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeTransactionGroups(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setUpdate(u => u + 1);
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAdd = (params) => {
    setLoading(true);
    apiClient.createTransactionGroup(params)
      .then(res => {
        message.success("Запись добавлена");
        setUpdate(u => u + 1);
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      })

  };

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '60%', float: 'left'}} >
          <Space direction={"horizontal"} wrap>
            <Search
              allowClear
              placeholder="Поиск"
              onSearch={(value) => setSearch(value)}
              style={{width: 400}}
              enterButton
            />
          </Space>
        </div>
        <div style={{width: '40%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
            {
              selected.length > 0 && (<span>{`Выбрано записей: ${selected.length}`}</span>)
            }
            <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
            <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>
              Добавить
            </Button>
            {
              userProfile.is_superuser && (
                <Button type="danger" loading={loading} onClick={onDeleteSelected} disabled={selected.length === 0}>
                  Удалить
                </Button>
              )
            }
          </Space>
        </div>
      </div>
      <Table
        pagination={
          {
            position: 'topRight',
            defaultPageSize: 50,
            pageSizeOptions: [25, 50, 75]
          }
        }
        rowSelection={{
          selectedRowKeys: selected,
          onChange: onSelectChange,
        }}
        size={'small'}
        columns={cols}
        scroll={{y: 600}}
        dataSource={records}
        rowKey="id"
      />

      {
        editedRecord !== null && (
          <TransactionGroupEditDialog
            visible={true}
            transactionGroup={editedRecord}
            onOk={(p) => {
              onAdd(p);
              setEditedRecord(null);
            }}
            onCancel={() => {
              setEditedRecord(null)
            }}
            title={"Группа платежа"}
          />
        )
      }
    </>
  )
}
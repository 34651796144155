import React from "react";
import {DatePicker, Form, Modal, message} from "antd";
import './EditFineTransactionExecutionDialog.css'

import moment from "moment";

export default function EditTransactionTemplateSuspendPeriodDialog({visible, onOK, onCancel}) {
  const [form] = Form.useForm();

  function draw() {
    return (
      <Modal
        className="edit-transaction-modal-style"
        title="Приостановить списание"
        visible={visible}
        okText={'Выполнить'}
        destroyOnClose={true}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onOK(values);
              form.resetFields();
            })
            .catch(info => {
              message.info("Проверьте корректность заполнения формы")
            });
        }}
        onCancel={() => onCancel()}
      >
        <Form
          form={form}
          layout="vertical"
        >

          <Form.Item
            label="Дата приостановки"
            name="suspend_dt"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: 'Укажите дату приостановки',
              },
            ]}
          >
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>

          <Form.Item
            label="Дата возобновления"
            name="resume_dt"
            initialValue={null}
          >
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>

        </Form>
      </Modal>
    );
  }

  return draw();
}
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, Image, message, Modal, Space, Table, Upload} from "antd";
import {DeleteOutlined, DownloadOutlined, EyeOutlined, QuestionOutlined, UploadOutlined} from '@ant-design/icons';
import moment from "moment";
import LinkButton from "../../../buttons/LinkButton";

export default function VehicleFiles({vehicle, active}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState([]);
  let [imageFiles, setImageFiles] = useState([]);
  let [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  useEffect(() => {
    if(vehicle && vehicle.id > 0 && active) {
      setLoading(true);
      apiClient.getVehicleFiles(vehicle.id)
        .then(res => {
          setRecords(res.records);

          const images = res.records.filter(el => el.content_type && el.content_type.startsWith("image/"));
          setImageFiles(images);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [vehicle, active, update]);

  const downloadFile = file => {
    window.open(file.url, '_blank');
  };

  const deleteFile = file => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить файл?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        setLoading(true);
        apiClient.removeVehicleFile(vehicle.id, file.id)
          .then(res => {
            setUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error(err))
          .finally(() => setLoading(false));
      }
    });
  };

  return (
    <>
      <div>
        <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
          {
            vehicle?.id > 0
              ? (
                <Upload
                  name={"file"}
                  action={apiClient.actionUrl(`park/vehicles/${vehicle.id}/files`)}
                  headers={apiClient.requestConfig().headers}
                  multiple={true}
                  showUploadList={false}
                  onChange={(info) => {
                    if (info.file.status !== 'uploading') {
                      console.log(info.file, info.fileList);
                      setLoading(true);
                    }
                    if (info.file.status === 'done') {
                      message.success(`Файл загружен`);
                      setUpdate(prevState => prevState + 1);
                      setLoading(false);
                    } else if (info.file.status === 'error') {
                      message.error(`Ошибка`);
                      setUpdate(prevState => prevState + 1);
                      setLoading(false);
                    }
                  }}
                >
                  <Button type="primary" icon={<UploadOutlined />}>Добавить</Button>
                </Upload>
              ) : null
          }
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: "",
            width: 100,
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  {
                    record.content_type && record.content_type.startsWith("image/")
                      ? (
                        <LinkButton onClick={() => { setImagePreviewVisible(true) }} label={<EyeOutlined/>}/>
                      ) : null
                  }
                  <LinkButton onClick={() => { downloadFile(record) }} label={<DownloadOutlined/>}/>
                  <LinkButton onClick={() => { deleteFile(record) }} label={<DeleteOutlined/>}/>
                </Space>
              )
            }
          },
          {
            title: 'Дата',
            render: (text, record, index) => {
              return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
            },
            defaultSortOrder: 'descend',
          },
          {
            title: 'Имя файла',
            render: (text, record, index) => {
              return record.filename;
            }
          },
          {
            title: 'Пользователь',
            render: (text, record, index) => {
              return record.created_by ? record.created_by : "Система";
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />
      {
        imagePreviewVisible
          ? (
            <Modal
              title="Изображения"
              width={'80%'}
              visible={true}
              destroyOnClose={true}
              onOk={() => setImagePreviewVisible(false)}
              onCancel={() => setImagePreviewVisible(false) }
            >
              <div style={{textAlign: 'center'}}>
                <Image.PreviewGroup>
                  {
                    imageFiles.map(photo => (
                      <div style={{padding: '10px 10px', display: 'inline-block'}} key={photo.id}>
                        <Image title={photo.filename} width={200} src={photo.url}/>
                      </div>
                    ))
                  }
                </Image.PreviewGroup>
              </div>
            </Modal>
          ) : null
      }
    </>
  )
}